import React from 'react';
import PropTypes from 'prop-types';
import { IconButton, Menu, MenuItem } from '@material-ui/core';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  iconRoot: {
    '&:hover, &:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .icon': {
        color: props => (props.listMode ? 'white' : 'white'),
        opacity: 1,
      },
    },
    '& .icon': {
      color: props => (props.listMode ? 'white' : 'white'),
      opacity: props => (props.listMode ? 1 : 1),
    },
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    borderRadius: 3,
    minWidth: 40,
  },
  gridPosition: {
    float: 'right',
    margin: '-.5rem -1rem 0 0',
  },
  listPosition: {
    padding: 0,
    marginRight: '20px',
  },
  menuPaper: {
    borderRadius: 1,
    width: '9rem',
    padding: 0,
  },
  button: {
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: 0,
    tabIndex: 0,
    color: 'black',
    fontFamily: 'inherit',
    padding: '0 0 0 1rem',
    height: '2rem',
    maxWidth: '250px',
    '&:hover': {
      backgroundColor: '#ececec',
    },
  },
}));

const IconMenu = ({
  actionMenu,
  gridMode,
  listMode,
  setOpen,
  stopPropagation,
  menuIconStyle,
  menuPaperOffset,
}) => {
  const classes = useStyles({ listMode });
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const menuRef = React.createRef();

  const handleClick = e => {
    setAnchorEl(e.currentTarget);
    if (setOpen) setOpen('open');
    menuRef.current.focus();
    if (stopPropagation) e.stopPropagation();
  };

  const handleClose = () => {
    setAnchorEl(null);
    if (setOpen) setOpen('close');
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        disableRipple
        onClick={handleClick}
        className={[
          menuIconStyle || classes.iconRoot,
          gridMode ? classes.gridPosition : null,
          listMode ? classes.listPosition : null,
        ].join(' ')}
      >
        <MoreHorizIcon className="icon" />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        MenuListProps={{
          onMouseLeave: handleClose,
          disablePadding: true,
        }}
        className={menuPaperOffset || ''}
        classes={{
          paper: classes.menuPaper,
        }}
        ref={menuRef}
      >
        {actionMenu.map(item => (
          <MenuItem
            onClose={handleClose}
            onClick={e => {
              item.handleClick();
              handleClose();
              if (stopPropagation) e.stopPropagation();
            }}
            className={classes.button}
          >
            {item.title}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

IconMenu.propTypes = {
  actionMenu: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      handleClick: PropTypes.func.isRequired,
    })
  ).isRequired,
  gridMode: PropTypes.bool,
  listMode: PropTypes.bool,
  stopPropagation: PropTypes.bool,
  setOpen: PropTypes.func,
  menuIconStyle: PropTypes.string,
  menuPaperOffset: PropTypes.string,
};

IconMenu.defaultProps = {
  gridMode: false,
  listMode: false,
  stopPropagation: false,
  setOpen: null,
  menuIconStyle: '',
  menuPaperOffset: '',
};

export default IconMenu;
