import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import Dropdown from 'react-dropdown';
import { DatePicker } from 'antd';
import 'react-dropdown/style.css';
import { getAvailableSlots } from 'ui-common/src/lib/utils';
import './SAPReserveSlot.scss';

const dat = [1, 2, 3, 4, 5, 6];
const options = [
  { value: 48, label: '48 hours' },
  { value: 24, label: '24 hours' },
  { value: 12, label: '12 hours' },
];
const SlotComponent = ({
  selectedTime,
  setSelectedTime,
  timeDuration,
  requireDisabledSlots,
  detailData,
  isIdRequestable,
  title,
  intl,
  reset,
  data,
  setResetFlag,
  onlyTime,
  editMode,
}) => {
  const [firstSlotSelected, setFirstSlotSelected] = useState(-1);
  const [secondSlotSelected, setsecondSlotSelected] = useState(-1);
  const [rangeEnabled, setRangeEnabled] = useState(false);
  const [currMousePosi, setCurrMousePosi] = useState(-1);
  const [slotItems, setSlotItems] = useState({
    value: 48,
    label: '48 hours',
  });

  const [totalSlots, setTotalSlots] = useState([]);
  const [startTime, setStartTime] = useState(
    onlyTime
      ? moment()
          .add(1, 'day')
          .set({ hours: 0, minutes: 0, seconds: 0 })
      : moment()
          .add(15, 'minutes')
          .set({ seconds: 0 })
  );
  const [disabledSlots, setDisabledSlots] = useState([]);

  const slotSelected = index => {
    if (firstSlotSelected === -1) {
      setFirstSlotSelected(index);
      setRangeEnabled(true);
    } else if (secondSlotSelected === -1) {
      setsecondSlotSelected(index);
      setRangeEnabled(false);

      let timeDiff = 0;
      let firstslot = -1;
      let secondslot = -1;

      if (firstSlotSelected > index) {
        firstslot = index;
        secondslot = firstSlotSelected;
      } else {
        firstslot = firstSlotSelected;
        secondslot = index;
      }
      timeDiff = (secondslot - firstslot + 1) * 0.5;
      setSelectedTime({
        startTime: startTime
          .clone()
          .add(firstslot * 0.5, 'hours')
          .format('MMM DD, YYYY h:mm a'),
        endTime: startTime
          .clone()
          .add(firstslot * 0.5 + timeDiff, 'hours')
          .format('MMM DD, YYYY h:mm a'),
        timeDiff,
      });
      if (setResetFlag) setResetFlag(false);
    } else {
      setsecondSlotSelected(-1);
      setFirstSlotSelected(index);
      setRangeEnabled(true);
    }
  };

  const isSlotAvailable = (slotTime, slotAvailabilityDetails) => {
    let isAvailable = true;
    for (let i = 0; i < slotAvailabilityDetails.length; i += 1) {
      const startDate = moment(moment.utc(slotAvailabilityDetails[i].startDate))
        .local()
        .format('YYYY-MM-DD HH:mm:ss');
      const endDate = moment(moment.utc(slotAvailabilityDetails[i].enddate))
        .local()
        .format('YYYY-MM-DD HH:mm:ss');
      if (
        slotTime.isBetween(
          moment(startDate, 'YYYY-MM-DD HH:mm:ss'),
          moment(endDate, 'YYYY-MM-DD HH:mm:ss')
        )
      ) {
        isAvailable = false;
        break;
      }
    }
    return isAvailable;
  };

  const setSlotsData = response => {
    const slotAvailabilityDetails = response;
    const det = Array((slotItems.value / 3) * dat.length).fill({});
    const disabledSlots = [];
    const mappedData = det.map((item, index) => {
      const slotTime = startTime.clone().add(index * 0.5, 'hours');
      const slotAvailable = slotAvailabilityDetails
        ? isSlotAvailable(slotTime, slotAvailabilityDetails)
        : true;
      if (!slotAvailable) disabledSlots.push(index);
      return { ...item, slotTime, slotAvailable };
    });
    const tot = totalSlots.length;

    setTotalSlots(mappedData);
    setDisabledSlots(disabledSlots);
    if (tot === 0) {
      if (timeDuration) {
        setSlotItems({ value: timeDuration, label: `${timeDuration} hours` });
      }
    }
  };

  const setSelectedSlots = () => {
    const alreadyselectedstart = moment(data.startTimeSlot, 'MMM DD, YYYY h:mm a');
    const alreadyselectedend = moment(data.endTimeSlot, 'MMM DD, YYYY h:mm a');
    const currstarttime = moment(startTime);

    if (alreadyselectedstart.isAfter(currstarttime)) {
      const duration = moment.duration(alreadyselectedstart.diff(currstarttime));
      const firstslot = duration.asMinutes() / 30;
      setFirstSlotSelected(firstslot);

      const durationend = moment.duration(alreadyselectedend.diff(currstarttime));
      const secondslot = durationend.asMinutes() / 30 - 1;
      setsecondSlotSelected(secondslot);
      const timeDiff = (secondslot - firstslot + 1) * 0.5;
      setSelectedTime({
        startTime: startTime
          .clone()
          .add(firstslot * 0.5, 'hours')
          .format('MMM DD, YYYY h:mm a'),
        endTime: startTime
          .clone()
          .add(firstslot * 0.5 + timeDiff, 'hours')
          .format('MMM DD, YYYY h:mm a'),
        timeDiff,
      });
    } else if (alreadyselectedend.isAfter(currstarttime)) {
      setFirstSlotSelected(0);
      const durationend = moment.duration(alreadyselectedend.diff(currstarttime));
      const secondslot = durationend.asMinutes() / 30;
      setsecondSlotSelected(secondslot);
    }
  };

  useEffect(() => {
    if (editMode) {
      setSelectedSlots();
    }
  }, []);

  useEffect(() => {
    if (requireDisabledSlots) {
      const slotAvailabilityPromise = getAvailableSlots(
        detailData,
        moment.utc(startTime).format('YYYY-MM-DD HH:mm:ss'),
        moment
          .utc(startTime)
          .clone()
          .add(48, 'hours')
          .format('YYYY-MM-DD HH:mm:ss')
      );

      slotAvailabilityPromise.then(response => setSlotsData(response));
    } else {
      setSlotsData(null);
    }

    // if (editMode) {
    //   setSelectedSlots();
    // }
  }, [startTime]);

  const resetSlotSelection = () => {
    setFirstSlotSelected(-1);
    setsecondSlotSelected(-1);
    setRangeEnabled(false);
    setSelectedTime({ startTime: '', endTime: '', timeDiff: '' });
  };

  useEffect(() => {
    if (reset === true) {
      resetSlotSelection();
    }
  }, [reset]);

  const isDisabledSlotPresent = index => {
    let first = 0;
    let second = 0;
    if (firstSlotSelected < index) {
      first = firstSlotSelected;
      second = index;
    } else {
      first = index;
      second = firstSlotSelected;
    }
    for (let i = second - 1; i > first; i -= 1) {
      if (disabledSlots.includes(i)) {
        return true;
      }
    }
    return false;
  };

  const det = Array(slotItems.value / 3).fill(4);
  let items = [];
  if (totalSlots.length > 0) {
    items = det.map((item, key) => {
      return (
        <div>
          <div className="d-flex slot-parent">
            {dat.map((itm, index) => {
              const itemIndex = key * dat.length + index;
              let selSlot = false;
              let applyBg = false;
              let finalselectSlot = false;
              if (totalSlots[itemIndex].slotAvailable) {
                if (firstSlotSelected === itemIndex || secondSlotSelected === itemIndex) {
                  selSlot = true;
                } else if (rangeEnabled && currMousePosi !== -1) {
                  if (
                    firstSlotSelected < currMousePosi &&
                    itemIndex > firstSlotSelected &&
                    itemIndex < currMousePosi &&
                    !isDisabledSlotPresent(itemIndex)
                  ) {
                    applyBg = true;
                  } else if (
                    firstSlotSelected > currMousePosi &&
                    itemIndex < firstSlotSelected &&
                    itemIndex > currMousePosi &&
                    !isDisabledSlotPresent(itemIndex)
                  ) {
                    applyBg = true;
                  }
                } else if (secondSlotSelected !== -1) {
                  if (
                    firstSlotSelected > secondSlotSelected &&
                    itemIndex < firstSlotSelected &&
                    itemIndex > secondSlotSelected
                  ) {
                    finalselectSlot = true;
                  } else if (
                    firstSlotSelected < secondSlotSelected &&
                    itemIndex > firstSlotSelected &&
                    itemIndex < secondSlotSelected
                  ) {
                    finalselectSlot = true;
                  }
                }
              }
              let slotClass = 'slot';
              if (!totalSlots[itemIndex].slotAvailable) {
                slotClass = 'slot-notavailable';
              } else if (selSlot) {
                slotClass = 'slot-selected';
              } else if (applyBg) {
                slotClass = 'slot-range-select';
              } else if (finalselectSlot) {
                slotClass = 'final-slot';
              }
              return (
                <div
                  role="button"
                  tabIndex={0}
                  className={slotClass}
                  onClick={() => {
                    if (totalSlots[itemIndex].slotAvailable) {
                      if (rangeEnabled) {
                        if (!isDisabledSlotPresent(itemIndex)) {
                          slotSelected(itemIndex);
                        }
                      } else {
                        slotSelected(itemIndex);
                      }
                    }
                  }}
                  onKeyPress={() => {
                    if (totalSlots[itemIndex].slotAvailable) {
                      if (rangeEnabled) {
                        if (!isDisabledSlotPresent(itemIndex)) {
                          slotSelected(itemIndex);
                        }
                      } else {
                        slotSelected(itemIndex);
                      }
                    }
                  }}
                  onFocus={() => {
                    if (rangeEnabled && totalSlots[itemIndex].slotAvailable) {
                      setCurrMousePosi(itemIndex);
                    }
                  }}
                  onMouseOver={() => {
                    if (rangeEnabled && totalSlots[itemIndex].slotAvailable) {
                      setCurrMousePosi(itemIndex);
                    }
                  }}
                  onMouseLeave={() => {
                    setCurrMousePosi(-1);
                  }}
                />
              );
            })}
          </div>
          <div className="slot-time-label">
            {startTime
              .clone()
              .add(key * 3, 'hours')
              .format('h:mm a')}
          </div>
        </div>
      );
    });
  }

  return (
    <div
      className="slot-selection"
      style={isIdRequestable ? { display: 'block' } : { display: 'block' }}
    >
      {title || (
        <div className="future-parent">
          <div className="see-future">
            {' '}
            {intl.formatMessage({
              id: 'seeSlotFuture',
            })}
          </div>
          <Dropdown
            id="hours_categories"
            options={options}
            onChange={e => {
              setSlotItems(e);
            }}
            aria-label="dropdown"
            value={slotItems}
          />
          <div className="slot-date-picker">
            <div className="datepicker-wrapper">
              <img
                className="calender-icon"
                src="/ECMv6/assets/images/CalendarCopy.svg"
                alt="calendar copy"
              />
              <div className="cal-area">
                {' '}
                <DatePicker
                  disabledDate={curr => {
                    if (!isIdRequestable && data && data.endDate) {
                      return (
                        curr >
                          moment(moment.utc(data.endDate))
                            .local()
                            .endOf('day') || curr < moment().startOf('day')
                      );
                    }
                    return curr < moment().startOf('day');
                  }}
                  format="MMM DD, YYYY"
                  size="small"
                  onChange={val => {
                    // resetSlotSelection();
                    if (!val.isSame(new Date(), 'day')) {
                      setSelectedTime({
                        ...selectedTime,
                        startTime: val.set({
                          hours: 0,
                          minutes: 0,
                          seconds: 0,
                        }),
                      });
                      setStartTime(
                        val.set({
                          hours: 0,
                          minutes: 0,
                          seconds: 0,
                        })
                      );
                    } else {
                      setSelectedTime({
                        ...selectedTime,
                        startTime: moment()
                          .add(15, 'minutes')
                          .set({ seconds: 0 }),
                      });
                      setStartTime(
                        moment()
                          .add(15, 'minutes')
                          .set({ seconds: 0 })
                      );
                    }
                  }}
                />{' '}
              </div>{' '}
              <div> </div>
            </div>
          </div>
        </div>
      )}
      <div className="d-flex slot-picker">{items}</div>
      <div className="slot-selectddate">
        {secondSlotSelected !== -1
          ? `${selectedTime.timeDiff.toFixed(2)} hours selected from 
  ${selectedTime.startTime} - ${selectedTime.endTime}`
          : ''}
      </div>
    </div>
  );
};
SlotComponent.defaultProps = {
  detailData: [],
  data: {},
};
SlotComponent.propTypes = {
  intl: PropTypes.arrayOf(
    PropTypes.shape({
      locale: PropTypes.string.isRequired,
      formatMessage: PropTypes.func.isRequired,
    })
  ).isRequired,
  detailData: PropTypes.arrayOf(
    PropTypes.shape({
      startDate: PropTypes.string,
      enddate: PropTypes.string,
    })
  ),
  data: PropTypes.shape({
    timeFrame: PropTypes.string,
    endDate: PropTypes.string,
    startTimeSlot: PropTypes.string,
    endTimeSlot: PropTypes.string,
  }),
  selectedTime: PropTypes.string.isRequired,
  setSelectedTime: PropTypes.string.isRequired,
  timeDuration: PropTypes.string.isRequired,
  requireDisabledSlots: PropTypes.string.isRequired,
  isIdRequestable: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  reset: PropTypes.string.isRequired,
  setResetFlag: PropTypes.string.isRequired,
  onlyTime: PropTypes.string.isRequired,
  editMode: PropTypes.string.isRequired,
};
export default injectIntl(withRouter(SlotComponent));
