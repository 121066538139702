import React, { useState, useEffect, useRef } from "react";
import { GridSearch } from '../../GridSearch';
import { Pagination } from '../../Pagination';
import styles from '../GridBoot.module.scss';
import { A } from '../../A';
import Drawer from '@material-ui/core/Drawer';
import { Icon } from '../../Icon';
import { injectIntl } from 'react-intl';
import { AdvancedFilter } from '../../AdvancedFilter';




const GridHeader = ({
  globalSearchText,
  reqBodyOptions,
  setGlobalSearchText,
  setShowAdvancedFilter,
  topOffset,
  disableAdvancedFilters,
  totalCount,
  setReqBodyOptions,
  filtersCount,
  intl,
  showFilterBar,
  appliedFilters,
  setAppliedFilters,
  setCheckedFilters,
  showAdvancedFilter,
  advancedFilterColumns,
  requestColCacheEnabled,
}) => {
  const filterPanelRef = useRef(null);


  const showGlobalText = globalSearchText => {
    if (!globalSearchText) return null;
    return (
      <li key={globalSearchText}>
        {globalSearchText}
        <A
          href="#"
          className={styles.remove_icon}
          onClick={e => {
            e.preventDefault();
            setGlobalSearchText(undefined);
          }}
        >
          <Icon name="remove" />
        </A>
      </li>
    );
  };

  const toggleDrawer = (side, open) => event => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    if (side === 'right' && open) {
      setShowAdvancedFilter(true);
    }
    else if (side === 'right' && !open)
      setShowAdvancedFilter(false);
  };

  const openAdvanceFilterCode = e => {
    if(e.keyCode === 13 && e.target.id === "advanceFilterShowBtn"){
      setShowAdvancedFilter(true);
    }
  }

  useEffect(() => {
   
    window.addEventListener('keydown', openAdvanceFilterCode)
    return () => window.removeEventListener('keydown', openAdvanceFilterCode)
  }, [setShowAdvancedFilter])


  return (
    <>
      <div className={`${styles.gridFilter}`} style={{ top: `${topOffset}px` }}>
        <div className={styles.gridFilterArea}>
          <div className="grid-filterBlk d-flex">
            <div className="grid_search">
              <GridSearch
                globalSearchText={globalSearchText}
                setGlobalSearchText={setGlobalSearchText}
              />
              {!disableAdvancedFilters && (
                <div
                  className="AdFilter-FullScreen"
                  tabIndex="1"
                  id="advanceFilterShowBtn"
                  href=""
                  onClick={() => {
                    // toggleDrawer('right', true);
                    setShowAdvancedFilter(true);
                  }}
                  role="presentation"
                >
                  <Icon name="advFilter" />
                </div>
              )}
            </div>
          </div>
          {!!totalCount && (
            <Pagination
              totalRecords={totalCount}
              recordsPerPage={reqBodyOptions.max}
              offset={reqBodyOptions.offset}
              reqBodyOptions={reqBodyOptions}
              setReqBodyOptions={setReqBodyOptions}
            />
          )}
        </div>
      </div>
      {filtersCount > 0 && (
        <div
          ref={filterPanelRef}
          className={`${styles.applied_filter_row}`}
          style={{
            top: `${topOffset}px`,
          }}
        >
          <div className={styles.filter_result}>
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label>{`${intl.formatMessage({ id: 'SaviyntGrid.Filter' })} ${filtersCount}`}</label>
            <ul className={styles.fl_applied_list}>
              {showFilterBar(appliedFilters)}
              {showGlobalText(globalSearchText)}
            </ul>
          </div>
          <div className={styles.fl_btn}>
            <A
              className={styles.clear}
              href="#"
              onClick={e => {
                e.preventDefault();
                setAppliedFilters({});
                setCheckedFilters({});
                setGlobalSearchText(undefined);
              }}
            >
              {intl.formatMessage({ id: 'SaviyntGrid.Clear' })}
            </A>
          </div>
        </div>
      )}

      <Drawer
        anchor="right"
        open={showAdvancedFilter}
        classes={{ modal: styles.advanceFilterModal }}
        onClose={() => setShowAdvancedFilter(false)}
      >
        <AdvancedFilter
          hideAdvanceFilter={() => setShowAdvancedFilter(false)}
          appliedFilters={appliedFilters}
          setAppliedFilters={setAppliedFilters}
          columns={advancedFilterColumns}
          requestBody={reqBodyOptions}
          requestColCacheEnabled={requestColCacheEnabled}
        />
      </Drawer>


    </>

  );
};

export default GridHeader;
