/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable no-use-before-define */
import React, { useState, useEffect, useRef } from 'react';
import useAutocomplete from '@material-ui/lab/useAutocomplete';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Chip from '@material-ui/core/Chip';
import TextField from '@material-ui/core/TextField';
import NoSsr from '@material-ui/core/NoSsr';
import './ScrollPaginationDropdown.scss';
import { useDebounce } from 'use-debounce';
import { IconButton } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import OptionsWrapper from './OptionsWrapper';

const useStyles = makeStyles(theme => ({
  label: {
    display: 'block',
    fontSize: '12px',
    color: '#9d9d9d',
  },
  input: {
    width: '100%',
    zIndex: 2,
    cursor: 'pointer',
  },
  listbox: {
    width: '100% !important',
    margin: 0,
    padding: 0,
    zIndex: 1,
    paddingLeft: '20px',
    listStyle: 'none',
    maxHeight: 200,
    border: '1px solid rgba(0,0,0,.25)',
    backgroundColor: theme.palette.common.white,
    borderRadius: '4px',
    boxShadow: '2px 10px 13px 1px rgba(0,0,0,0.19)',
    color: `${getComputedStyle(document.body).getPropertyValue('--black-li')} !important`,
    '& .checked svg': {
      color: 'transparent',
    },
    '& li[data-focus="true"]': {
      backgroundColor: `${getComputedStyle(document.body).getPropertyValue(
        '--primary-bg-li'
      )} !important`,
      color: `${getComputedStyle(document.body).getPropertyValue('--black-li')} !important`,
      cursor: 'pointer',
    },

    '& li[aria-selected="true"]': {
      backgroundColor: '#fafafa',
      fontWeight: 600,

      '& .checked svg': {
        color: '#1890ff',
      },
    },
    '& li:active': {
      backgroundColor: `${getComputedStyle(document.body).getPropertyValue(
        '--primary-bg-li'
      )} !important`,
      color: `${getComputedStyle(document.body).getPropertyValue('--black-li')} !important`,
    },
    '& ul li': {
      fontSize: '13px',
      paddingLeft: '10px',
      paddingRight: '10px',
      paddingTop: '4px',
      display: 'flex',
      justifyContent: 'space-between',
    },
  },
  inputBox: {
    width: '100% !important',
  },
  option: {
    marginBottom: '5px',
    marginRight: '5px',
  },
  root: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: '8px',
    alignContent: 'flex-end',
    '& > * + *': {
      marginTop: theme.spacing(3),
    },
  },
  helperText: {
    margin: '10px 0 0',
    fontSize: '12px',
    lineHeight: 1.4,
  },
  field: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    alignSelf: 'flex-end',
  },
  popper: {
    marginTop: 50,
  },
}));

const ScrollPaginationDropdown = ({
  multiSelect,
  placeholder,
  fetchItems,
  items,
  totalCount,
  hasNext = false,
  getLabel = option => option.ID,
  getId = option => option.ID,
  offset,
  _handleChange,
  wrappedHandleChange,
  attributeKey,
  defaultValue,
  required,
  showError,
  readOnly,
  descriptionType,
  scrollToError,
  intl,
  name,
  supportsHtmlTags,
  filterSelectedOptions,
  from,
  ...props
}) => {
  const classes = useStyles();
  const inputTextFieldRef = useRef(null);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [isNextPageLoading, setIsNextPageLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [searchValue, setValue] = useState(null);
  const [debouncedText] = useDebounce(searchValue, 500);

  const loadNextPage = (...args) => {
    setIsNextPageLoading(true);
    fetchItems(page + offset, searchValue);
    setPage(page + offset);
    setHasNextPage(items.length < totalCount || hasNext); // It should total records which API is sending
    setIsNextPageLoading(false);
  };

  const fetchInitialItems = () => {
    setHasNextPage(true);
    if(from !== 'applicationRequest')
    setIsNextPageLoading(true);
    setPage(0);
    setValue(debouncedText);
    fetchItems(0, debouncedText, true);
  };

  useEffect(() => {
    if(from !== 'applicationRequest'){
      if (!hasNext) {
        setHasNextPage(false);
      }
      setIsNextPageLoading(false);
    }
  }, [hasNext, items]);

  useEffect(() => {
    if (debouncedText || debouncedText === '') {
      fetchInitialItems();
    }
    return () => {};
  }, [debouncedText]);

  const handleFocus = () => {
    if ((!items || !items.length) && hasNextPage) {
      fetchInitialItems();
    }
  };

  useEffect(() => {
    if (scrollToError === 'true') {
      inputTextFieldRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [scrollToError]);

  const [prevURL, setPrevURL] = useState('');
  useEffect(() => {
    if (props.apiURL) {
      if (props.apiURL !== prevURL) {
        fetchInitialItems();
      }
      setPrevURL(props.apiURL);
    }
  }, [props.apiURL]);

  const searchChange = (e, val) => {
    if (e) {
      e.preventDefault();
      setValue(e.target.value);
    }
  };

  const handleChange = (event, val) => {
    if (!multiSelect) {
      _handleChange(val ? [getId(val)] : [], attributeKey, val);
    } else {
      const values = [];
      if (val) {
        val.map(item => {
          values.push(getId(item));
        });
      }
      _handleChange(values || [], attributeKey, val);
    }
  };

  const {
    getRootProps,
    getInputLabelProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    getTagProps,
    groupedOptions,
    value,
  } = useAutocomplete({
    id: 'use-autocomplete-demo',
    options: items,
    multiple: multiSelect,
    clearOnBlur: false,
    clearOnEscape: false,
    getOptionLabel: getLabel,
    onInputChange: searchChange,
    defaultValue: defaultValue || [],
    onChange: wrappedHandleChange || handleChange,
    openOnFocus: true,
    onOpen: handleFocus,
    getOptionSelected:
      from === 'applicationRequest' ? undefined : (option, value) => option.value === value.value,
    filterSelectedOptions: from === 'applicationRequest' ? undefined : filterSelectedOptions,
  });

  return (
    <NoSsr>
      <div className={classes.root} ref={inputTextFieldRef}>
        <div className={classes.field}>
          {supportsHtmlTags && (
            <div className="support-html">
              <span dangerouslySetInnerHTML={{ __html: placeholder }} />
              {required && <span className="mandatory">&nbsp;*</span>}
            </div>
          )}
          <div className="pagination-scroll-dropdown">
            <div {...getRootProps()}>
              <div className={classes.inputBox}>
                <div>
                  {multiSelect
                    ? value.map((option, index) => (
                        <div {...getTagProps({ index })} className="selected-options">
                          <Chip
                            className={classes.option}
                            label={getLabel(option)}
                            {...getTagProps({ index })}
                          />
                        </div>
                      ))
                    : ''}
                </div>
                <div className="formlayout">
                  <TextField
                    name="pagination-dropdown"
                    autoComplete="off"
                    margin="dense"
                    className={classes.input}
                    fullWidth
                    label={
                      supportsHtmlTags ? (
                        ''
                      ) : (
                        <>
                          <span dangerouslySetInnerHTML={{ __html: placeholder }} />
                          {required && <span className="mandatory">&nbsp;*</span>}
                        </>
                      )
                    }
                    error={required ? showError : false}
                    {...getInputProps()}
                  />
                  <IconButton aria-label="close" className="dropdownicon">
                    <ArrowDropDownIcon />
                  </IconButton>
                </div>
              </div>
            </div>
            {groupedOptions.length > 0 ? (
              <>
                <OptionsWrapper
                  hasNextPage={hasNextPage}
                  isNextPageLoading={isNextPageLoading}
                  items={items}
                  loadNextPage={loadNextPage}
                  wrapperClassName={classes.listbox}
                  getOptionProps={getOptionProps}
                  groupedOptions={groupedOptions}
                  listboxProps={getListboxProps()}
                  getLabel={getLabel}
                  menuStyle={props.menuStyle}
                />
              </>
            ) : null}
          </div>
        </div>
      </div>
    </NoSsr>
  );
};

ScrollPaginationDropdown.propTypes = {
  multiSelect: PropTypes.bool.isRequired,
  placeholder: PropTypes.string.isRequired,
  fetchItems: PropTypes.func.isRequired,
  items: PropTypes.arrayOf({}).isRequired,
  _handleChange: PropTypes.func.isRequired,
  wrappedHandleChange: PropTypes.func.isRequired,
  totalCount: PropTypes.number.isRequired,
  hasNext: PropTypes.bool.isRequired,
  getLabel: PropTypes.func.isRequired,
  getId: PropTypes.func.isRequired,
  offset: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  attributeKey: PropTypes.string.isRequired,
  defaultValue: PropTypes.string.isRequired,
  required: PropTypes.bool.isRequired,
  showError: PropTypes.bool.isRequired,
  readOnly: PropTypes.bool.isRequired,
  descriptionType: PropTypes.string.isRequired,
  supportsHtmlTags: PropTypes.bool.isRequired,
};

// ScrollPaginationDropdown.defaultProps = {
//   _handleChange: () => {},
//   wrappedHandleChange: () => {},
// };

export default ScrollPaginationDropdown;
