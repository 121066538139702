import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import './FormComponents.scss';
import { makeStyles } from '@material-ui/core/styles';
import FormHelperText from '@material-ui/core/FormHelperText';

const useStyles = makeStyles({
  label: {
    fontFamily: 'Rubik',
    fontSize: '12px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: 'black',
  },
  checkBoxStyle: {
    color: '#999999',
  },
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    alignContent: 'flex-end',
    position: 'relative',
  },
  field: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    alignSelf: 'flex-end',
    marginBottom: '20px',
  },
  labelText: {
    display: 'flex',
    flexWrap: 'wrap',
    alignSelf: 'flex-end',
  },
  helperText: {
    margin: '10px 0 0',
    fontSize: '12px',
    lineHeight: 1.4,
  },
  marginDense: {
    marginBottom: 0,
  },
  errorText: {
    color: '#F06A60',
    marginTop: '0px',
    marginBottom: '2px',
    position: 'absolute',
    bottom: '0',
  },
  rootLabel: {
    marginRight: '10px',
  },
  option: {
    wordBreak: 'break-all',
    maxWidth: '50%',
    minWidth: '50%',
  },
});
const CheckboxField = ({
  placeholder,
  _handleChange,
  attributeKey,
  options,
  defaultValue,
  required,
  showError,
  readOnly,
  multiSelect,
  scrollToError,
}) => {
  const [newOption, setOptions] = React.useState([]);
  const classes = useStyles();
  const inputTextFieldRef = useRef(null);
  useEffect(() => {
    if (scrollToError === 'true') {
      inputTextFieldRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [scrollToError]);
  useEffect(() => {
    const newObj = [];
    const newOptions = Object.assign([], options);

    newOptions.map(key => {
      if (key && typeof key === 'object' && key.ID) {
        newObj.push({ name: key.ID, checked: false });
      }
      return true;
    });

    if (defaultValue) {
      newObj.map(option => {
        defaultValue.map(value => {
          const optionValue = option;
          if (optionValue.name === value) {
            optionValue.checked = true;
          }
          return true;
        });
        return false;
      });
    }

    setOptions(newObj);
  }, [defaultValue]);

  const handleChange = (event, name) => {
    const optionsSelected = [];

    const updatedOption = newOption.map(item => {
      if (item.name === name) {
        return { name, checked: event.target.checked };
      }
      if (multiSelect) {
        return { name: item.name, checked: false };
      }
      return item;
    });
    setOptions(updatedOption);
    updatedOption.map(item => {
      if (item.checked) return optionsSelected.push(item.name);
      return true;
    });

    _handleChange(optionsSelected, attributeKey);
  };

  return (
    <div className={classes.root} ref={inputTextFieldRef}>
      <div className={classes.labelText}>
        <FormHelperText className={classes.helperText}>
          <span dangerouslySetInnerHTML={{ __html: placeholder }} />
          {required && <span className="mandatory">&nbsp;*</span>}
        </FormHelperText>
      </div>
      <div className={classes.field}>
        {newOption.map(item => {
          return (
            <div className={classes.option}>
              <FormControlLabel
                classes={{
                  root: classes.rootLabel,
                  label: classes.label,
                }}
                disabled={readOnly}
                control={
                  <Checkbox
                    onChange={e => {
                      handleChange(e, item.name);
                    }}
                    value={item.name}
                    checked={item.checked}
                    color="primary"
                    className={classes.checkBoxStyle}
                    error={required ? showError : false}
                  />
                }
                label={item.name}
              />
            </div>
          );
        })}
      </div>
      <FormHelperText className={classes.errorText}>
        {required && showError ? 'Please select an option' : ''}
      </FormHelperText>
    </div>
  );
};

CheckboxField.propTypes = {
  _handleChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  scrollToError: PropTypes.bool.isRequired,
  attributeKey: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  defaultValue: PropTypes.arrayOf(PropTypes.string).isRequired,
  showError: PropTypes.bool.isRequired,
  readOnly: PropTypes.bool.isRequired,
  required: PropTypes.bool.isRequired,
  multiSelect: PropTypes.bool.isRequired,
};

export default CheckboxField;
