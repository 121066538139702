import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { Button, } from '@material-ui/core';
import clsx from 'clsx';
import { ToggleButtonComponent, Icon } from 'ui-components/src';
import { supportedRequestTypes, checkEnvironment } from 'ui-common/src/lib/utils';
import { ModuleLink } from 'ui-components/src';
import { getPendingActions } from '../redux/requestHome-actions';
import './RequestHomeDashboard.scss';
import { Cookies } from 'react-cookie';
import { makeStyles, useTheme } from '@material-ui/core/styles';

const cookies = new Cookies();

const useStyles = makeStyles(theme => ({
  button: {
    fontWeight: 500,
    textAlign: 'center',
    borderRadius: '3px',
    textTransform: 'capitalize',
    color: theme.palette.primary.main,
  },
  reviewButton: {
    minWidth: 80,
    padding: '4px',
    background: 'white',
  },
  viewAllButton: {
    background: 'white',
    width: '100%',
  }
}));


const PendingActions = props => {
  const classes = useStyles();
  const { ECM_URL } = useSelector(state => state.user.domainInfo);
  const options = [
    { value: 'Certification', label: props.intl.formatMessage({ id: 'PendingActions.Certification.label' }) },
    { value: 'Request', label: props.intl.formatMessage({ id: 'PendingActions.Request.label' }) },
  ];
  const [value, setToggleValue] = useState('Certification');
  const { REQUESTHOME_PENDINGACTIONS_DEFAULT_COUNT } = useSelector(state => state.user.domainInfo) || {
    REQUESTHOME_PENDINGACTIONS_DEFAULT_COUNT: {},
  };
  const pendingCount = REQUESTHOME_PENDINGACTIONS_DEFAULT_COUNT ? REQUESTHOME_PENDINGACTIONS_DEFAULT_COUNT > 100 ? 100 : REQUESTHOME_PENDINGACTIONS_DEFAULT_COUNT : 10;
  useEffect(() => {
    props.getPendingActions('Certification', pendingCount);
  }, []);

  const onToggleChange = value => {
    setToggleValue(value);
    props.getPendingActions(value, pendingCount);
  };

  const openApprovalDetail = item => {
    props.history.push(`/review/requestApproval/${item.reqId}`);
  };

  const openApproval = reqId => {
    document.getElementById(`pendingActionLink${reqId}`).submit();
  };

  const pendingKeys = ['today', 'lastWeek', 'others'];

  return (
    <div className={`pending-action-layout ${!props.pendingActionsLoading && props.pendingActions && Object.keys(props.pendingActions).length > 0 &&
      props.pendingActions.count > pendingCount ? '' : 'h-100'}`}>
      <div className="h-100">
        <div className="toggleLayout">
          <ToggleButtonComponent
            options={options}
            defaultValue={value}
            onToggleChange={onToggleChange}
          />
        </div>
        {props.pendingActionsLoading && (
          [1, 2, 3, 4, 5, 6].map(row => (
            <div key={row}>
              {[1].map(column => (
                <div className="loaderCss" key={column}>
                  <div className="imgIcon" />
                  <div className="dataLoading">
                    <div className="gridLoading mb-2" />
                    <div className="gridLoading" />
                  </div>
                </div>
              ))}
            </div>
          ))
        )}

        {!props.pendingActionsLoading && props.pendingActions && Object.keys(props.pendingActions).length > 0 &&
          (props.pendingActions.lastWeek.length + props.pendingActions.others.length +
            props.pendingActions.today.length) === 0 && (
            <div className="pendingemptyrequest">
              <Icon name="pendingActionsEmptyRequestsImage" />
              <div className="pendingmessage">{props.intl.formatMessage({
                id: 'PendingActions.EmptyMessage.label',
              })}</div>
            </div>
          )}

        {!props.pendingActionsLoading && props.pendingActions && Object.keys(props.pendingActions).length > 0 &&
          (props.pendingActions.lastWeek.length + props.pendingActions.others.length +
            props.pendingActions.today.length) > 0 &&
          <div className="recent-notification">
            {pendingKeys.map(key =>
              props.pendingActions[key] &&
              <> {props.pendingActions[key].length > 0
                && <div className="pendingkey">
                  {props.intl.formatMessage({
                    id: `PendingActions.${key}.label`,
                  })}</div>}
                {props.pendingActions[key].map(item => {
                  const jbpmactname = item.processInstanceID && item.processInstanceID.split('.')[0];
                  return <div
                    className="notification-content d-flex row m-0 content-pending"
                    tabIndex="0"
                    role="button"
                  >

                    <div className="d-flex w-100">
                      <div className="notification-icon col-2 p-0">
                        <div className="icon iconpending pt-1">
                          {item.requestType ? <Icon name="manage_access_review" /> :
                            <Icon name="pending_action_certification" />}
                        </div>
                      </div>
                      <div className="content-section p-0 wauto">
                        <h5 className="notification-title mb-0">{item.requestType ? item.requestType :
                          item.certificationName ? item.certificationName : ''}</h5>
                        <p className="notification-desc mb-0 wauto">
                          {item.reqId ? '#' + item.reqId : item.type ? item.type : ''}
                        </p>
                        <p className="notification-desc mb-0 wauto certifierlabel">
                          {item.requestedFor ? props.intl.formatMessage({
                            id: `PendingActions.RequestedFor.label`,
                          }) : item.type ? props.intl.formatMessage({
                            id: `PendingActions.Certifier.label`,
                          }) : ''}{' '}
                          {item.requestedFor && <span className="requestedfor"> {item.requestedFor}</span>}
                          {item.certifier && <span className="requestedfor" dangerouslySetInnerHTML={{ __html: item.certifier }} />}
                        </p>
                      </div>
                      <div className="review-btn ml-auto">
                        {/* <div className="d-flex align-items-center mb-2">
                          <div className={`ml-auto ${item.risk === 'Very High' ? 'High' : item.risk}Text`}>{item.requestType && (item.risk === 'High'
                            || item.risk === 'Very High')
                            ? props.intl.formatMessage({
                              id: `PendingActions.Risky.label`,
                            }) : ''}</div>
                          <div className={`riskicon ${item.risk}`} />
                        </div> */}
                        {item.reqId && item.platformType === 'ECMV6' && supportedRequestTypes.includes(item.requestType) ?
                          (<Button
                            variant="outlined"
                            fontSize="small"
                            className={clsx(classes.button, classes.reviewButton)}
                            onClick={() => {
                              openApprovalDetail(item);
                            }}
                          >
                            {props.intl.formatMessage({
                              id: `PendingActions.viewdetail.label`,
                            })}
                          </Button>) :
                          item.reqId && (item.requestType === 'Create User' || item.requestType === 'Update User') ?
                            <React.Fragment>
                              <form
                                name={`pendingActionLink${item.reqId}`}
                                target="_self"
                                id={`pendingActionLink${item.reqId}`}
                                method="post"
                                useToken="true"
                                action={`${ECM_URL}/workflowmanagement/viewcreateupdateuserrequest/${item.requestKey}?jbpmactivityname=${jbpmactname}&ACCESS_APPROVERSKEY=${item.accessApproversPk}`}
                              />
                              <Button
                                color="primary"
                                onClick={() => {
                                  openApproval(item.reqId);
                                }}
                              >
                                {props.intl.formatMessage({
                                  id: `PendingActions.viewdetail.label`,
                                })}
                              </Button>
                            </React.Fragment>
                            : item.reqId ?
                              <React.Fragment>
                                <form
                                  name="reloadapprovalpage"
                                  target="_self"
                                  id={`pendingActionLink${item.reqId}`}
                                  method="post"
                                  useToken="true"
                                  action={`${ECM_URL}/jbpmworkflowmanagement/showrequestdetails`}
                                >
                                  <input type="hidden" name="id" value={item.processInstanceID} />
                                  <input
                                    type="hidden"
                                    name="jbpmactivityname"
                                    id="jbpmactivityname"
                                    value={item.jbpmActivityName}
                                  />
                                </form>
                                <Button
                                  color="primary"
                                  onClick={() => {
                                    openApproval(item.reqId);
                                  }}
                                >
                                  {props.intl.formatMessage({
                                    id: `PendingActions.viewdetail.label`,
                                  })}
                                </Button>
                              </React.Fragment>
                              :
                              <Button
                                variant="outlined"
                                fontSize="small"
                                className={clsx(classes.button, classes.reviewButton)}
                                onClick={() => {
                                  //certification
                                  let certificationLink = 'certification/list';
                                  switch (item.type) {
                                    case 'User Manager':
                                      certificationLink = `certification/showstep1forusermanager/${item.id}?campaignKey=${item.campaignKey}`;
                                      break;
                                    case 'Organization Owner':
                                      certificationLink = `campaignOrganization/organizationCertification/${item.id}?campaignKey=${item.campaignKey}`;
                                      break;
                                    case 'Application Owner':
                                      certificationLink = `certification/showstep1forusermanager/${item.id}?campaignKey=${item.campaignKey}`;
                                      break;
                                    case 'Service Account':
                                      certificationLink = `certification/showstep1forusermanager/${item.id}?campaignKey=${item.campaignKey}`;
                                      break;
                                    case 'Entitlement Owner':
                                      certificationLink = `campaignEoAndRo/step1entowner/${item.id}?campaignKey=${item.campaignKey}`;
                                      break;
                                    case 'ROle owner campaign':
                                      certificationLink = `campaignEoAndRo/roleOwnerCertification/${item.id}?campaignKey=${item.campaignKey}`;
                                      break;
                                    default:
                                      break;
                                  }
                                  window.open(
                                    `${ECM_URL}/${certificationLink}`,
                                    '_self'
                                  );
                                }}
                              >
                                {props.intl.formatMessage({
                                  id: `PendingActions.viewdetail.label`,
                                })}
                              </Button>
                        }
                      </div></div>
                  </div>
                })

                }</>
            )}

          </div>
        }
      </div>
      {
        !props.pendingActionsLoading && props.pendingActions && Object.keys(props.pendingActions).length > 0 &&
        (props.pendingActions.lastWeek.length + props.pendingActions.others.length +
          props.pendingActions.today.length) < 4 && (props.pendingActions.lastWeek.length + props.pendingActions.others.length +
            props.pendingActions.today.length) > 0 && (
          <div className="lastpageimage">
            <Icon name="pendingActionsLastPageImage" />
            <div className="lastpagemessage">{props.intl.formatMessage({
              id: 'PendingActions.ReachedListEnd.label',
            })}</div>
          </div>
        )
      }

      {
        !props.pendingActionsLoading && props.pendingActions && Object.keys(props.pendingActions).length > 0 &&
        props.pendingActions.count > pendingCount && (
          <div className="lastpageimage viewall">
            <Button
              variant="outlined"
              fontSize="small"
              className={clsx(classes.button, classes.viewAllButton)}
              onClick={() => {
                if (value === 'Certification') {
                  window.open(
                    `${ECM_URL}/certification/list`,
                    '_self'
                  );
                } else
                  props.history.push(`/review/requestApproval`);
                //value === 'Certification' ? 'View All Certifications' : 'View All Requests'
              }}
            >
              {value === 'Certification' ? props.intl.formatMessage({
                id: 'View All Certifications',
              }) : props.intl.formatMessage({
                id: 'PendingActions.ViewAllRequests',
              })}
            </Button>
          </div>
        )
      }
    </div >
  );
};

PendingActions.propTypes = {
  requestActionsList: PropTypes.func.isRequired,
  requestImportantActionsList: PropTypes.func.isRequired,
  intl: PropTypes.shape({
    locale: PropTypes.string.isRequired,
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

PendingActions.defaultProps = {

};

const mapStateToProps = state => {
  return {
    pendingActions: state.requestHome.initialLoad.pendingActions,
    pendingActionsLoading: state.requestHome.initialLoad.pendingActionsLoading,
  };
};

const mapDispatchToProps = {
  getPendingActions,
};

const ConnectedPendingActions = injectIntl(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(PendingActions))
);
ConnectedPendingActions.displayName = 'ConnectedPendingActions';
export default ConnectedPendingActions;
