import React, { useState, useEffect, useRef } from 'react';
import 'date-fns';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import { Event } from "@material-ui/icons";
import moment from 'moment';
import './FormComponents.scss';

const useStyles = makeStyles(() => ({
  marginDense: {
    marginBottom: 0,
  },
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    alignContent: 'flex-end',
  },
  field: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    alignSelf: 'flex-end',
  },
  label: {
    display: 'flex',
    flexWrap: 'wrap',
    alignSelf: 'flex-end',
  },
  helperText: {
    margin: '10px 0 0',
    fontSize: '12px',
    lineHeight: 1.4,
  },
}));

const DatePickerComponent = ({
  placeholder,
  _handleChange,
  attributeKey,
  defaultValue,
  showError,
  required,
  readOnly,
  minDate,
  scrollToError,
}) => {
  const classes = useStyles();
  const [selectedDate, setSelectedDate] = useState(defaultValue);
  const inputTextFieldRef = useRef(null);

  useEffect(() => {
    setSelectedDate(defaultValue);
  }, [defaultValue]);

  const handleDateChange = date => {
    setSelectedDate(date);
    _handleChange(moment(date).format('YYYY-MM-DD'), attributeKey);
  };

  useEffect(() => {
    if (scrollToError === "true") {
      inputTextFieldRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [scrollToError]);

  return (
    <div className={classes.root} ref={inputTextFieldRef}>
      <div className={classes.field}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid container>
            <DatePicker
              autoOk
              disableToolbar
              variant="inline"
              autoComplete="off"
              error={required ? showError : false}
              format="yyyy-MM-dd"
              className={classes.marginDense}
              margin="dense"
              id="date-picker-inline"
              value={selectedDate || null}
              InputAdornmentProps={{ position: 'end', edge: 'end' }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton edge="end">
                      <Event />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              onChange={handleDateChange}
              fullWidth
              disabled={readOnly}
              minDate={minDate ? minDate : new Date()}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              label={
                <>
                  <span dangerouslySetInnerHTML={{ __html: placeholder }} />
                  {required && <span className="mandatory">&nbsp;*</span>}
                </>
              }
            />
          </Grid>
        </MuiPickersUtilsProvider>
      </div>
    </div>
  );
};

DatePickerComponent.propTypes = {
  _handleChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  attributeKey: PropTypes.string.isRequired,
  required: PropTypes.bool.isRequired,
  showError: PropTypes.bool.isRequired,
  readOnly: PropTypes.bool.isRequired,
  defaultValue: PropTypes.string.isRequired,
  minDate: PropTypes.string.isRequired,
};

export default DatePickerComponent;
