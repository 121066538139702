import React from 'react';
import PropTypes from 'prop-types';

const AppLogo = ({ src }) => (
  <div className="cl-logo">
    <img className="cl-logo-icon" src={src} alt="Application Logo" width="100px" />
  </div>
);

AppLogo.propTypes = {
  src: PropTypes.string.isRequired,
};

export default AppLogo;
