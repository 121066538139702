import codegen from 'codegen.macro';
import preval from 'preval.macro';
import React, { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  withRouter,
  useLocation,
} from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { Redirector } from 'react-router-redirect';
import { syncHistoryWithStore } from 'react-router-redux';
import { throttle } from 'lodash';
import { Route404, Global404 } from 'ui-components/src';
import { Cookies } from 'react-cookie';
import { useSelector, useDispatch } from 'react-redux';
import { initialiseEnv, pingECM } from 'ui-common/src/lib/utils';
import { userModulesAction } from 'ui-common/src/utils/LoginActions';
import store from './store';
import { Login } from '../features/MainLayout/Login';
import { RequestHome } from '../features/MainLayout/RequestHome';
// eslint-disable-next-line no-unused-vars
import { getModule } from './dynamic-modules';
import { Header } from '../features/MainLayout/Header';
import { Footer } from '../features/MainLayout/Footer';
import { Control } from '../features/MainLayout/Control';
import AuthRedirect from '../auth/AuthRedirect';
import UserLogout from '../logout/UserLogout';
import SomethingWentWrong from '../logout/SomethingWentWrong';
import SessionTimeout from '../logout/SessionTimeout';

const dynRouteArr = preval`const moduleList= require('./moduleList');
                            module.exports=moduleList.map(m=>m.route)`;
const cookies = new Cookies();
const PrivateRoute = withRouter(({ component: Component, location, ...rest }) => {
  window.throttledPingECM = throttle(pingECM, 2000, { trailing: false });
  useEffect(() => {
    window.throttledPingECM();
  }, [location]);
  return (
    <Route
      {...rest}
      render={
        props => (cookies.get('access_token') !== undefined ? <Component {...props} /> : null)
        // (
        // <Redirect
        //   to={{
        //     pathname: '/login',
        //     state: { destinationLocation: location },
        //   }}
        // />
        // )
      }
    />
  );
});

const LoginRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      cookies.get('access_token') === undefined ? (
        <Component {...props} />
      ) : (
        <Redirect to="/request/requestHome" />
      )
    }
  />
);

const LogoutRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      cookies.get('access_token') === undefined ? (
        <Component {...props} />
      ) : (
        <Redirect to="/request/requestHome" />
      )
    }
  />
);

class DynamicModules extends React.Component {
  render() {
    const loadedCSSClass =
      this.props.location.pathname.indexOf('mySessions') > 0 ? 'extendedTabHeight' : '';
    const loginClass = this.props.location.pathname === '/login' ? 'd-none' : '';
    const UserLogoutClass = this.props.location.pathname === '/logout' ? 'd-none' : '';
    const SessionTimeoutClass = this.props.location.pathname === '/sessiontimeout' ? 'd-none' : '';

    return (
      <div
        className={`dynamicModules ${loadedCSSClass} ${loginClass} ${UserLogoutClass} ${SessionTimeoutClass}`}
      >
        {codegen.require('./dynamicRouteCodegen')}
      </div>
    );
  }
}

const WithRuterModule = withRouter(DynamicModules);
const allowedRoutes = {
  staticRoutes: [
    '/',
    '/login',
    '/login/',
    '/logout',
    '/logout/',
    '/auth',
    '/auth/',
    '/dashboard',
    '/dashboard/',
    '/somethingwentwrong',
    '/somethingwentwrong/',
    '/sessiontimeout',
    '/sessiontimeout/',
    '/request/requestHome',
  ],
  dynamicRoutes: dynRouteArr,
};
const Routes = props => {
  const dispatch = useDispatch();
  // const browserHistory = createBrowserHistory();
  // const history = syncHistoryWithStore(browserHistory, store);
  const allowedRoutes = useSelector(state => state.user.userModules);
  const domainInfo = useSelector(state => state.user.domainInfo);
  const [allowedReactRoutes, setAllowedReactRoutes] = useState(['/login']);
  useEffect(() => {
    if (domainInfo && Object.keys(domainInfo).length) {
      initialiseEnv(domainInfo);
      // if (cookies.get('access_token') && !allowedRoutes.length) {
      //   dispatch(userModulesAction());
      //   return 'Loading';
      // }
    }
  }, [domainInfo]);
  useEffect(() => {
    if (allowedRoutes.length === 0) {
      return;
    }

    const allowedFilteredRoutes = allowedRoutes.map(r => {
      if (r) {
        return r.replace('/ECMv6', '');
      }
      return r;
    });
    setAllowedReactRoutes([...allowedFilteredRoutes]);
  }, [allowedRoutes]);

  return (
    <Router basename="/ECMv6">
      {/* <Route404 routes={allowedRoutes} render={path => <Global404 path={path} />}> */}
      <Route404
        routes={{ staticRoutes: allowedReactRoutes, dynamicRoutes: [] }}
        render={path => <Global404 path={path} />}
      >
        <PrivateRoute
          exact
          path="/"
          render={props => <Redirect to="/request/requestHome" {...props} />}
        />
        <Redirector />
        <PrivateRoute path="/request" component={Header} />
        <PrivateRoute path="/review" component={Header} />
        <PrivateRoute path="/design" component={Header} />
        <PrivateRoute path="/control" component={Header} />
        <PrivateRoute path="/campaign" component={Header} />
        <PrivateRoute path="/admin" component={Header} />
        <LoginRoute exact path="/login" component={Login} />
        <PrivateRoute exact path="/request/requestHome" component={RequestHome} />
        <Route exact path="/auth" component={AuthRedirect} />
        <LogoutRoute exact path="/logout" component={UserLogout} />
        <LogoutRoute exact path="/somethingwentwrong" component={SomethingWentWrong} />
        <LogoutRoute exact path="/sessiontimeout" component={SessionTimeout} />
        <WithRuterModule {...props} />
        <PrivateRoute path="/request" component={Footer} />
        <PrivateRoute path="/review" component={Footer} />
        <PrivateRoute path="/design" component={Footer} />
        <PrivateRoute path="/control" component={Control} />
        <PrivateRoute path="/campaign" component={Footer} />

        <PrivateRoute path="/admin" component={Footer} />
      </Route404>
    </Router>
  );
};

export default Routes;
