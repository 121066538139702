/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
// import DatePicker from "react-datepicker";
import moment from 'moment';
// import useOnClickOutside from 'use-onclickoutside';
import './IDAssignment.scss';
import { injectIntl } from 'react-intl';
// import { Cookies } from 'react-cookie';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import NotesIcon from '@material-ui/icons/Notes';
import {
  instanceCheckoutStartDateTime,
  instanceCheckoutEndDateTime,
  getDateAndTime,
} from 'ui-common/src/lib/utils';
import { SaviyntRangePicker } from '../SaviyntRangePicker';
import { ManageTicket } from '../ManageTicket';

// import styles from '../SelectedCredentials/SelectedCredentials.module.scss';

// const cookies = new Cookies();
const useStyles = makeStyles(theme => ({
  cssOutlinedInput: {
    '&$cssFocused $notchedOutline': {
      borderColor: `${theme.palette.primary.main} !important`,
    },
  },
  notchedOutline: {
    border: 'none',
  },
  cssFocused: {},
}));

const IDAssignment = ({
  setRequestableDates,
  from,
  instance,
  captureSelectedInstanceParams,
  businessJustification,
  setBusinessJustification,
  updateBusinessJustification,
  idAssignmentWidth,
  disableProgress,
  selectedTickets,
  setSelectedTickets,
  // showModal,
  intl,
}) => {
  const classes = useStyles();
  const { defaultFromTime, defaultFromDate } = instance.startDate
    ? {
        defaultFromDate: moment(instance.startDate, 'MMM DD, YYYY h:mm a').set({
          hours: 0,
          minutes: 0,
          seconds: 0,
        }),
        defaultFromTime: {
          hours: moment(instance.startDate, 'MMM DD, YYYY h:mm a').get('hours'),
          minutes: moment(instance.startDate, 'MMM DD, YYYY h:mm a').get('minutes'),
          seconds: moment(instance.startDate, 'MMM DD, YYYY h:mm a').get('seconds'),
        },
      }
    : instanceCheckoutStartDateTime();
  const { defaultToTime, defaultToDate } = instance.endDate
    ? {
        defaultToDate: moment(instance.endDate, 'MMM DD, YYYY h:mm a').set({
          hours: 0,
          minutes: 0,
          seconds: 0,
        }),
        defaultToTime: {
          hours: moment(instance.endDate, 'MMM DD, YYYY h:mm a').get('hours'),
          minutes: moment(instance.endTime, 'MMM DD, YYYY h:mm a').get('minutes'),
          seconds: moment(instance.endDate, 'MMM DD, YYYY h:mm a').get('seconds'),
        },
      }
    : {
        defaultToDate: moment().set({
          hours: 23,
          minutes: 59,
          seconds: 0,
        }),
        defaultToTime: {
          hours: 23,
          minutes: 59,
          seconds: 0,
        },
      };

  const fromDateTime = instance.startTime || defaultFromDate.set(defaultFromTime);
  const toDateTime = instance.endTime || defaultToDate.set(defaultToTime);

  // const multiEdit = instance.length !== 1;
  // const [fromDateTime, setFromDateTime] = useState(
  //   multiEdit ? defaultFromDate.set(defaultFromTime) : instance[0].fromDateTime
  // );
  // const [toDateTime, setToDateTime] = useState(
  //   multiEdit ? defaultToDate.set(defaultToTime) : instance[0].toDateTime
  // );
  const fromDateHour = moment(fromDateTime).get('hours');
  const defaultRequestableTime =
    instance.accountConfig && instance.accountConfig.defaultrequestabletimeinsecs;

  const tDateTime =
    !instance.edit && defaultRequestableTime && defaultRequestableTime > 0
      ? getDateAndTime(fromDateHour, defaultRequestableTime, fromDateTime)
      : toDateTime;

  const instanceFromDate = fromDateTime.clone().set({
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  const instanceFromTime = {
    hours: fromDateTime.get('hours'),
    minutes: fromDateTime.get('minutes'),
    seconds: fromDateTime.get('seconds'),
  };
  const instanceToDate = tDateTime.clone().set({
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  const instanceToTime = {
    hours: tDateTime.get('hours'),
    minutes: tDateTime.get('minutes'),
    seconds: tDateTime.get('seconds'),
  };

  const usedTickets = [];

  return (
    <div className="IDAssignment" style={{ width: idAssignmentWidth }}>
      <div className="justify-content-between">
        <SaviyntRangePicker
          dateFormat="MMM DD, YYYY"
          onChange={range => {
            if (from === 'slot')
              setRequestableDates({
                startDate: range[0],
                endDate: range[1],
                // startDate: fromDateTime,
                // endDate: range[1].set({
                //   hours: 23,
                //   minutes: 59,
                //   seconds: 0,
                // }),
              });
            else if (from === 'credential')
              captureSelectedInstanceParams({
                ...instance,
                fromDateTime: range[0],
                toDateTime: range[1],
              });
          }}
          {...{
            instanceFromDate,
            instanceToDate,
            instanceFromTime,
            instanceToTime,
            disableProgress,
          }}
        />
        <div className="bj-ticket-section-id">
          {/* className="businessJustificationMandatory" */}
          <TextField
            multiline
            rows="1"
            margin="none"
            variant="outlined"
            placeholder={intl.formatMessage({ id: 'BusinessJustification' })}
            value={businessJustification && businessJustification.split('[')[0]}
            InputLabelProps={{
              shrink: true,
              marginTop: 10,
              style: { backgroundColor: '#ffffff', color: '#000000' },
            }}
            InputProps={{
              style: { height: 40, fontSize: 11 },
              classes: {
                root: classes.cssOutlinedInput,
                focused: classes.cssFocused,
                notchedOutline: classes.notchedOutline,
              },
              startAdornment: (
                <InputAdornment position="start">
                  <NotesIcon />
                </InputAdornment>
              ),
            }}
            className="form-control"
            onChange={e => {
              if (e.target.value.length <= 1000) {
                setBusinessJustification(e.target.value);
                updateBusinessJustification(e.target.value);
              }
            }}
          />
          <ManageTicket
            selectedTickets={selectedTickets}
            usedTickets={usedTickets}
            addTicket={tNum => setSelectedTickets([...selectedTickets, tNum])}
            removeTicket={tNum => setSelectedTickets(selectedTickets.filter(t => t !== tNum))}
          />
        </div>
        {/* <textarea
        value={businessJustification}
        className="form-control"
        id="text"
        name="text"
        placeholder=""
        rows="5"
        onChange={e => {
          if (e.target.value.length <= 1000) {
            setBusinessJustification(e.target.value);
          }
        }}
      /> */}
      </div>
    </div>
  );
};
IDAssignment.propTypes = {
  // showModal: PropTypes.func.isRequired,
  // onDone: PropTypes.func,
  instance: PropTypes.arrayOf(
    PropTypes.shape({
      instanceId: PropTypes.string,
      fromDateTime: PropTypes.string,
      toDateTime: PropTypes.string,
      selectedRole: PropTypes.string,
      businessJustification: PropTypes.string,
    })
  ).isRequired,
  businessJustification: PropTypes.string,
  setBusinessJustification: PropTypes.func,
  updateBusinessJustification: PropTypes.func,
  // intl: PropTypes.arrayOf(
  //   PropTypes.shape({
  //     locale: PropTypes.string.isRequired,
  //     formatMessage: PropTypes.func.isRequired,
  //   })
  // ).isRequired,
  setRequestableDates: PropTypes.func.isRequired,
  from: PropTypes.string.isRequired,
  captureSelectedInstanceParams: PropTypes.func.isRequired,
};

IDAssignment.defaultProps = {
  businessJustification: '',
  setBusinessJustification: () => {},
  updateBusinessJustification: () => {},
};

export default injectIntl(IDAssignment);
