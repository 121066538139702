import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { checkEnvironment } from 'ui-common/src/lib/utils';
import './Logout.scss';

const { THEME } = checkEnvironment();

class SomethingWentWrong extends Component {
  constructor(props) {
    super(props);
    this.login = this.login.bind(this);
  }

  login() {
    const { history } = this.props;
    history.push('/login');
  }

  renderImage = theme => {
    switch (theme) {
      case 'defaultTheme':
        return (
          <img
            className="app-logout-logo pb-5"
            src="/ECMv6/assets/images/logo/saviynt-app-logo.png"
            alt="saviyntlogo"
          />
        );
      case 'marriottTheme':
        return (
          <img
            className="app-logout-logo pb-5"
            src="/ECMv6/assets/images/logo/marriott.png"
            alt="marriottlogo"
          />
        );
      case 'ibmTheme':
        return (
          <img
            className="app-logout-logo"
            src="/ECMv6/assets/images/logo/ibm_accesshub.png"
            alt="ibmlogo"
          />
        );
      default:
        return (
          <img
            className="app-logout-logo pb-5"
            src="/ECMv6/assets/images/logo/saviynt-app-logo.png"
            alt="saviyntlogo"
          />
        );
    }
  };

  render() {
    return (
      <div className="h-100 logout-section">
        <h2 className="logout-logo">{this.renderImage(THEME)}</h2>
        <div className="d-flex justify-content-center">
          <div className="d-flex flex-column justify-content-center">
            <div className="align-self-center">
              <img src="/ECMv6/assets/images/logout_illustration.png" alt="logout_illustration" />
            </div>
            <p className="align-self-center logout-msg">Oops something went wrong!!!</p>

            <button type="button" className="btn btn-logout btn-primary" onClick={this.login}>
              Back to Login
            </button>
          </div>
        </div>
      </div>
    );
  }
}

SomethingWentWrong.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default withRouter(SomethingWentWrong);
