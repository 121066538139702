import React, { useState, useEffect } from 'react';
import MuiTextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import { useDebounce } from 'use-debounce';

const TextField = withStyles(theme => ({
  root: {
    width: '100%',
    '& label.Mui-focused': {
      color: theme.palette.primary.main,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: theme.palette.primary.main,
    },
    '& .MuiOutlinedInput-root': {
      color: getComputedStyle(document.body).getPropertyValue('--black'),
      '& fieldset': {
        borderColor: getComputedStyle(document.body).getPropertyValue('--grey-border-input'),
      },
      '&:hover fieldset': {
        borderColor: theme.palette.primary.main,
      },
      '&.Mui-focused fieldset': {
        border: '1px solid',
        borderColor: theme.palette.primary.main,
      },
    },
  },
}))(MuiTextField);

const OutlinedTextField = ({
  name,
  placeholder,
  _handleChange,
  attributeKey,
  defaultValue,
  isPassword,
  showError,
  errorMessage,
  multiline,
  required,
  customEndAdornmentIcon,
  isDisabled
}) => {
  const [value, setValue] = useState('');
  const [debouncedText] = useDebounce(value, 500);
  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);
  useEffect(() => {
    if (debouncedText) {
      _handleChange(value, attributeKey);
    } else {
      setValue('');
      _handleChange('', attributeKey);
    }
  }, [debouncedText]);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);
  
  const handleChange = e => setValue(e.target.value);

  const passwordProps = isPassword
    ? {
        type: 'password',
        autoComplete: 'current-password',
        disabled: isDisabled,
      }
    : {};

  return (
    <TextField
      multiline={multiline}
      margin="none"
      variant="outlined"
      // placeholder={<span className="mandatory">&nbsp;*</span>}
      placeholder={`${placeholder}`}
      value={value}
      InputLabelProps={{ shrink: true }}
      InputProps={{
        style: { height: 42, fontSize: 12 },
        endAdornment: required ? (
          <InputAdornment position="end">
            <span className="mandatory">&nbsp;*</span>
          </InputAdornment>
        ): customEndAdornmentIcon ? customEndAdornmentIcon:  null,
      }}
      // label={<span className="mandatory">&nbsp;*</span>}
      // label={
      //   <>
      //     <span dangerouslySetInnerHTML={{ __html: placeholder }} />
      //     {required && <span className="mandatory">&nbsp;*</span>}
      //   </>
      // }
      id={name}
      onChange={handleChange}
      error={showError}
      helperText={showError ? errorMessage : null}
      {...passwordProps}

    />
  );
};

OutlinedTextField.propTypes = {
  _handleChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  attributeKey: PropTypes.string.isRequired,
  defaultValue: PropTypes.string.isRequired,
  isPassword: PropTypes.bool,
  showError: PropTypes.bool,
  multiline: PropTypes.bool,
  errorMessage: PropTypes.string,
  // _onFocus: PropTypes.func.isRequired,
  // required: PropTypes.bool.isRequired,
  // multiLine: PropTypes.bool.isRequired,
  // showError: PropTypes.bool.isRequired,
  // readOnly: PropTypes.bool.isRequired,
  customEndAdornmentIcon: PropTypes.string,
  isDisabled: PropTypes.bool,
};

OutlinedTextField.defaultProps = {
  showError: false,
  multiline: false,
  isPassword: false,
  errorMessage: 'Invalid Input',
  customEndAdornmentIcon:'',
  isDisabled: false,
};

export default OutlinedTextField;
