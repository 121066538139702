import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: small => ({
    color: theme.palette.grey[500],
    padding: small ? 6 : 9,
    zIndex: 2000,
  }),
  defaultPos: small => ({
    position: 'absolute',
    right: theme.spacing(small ? 1 : 5),
    top: theme.spacing(small ? 0.25 : 2.5),
  }),
  icon: small => ({
    height: small ? 23 : 31,
    width: small ? 23 : 31,
    color: small ? '#9d9d9d' : '#000',
  }),
}));

const CloseButton = ({ handleClose, small, defaultPosition, className }) => {
  const classes = useStyles(small);
  return (
    <IconButton
      className={`${classes.root} ${defaultPosition ? classes.defaultPos : ''} ${className}`}
      aria-label="close"
      onClick={handleClose}
    >
      <CloseIcon className={classes.icon} />
    </IconButton>
  );
};

CloseButton.propTypes = {
  handleClose: PropTypes.func.isRequired,
  small: PropTypes.bool,
  defaultPosition: PropTypes.bool,
  className: PropTypes.string,
};

CloseButton.defaultProps = {
  small: false,
  defaultPosition: false,
  className: '',
};

export default CloseButton;
