import React from 'react';
import './Footer.css';
import { injectIntl } from "react-intl";

const Footer = props => (
    <div className="footer-layout" dangerouslySetInnerHTML={{
        __html: props.intl.formatMessage({
            id: 'footer',
        })
    }} />
);


export default injectIntl(Footer);
