import React from 'react';
import PropTypes from 'prop-types';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles, withStyles } from '@material-ui/core/styles';
// import clsx from 'clsx';

const fileNames = {
  awsLogo: 'aws_logo.png',
  awsConsoleLogo: 'aws-console.png',
  awsWindows: 'aws-windows.png',
  awsLinux: 'aws-linux.png',
  awsRds: 'aws-database.png',
  azureLogo: 'azure_logo.png',
  gcpLogo: 'gcp_logo.png',
  sapLogo: 'sap_logo_new.png',
  RDSLogo: 'rds.png',
  ec2Logo: 'ec2.png',
  cloudSqlLogo: 'cloud_sql.png',
  computeEngineLogo: 'compute_engine.png',
  azureVmLogo: 'azure_vm.png',
  adLogo: 'ad_logo.png',
  azureMysql: 'azure_mysql.png',
  salesForce: 'salesforce_logo.png',
  office365: 'Office365_logo.png',
  mySql: 'mysql_logo.png',
  ansible: 'ansible.png',
  jenkins: 'jenkins.png',
  sap: 'sap.png',
  chef: 'chef.png',
  azure: 'azure.png',
  teams: 'teams.png',
  dynamics: 'dynamics.png',
  salesforce: 'sfdc.png',
  workday: 'workday.png',
  microsoft365: 'microsoft365.png',
  okta_logo: 'okta_logo.png',
  Credentials_logo: 'Credentials_logo.png',
  OnPrem_logo: 'OnPrem_logo.png',
  serviceaccounts: 'service_accounts.png',
  database: 'DB.png',
  onPremServer: 'onPremServer.png',
  linux: 'Linux.png',
  windows: 'windows.png',
  GeneralApp:'GENERAL.png'
};

const imageSelection = (connName, entName, instanceId) => {
  const basePath = '/ECMv6/assets/images/';
  const basePath2 = '/ECMv6/assets/images/app_req_icons/';
  let [connectionType, entitlement] = connName.split('-');
  connectionType = connectionType.toLowerCase();
  if (entName) entitlement = entName.toLowerCase();
  else if (entitlement) entitlement = entitlement.toLowerCase();

  if (instanceId !== undefined || instanceId !== '' || instanceId !== 'undefined') {
    if (instanceId === 'SFDC-RemoteApp') {
      return `${basePath}${fileNames.salesForce}`;
    }
    if (instanceId === 'SAPShcut-RemoteApp') {
      return `${basePath}${fileNames.sapLogo}`;
    }
    if (instanceId === 'O365-RemoteApp') {
      return `${basePath}${fileNames.office365}`;
    }
    if (instanceId === 'MySQL - RemoteApp') {
      return `${basePath}${fileNames.mySql}`;
    }
    if (instanceId === 'SAPLogin-RemoteApp') {
      return `${basePath}${fileNames.sapLogo}`;
    }
    if (instanceId.includes('O365-RemoteApp')) {
      return `${basePath}${fileNames.office365}`;
    }
    if (instanceId.includes('MySQL')) {
      return `${basePath}${fileNames.mySql}`;
    }
    if (instanceId.includes('ansible') || instanceId.includes('Ansible')) {
      return `${basePath2}${fileNames.ansible}`;
    }
    if (instanceId.includes('jenkins') || instanceId.includes('Jenkins')) {
      return `${basePath2}${fileNames.jenkins}`;
    }
    if (instanceId.includes('sap') || instanceId.includes('SAP')) {
      return `${basePath2}${fileNames.sap}`;
    }
    if (instanceId.includes('chef') || instanceId.includes('Chef')) {
      return `${basePath2}${fileNames.chef}`;
    }
    if (instanceId.includes('azure') || instanceId.includes('Azure')) {
      return `${basePath2}${fileNames.azure}`;
    }
    if (instanceId.includes('teams') || instanceId.includes('Teams')) {
      return `${basePath2}${fileNames.teams}`;
    }
    if (instanceId.includes('dynamics') || instanceId.includes('Dynamics')) {
      return `${basePath2}${fileNames.dynamics}`;
    }
    if (instanceId.includes('microsoft365') || instanceId.includes('Microsoft365')) {
      return `${basePath2}${fileNames.microsoft365}`;
    }
    if (instanceId.includes('workday') || instanceId.includes('Workday')) {
      return `${basePath2}${fileNames.workday}`;
    }
    if (instanceId.includes('salesforce') || instanceId.includes('Salesforce')) {
      return `${basePath2}${fileNames.salesforce}`;
    }
  }
  if (connectionType === 'aws')
    switch (entitlement) {
      case 'rds':
      case 'database':
        return `${basePath}${fileNames.database}`;
      case 'db':
        return `${basePath}${fileNames.database}`;
      case 'instance':
        return `${basePath}${fileNames.ec2Logo}`;
      case 'linux':
        return `${basePath}${fileNames.linux}`;
      case 'unix':
        return `${basePath}${fileNames.linux}`;
      case 'windows':
        return `${basePath}${fileNames.windows}`;
      case 'console':
        return `${basePath}${fileNames.awsConsoleLogo}`;
      default:
        return `${basePath}${fileNames.awsLogo}`;
    }
  else if (connectionType === 'gcp')
    switch (entitlement) {
      case 'rds':
      case 'sql':
      case 'database':
        return `${basePath}${fileNames.database}`;
      case 'db':
        return `${basePath}${fileNames.database}`;
      case 'linux':
        return `${basePath}${fileNames.linux}`;
      case 'unix':
        return `${basePath}${fileNames.linux}`;
      case 'windows':
        return `${basePath}${fileNames.windows}`;
      case 'instance':
        return `${basePath}${fileNames.computeEngineLogo}`;
      case 'serviceaccounts':
        return `${basePath}${fileNames.serviceaccounts}`;
      default:
        return `${basePath}${fileNames.gcpLogo}`;
    }
  else if (connectionType === 'azure')
    switch (entitlement) {
      case 'rds':
        return `${basePath}${fileNames.azureMysql}`;
      case 'vm_instance':
        return `${basePath}${fileNames.azureVmLogo}`;
      default:
        return `${basePath}${fileNames.azureLogo}`;
    }
  else if (connectionType === 'sap') {
    return `${basePath}${fileNames.sapLogo}`;
  } else if (connectionType === 'ad' || connectionType === 'active directory') {
    return `${basePath}${fileNames.adLogo}`;
  } else if (connectionType === 'Okta' || connectionType === 'okta') {
    return `${basePath2}${fileNames.okta_logo}`;
  } else if (connectionType === 'rest') {
    return `${basePath2}${fileNames.Credentials_logo}`;
  } else if (connectionType === 'on') {
    switch (entitlement) {
      case 'database':
        return `${basePath}${fileNames.database}`;
      case 'linux':
        return `${basePath}${fileNames.linux}`;
      case 'unix':
        return `${basePath}${fileNames.linux}`;
      case 'windows':
        return `${basePath}${fileNames.windows}`;
      default:
        return `${basePath}${fileNames.OnPrem_logo}`;
    }
  }
  else if (connectionType === "general app"){
        return `${basePath2}${fileNames.GeneralApp}`;
      }
  return '';
};

const useStyles = makeStyles({
  root: {
    background: '#f1f1f1',
    borderRadius: '50%',
    minWidth: 39,
    maxWidth: 39,
    height: 39,
    marginRight: 25,
    marginLeft: 8,
    marginTop: 3,
    padding: 0,
    alignSelf: 'center',
  },
  headerRoot: {
    background: '#f1f1f1',
    borderRadius: '50%',
    minWidth: 52,
    maxWidth: 52,
    height: 52,
    marginRight: 10,
    // marginLeft: 10,
    marginTop: 0,
    padding: 0,
    alignSelf: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

const CustomAvatar = withStyles({
  root: {
    width: '100%',
    height: '100%',
  },
  img: {
    width: props => (props.smallerImg ? '71%' : '100%'),
    borderRadius: 0,
    objectFit: 'contain',
  },
})(Avatar);

const AvatarLogo = ({ connectionType, entitlement, isHeader, instanceId }) => {
  // console.log(
  //   `**** connectionType: ${connectionType}, entitlement: ${entitlement} instanceId: ${instanceId}`
  // );
  const classes = useStyles();
  const imgSrc = imageSelection(connectionType, entitlement, instanceId);
  return (
    imgSrc && (
      <ListItemAvatar className={isHeader ? classes.headerRoot : classes.root}>
        <CustomAvatar
          alt="App Type"
          src={imgSrc}
          smallerImg={['SAP', 'Active Directory'].indexOf(connectionType) === -1}
          style={
            ['Azure', 'GCP', 'Okta', 'REST', 'On-Premise'].indexOf(connectionType) !== -1
              ? { paddingBottom: '4px' }
              : {}
          }
        />
      </ListItemAvatar>
    )
  );
};

AvatarLogo.propTypes = {
  connectionType: PropTypes.string,
  entitlement: PropTypes.string,
  instanceId: PropTypes.string,
  isHeader: PropTypes.bool,
};

AvatarLogo.defaultProps = {
  connectionType: '',
  entitlement: '',
  instanceId: '',
  isHeader: false,
};

export default AvatarLogo;
