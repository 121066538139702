import React from 'react';
// eslint-disable-next-line import/extensions
import './role_entitlement_tab.scss';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { SaviyntGrid } from '../Grid/SaviyntGrid';

const RoleEntitlementTab = ({ entitlementvalue, intl }) => {
  const getFormattedMessage = messageId => intl.formatMessage({ id: messageId });
  const columns = [
    {
      viewKey: 'entitlementValueName',
      key: 'entitlementValueName',
      label: getFormattedMessage('EntitlementValue'),
    },
    {
      viewKey: 'entitlementType',
      key: 'entitlementType',
      label: getFormattedMessage('EntitlementType'),
    },
    {
      viewKey: 'systemName',
      key: 'systemName',
      label: getFormattedMessage('SystemName'),
      maxLength: 10,
    },
    {
      viewKey: 'description',
      key: 'description',
      label: getFormattedMessage('Description'),
      maxLength: 10,
      defaultToolTip: true,
    },
  ];

  return (
    <div className="ele-container">
      <SaviyntGrid
        columns={columns}
        data={entitlementvalue || []}
        setAppliedFilters={() => { }}
        appliedFilters={{}}
        reqBodyOptions={{}}
        setReqBodyOptions={() => { }}
        topOffset={34}
      />
      {entitlementvalue.length === 0 && <div className="rolesFound">{
        intl.formatMessage({ id: 'RoleRequestDetails.EmptyEntitlements.label' })}</div>}
    </div>
  );
};

RoleEntitlementTab.propTypes = {
  entitlementvalue: PropTypes.arrayOf(
    PropTypes.shape({
      entitlementValue: PropTypes.string,
    })
  ).isRequired,
  intl: PropTypes.arrayOf(
    PropTypes.shape({
      locale: PropTypes.string.isRequired,
      formatMessage: PropTypes.func.isRequired,
    })
  ).isRequired,
};

export default injectIntl(RoleEntitlementTab);
