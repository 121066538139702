import { getCssTheme, getMuiTheme } from './themeCreator';

const rgbTheme = theme => {
  const cssTheme = getCssTheme(theme);
  const muiTheme = getMuiTheme(cssTheme, theme);

  return {
    cssTheme,
    muiTheme,
  };
};

export default rgbTheme;
