import React, { useState, useEffect } from 'react';
import { IntlProvider, addLocaleData } from 'react-intl';
import { Provider, useDispatch, useSelector } from 'react-redux';
import fr from 'react-intl/locale-data/fr';
import { MuiThemeProvider } from '@material-ui/core/styles';
import ConnectedResponseMessageHandling from './misc/ResponseHandler/ResponseHandler';
import { initialiseEnv, loadStateFromStorage } from 'ui-common/src/lib/utils';
import { BrowserRouter as Router } from 'react-router-dom';
import { useQuery } from './misc/hooks';
import ConnectedLoadingHandling from './misc/Loading/Loading';
import store from './misc/store';
import themes from './themes';
// import 'antd/dist/antd.css';
import './AppHeader.css';
import { Header } from './features/MainLayout/Header';
import {
  appConstantsAction,
  appConstantsSuccess,
  getHeaderDetails,
  appMessages,
  userModulesAction,
  loginInfoAction,
  userDetailsAction,
} from 'ui-common/src/utils/LoginActions';
import { Footer } from './features/MainLayout/Footer';
import { fetchThemeConfigList } from './features/AdminHome/redux/adminHome-actions';

addLocaleData([...fr]);

const AppHeaderBody = () => {
  const dispatch = useDispatch();
  const query = useQuery();
  // const { THEME } = useSelector(state => state.user.domainInfo) || { THEME: 'defaultTheme' };
  const messages = useSelector(state => state.user.messages) || {};
  const envConfig = useSelector(state => state.user.domainInfo);
  // let { cssTheme, muiTheme } = themes[THEME];
  const THEME_COLORS = useSelector(state => state.user.rgb);
  const { RGB_THEME_COLORS } = useSelector(state => state.user.domainInfo) || {
    RGB_THEME_COLORS: THEME_COLORS,
  };
  const THEME = themes({});
  const [jsonEditorTheme, setJsonEditorTheme] = useState({});
  const [previewTheme, setPreviewTheme] = useState(false);
  const [configThemeObj, setThemeObj] = useState({});
  const { defaultThemeConfig } = useSelector(state => state.adminHome.uiConfig);
  // const THEME_COLOR = themes(RGB_THEME_COLORS);
  const domainName = query.get('domain') || window.location.hostname.split('.')[0];
  if (query.get('DEMO_MODE')) window.DEMO_MODE = true;
  // useEffect(() => {
  //   ({ cssTheme, muiTheme } = themes[THEME]);
  //   Object.keys(cssTheme).forEach(key => {
  //     const value = cssTheme[key];
  //     document.documentElement.style.setProperty(key, value);
  //   });
  // }, [THEME]);
  // useEffect(() => {
  //   // ({ cssTheme, muiTheme } = themes[THEME] || themes.defaultTheme);
  //   // &Nagesh, THEME_COLOR is already locked line 82. how change in RGB_THEME_COLORS affect output of following code.
  //   Object.keys(THEME_COLOR.cssTheme).forEach(key => {
  //     const value = THEME_COLOR.cssTheme[key];
  //     document.documentElement.style.setProperty(key, value);
  //   });
  // }, [RGB_THEME_COLORS]);

  useEffect(() => {
    const appConstants =
      loadStateFromStorage('appConstants') && loadStateFromStorage('appConstants')[domainName];
    if (appConstants && Object.keys(appConstants).length) {
      dispatch(appConstantsSuccess(appConstants));
      dispatch(loginInfoAction());
      dispatch(userDetailsAction());
      dispatch(getHeaderDetails(appConstants.GATEWAY_API_URL));
      dispatch(
        appMessages(
          query.get('locale') || localStorage.getItem('locale') || 'en',
          appConstants.GATEWAY_API_URL
        )
      );
      dispatch(fetchThemeConfigList());
    } else {
      dispatch(appConstantsAction(domainName));
    }
  }, []);


  useEffect(() => {
    const serializedState = JSON.parse(localStorage.getItem('uiConfigTheme'));
    const serializedStatePreview = JSON.parse(localStorage.getItem('uiConfigThemeModal'));

    // ({ cssTheme, muiTheme } = themes[THEME] || themes.defaultTheme);
    // &Nagesh, THEME_COLOR is already locked line 82. how change in RGB_THEME_COLORS affect output of following code.

    if (
      serializedState &&
      Object.keys(serializedState).length > 0 &&
      Object.keys(serializedState.previewTheme).length > 0 &&
      serializedStatePreview.showPreviewTheme
    ) {
      const preview = themes(JSON.parse(serializedState.previewTheme));
      Object.keys(preview.cssTheme).forEach(key => {
        const value = preview.cssTheme[key];
        document.documentElement.style.setProperty(key, value);
      });
      setJsonEditorTheme(preview);
      setPreviewTheme(true);
      setThemeObj(JSON.parse(serializedState.previewTheme));
    } else if (defaultThemeConfig && Object.keys(defaultThemeConfig).length) {
      const THEME_COLOR = themes(defaultThemeConfig);

      Object.keys(THEME_COLOR.cssTheme).forEach(key => {
        const value = THEME_COLOR.cssTheme[key];
        document.documentElement.style.setProperty(key, value);
      });
      setJsonEditorTheme(THEME_COLOR);
      setPreviewTheme(false);
      setThemeObj(defaultThemeConfig);
    }
  }, [defaultThemeConfig]);

  useEffect(() => {
    initialiseEnv(envConfig);
    dispatch(userModulesAction());
    const appConstants =
      loadStateFromStorage('appConstants') && loadStateFromStorage('appConstants')[domainName];
    if (appConstants && Object.keys(appConstants).length) {
      dispatch(getHeaderDetails(appConstants.GATEWAY_API_URL));
      dispatch(userDetailsAction());
      dispatch(fetchThemeConfigList());
    }

  }, [envConfig]);
  return (
    <IntlProvider locale="en" messages={messages}>
      <div className="main-container">
        <MuiThemeProvider theme={jsonEditorTheme.muiTheme}>
          <Router basename="/ECMv6">
            <Header />
            <Footer />
          </Router>
          <ConnectedResponseMessageHandling />
          <ConnectedLoadingHandling />
        </MuiThemeProvider>
      </div>
    </IntlProvider>
  );
};
const AppHeader = () => (
  <Provider store={store}>
    <AppHeaderBody />
  </Provider>
);
export default AppHeader;
