import { ShowLoaderType, HideLoaderType } from 'ui-common/src/utils/Loading-actions';

export const LoadingReducer = (state = { isLoading: false }, action) => {
  switch (action.type) {
    case ShowLoaderType: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case HideLoaderType: {
      return {
        ...state,
        isLoading: false,
      };
    }
    default: {
      return state;
    }
  }
};

export default LoadingReducer;
