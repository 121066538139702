import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import useSyncScroll from 'react-use-sync-scroll';
import {
  hideBackgroundScroll,
  userAttributePrefix,
  userAttributePostfix,
} from 'ui-common/src/lib/utils';
import { cloneDeep } from 'lodash';
import { Icon } from '../Icon';
import GridHeader from './components/GridHeader';
import { SaviyntGrid } from './SaviyntGrid';
import { A } from '../A';
import styles from './GridBoot.module.scss';

const GridV2 = ({
  columns,
  data,
  uniqueKey,
  onRowSelected,
  totalCount,
  advancedFilterColumns,
  appliedFilters,
  setAppliedFilters,
  reqBodyOptions,
  setReqBodyOptions,
  globalSearchText,
  setGlobalSearchText,
  topOffset,
  isLoading,
  disableAdvancedFilters,
  hiddenColumns,
  fixedWidths,
  intl,
  children,
  intlColumnsrequired,
  requestColCacheEnabled,
  dimensions,
  countLoading,
  setReqBodyOptionsPaginaton,
  dynamicView,
  isArchive,
  disablePagination,
  columnPaginationEnabled,
  columnHeaderAlignment,
}) => {
  const [displayColumns, setDisplayColumns] = useState(columns || []);
  const [unCheckedColumns, setUnCheckedColumns] = useState(hiddenColumns || []);
  const [filtersCount, setFiltersCount] = useState(0);
  const [checkedFilters, setCheckedFilters] = useState(appliedFilters);
  const [showAdvancedFilter, setShowAdvancedFilter] = useState(false);
  const [shownColumns, setShownColumns] = useState(
    displayColumns.filter(col => !unCheckedColumns.includes(col.key))
  );
  const topScrollRef = useRef(null);
  const movingTableRef = useRef(null);
  const movingTransparentTableRef = useRef(null);
  const fixedTableRef = useRef(null);
  const fixedTransparentRef = useRef(null);
  const scrollingRefs = useRef([movingTransparentTableRef, movingTableRef, topScrollRef]);
  useSyncScroll(scrollingRefs, { vertical: false, horizontal: true });
  const clonedDisplayColumns = cloneDeep(displayColumns);

  let nofLastFixedColumns = displayColumns.filter((d, i) => {
    if (i > displayColumns.length / 2 && d.isFixedColumn) return d;
  }).length;
  const [lastTableShowColumns, setLastTableShowColumns] = useState(
    clonedDisplayColumns
      .slice(clonedDisplayColumns.length - 2, clonedDisplayColumns.length)
      .concat(clonedDisplayColumns.slice(0, clonedDisplayColumns.length - 2).reverse())
      .filter(col => !unCheckedColumns.includes(col.key))
  );

  // const lastTableShowColumns = () => cloneDeep(displayColumns).reverse().filter(col => !unCheckedColumns.includes(col.key));

  useEffect(() => {
    nofLastFixedColumns = displayColumns.filter((d, i) => {
      if (i > displayColumns.length / 2 && d.isFixedColumn) return d;
    }).length;
    setShownColumns(displayColumns.filter(col => !unCheckedColumns.includes(col.key)));
    setLastTableShowColumns(
      clonedDisplayColumns
        .slice(clonedDisplayColumns.length - nofLastFixedColumns, clonedDisplayColumns.length)
        .concat(
          clonedDisplayColumns.slice(0, clonedDisplayColumns.length - nofLastFixedColumns).reverse()
        )
        .filter(col => !unCheckedColumns.includes(col.key))
    );
  }, [unCheckedColumns, displayColumns]);

  useEffect(() => {
    if (dimensions) {
      if (dimensions.margin)
        document.documentElement.style.setProperty('--gridMargin', `${dimensions.margin}%`);
      if (dimensions.width)
        document.documentElement.style.setProperty('--gridWidth', `${dimensions.width}%`);
      document.documentElement.style.setProperty(
        '--fixedTable2Right',
        `${100 -
          ((dimensions.margin ? dimensions.margin : 0) +
            (dimensions.width ? dimensions.width : 0))}%`
      );
    } else {
      document.documentElement.style.setProperty('--gridMargin', '10%');
      document.documentElement.style.setProperty('--gridWidth', '80%');
      document.documentElement.style.setProperty('--fixedTable2Right', '10%');
    }
  }, [dimensions]);

  useEffect(() => {
    setDisplayColumns(columns);
  }, [columns]);
  useEffect(() => {
    setTimeout(() => {
      // dont show top scrollbar if data has not arrived yet.
      if (isLoading) {
        document.querySelector('.noContent').style.width = 0;
        return;
      }
      document.querySelector('.noContent').style.width = `${
        document.querySelector('.gridData.fixedTable .divTable').offsetWidth
      }px`;
    }, 100);
    // by the time data arrives tablerefs are ready.
  }, [unCheckedColumns, isLoading]);
  useEffect(() => {
    hideBackgroundScroll(showAdvancedFilter);
    return () => {
      hideBackgroundScroll(false);
    };
  }, [showAdvancedFilter]);

  const getColumnLabel = key => {
    let mappedKey = key;
    if (key === 'requestedForUserAttrs') mappedKey = 'requestedFor';
    else if (key === 'requestorUserAttrs') mappedKey = 'requestor';
    return columns.filter(item => item.key === mappedKey)[0].label;
  };
  const getLabelFunction = key => {
    const defaultFn = val => val;
    const col = columns.find(c => c.key === key);
    if (!col || !col.filter || !col.filter.optionFunctions) return defaultFn;
    return col.filter.optionFunctions.getTitle || defaultFn;
  };

  const showFilterBar = filters =>
    Object.keys(filters).map(filterId => {
      if (Array.isArray(filters[filterId]) && filters[filterId].length) {
        const getTitle = getLabelFunction(filterId);
        return filters[filterId]
          .map(op => getTitle(op))
          .map(filterLabel => (
            <li key={filterLabel}>
              <span className="uppercase text-black font-normal">
                {' '}
                {getColumnLabel(filterId)}
:{' '}
              </span>
              {filterLabel.length > 0
                ? intlColumnsrequired && intlColumnsrequired.includes(filterId)
                  ? intl.formatMessage({ id: filterLabel })
                  : filterLabel
                : '(No Value)'}
              <A
                href="#"
                className={styles.remove_icon}
                onClick={e => {
                  e.preventDefault();
                  removeFilter({ filterId, filterLabel, getTitle });
                }}
              >
                <Icon name="remove" />
              </A>
            </li>
          ));
      }
      if (typeof filters[filterId] === 'string') {
        return (
          <li key={filters[filterId]}>
            <span className="uppercase text-black font-normal"> 
{' '}
{getColumnLabel(filterId)}: </span>
            {filters[filterId]}
            <A
              href="#"
              className={styles.remove_icon}
              onClick={e => {
                e.preventDefault();
                removeFilter({ filterId, filterLabel: filters[filterId] });
              }}
            >
              <Icon name="remove" />
            </A>
          </li>
        );
      }
      if (typeof filters[filterId] === 'object' && Object.keys(filters[filterId]).length) {
        return Object.keys(filters[filterId]).map(key => {
          if (filters[filterId][key].length) {
            return filters[filterId][key].map(val => {
              return (
                <li key={val}>
                  <span className="uppercase text-black font-normal">
                    {getColumnLabel(filterId)}
:{' '}
                  </span>
                  {`${intl.formatMessage({
                    id:
                      key === 'username'
                        ? `AdvanceFilter.UserAttribute.${key}`
                        : `${userAttributePrefix}${key}${userAttributePostfix}`,
                  })}: ${val}`}
                  <A
                    href="#"
                    className={styles.remove_icon}
                    onClick={e => {
                      e.preventDefault();
                      removeFilter({ filterId, filterLabel: filters[filterId], key, val });
                    }}
                  >
                    <Icon name="remove" />
                  </A>
                </li>
              );
            });
          }

          // return null;
        });
      }
      return null;
    });

  const removeFilter = ({ filterId, filterLabel, key, val, getTitle }) => {
    let updatedFilters = {};
    if (Array.isArray(appliedFilters[filterId])) {
      updatedFilters = {
        ...appliedFilters,
        [filterId]: appliedFilters[filterId].filter(val => getTitle(val) !== filterLabel),
      };
    } else if (typeof appliedFilters[filterId] === 'string') {
      updatedFilters = {
        ...appliedFilters,
        [filterId]: undefined,
      };
    } else if (typeof appliedFilters[filterId] === 'object') {
      const resultArr = appliedFilters[filterId][key].filter(item => item !== val);
      const filter = {
        ...appliedFilters[filterId],
        [key]: resultArr,
      };
      if (resultArr.length === 0) {
        delete filter[key];
      }
      updatedFilters = {
        ...appliedFilters,
        [filterId]: filter,
      };
    }
    setAppliedFilters(updatedFilters);
    setCheckedFilters(updatedFilters);
  };

  return (
    <>
      {!children && (
        <GridHeader
          globalSearchText={globalSearchText}
          setGlobalSearchText={setGlobalSearchText}
          reqBodyOptions={reqBodyOptions}
          showAdvancedFilter={showAdvancedFilter}
          setShowAdvancedFilter={setShowAdvancedFilter}
          topOffset={topOffset}
          disableAdvancedFilters={disableAdvancedFilters}
          totalCount={totalCount}
          countLoading={countLoading}
          setReqBodyOptions={setReqBodyOptions}
          filtersCount={filtersCount}
          intl={intl}
          showFilterBar={showFilterBar}
          setAppliedFilters={setAppliedFilters}
          appliedFilters={appliedFilters}
          setCheckedFilters={setCheckedFilters}
          advancedFilterColumns={advancedFilterColumns}
          requestColCacheEnabled={requestColCacheEnabled}
          setReqBodyOptionsPaginaton={setReqBodyOptionsPaginaton}
          dynamicView={dynamicView}
          isArchive={isArchive}
          disablePagination={disablePagination}
          columnPaginationEnabled={columnPaginationEnabled}
        />
      )}
      {children &&
        children({
          globalSearchText,
          setGlobalSearchText,
          reqBodyOptions,
          showAdvancedFilter,
          setShowAdvancedFilter,
          topOffset,
          disableAdvancedFilters,
          totalCount,
          setReqBodyOptions,
          filtersCount,
          intl,
          showFilterBar,
          setAppliedFilters,
          appliedFilters,
          setCheckedFilters,
          advancedFilterColumns,
          uniqueKey,
          onRowSelected,
        })}
      <div className="noContentScroll" ref={topScrollRef} style={{ top: `${topOffset + 50}px` }}>
        <div className="noContent">.</div>
      </div>
      <div className="d-flex fixedGrid">
        {/* <div className="clickArrow1">
        <IconButton>
        <KeyboardArrowLeftIcon
        className="cursor-pointer"
        // onClick={scrollTab()}
        onClick={() => scrollTabLeft()}
        />      
        </IconButton>

        </div> */}
        <SaviyntGrid
          columns={columns}
          // columns={columns.slice(0, 1)}
          data={data}
          uniqueKey={uniqueKey}
          onRowSelected={onRowSelected}
          setAppliedFilters={setAppliedFilters}
          appliedFilters={appliedFilters}
          reqBodyOptions={reqBodyOptions}
          setReqBodyOptions={setReqBodyOptions}
          globalSearchText={globalSearchText}
          setGlobalSearchText={setGlobalSearchText}
          topOffset={topOffset + 57}
          // topScrollRef={topScrollRef}
          isLoading={isLoading}
          hiddenColumns={hiddenColumns}
          id="fixedTable"
          tableRef={fixedTableRef}
          transparentTableRef={fixedTransparentRef}
          displayColumns={[]}
          setDisplayColumns={null}
          unCheckedColumns={[]}
          setUnCheckedColumns={null}
          shownColumns={shownColumns}
          checkedFilters={checkedFilters}
          setCheckedFilters={setCheckedFilters}
          setFiltersCount={setFiltersCount}
          width={fixedWidths.start}
          hideRearrangeColumns
          requestColCacheEnabled={requestColCacheEnabled}
          isArchive={isArchive}
          columnPaginationEnabled={columnPaginationEnabled}
          columnHeaderAlignment={columnHeaderAlignment}
        />
        <SaviyntGrid
          columns={columns}
          data={data}
          uniqueKey={uniqueKey}
          onRowSelected={onRowSelected}
          setAppliedFilters={setAppliedFilters}
          appliedFilters={appliedFilters}
          reqBodyOptions={reqBodyOptions}
          setReqBodyOptions={setReqBodyOptions}
          globalSearchText={globalSearchText}
          setGlobalSearchText={setGlobalSearchText}
          topOffset={topOffset + 57}
          tableRef={movingTableRef}
          transparentTableRef={movingTransparentTableRef}
          isLoading={isLoading}
          hiddenColumns={hiddenColumns}
          id="movingTable"
          displayColumns={[]}
          setDisplayColumns={null}
          unCheckedColumns={[]}
          setUnCheckedColumns={null}
          shownColumns={shownColumns}
          checkedFilters={checkedFilters}
          setCheckedFilters={setCheckedFilters}
          setFiltersCount={setFiltersCount}
          hideRearrangeColumns
          requestColCacheEnabled={requestColCacheEnabled}
          isArchive={isArchive}
          columnPaginationEnabled={columnPaginationEnabled}
          columnHeaderAlignment={columnHeaderAlignment}
          // style={{ marginLeft: "-" + fixedWidths.start }}
        />
        <SaviyntGrid
          columns={columns}
          data={data}
          // uniqueKey={uniqueKey}
          onRowSelected={onRowSelected}
          setAppliedFilters={setAppliedFilters}
          appliedFilters={appliedFilters}
          reqBodyOptions={reqBodyOptions}
          setReqBodyOptions={setReqBodyOptions}
          globalSearchText={globalSearchText}
          setGlobalSearchText={setGlobalSearchText}
          topOffset={topOffset + 57}
          // topScrollRef={topScrollRef}
          isLoading={isLoading}
          hiddenColumns={hiddenColumns}
          id="fixedTable2"
          displayColumns={displayColumns}
          setDisplayColumns={setDisplayColumns}
          unCheckedColumns={unCheckedColumns}
          setUnCheckedColumns={setUnCheckedColumns}
          shownColumns={lastTableShowColumns}
          checkedFilters={checkedFilters}
          setCheckedFilters={setCheckedFilters}
          setFiltersCount={setFiltersCount}
          width={fixedWidths.end}
          requestColCacheEnabled={requestColCacheEnabled}
          isArchive={isArchive}
          columnPaginationEnabled={columnPaginationEnabled}
          columnHeaderAlignment={columnHeaderAlignment}
        />
      </div>
      <div id="filterPanelPortal" />
    </>
  );
};

GridV2.defaultProps = {
  disablePagination: false,
};

GridV2.propTypes = {
  topOffset: PropTypes.number.isRequired,
  totalCount: PropTypes.number.isRequired,
  isLoading: PropTypes.bool.isRequired,
  globalSearchText: PropTypes.string.isRequired,
  setReqBodyOptions: PropTypes.func.isRequired,
  setAppliedFilters: PropTypes.func.isRequired,
  setGlobalSearchText: PropTypes.func.isRequired,
  advancedFilterColumns: PropTypes.node.isRequired,
  reqBodyOptions: PropTypes.shape({
    offset: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
  }).isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
    })
  ).isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      viewKey: PropTypes.string.isRequired,
      key: PropTypes.string.isRequired,
    })
  ).isRequired,
  appliedFilters: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
    })
  ).isRequired,
  uniqueKey: PropTypes.string.isRequired,
  onRowSelected: PropTypes.func.isRequired,
  disableAdvancedFilters: PropTypes.bool.isRequired,
  disablePagination: PropTypes.bool.isRequired,
};

const GridIntl = injectIntl(GridV2);
export { GridIntl as GridV2 };
