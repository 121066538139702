export const ShowMessageActionType = 'showmessagetype';
export const ResetErrorActionType = 'resererroractiontype';

export const ShowMessageModal = data => ({
  type: ShowMessageActionType,
  payload: data,
});

export const resetError = () => ({
  type: ResetErrorActionType,
});
