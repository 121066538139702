import React from 'react';
import PropTypes from 'prop-types';
import { EllipsedLabel } from 'ui-components/src';
import styles from './DynamicAttributes.module.scss';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';



const useStyles = makeStyles(theme => ({
  chip: {
    marginRight: theme.spacing(0.5),
  },
  infoIcon: {
    fill: '#c9c9c9',
  },
}));
const DynamicAttributes = ({ dynamicAttributes, requestDescription }) => {
  const dynamicAttri = dynamicAttributes;
  const classes = useStyles();
  const attributeValueDisplay = item => {
    try {
      switch (item.format) {
        case '4':
        case '5':
        case '6':
        case '7':
        case '10':
          if (item.attributeValue && Array.isArray(item.attributeValue)) {
            return (
              item.attributeValue.map(value => {
                return <Chip label={value} className={classes.chip} />;
              })
            );
          }
          return <span
            className={`${styles.dynamicColor} mb-3`}
            dangerouslySetInnerHTML={{ __html: item.attributeValue }}
          />;

        case '8':
          return <EllipsedLabel
            label={'*'.repeat([item.attributeValue.length])
            }
            maxLength={45}
          />;
        default:
          const isHtml = /<\/?[a-z][\s\S]*>/i.test(item.attributeValue);
          let value = '';
          if (item.format === '8') {
            const masskedtext = [...item.attributeValue].map(() => "*");
            value = (<span className={`${styles.dynamicColor} mb-3`}>{masskedtext}</span>)
          } else {
            (
              value = <span className={`${styles.dynamicColor} mb-3`}>
                {' '}
                <EllipsedLabel
                  label={item.attributeValue
                  }
                  maxLength={item.attributeValue.length
                  }
                />{' '}
              </span>
            )
          }
          if (isHtml) {
            value = (
              <span
                className={`${styles.dynamicColor} mb-3`}
                dangerouslySetInnerHTML={{ __html: item.attributeValue }}
              />
            );
          }
          return value;
      }
    } catch {
      //exception
      return <span />;
    }

  };

  return (
    <div className={styles.endpointAttribute}>
      {dynamicAttri.map(attr => {
        const dynamicAttributesHavingValue = attr.attributesList.filter(
          attribute => attribute.attributeValue
        );
        return (
          <>
            {dynamicAttributesHavingValue.length > 0 && <h6 dangerouslySetInnerHTML={{ __html: attr.groupName }}>
            </h6>}
            <div className={`${styles.dynamicAttributeHeader} flex-wrap`}>
              {attr.attributesList.map(atrList => {
                let description = '';
                if (atrList.selectedValue && atrList.selectedValue.length > 0) {
                  description = atrList.selectedValue[0].description;
                }
                if (atrList.attributeValue && atrList.showAttribute !== 'HIDE') {
                  return (
                    <>
                      <div className="d-flex flex-column flex-wrap pl-0 text-break-all col-4">
                        <div
                          className={styles.dynamicLabelKey}
                          dangerouslySetInnerHTML={{ __html: atrList.attributeDisplayName }}
                        />
                        <div className="d-flex">
                          {attributeValueDisplay(atrList)}
                          {description &&
                            <Tooltip
                              enterDelay="300"
                              arrow
                              title={description}
                              placement="top"
                              classes={{ tooltip: styles.tooltip }}
                              className="mb-3 ml-1"
                            >
                              <InfoIcon fontSize="small"
                                classes={{ root: classes.infoIcon }} />
                            </Tooltip>
                          }
                        </div>

                      </div>


                    </>
                  );
                }
                return null;
              })}
            </div>

          </>
        );
        return null;
      })}
    </div>
  );
};
DynamicAttributes.propTypes = {
  dynamicAttributes: PropTypes.shape({
    groupName: PropTypes.string,
    attributesList: PropTypes.shape({
      attributeKey: PropTypes.string,
      format: PropTypes.string,
    }),
  }).isRequired,
};
export default DynamicAttributes;
