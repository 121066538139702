import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/extensions
import { matchRouteToRegExArr } from 'ui-common/src/lib/utils';
import { Cookies } from 'react-cookie';

const ModuleLink = ({ url, children, type, className, id, setAppName, featureName, ...props }) => {
  const cookies = new Cookies();
  const allowedRoutes = useSelector(state => state.user.userModules);
  const featureList = useSelector(state => state.user.featureNameList);
  const allowedReactRoutes = allowedRoutes.map(r => {
    if (r) return r.replace('/ECMv6', '');
    return r;
  });
  if (matchRouteToRegExArr(url, allowedReactRoutes, featureList, featureName).length) {
    if (type === 'sidebar')
      return (
        <a
          href={url}
          target="_self"
          className={className}
          role="menuitem"
        >
          {children}
        </a>
      );
    if (type === 'appSwitcher')
      return (
        <div className="col-md-4">
          <a
            href={url}
            target="_self"
            onClick={() => {
              // sessionStorage.setItem('micro_app', id);
              setAppName(id);
              return true;
            }}
            className="link"
          >
            {children}
          </a>
        </div>
      );
    return (
      <Link to={url} className={className} {...props}>
        {children}
      </Link>
    );
  }
  return null;
};
ModuleLink.propTypes = {
  url: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  type: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  setAppName: PropTypes.func.isRequired,
};
export default ModuleLink;
