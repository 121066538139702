import React, { useRef } from 'react';
import useOnClickOutside from 'use-onclickoutside';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { injectIntl } from 'react-intl';
// import '../PrivilegedSessions.scss';
import { Progress } from 'antd';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { showLeftDurationForSession } from 'ui-common/src/lib/utils';
import style from './EndSessionModal.module.scss';

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={() => onClose(false)}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const EndSessionModal = ({ ...props }) => {
  const wrapperRef = useRef(null);

  useOnClickOutside(wrapperRef, () => {
    props.showEndModal(false);
  });

  const getProgressPercent = (endDate, startDate) => {
    let percent = 0;
    if (startDate !== '' && endDate !== '') {
      const now = moment(new Date());
      const start = moment(startDate);
      const end = moment(endDate);
      const duration1 = moment.duration(start.diff(now));
      const duration2 = moment.duration(end.diff(now));
      percent = (duration1.asHours() / (duration1.asHours() + duration2.asHours())) * 100;
    }
    return percent;
  };

  const terminateSession = () => {
    const finalActiveSessionsSelected = {
      endSessionModels: props.activeSessionsSelected.map(session => ({
        requestAccessKey: session.requestAccessKey.toString(),
        accountName: session.privilegeId,
        userName: session.username,
        resourceId: session.instanceIdSession,
        removeaccess: 'true',
      })),
    };

    props.captureSelectedPrivilegedSessionParams({
      finalActiveSessionsSelected,
      typeOfAction: 'endsession',
    });
    props.showEndModal(false);
  };

  return (
    <Dialog
      aria-labelledby="customized-dialog-title"
      open
      classes={{ paperScrollPaper: style.endSessiondialog }}
    >
      <DialogTitle id="customized-dialog-title" onClose={() => props.showEndModal(false)}>
        {props.intl.formatMessage({
          id: 'activeSessions.ConfSessionTermination',
        })}
      </DialogTitle>
      <DialogContent classes={{ root: style.endTerminalDialog }}>
        <Typography className={style.sessionText} gutterBottom>
          {props.intl.formatMessage({
            id: 'activeSessions.EndSessionDiscription',
          })}
        </Typography>

        <div className={style.overflowSession}>
          {props.activeSessionsSelected &&
            props.activeSessionsSelected.map(session => (
              <div className={style.endSessionList}>
                <div className="d-flex flex-column pb-1">
                  <div className="d-flex">
                    <div className="d-flex flex-column col-md-4">
                      <span className={style.requestText}>
                        {props.intl.formatMessage({
                          id: 'EndSessionModal.RequestID',
                        })}
                      </span>
                      <span className={style.requestSubText}>{session.requestId}</span>
                    </div>
                    <div className="d-flex flex-column col-md-3">
                      <span className={style.requestText}>
                        {' '}
                        {props.intl.formatMessage({
                          id: 'EndSessionModal.UserID',
                        })}
                        :
                      </span>
                      <span className={style.requestSubText}>{session.privilegeId}</span>
                    </div>
                    <div className="d-flex flex-column col-md-4">
                      <span className={style.requestText}>
                        {props.intl.formatMessage({
                          id: 'advancefilter.col.Name',
                        })}
                      </span>
                      <span className={style.requestSubText}>{session.instanceId}</span>
                    </div>
                  </div>
                </div>

                <div className="d-flex flex-column pt-2">
                  <div className="d-flex">
                    <div className={`${style.validDate} d-flex flex-column col-md-4`}>
                      <span className={style.requestText}>
                        {props.intl.formatMessage({
                          id: 'EndSessionModal.ValidUntil',
                        })}
                      </span>
                      <span className={style.dateText}>{session && session.enddate}</span>
                      <Progress
                        percent={getProgressPercent(session.enddate, session.startDate)}
                        showInfo={false}
                        strokeColor={{
                          '0%': getComputedStyle(document.body).getPropertyValue('--primary-bg'),
                          '100%': getComputedStyle(document.body).getPropertyValue(
                            '--primary-main-me-op'
                          ),
                        }}
                        strokeWidth="5px"
                      />
                    </div>
                    <div className="d-flex flex-column col-md-3 justify-content-end pb-1">
                      <span className={style.hoursLeft}>
                        {props.intl.formatMessage({
                          id: 'EndSessionModal.DurationLeft',
                        })}
                      </span>
                      <span className={style.dateText}>
                        {showLeftDurationForSession(session.enddate)}
                      </span>
                    </div>
                    <div className="col-sm-2 left-brdr">
                      <div className="text-left top-spacing">
                        <img
                          src={`/ECMv6/assets/images/app_req_icons/${session.icon}.png`}
                          width="30px"
                          alt="aws_logo"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </DialogContent>
      <DialogActions className={style.endsessionFooter}>
        <Button
          className={style.cancelSessionButton}
          autoFocus
          color="primary"
          data-dismiss="modal"
          onClick={() => {
            props.showEndModal(false);
            props.setShowSmallModal(false);
            props.setUncheckCheckbox(false);
          }}
        >
          {props.intl.formatMessage({
            id: 'activeSessions.CancelTerminateBtn',
          })}
        </Button>
        <Button
          className={style.terminateSessionButton}
          autoFocus
          color="primary"
          onClick={() => terminateSession()}
        >
          {props.intl.formatMessage({
            id: 'activeSessions.TerminateBtn',
          })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
EndSessionModal.propTypes = {
  intl: PropTypes.arrayOf(
    PropTypes.shape({
      locale: PropTypes.string.isRequired,
      formatMessage: PropTypes.func.isRequired,
    })
  ).isRequired,
  activeSessionsSelected: PropTypes.arrayOf(
    PropTypes.shape({
      activeSessionsSelected: PropTypes.number.isRequired,
    })
  ).isRequired,
  showEndModal: PropTypes.func.isRequired,
  captureSelectedPrivilegedSessionParams: PropTypes.func.isRequired,
};
export default injectIntl(EndSessionModal);
