import React from 'react';
import PropTypes from 'prop-types';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';
import './FormComponents.scss';

const useStyles = makeStyles({
  label: {
    fontFamily: 'Rubik',
    fontSize: '12px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#999999',
  },
  checkBoxStyle: {
    color: '#999999',
  },
});
const BooleanField = ({ placeholder }) => {
  const [checkedValue, setCheckedValue] = React.useState(null);
  const classes = useStyles();
  const handleChange = event => {
    // ToDo -  console.log(event.target.checked);
    setCheckedValue(event.target.checked);
  };
  return (
    <div>
      <FormControlLabel
        classes={{
          label: classes.label,
        }}
        control={
          <Checkbox
            onChange={e => {
              handleChange(e);
            }}
            value={placeholder}
            checked={checkedValue}
            color="primary"
            className={classes.checkBoxStyle}
          />
        }
        label={<span dangerouslySetInnerHTML={{ __html: placeholder }} />}
      />
    </div>
  );
};

BooleanField.propTypes = {
  placeholder: PropTypes.string.isRequired,
};

export default BooleanField;
