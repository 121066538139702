import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { withStyles } from "@material-ui/core/styles";
import MuiExpansionPanel from "@material-ui/core/ExpansionPanel";
import MuiExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import MuiExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { EllipsedLabel } from "../EllipsedLabel";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const ExpansionPanel = withStyles({
  root: {
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
  root: {
    minHeight: 56,
    padding: 0,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "0",
    },
  },
  expanded: {},
})(MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles((theme) => ({
  root: {
    padding: 0,
  },
}))(MuiExpansionPanelDetails);

const useStyles = makeStyles((theme) => ({
  checboxWidth: { width: "100%" },
  checkText: {
    fontSize: 14,
    color: "#242424",
  },
}));

const CheckBoxView = ({
  getDistinctColumnValues,
  column,
  appliedFilters,
  setAppliedFilters,
  dataSource,
  requestBody,
  loadingColumns,
}) => {
  const [selectedList, setSelectedList] = useState([]);
  const [expanded, setExpanded] = useState(false);

  const classes = useStyles();
  useEffect(() => {
    const url = { ...column.filter };
    if (
      appliedFilters[column.key] !== undefined &&
      appliedFilters[column.key].length > 0
    ) {
      const url = { ...column.filter };
      getDistinctColumnValues(column.key, url.filterURL, requestBody, column);
      setExpanded(true);
    }
  }, []);

  useEffect(() => {
    const selected =
      Object.keys(appliedFilters).length === 0
        ? []
        : appliedFilters[column.key];
    if (selected !== undefined && selected.length > 0) {
      setSelectedList(selected);
    } else if (selected !== undefined && selected.length === 0) {
      setSelectedList(selected);
    }
  }, [appliedFilters]);

  const onCheckedChange = (checked, column, item) => {
    let selected = selectedList.slice();
    if (checked) {
      selected.push(item);
      setSelectedList(selected);
    } else {
      selected = selected.filter((ele) => ele !== item);
      setSelectedList(selected);
    }
    setAppliedFilters({ ...appliedFilters, [column]: selected });
  };

  const onExpand = (e, expanded) => {
    if (expanded) {
      setExpanded(true);
    } else {
      setExpanded(false);
    }
    if (expanded && dataSource.length === 0) {
      const url = { ...column.filter };
      getDistinctColumnValues(column.key, url.filterURL, requestBody, column);
    }
  };

  return (
    <div>
      <ExpansionPanel square expanded={expanded} onChange={onExpand}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1d-content"
          id="panel1d-header"
        >
          <div className="advfilter-title">{column.label}</div>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className="d-flex flex-column panel-det-padd">
          {!loadingColumns.includes(column.key) ? <div className="row filter-check-items">
            {dataSource.map(
              (item, key) =>
                item && (
                  <div className="col-sm-12">
                    <div>
                      <FormControlLabel
                        classes={{
                          label: classes.checkText,
                        }}
                        value="end"
                        control={
                          <Checkbox
                            size="small"
                            color="primary"
                            checked={!!selectedList.includes(item)}
                            onChange={(e) =>
                              onCheckedChange(
                                e.target.checked,
                                column.key,
                                item
                              )
                            }
                          />
                        }
                        label={<EllipsedLabel label={item} maxLength={41} />}
                        labelPlacement="end"
                      />
                    </div>{" "}
                  </div>
                )
            )}
          </div> :
            <div className="d-flex justify-center adv-fil-loader"><img src="/ECMv6/assets/images/Double Ring-1s-80px.gif" /></div>}
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  );
};

CheckBoxView.propTypes = {
  getDistinctColumnValues: PropTypes.func.isRequired,
  setAppliedFilters: PropTypes.func.isRequired,
  column: PropTypes.objectOf(
    PropTypes.shape({
      key: PropTypes.string,
      label: PropTypes.string,
      filter: PropTypes.objectOf(
        PropTypes.shape({
          filterURL: PropTypes.string,
          type: PropTypes.string,
        })
      ),
    })
  ).isRequired,
  appliedFilters: PropTypes.objectOf(PropTypes.shape({})).isRequired,
  dataSource: PropTypes.arrayOf(PropTypes.string).isRequired,
  requestBody: PropTypes.objectOf(
    PropTypes.shape({
      connectiontype: PropTypes.string,
    })
  ).isRequired,
  loadingColumns: PropTypes.arrayOf(PropTypes.string).isRequired,
};
export default CheckBoxView;
