import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { login, loginRequestAction, sessionExpireToast } from 'ui-common/src/utils/LoginActions';
import './login.css';
import MessageModal from 'ui-components/src/components-dup/MessageModal/MessageModal';
import { checkEnvironment } from 'ui-common/src/lib/utils';

const Login = props => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(false);

  const { THEME, SSO_LOGIN_URL, CALLBACK_URL, SSO_LOGIN } = useSelector(
    state => state.user.domainInfo
  ) || {
    THEME: 'defaultTheme',
  };

  const loginHandler = e => {
    e.preventDefault();
    if (username === '' || password === '') {
      setError(true);
    }

    const userData = {
      username,
      password,
    };

    props.loginRequestAction(
      userData,
      props.location.state ? props.location.state.destinationLocation : null
    );
  };
  useEffect(() => {
    if (SSO_LOGIN === true) {
      window.location.href = `//${SSO_LOGIN_URL}?url=/request/requestHome&callbackurl=${CALLBACK_URL}`;
    }
  }, [SSO_LOGIN_URL]);
  const ssologinHandler = e => {
    e.preventDefault();
    window.location.href = `//${SSO_LOGIN_URL}?url=/request/requestHome&callbackurl=${CALLBACK_URL}`;
  };

  const onDismissModal = e => {
    e.preventDefault();
    props.sessionExpireToast(false);
  };

  const renderImage = theme => {
    switch (theme) {
      case 'defaultTheme':
        return (
          <img
            className="saviynt-login-logo"
            src="/ECMv6/assets/images/logo/saviynt-app-logo.png"
            alt="saviyntlogo"
          />
        );
      case 'marriottTheme':
        return (
          <img
            className="marriott-logo pb-5"
            src="/ECMv6/assets/images/logo/saviynt-app-logo.png"
            alt="marriottlogo"
          />
        );
      case 'ibmTheme':
        return (
          <img
            className="ibmaccesshub-logo"
            src="/ECMv6/assets/images/logo/ibm_accesshub.png"
            alt="ibmlogo"
          />
        );
      default:
        return (
          <img
            className="saviynt-login-logo"
            src="/ECMv6/assets/images/logo/saviynt-app-logo.png"
            alt="saviyntlogo"
          />
        );
    }
  };
  if (window.location.hostname !== 'localhost') return null;
  return (
    <div className="container-fluid">
      <div className="row no-gutter">
        <div className="col-md-7 col-lg-5 loginarea">
          <div className="login d-flex py-5">
            <div className="container">
              <div className="row">
                {props.user.session ? (
                  <MessageModal
                    onDismiss={onDismissModal}
                    MesgType="failure"
                    mesgToDisplay="Session Expired. Please login to continue."
                  />
                ) : null}
                <div className="col-md-9 col-lg-8 mx-auto">
                  {renderImage(THEME)}
                  {error ? (
                    <p className="alert alert-danger">
                      You have not filled either username or password{' '}
                    </p>
                  ) : (
                    ''
                  )}
                  {props.user.error && (
                    <p className="alert alert-danger">
                      {props.intl.formatMessage({ id: props.user.error })}
                    </p>
                  )}
                  {checkEnvironment() && checkEnvironment().SSO_LOGIN ? (
                    <button
                      className="btn btn-primary btn-lg btn-block btn-okta"
                      type="submit"
                      onClick={ssologinHandler}
                    >
                      Login
                    </button>
                  ) : (
                    <form>
                      <div className="form-label-group">
                        <span className="icon-placeholder">
                          <svg
                            data-name="user"
                            width="14px"
                            height="15px"
                            viewBox="0 0 14 15.57"
                            fill={getComputedStyle(document.body).getPropertyValue('--primary-bg')}
                          >
                            <g>
                              <path d="M7 7.44a3.72 3.72 0 1 0-3.73-3.72A3.73 3.73 0 0 0 7 7.44zM10.63 8.3a2.82 2.82 0 0 0-2.37.11 3.94 3.94 0 0 1-1.28.3 3.94 3.94 0 0 1-1.29-.33 2.86 2.86 0 0 0-2.39-.11C1.14 9.48-.01 11.8-.01 14.99v.55h14v-.55c-.01-3.17-1.16-5.5-3.36-6.69z" />
                            </g>
                          </svg>
                        </span>
                        <input
                          type="text"
                          id="inputEmail"
                          className="form-control"
                          placeholder="User Name"
                          required
                          value={username}
                          onChange={e => setUsername(e.target.value)}
                        />
                      </div>

                      <div className="form-label-group">
                        <span className="icon-placeholder">
                          <svg data-name="key" width="14px" height="14px" viewBox="0 0 14 13.96">
                            <path
                              d="M5.23 6.59a3.82 3.82 0 1 0 1.29 6.25 3.78 3.78 0 0 0 .87-4l1-1 .31-1.82 1.73-.3.3-.3.27-1.69 1.73-.3L14 2.2V.02h-2.2zM3.6 11.65a.87.87 0 1 1 0-1.23.86.86 0 0 1 0 1.23z"
                              fill={getComputedStyle(document.body).getPropertyValue(
                                '--primary-bg'
                              )}
                            />
                          </svg>
                        </span>
                        <input
                          type="password"
                          id="inputPassword"
                          className="form-control"
                          placeholder="Password"
                          required
                          value={password}
                          onChange={e => setPassword(e.target.value)}
                        />
                      </div>
                      <div className="login-action">
                        <div className="forgot_link" />

                        <button
                          className="btn btn-primary btn-login text-uppercase mb-2"
                          type="submit"
                          onClick={loginHandler}
                        >
                          LOGIN
                        </button>
                      </div>
                    </form>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-5 col-lg-7 bg-image">
          <div className="d-flex slide-item h-100">
            <img src="/ECMv6/assets/images/PAM_login1.png" width="482px" alt="PAM Login" />
            <div className="taglines">
              <h1 className="title">
                Intelligent Identity. <br />
                Smarter Security.
              </h1>
              <p className="subtitle">Cloud Security & Identity Governance</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  user: state.user,
});

const mapDispatchToProps = {
  login,
  loginRequestAction,
  sessionExpireToast,
};

Login.propTypes = {
  location: PropTypes.string.isRequired,
  loginRequestAction: PropTypes.func.isRequired,
  sessionExpireToast: PropTypes.func.isRequired,
  user: PropTypes.arrayOf(
    PropTypes.shape({
      session: PropTypes.string.isRequired,
    })
  ).isRequired,
  intl: PropTypes.arrayOf(
    PropTypes.shape({
      locale: PropTypes.string.isRequired,
      formatMessage: PropTypes.func.isRequired,
    })
  ).isRequired,
};

const ConnectedLogin = injectIntl(connect(mapStateToProps, mapDispatchToProps)(Login));
ConnectedLogin.displayName = 'ConnectedLogin';
export default ConnectedLogin;
