import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import VerticalIconMenu from './VerticalIconMenu';
import './IconMenu.scss';

const useStyle = makeStyles({
  button: {
    height: 26,
    padding: '0 10px',
    whiteSpace: 'nowrap',
  },
});

const IconMenuWithButtons = ({ actionMenu, isSelected, displayButton, intl }) => {
  const classes = useStyle();
  return (
    <div className={`d-flex align-items-center row-action-area fixed-width-${actionMenu.length}`}>
      {!isSelected && !displayButton && (
        <div className="select-buttons">
          {actionMenu.map(item => (
            <Button
              color="primary"
              // variant="outlined"
              className={classes.button}
              onClick={item.handleClick}
            >
              {item.title}
            </Button>
          ))}
        </div>
      )}
      <div className={`selected-area${isSelected ? '' : ' not-selected'}`}>
        <Button
          color="primary"
          variant="contained"
          className={`${classes.button}${isSelected ? '' : ' hide'}`}
        >
          {intl.formatMessage({
            id: 'cc.button.selected',
          })}
        </Button>
        <VerticalIconMenu actionMenu={actionMenu} />
      </div>
    </div>
  );
};

IconMenuWithButtons.propTypes = {
  actionMenu: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      handleClick: PropTypes.func.isRequired,
    })
  ).isRequired,
  isSelected: PropTypes.bool.isRequired,
  displayButton: PropTypes.string.isRequired,
  intl: PropTypes.arrayOf(
    PropTypes.shape({
      locale: PropTypes.string.isRequired,
      formatMessage: PropTypes.func.isRequired,
    })
  ).isRequired,
};

export default injectIntl(IconMenuWithButtons);
