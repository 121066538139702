import React, { useState, useRef } from 'react';
// import DatePicker from "react-datepicker";
import moment from 'moment';
// import AutoSuggest from '../A'utoSuggest';
import useOnClickOutside from 'use-onclickoutside';
import './SelectDatesModal.css';
import { injectIntl } from 'react-intl';
import { Cookies } from 'react-cookie';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
// import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import {
  instanceCheckoutStartDateTime,
  instanceCheckoutEndDateTime,
} from 'ui-common/src/lib/utils';
import { MessageModal } from '../MessageModal';
import { TimeframeForm } from '../TimeframePopup';

const cookies = new Cookies();
// const getPrivilegeOptions = instances => {
//   if (instances.length === 1) {
//     const instance = instances[0];
//     if (instance.privilegedId) {
//       const isPrivIdsIncludeUserName = instance.privilegedId
//         .map(privId => privId.name)
//         .includes(cookies.get('user_name').split('@')[0]);
//       const privilegedIdOptions = preparePrivilegeIdDropDownOtions(
//         instance.privilegedId,
//         isPrivIdsIncludeUserName
//       );
//       return privilegedIdOptions;
//     }
//   }
//   if (instances.length > 1) {
//     // const commonPrivilegeIds = getCommonPrivilegIds(instances);
//     // const unionPrivilegeIds = getUnionOfPrivilegeIds(instances);
//     // // Check if any of the instanes already have logged in username as privlegeId
//     // const isPrivIdsIncludeUserName = unionPrivilegeIds
//     //   .map(privId => privId.name)
//     //   .includes(cookies.get('user_name').split('@')[0]);
//     // const privilegedIdOptions = preparePrivilegeIdDropDownOtions(
//     //   commonPrivilegeIds,
//     //   isPrivIdsIncludeUserName
//     // );
//     // return privilegedIdOptions;
//   }
//   return [];
// };

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const SelectDateModalNew = ({ instances, showModal, onDone, intl, configData }) => {
  const { defaultFromTime, defaultFromDate } = instanceCheckoutStartDateTime();
  const { defaultToTime, defaultToDate } = instanceCheckoutEndDateTime();
  const multiEdit = instances.length !== 1;
  const [messageModal, setMessageModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [fromDateTime, setFromDateTime] = useState(
    multiEdit
      ? defaultFromDate.set(defaultFromTime)
      : (instances[0].startTime && moment(instances[0].startTime)) ||
          (instances[0].fromDateTime && moment(instances[0].fromDateTime))
  );

  const [toDateTime, setToDateTime] = useState(
    multiEdit
      ? defaultToDate.set(defaultToTime)
      : (instances[0].endTime && moment(instances[0].endTime)) ||
          (instances[0].toDateTime && moment(instances[0].toDateTime))
  );
  const [startDate, endDate] = [instances[0].startDate, instances[0].endDate];
  const [startTime, endTime] = [fromDateTime, toDateTime];
  const selectedRole = !multiEdit && instances[0].selectedRole ? instances[0].selectedRole : null;
  const [selectedTickets, setSelectedTickets] = useState(instances[0].selectedTickets || []);

  const [businessJustification, setBusinessJustification] = useState(
    !multiEdit && instances[0].businessJustification ? instances[0].businessJustification : ''
  );
  const calendarOpen = false;

  const [selectedUser, setSelectedUser] = useState(instances && instances[0]);
  const [disable, setDisabled] = useState(false);
  const [selectedDateTime, setSelectedDateTime] = useState({});

  const selectDateModalRef = useRef(null);
  const disableProgress = disabledState => {
    // setDisabled(disabledState);
  };
  useOnClickOutside(selectDateModalRef, () => {
    if (!calendarOpen) showModal(true);
  });

  const instanceFromDate = fromDateTime.clone().set({
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  const instanceFromTime = {
    hours: fromDateTime.get('hours'),
    minutes: fromDateTime.get('minutes'),
    seconds: fromDateTime.get('seconds'),
  };
  const instanceToDate = toDateTime.clone().set({
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  const instanceToTime = {
    hours: toDateTime.get('hours'),
    minutes: toDateTime.get('minutes'),
    seconds: toDateTime.get('seconds'),
  };

  const submit = () => {
    if (fromDateTime.isAfter(toDateTime)) {
      setErrorMessage(intl.formatMessage({ id: 'StartDate.Less.EndDate' }));
      setMessageModal(true);
      setTimeout(() => {
        setMessageModal(false);
      }, 3000);
    } else if (fromDateTime.isBefore(moment())) {
      setErrorMessage(intl.formatMessage({ id: 'StartDate.More.CurrDate' }));
      setMessageModal(true);
      setTimeout(() => {
        setMessageModal(false);
      }, 3000);
    } else {
      onDone({
        fromDateTime,
        toDateTime,
        startDate,
        endDate,
        startTime,
        endTime,
        selectedRole,
        businessJustification:
          selectedTickets && selectedTickets.length > 0
            ? `${businessJustification.split('[')[0]}[ticket-${selectedTickets.join(',')}]`
            : businessJustification && businessJustification.split('[')[0],
        selectedTickets,
      });
      showModal(false);
      //   setSelectedRow({});
    }
  };

  const onDismissModal = e => {
    e.preventDefault();
    setMessageModal(false);
  };

  // const [open, setOpen] = React.useState(false);

  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  const handleClose = () => {
    // setOpen(false);
  };

  const isBusinessJustificationEmpty = (businessJustification, selectedTickets, instances) => {
    const isTicket = selectedTickets && selectedTickets.length > 0;
    let businessJustiValue = '';
    if (typeof businessJustification === 'object') {
      if (isTicket) {
        businessJustiValue = businessJustification.value.split('[');
        const [first] = [businessJustiValue[0]];
        businessJustiValue = first;
      } else {
        businessJustiValue = businessJustification.value;
      }
    } else if (isTicket) {
      businessJustiValue = businessJustification.split('[');
      const [first] = [businessJustiValue[0]];
      businessJustiValue = first;
    } else {
      businessJustiValue = businessJustification;
    }

    if (
      instances[0].from === 'role' &&
      (configData === 0 || configData === 1) &&
      businessJustiValue.trim() === ''
    ) {
      return true;
    }
    return false;
  };

  return (
    <div
      className="modal fade show"
      id="myModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-modal="true"
      // style="padding-right: 15px; display: block;"
    >
      {messageModal ? (
        <MessageModal onDismiss={onDismissModal} mesgToDisplay={errorMessage} MesgType="failure" />
      ) : null}
      <Dialog
        open
        TransitionComponent={Transition}
        // keepMounted
        classes={{ root: 'dialog-style' }}
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent classes={{ root: 'dialog-content' }}>
          {/* //className="modal-dialog" */}
          <div role="document" ref={selectDateModalRef}>
            <div className="modal-content confirm-modal">
              <div className="modal-header">
                <h5 className="modal-title instance">
                  {multiEdit
                    ? `${intl.formatMessage({ id: 'selInstSecStage.Edit' })} ${
                        instances.length
                      } instances`
                    : instances[0].from === 'role'
                    ? instances[0].roleName
                    : instances[0].name}
                </h5>
                <button
                  type="button"
                  className="btnClose"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    // setSelectedRow({});
                    showModal(false);
                  }}
                >
                  <span aria-hidden="true">
                    {/* <Icon name="close" /> */}
                    <i className="la la-close close-icon" />
                  </span>
                </button>
              </div>
              <div className="modal-body">
                <TimeframeForm
                  {...{
                    instanceFromDate,
                    instanceToDate,
                    instanceFromTime,
                    instanceToTime,
                    disableProgress,
                    businessJustification,
                    setBusinessJustification,
                    intl,
                    selectedUser,
                    setDisabled,
                    selectedDateTime,
                    selectedTickets,
                    setSelectedTickets,
                    configData,
                  }}
                  setSelectedDateTime={range => {
                    setFromDateTime(range.fromDateTime);
                    setToDateTime(range.toDateTime);
                    setSelectedDateTime({
                      fromDateTime: range.fromDateTime,
                      toDateTime: range.toDateTime,
                    });
                  }}
                />
              </div>
              <div className="modal-footer">
                <button
                  disabled={
                    disable ||
                    isBusinessJustificationEmpty(businessJustification, selectedTickets, instances)
                  }
                  type="button"
                  className="btn btn-outline-primary"
                  data-dismiss="modal"
                  onClick={submit}
                >
                  {intl.formatMessage({ id: 'DoneLabel' })}
                </button>
              </div>
            </div>
          </div>
        </DialogContent>
        {/* <DialogActions></DialogActions>   */}
      </Dialog>
    </div>
  );
};
SelectDateModalNew.propTypes = {
  showModal: PropTypes.func.isRequired,
  onDone: PropTypes.func,
  instances: PropTypes.arrayOf(
    PropTypes.shape({
      instanceId: PropTypes.string,
      fromDateTime: PropTypes.string,
      toDateTime: PropTypes.string,
      startTime: PropTypes.shape,
      endTime: PropTypes.shape,
      selectedRole: PropTypes.string,
      businessJustification: PropTypes.string,
    })
  ).isRequired,
  intl: PropTypes.arrayOf(
    PropTypes.shape({
      locale: PropTypes.string.isRequired,
      formatMessage: PropTypes.func.isRequired,
    })
  ).isRequired,
};
SelectDateModalNew.defaultProps = {
  onDone: {},
};
export default injectIntl(SelectDateModalNew);
