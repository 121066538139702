import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import useAutocomplete, { createFilterOptions } from '@material-ui/lab/useAutocomplete';
import NoSsr from '@material-ui/core/NoSsr';
import { Icon } from '../Icon';
import './AutocompleteDropdown.scss';
// import CheckIcon from '@material-ui/icons/Check';

const AutocompleteDropdown = ({
  name,
  options,
  defaultValue,
  placeholder,
  disabled,
  attributeKey,
  _handleChange,
  _getLabel,
  multiple,
  minInputLength,
  shortMenu,
  hideValue,
  disableAutoChange,
  filterOptions = createFilterOptions(),
}) => {
  const [value, setValue] = useState(defaultValue);
  const {
    getRootProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    focused,
    setAnchorEl,
  } = useAutocomplete({
    id: name,
    value,
    multiple,
    options: options || [],
    filterOptions,
    getOptionLabel: _getLabel,
    onChange: (e, value) => {
      e.preventDefault();
      setValue(value);
      _handleChange(value, attributeKey);
      if (hideValue) setValue('');
    },
  });

  useEffect(() => {
    setValue(defaultValue);
    if (!disableAutoChange) _handleChange(defaultValue, attributeKey);
  }, [defaultValue]);

  const inputProps = getInputProps();
  const showMenu = groupedOptions.length > 0;
  // !inputProps.value || (inputProps.value.length >= minInputLength && groupedOptions.length > 0);

  return (
    <NoSsr>
      <div className="dropdown-container">
        <div {...getRootProps()}>
          <div ref={setAnchorEl} className={`input-wrapper ${focused ? 'focused' : ''}`}>
            <input {...inputProps} {...{ placeholder, disabled }} />
            <Icon name="arrowDownForInput" className={(disabled) ? 'disabled': ''} onMouseDown={(disabled) ? null: inputProps.onMouseDown} />
          </div>
        </div>
        {showMenu ? (
          <ul {...getListboxProps()} className={`list-box${shortMenu ? ' short-menu' : ''}`}>
            {groupedOptions.map((option, index) => (
              <li {...getOptionProps({ option, index })}>
                <span>{_getLabel(option)}</span>
                {/* <CheckIcon fontSize="small" /> */}
              </li>
            ))}
          </ul>
        ) : null}
      </div>
    </NoSsr>
  );
};

AutocompleteDropdown.propTypes = {
  name: PropTypes.string,
  attributeKey: PropTypes.string.isRequired,
  defaultValue: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape).isRequired,
  filterOptions: PropTypes.func.isRequired,
  _handleChange: PropTypes.func,
  multiple: PropTypes.bool,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  _getLabel: PropTypes.func,
  minInputLength: PropTypes.number,
  shortMenu: PropTypes.bool,
  hideValue: PropTypes.bool,
  disableAutoChange: PropTypes.bool,
};

AutocompleteDropdown.defaultProps = {
  name: 'customized-select-input',
  placeholder: '',
  multiple: false,
  disabled: false,
  _getLabel: option => option,
  _handleChange: () => {},
  minInputLength: 0,
  shortMenu: false,
  hideValue: false,
  disableAutoChange: false,
};

export default AutocompleteDropdown;
