import React, { useEffect, useState } from 'react';
import MaskedInput from 'react-text-mask';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import './FormComponents.scss';

const hexRegEx = /[-!$%^&*()_+|~=`{}[:;<>?.@#\]\\'"/]/;

// const hexRegEx = /^[a-z]+(?!\1)[&-]/;

const maskFunction = rawValue => {
  const mask = new Array(rawValue.length);
  mask.fill(hexRegEx);
  return mask;
};
function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={maskFunction}
      guide={false}
    />
  );
}

TextMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

const SpecialCharatersTextField = ({ variant, _handleChange, defaultValue, unique=false }) => {
  const [textmask, setTextmask] = useState('');

  useEffect(() => {
    setTextmask(defaultValue);
  }, [defaultValue]);

  const handleChange = event => {
    const inputVal = event.target.value;
    const val = (unique) ? handleUnique(inputVal) : inputVal;
    event.target.value = val;
    setTextmask(val);
    _handleChange(val);
  };

  const handleUnique = (value) => {
    const strArray = value.split('');
    const charsSeen = {}
    let stripped = ''
    strArray.forEach(char => {
      if (!charsSeen[char.toLowerCase()]) {
        stripped += char
        charsSeen[char.toLowerCase()] = true
      }
    })
    return stripped
  }

  return (
    <div>
      <TextField
        fullWidth
        id="outlined-basic"
        autoComplete="off"
        label="eg: !@#$%*"
        variant={variant}
        value={textmask}
        onChange={e => handleChange(e)}
        InputProps={{
          inputComponent: TextMaskCustom,
        }}
      />
    </div>
  );
};

SpecialCharatersTextField.propTypes = {
  variant: PropTypes.string.isRequired,
  _handleChange: PropTypes.func.isRequired,
  defaultValue: PropTypes.string.isRequired,
  unique: PropTypes.bool
};

export default SpecialCharatersTextField;
