import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';

const EllipsedLabel = ({ maxLength, label, className }) => {
  const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      fontSize: theme.typography.pxToRem(13),
    },
  }))(Tooltip);

  return label && label.length > maxLength ? (
    <HtmlTooltip enterDelay="500" title={label} arrow >
      <span className={className ? className : ''}>{`${label.substring(0, maxLength)}...`}</span>
    </HtmlTooltip>
  ) : (
      <span className={className ? className : ''}>{label}</span>
    );
};
EllipsedLabel.defaultProps = {
  className: '',
};

EllipsedLabel.propTypes = {
  maxLength: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
};
export default EllipsedLabel;
