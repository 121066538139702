import _ from 'lodash';
import {
  loginRequestActionType,
  loginFailedActionType,
  loginSuccessActionType,
  loginInfoSuccessActionType,
  userInfoActionType,
  sessiontoast,
  logoutActionType,
  userModulesSuccessActionType,
  APP_CONSTANTS_SUCCESS,
  APP_MESSAGES_SUCCESS,
  GET_HEADER_MENU_SUCCESS,
  FULL_SCREEN_GLOBAL_OPEN,
  loginInfoAction,
  GET_INITIAL_DATA_ACTION,
  GET_INITIAL_DATA_SUCCESS_ACTION,
  PAGE_RELOAD
} from 'ui-common/src/utils/LoginActions';

export const loginReducer = (
  state = {
    userData: [],
    userInfo: [],
    userModules: [],
    userDetails: {},
    loginInfo: {},
    error: null,
    messages: {},
    session: false,
    fullscreenGlobalOpen: false, // global redux state to tell header component that fullscreen open. or closed for accessability
    headerMenu: {},
    rgb:
      '{"primary":[55,81,255],"primaryBg":[223,224,235],"secondaryBg":[234,238,251],"primaryBgLi":[242,244,255],"primaryCardBg":[105,116,208],"primaryCardBgText":[199,205,255],"secondary":[234,169,69],"lightBorder":[210,219,255],"hoverBg":[244,246,250],"pageBg":[247,248,252],"darkBg":[38,39,49],"darkBgLi":[50,50,62],"darkText":[208,208,208],"darkTitle":[208,208,208],"darkLine":[65,65,80],"navBarTitle":[100,100,100],"navBarBg":[255,255,255],"navBarLabel":[131,129,129],"navBarLabelHover":[55,81,255],"navBarLabelActive":[55,81,255],"navBarIcon":[131,129,129],"navBarIconHover":[55,81,255],"navBarIconActive":[55,81,255],"sodBg":[127, 105, 212],"approvalIconBg":[239,241,255],"disabledColor":[116,116,116],"primaryTextColor":[116,116,116]} ',
    appLoading: false,
  },
  action
) => {
  switch (action.type) {
    case loginRequestActionType: {
      return { ...state, userData: [], session: false };
    }

    case loginSuccessActionType: {
      return { ...state, userData: action.payload, session: false };
    }

    case loginFailedActionType: {
      return {
        ...state,
        error: 'LoginError',
        session: false,
      };
    }
    case loginInfoSuccessActionType: {
      return {
        ...state,
        loginInfo: action.payload,
      };
    }

    case userInfoActionType: {
      return { ...state, userInfo: action.payload, session: false };
    }
    case APP_CONSTANTS_SUCCESS: {
      return { ...state, domainInfo: action.payload };
    }
    case APP_MESSAGES_SUCCESS: {
      return { ...state, messages: action.payload };
    }
    case userModulesSuccessActionType: {
      const urlList = [];
      const featureNames = [];
      let featureNameList = [];
      if (action.payload) {
        _.forEach(action.payload, val => {
          if (val.url) urlList.push(val.url);
          if (val.name) featureNames.push(val.name);
        });
      }
      featureNameList = [...new Set(featureNames)];
      return { ...state, userModules: urlList, featureNameList };
    }

    case sessiontoast: {
      return { ...state, session: action.payload };
    }

    case logoutActionType:
      return {
        ...state,
        userData: [],
        userInfo: [],
        userModules: [],
        userDetails: {},
        loginInfo: {},
        error: null,
        session: false,
        fullscreenGlobalOpen: false,
      };

    case GET_HEADER_MENU_SUCCESS: {
      action.payload.ecmDateFormat =
        action.payload && action.payload.ecmDateFormat
          ? action.payload.ecmDateFormat.replace('DD', 'dd').replace('YYYY', 'yyyy')
          : 'MM-dd-yyyy';
      return {
        ...state,
        headerMenu: action.payload,
      };
    }

    case FULL_SCREEN_GLOBAL_OPEN: {
      return {
        ...state,
        fullscreenGlobalOpen: action.payload,
      };
    }

    case GET_INITIAL_DATA_ACTION: {
      return {
        ...state,
        appLoading: true,
      };
    }

    case GET_INITIAL_DATA_SUCCESS_ACTION: {
      return {
        ...state,
        appLoading: false,
      };
    }

    case PAGE_RELOAD:
      action.callback();
      return state;

    default: {
      return state;
    }
  }
};

export default loginReducer;
