import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@material-ui/core';
import ForwardIcon from '@material-ui/icons/NavigateNext';
import BackIcon from '@material-ui/icons/NavigateBefore';
import styles from './Carousel.module.scss';

const ArrowNav = ({ handleClick, backward, className, domRef }) => {
  return (
    <div className={className} ref={domRef}>
      <IconButton className={styles.button} onClick={handleClick}>
        {backward ? (
          <BackIcon fontSize="large" />
        ) : (
          <ForwardIcon fontSize="large" />
        )}
      </IconButton>
    </div>
  );
};

ArrowNav.propTypes = {
  handleClick: PropTypes.func.isRequired,
  backward: PropTypes.bool,
  className: PropTypes.string.isRequired,
};

ArrowNav.defaultProps = {
  backward: false,
};

export default ArrowNav;
