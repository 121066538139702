import React, { useState, useEffect, useRef } from 'react';
import './AdvancedFilter.scss';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { convertReduxToApi, RequestTypeList, RequestStatusList } from 'ui-common/src/lib/utils';
import CloseIcon from '@material-ui/icons/Close';
import { IconButton, Button } from '@material-ui/core';
import AutoSuggestView from './AutoSuggestView';
import CheckBoxView from './CheckBoxView';
import DateFilterView from './DateFilterView';

let completeDataGlobal = {};
const AdvancedFilterComponent = ({
  hideAdvanceFilter,
  appliedFilters,
  setAppliedFilters,
  columns,
  intl,
  requestColCacheEnabled,
}) => {
  const [completeData, setCompleteData] = useState({});
  const [advAppliedFilters, setAdvAppliedFilters] = useState(appliedFilters);
  const [resetDates, setResetDates] = useState(false);
  const [calendarOpen, setCalendarOpen] = useState(false);
  //const [isLoading, setIsLoading] = useState(false);
  const [preAppliedFilters, setPreAppliedFilters] = useState(appliedFilters);
  const [loadingColumns, setLoadingColumns] = useState([]);
  useEffect(() => {
    setAdvAppliedFilters(appliedFilters);
    setPreAppliedFilters(preAppliedFilters);
  }, [appliedFilters]);

  useEffect(() => {
    return () => {
      setCompleteData({});
      completeDataGlobal = {};
    };
  }, []);

  const setColumnData = (dat, column) => {
    let data = dat;
    data = Object.keys(data);
    data = data.filter(ele => ele !== '');
    completeDataGlobal = { ...completeDataGlobal, [column]: data };
    setCompleteData(completeDataGlobal);
  };

  const getDistinctColumnValues = (column, filterURL, requestBody, col) => {
    if (col && col.isCacheEnabled && requestColCacheEnabled) {
      let dat = column === 'requestType' ? RequestTypeList : RequestStatusList;
      setTimeout(() => setColumnData(dat, column), 100);
    } else {
      const url = filterURL;
      const colval = convertReduxToApi('columnvalues')[column] || column;
      const requestbody = requestBody;
      requestbody.column = colval;
      const temp = loadingColumns.slice();
      temp.push(col.key);
      setLoadingColumns(temp);
      // return;
      fetch(url, {
        method: 'POST',
        body: JSON.stringify(requestbody),
        headers: {
          'Content-Type': 'application/json',
          'X-NOLOADER': true,
        },
      })
        .then(response => response.json())
        .then(data => {
          setLoadingColumns(loadingColumns.filter(item => item !== col.key));
          if (!data.error) {
            setTimeout(() => setColumnData(data, column), 100);
          }
        })
        .catch(() => {
          setLoadingColumns(loadingColumns.filter(item => item !== col.key));
        });
    }

  };

  const applyFilters = appliedFilters => {
    setAppliedFilters(appliedFilters);
  };
  const getView = (filteritem, filterMainSection) => {
    if (filteritem.filter && filteritem.filter.type === 'unique') {
      return (
        <AutoSuggestView
          loadingColumns={loadingColumns}
          getDistinctColumnValues={getDistinctColumnValues}
          appliedFilters={advAppliedFilters}
          column={filteritem}
          dataSource={
            completeData[filteritem.key] !== undefined ? completeData[filteritem.key] : []
          }
          setAppliedFilters={setAdvAppliedFilters}
          requestBody={filterMainSection.ColumnRequestBody}
          preAppliedFilters={preAppliedFilters}
        />
      );
    }
    if (filteritem.filter && filteritem.filter.type === 'string') {
      return (
        <CheckBoxView
          getDistinctColumnValues={getDistinctColumnValues}
          appliedFilters={advAppliedFilters}
          column={filteritem}
          dataSource={
            completeData[filteritem.key] !== undefined ? completeData[filteritem.key] : []
          }
          setAppliedFilters={setAdvAppliedFilters}
          requestBody={filterMainSection.ColumnRequestBody}
          loadingColumns={loadingColumns}
        />
      );
    }
    if (filteritem.filter && filteritem.filter.type === 'date') {
      return (
        <DateFilterView
          appliedFilters={advAppliedFilters}
          column={filteritem}
          setAppliedFilters={setAdvAppliedFilters}
          resetDates={resetDates}
          setCalendarOpen={setCalendarOpen}
          preAppliedFilters={preAppliedFilters}
        />
      );
    }

    return null;
  };

  return (
    <div className="advance-filter-modal">
      <div className="advanced-filter-panel">
        <div className="advancefilter-heading">
          <div className="title"> {intl.formatMessage({ id: 'AdvFilter.Title' })}</div>
          <IconButton
            aria-label="close"
            onClick={() => {
              hideAdvanceFilter();
            }}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <div className="heading-border" />
        <div className="filter-body scrollbar" id="style-3">
          {columns.map(filterMainSection => (
            <>
              <h2 className="section-title">{filterMainSection.ColumnTitle}</h2>
              <div className="subtitle">{filterMainSection.ColumnSubTitle}</div>
              <div className="form-container">
                {filterMainSection.ColumnValues.map(filteritem =>
                  getView(filteritem, filterMainSection)
                )}
              </div>
            </>
          ))}
        </div>

        <div className="footer">
          <div
            type="button"
            className="reset"
            onClick={() => {
              setAdvAppliedFilters({});
              setResetDates(true);
            }}
          >
            {intl.formatMessage({ id: 'advancefilter.label.Reset' })}
          </div>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              applyFilters(advAppliedFilters);
              setTimeout(() => {
                hideAdvanceFilter();
              }, 300);
            }}
          >
            {`${intl.formatMessage({
              id: 'advancefilter.label.Apply',
            })}`}
          </Button>
        </div>
      </div>
    </div>
  );
};
AdvancedFilterComponent.propTypes = {
  hideAdvanceFilter: PropTypes.func.isRequired,
  setAppliedFilters: PropTypes.func.isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      ColumnTitle: PropTypes.string,
      ColumnSubTitle: PropTypes.string,
    })
  ).isRequired,
  appliedFilters: PropTypes.objectOf(PropTypes.shape({})).isRequired,
  intl: PropTypes.arrayOf(
    PropTypes.shape({
      locale: PropTypes.string.isRequired,
      formatMessage: PropTypes.func.isRequired,
    })
  ).isRequired,
};
export default injectIntl(AdvancedFilterComponent);
