export const UPDATE_KPI_CODE = 'kpiForm/neworupdateKpiFormCode';
export const UPDATE_KPI_ATTRIBUTES = 'kpiForm/updateKPIAttributes';
export const NEW_KPI_ATTRIBUTES = 'kpiForm/newKPIAttributes';
export const REFRESH_HOME_KPI = 'kpiForm/REFRESH_HOME_KPI';

export const newKpiCode = ({ codeStr, fileName }, refreshKPI) => ({
  type: UPDATE_KPI_CODE,
  payload: { resourceBody: codeStr, fileName, folderPath: '/app/kpi'},
  refreshKPI,
});
export const updateKpiAttributes = payload => ({
  type: UPDATE_KPI_ATTRIBUTES,
  payload: payload
});

export const newKpiAttributes = payload => ({
  type: NEW_KPI_ATTRIBUTES,
  payload: payload
});

export const refreshKPI = () => ({
  type: REFRESH_HOME_KPI,
});

