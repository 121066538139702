import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Cookies } from 'react-cookie';
import { push } from 'react-router-redirect';
import { showLeftDurationForSession } from 'ui-common/src/lib/utils';
import { Icon, A, EllipsedLabel } from 'ui-components/src';
import './CardView.scss';

const cookies = new Cookies();

const CardView = ({
  data,
  LaunchSessions,
  extraProps,
  requestHistoryCount,
  onRequestHistory,
  intl,
  setShowReserveNextSlot,
  setReserveSlotData,
}) => {
  const LaunchSession = row => {
    const dateinutc = moment
      .utc(moment(row.enddate, 'YYYY-MM-DD HH:mm:ss'))
      .format('YYYY-MM-DD HH:mm:ss');
    const sessiontobelaunchedArr = [];
    const sessiontobelaunched = {
      userKey: parseInt(cookies.get('userKey'), 10),
      userName: row.username,
      accountId: row.privilegeId,
      instanceId: row.instanceIdSession,
      endDate: dateinutc,
      accessKey: row.requestAccessKey.toString(),
      hostIp: row.hostIp,
      osType: row.osType,
      pamType: row.pamType,
      privilegeId: row.privilegeId,
      requestId: row.requestId,
      automatedProvisioning: row.automatedProvisioning,
      accountType: row.accountType,
      entitlementName: row.entitlementName,
      credentialtype: row.credentialtype,
      actualAccountId: row.accountId,
      publicIpSession: row.publicIpSession,
      sapInstanceId: row.sapInstanceId,
      sapHost: row.sapHost,
      sapSystemId: row.sapSystemId,
      awsAccountKey: row.awsAccountKey,
      connectionType: row.connectionType,
      remoteApp: row.remoteApp,
      port: row.port,
      remoteAppMetadata: row.remoteAppMetadata,
      authenticationType: row.authenticationType,
      codes: row.codes,
      requestAccessKey: row.requestAccessKey,
    };
    sessiontobelaunchedArr.push(sessiontobelaunched);
    LaunchSessions(sessiontobelaunchedArr);
  };

  return (
    <div className="d-flex flex-wrap card_view_sessions">
      {data.map(row => {
        const future = !(row.reqStatus === 3 && row.taskStatus > 1);
        return (
          <div className="card-view mb-3" key={row.privilegeId}>
            <div className="card d-inline-flex">
              <div className="card-header pl-0 pr-0 pt-2 ml-0 d-flex justify-content-between">
                {row.checkbox}
              </div>
              <div className="card-body d-flex flex-column">
                <div className="cardBody-header"> {row.instanceId}</div>
                <div className="cardBody-subHeader">
                  <EllipsedLabel maxLength="10" label={row.privilegeId} /> <span className="dot" />{' '}
                  <span className="bodySub-text">
                    {(row.authenticationType && row.authenticationType.toLowerCase() === 'snc') ||
                    (row.credentialtype && row.credentialtype.toLowerCase() === 'credentialless')
                      ? 'Credential-less'
                      : row.credentialtype}
                  </span>
                </div>
                {row.requestId === '530151' && extraProps ? (
                  <div className="suspicious-activity">
                    <img src="/ECMv6/assets/images/flag-icon.png" className="flag-icon" alt="flag-icon" />
                    <span className="suspicious-text">Suspicious Activity</span>
                  </div>
                ) : null}
                {/* {row.requestId === '530151' && extraProps ? (
                  <div className="suspicious-activity">(Suspicious Activity Detected)</div>
                ) : null} */}
              </div>
              <div className="card-footer d-flex flex-column">
                <div className="footer-requestId">
                  Request{' '}
                  <A
                    onClick={() => {
                      push(`/request/requestHistory/${row.requestId}`);
                    }}
                  >
                    #{row.requestId}
                  </A>
                </div>
                <div className="footer-text">
                  <span className={future ? 'label-left' : 'text-content'}>
                    {future ? 'not active yet' : showLeftDurationForSession(row.enddate)}
                  </span>
                  <span className="label-left">{future ? '' : ' left'}</span>
                  {/* <span className="body-text">{showLeftDurationForSession(row.enddate)}</span> */}
                </div>
                {row.requestType == 24 ? (
                  <button
                    className="btn btn-reserveslot mt-1"
                    disabled={row.requestType == 24 && row.reqStatus != 5}
                    type="submit"
                    onClick={() => {
                      setReserveSlotData({
                        accountkey: row.accountId,
                        credentialType: row.credentialtype,
                        endDate: row.ffidEndDate,
                        eDate: row.ffidEndDate,
                        name: row.privilegeId,
                        startDate: row.ffidStartDate,
                        stDate: row.ffidStartDate,
                        instance: row.connectionType,
                        accountId: row.accountKey,
                        endpointKey: row.endpointKey,
                        instanceId: row.instanceIdSession,
                      });
                      setShowReserveNextSlot(true);
                    }}
                  >
                    <Icon name="reserveSlot" className="customSQL-icon" />
                    Reserve Next Slot
                  </button>
                ) : (
                  <button
                    type="button"
                    className={future ? 'btn  btn-access mt-1 future-disabled' : 'btn btn-primary'}
                    onClick={() => {
                      if (!future) LaunchSession(row);
                    }}
                  >
                    {!!row.remoteAppMetadata &&
                      (row.remoteAppMetadata.icon.toLowerCase() === 'mysql_workbench' ? (
                        <img src="/ECMv6/assets/images/mysql_workbench.png" className="app-icon" />
                      ) : row.remoteAppMetadata.icon.toLowerCase() === 'toad' ? (
                        <img src="/ECMv6/assets/images/toad.png" className="app-icon" />
                      ) : (
                        <img src="/ECMv6/assets/images/globe.png" className="app-icon" />
                      ))}
                    {future
                      ? intl.formatMessage({ id: 'PrivilegedSessions.List.launch' })
                      : row.remoteAppMetadata === undefined
                      ? intl.formatMessage({ id: 'PrivilegedSessions.List.access' })
                      : intl.formatMessage({ id: 'PrivilegedSessions.List.launchViaApp' })}
                  </button>
                )}
              </div>
            </div>
          </div>
        );
      })}
      {requestHistoryCount > 0 && (
        <div className="card_view_sessions privileged_card_Sessions">
          <div className="card d-inline-flex">
            <div className="card-body">
              <a className="" rel="noopener noreferrer" target="_blank" href>
                <i className="icon-size">
                  <img src="/ECMv6/assets/images/time.png" alt="connector logo" className="connector" />
                </i>
              </a>
              <div className="pending_sessions_count">
                You have{' '}
                {requestHistoryCount === 1
                  ? `${requestHistoryCount} session`
                  : `${requestHistoryCount} sessions`}{' '}
                pending approval, go to{' '}
              </div>
              <div className="d-flex justify-content-center">
                <button
                  className="btn btn-ingrid btn-outline-primary request_history_button mt-1"
                  type="submit"
                  onClick={onRequestHistory}
                >
                  {intl.formatMessage({ id: 'activeSessions.RequestHistory' })}
                </button>
              </div>
              <div className="latestStatus">Or you can start</div>
              <span
                className="new-request"
                role="button"
                tabIndex={0}
                onClick={() => push('/request/privilegedAccess')}
                onKeyDown={() => {}}
              >
                {intl.formatMessage({ id: 'activeSessions.NewRequest' })}
              </span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

CardView.propTypes = {
  LaunchSessions: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      privilegeId: PropTypes.number.isRequired,
    })
  ).isRequired,
  extraProps: PropTypes.arrayOf.isRequired,
};

export default CardView;
