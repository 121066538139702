import React, { useState } from 'react';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import { useDispatch } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { LiveEditor, LiveProvider, LivePreview } from 'react-live';
import { NumberTextField, MultiSelectDropDown } from 'ui-components/src';
import { newKpiCode, updateKpiAttributes, newKpiAttributes } from '../redux/kpiForm-actions';
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import styles from './KpiSrcEdit.module.scss';
import Accordion from '@material-ui/core/Accordion';
import { Icon } from 'ui-components/src';
import DeleteIcon from '@material-ui/icons/Delete';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { useConfirm } from 'material-ui-confirm';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import CloseIcon from '@material-ui/icons/Close';
import Popover from '@material-ui/core/Popover';
import { injectIntl } from 'react-intl';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  popoverContent: {
    top: "10px !important",
    left: "65vw !important",
    height: "252px",
    width: '402px',
  },



}));

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});
const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    height: '500px',
  },
}))(MuiDialogContent);
const KPIDialog = isSafari
  ? withStyles(theme => ({
      paperScrollPaper: {
        margin: 10,
        marginLeft: "-12vw !important",
      },
    }))(Dialog)
  : withStyles(theme => ({
      root: {
        height: 'max-content',
      },
      paperScrollPaper: {
        margin: 10,
        marginLeft: "-12vw !important",
      },
    }))(Dialog);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);
const featureValues = [
  { ID: 'Application Request' },
  { ID: 'Application Request' },
  { ID: 'Application Collection' },
  { ID: 'Reset Account Password for Others' },
  { ID: 'Change Password for Self' },
  { ID: 'Change Password for Service Account' },
  { ID: 'Completed Tasks' },
  { ID: 'Create AD Group' },
  { ID: 'Create Delegate' },
  { ID: 'Create New Endpoint' },
  { ID: 'Create New Entitlement' },
  { ID: 'Create New Entitlement Type' },
  { ID: 'Create New Organization' },
  { ID: 'Create New Role' },
  { ID: 'Create Unix Group' },
  { ID: 'Create AAD Group' },
  { ID: 'Create User Request' },
  { ID: 'Manage Access for Others' },
  { ID: 'Manage AD Group' },
  { ID: 'Manage My Access' },
  { ID: 'Manage Roles' },
  { ID: 'Manage Service Account' },
  { ID: 'Manage UNIX Group' },
  { ID: 'Manage AAD Group' },
  { ID: 'One Click Disable User' },
  { ID: 'Pending Approvals' },
  { ID: 'Pending Tasks' },
  { ID: 'Request Access for Others' },
  { ID: 'Request Access for Others - Multi User' },
  { ID: 'Request Access for Self' },
  { ID: 'Application Request' },
  { ID: 'Pending Approvals' },
  { ID: 'Request Emergency Access IDs' },
  { ID: 'Request Emergency Access Role' },
  { ID: 'Request History' },
  { ID: 'Reset User Password' },
  { ID: 'Update Existing Endpoint' },
  { ID: 'Update Existing Entitlement' },
  { ID: 'Update Existing Entitlement Type' },
  { ID: 'Update Existing Organization' },
  { ID: 'Update User Request' },
  { ID: 'View Delegates' },
  { ID: 'View Existing Access' },
  { ID: 'Home' },
  { ID: 'Show Analytics Control List V1' },
  { ID: 'Show Analytics Control List in Merged View' },
  { ID: 'Show Analytics Control List' },
  { ID: 'Analytics Create Options' },
  { ID: 'Analytics V1 Create Options' },
  { ID: 'Analytics Create Options in Merged View' },
  { ID: 'Create Analytics Controls in Merged View' },
  { ID: 'Create Analytics Controls' },
  { ID: 'Create Analytics V1 Controls' },
  { ID: 'Edit Analytics Controls in Merged View' },
  { ID: 'Edit Analytics Controls' },
  { ID: 'Analytics Preview' },
  { ID: 'Analytics Preview V1' },
  { ID: 'Analytics Preview in Merged View' },
  { ID: 'Analytics Control Audit History' },
  { ID: 'Analytics V1 Control Audit History' },
  { ID: 'Analytics Control Audit History in Merged View' },
  { ID: 'Show Analytics Control Details V1' },
  { ID: 'Show Analytics Control Details' },
  { ID: 'Show Analytics Control Details in Merged View' },
  { ID: 'Run Analytics Control' },
  { ID: 'Run Analytics V1 Control' },
  { ID: 'Run Analytics Control in Merged View' },
  { ID: 'Show Analytics History' },
  { ID: 'Show Analytics History V1' },
  { ID: 'Show Analytics Flat History' },
  { ID: 'Show Analytics Flat History V1' },
  { ID: 'Create New Control Request' },
  { ID: 'Create New Control Request V1' },
  { ID: 'Run All V1 Controls from V1 history' },
  { ID: 'Run All Controls from analytics history' },
  { ID: 'Show Run History V1' },
  { ID: 'Show Run History' },
  { ID: 'Analytics History Details V1' },
  { ID: 'Analytics History Details' },
  { ID: 'Analytics History Details Runtime Analytics V1' },
  { ID: 'Show Analytics Archive V1' },
  { ID: 'Show Analytics Archive' },
  { ID: 'Analytics Configuration' },
  { ID: 'License Management' },
  { ID: 'Analytics Scan rules list' },
  { ID: 'Usage Analytics' },
  { ID: 'Analytics - Application Audit Logs' },
  { ID: 'Analytics - Privilege Logs' },
  { ID: 'Manage Infra Apps' },
  { ID: 'Dashboard List' },
  { ID: 'All Campaign Certification Review Access' },
  { ID: 'All Campaign Certification View Access' },
  { ID: 'Campaign Dashboard' },
  { ID: 'Campaign Edit Templates' },
  { ID: 'Campaign Export' },
  { ID: 'Campaign List' },
  { ID: 'Campaign Summary' },
  { ID: 'Campaign Template' },
  { ID: 'Campaign Templates List' },
  { ID: 'Campaigns Configuration' },
  { ID: 'controls.ECM.attestation' },
  { ID: 'Create Campaign' },
  { ID: 'Discontinue Campaign' },
  { ID: 'Preview Campaigns' },
  { ID: 'Reconfigure Campaign' },
  { ID: 'Schedule Campaign' },
  { ID: 'Schedule Campaigns' },
  { ID: 'Scheduled Campaigns' },
  { ID: 'Campaigns Configuration' },
  { ID: 'Roles - Dashboard' },
  { ID: 'Roles - Workbench' },
  { ID: 'Roles - Workbench - By Roles' },
  { ID: 'Roles - Workbench - By Users' },
  { ID: 'Roles - Workbench - Roles Not Assigned to User' },
  { ID: 'Roles - Workbench - By Role Mining Instance' },
  { ID: 'Roles - Mining - By Users' },
  { ID: 'Role Mining - By Users On The Basis Of Roles' },
  { ID: 'Role Mining - By Users and Entitlements On the Basis of Roles' },
  { ID: 'Entitlements Discovery - Entitlements to be added to the existinsg JRM Rules' },
  { ID: 'Entitlement Discovery - Discover Entitlement Associations' },
  { ID: 'Roles Entitlement Discovery' },
  { ID: 'JRM Rules' },
  { ID: 'JRM Rule - Edit' },
  { ID: 'JRM - View Requests' },
  { ID: 'SOD Workbench' },
  { ID: 'SOD Mitigation Controls' },
  { ID: 'SOD Rulesets - Business Process' },
  { ID: 'SOD Rulesets - Functions' },
  { ID: 'SOD Rulesets - Risks' },
  { ID: 'SOD Rulesets Comparison' },
  { ID: 'SOD Rulesets List' },
  { ID: 'SOD Recomendations' },
  { ID: 'SOD Groups' },
  { ID: 'SOD Simulation' },
  { ID: 'Create SOD Mitigating Controls' },
  { ID: 'Show SOD Mitigating Controls' },
  { ID: 'SOD Rulesets List' },
  { ID: 'Recommendation' },
  { ID: 'Role Remediation' },
  { ID: 'Mitigating Controls' },
  { ID: 'Risks' },
  { ID: 'Functions' },
  { ID: 'Business Process' },
  { ID: 'Comparision' },
  { ID: 'Simulation' },
  { ID: 'View Requests' },
  { ID: 'Manage PAM Sessions' },
  { ID: 'SSM Owner Persona' },
  { ID: 'Platform Owner Persona' },
  { ID: 'Data Owner Persona' },
  { ID: 'Compliance Owner Persona' },
  { ID: 'Application Owner Persona' },
  { ID: 'PAM Owner Persona' },
  { ID: 'WEBSERVICE' },
  { ID: 'Service API - Sync' },
  { ID: 'Service API - Cache Cleanup' },
  { ID: 'SMTP Settings' },
  { ID: 'Email Templates Catalog' },
  { ID: 'Create Email Templates' },
  { ID: 'Attestation Settings' },
  { ID: 'Analytics Settings' },
  { ID: 'Logger Settings' },
  { ID: 'UI branding Settings' },
  { ID: 'Dashboard Settings' },
  { ID: 'Manage Configuration Files' },
  { ID: 'Manage IDP Configuration files' },
  { ID: 'Manage web services authentication' },
  { ID: 'Manage File directories' },
  { ID: 'Manage External server configuration' },
  { ID: 'Manage API Config' },
  { ID: 'Manage MFA Config' },
  { ID: 'Manage Tab config' },
  { ID: 'Manage Microservices Config' },
  { ID: 'Manage Certificates' },
  { ID: 'Data Analyzer' },
  { ID: 'Application Audit Logs' },
  { ID: 'Application Logs' },
  { ID: 'Job Control Panel' },
  { ID: 'Job Control Panel History Details' },
  { ID: 'admin.ECM.joblist' },
  { ID: 'Security Systems List' },
  { ID: 'Sav Role List' },
  { ID: 'Admin Users List' },
  { ID: 'Admin Accounts List' },
  { ID: 'Admin User Groups' },
  { ID: 'Admin Connections' },
  { ID: 'Admin Password Policy' },
  { ID: 'Technical Rules' },
  { ID: 'User Update Rules' },
  { ID: 'Entitlement Update Rules' },
  { ID: 'Request Rules' },
  { ID: 'Global Configuration' },
  { ID: 'RequestMap' },
  { ID: 'Workflow' },
  { ID: 'Admin Function' },
  { ID: 'OAuth' },
  { ID: 'Application Onboarding List' },
  { ID: 'Questionnaire' },
  { ID: 'Import Package' },
  { ID: 'Import Package Bulk' },
  { ID: 'Transport' },
  { ID: 'SOD Report' },
  { ID: 'Entitlements' },
  { ID: 'Roles' },
  { ID: 'Configuration Files' },
  { ID: 'Dashboard Configuration' },
  { ID: 'Logger Configuration' },
  { ID: 'Analytics Configuration' },
  { ID: 'Campaign Configuration' },
  { ID: 'Email Templates' },
  { ID: 'SMTP Configuration' },
  { ID: 'Connections' },
  { ID: 'Organizations' },
  { ID: 'Admin Create User' },
  { ID: 'Application Restart' },
  { ID: 'Accounts Create' },
  { ID: 'Accounts Update' },
  { ID: 'Accounts Save' },
  { ID: 'Transport Cataloge' },
  { ID: 'Worfkflow Edit' },
  { ID: 'Rule excution trail list' },
  { ID: 'File Directory' },
  { ID: 'Service API - Sync' },
  { ID: 'Manage My Access' },
  { ID: 'Admin Accounts List' },
  { ID: 'Manage Service Account' },
  { ID: 'Home' },
  { ID: 'Admin Accounts List' },
  { ID: 'Manage My Access' },
  { ID: 'Home' },
  { ID: 'Pending Approvals' },
  { ID: 'home.myTeams' },
  { ID: 'home.pendingActions' },
  { ID: 'home.recentActivity' },
  { ID: 'Application Request' },
  { ID: 'Pending Approvals' },
];
const miniAppValues = [
  { ID: 'requestMiniApp' },
  { ID: 'reviewMiniApp' },
  { ID: 'adminMiniApp' },
  { ID: 'controlMiniApp' },
  { ID: 'designMiniApp' },
  { ID: 'attestationMiniApp' },
  { ID: 'intelligenceMiniApp' },
  { ID: 'sodMiniApp' },
  { ID: 'roleMiniApp' },
];
const KpiSrcEdit = ({ kpiInfo, setCodeStr, scopeObj, deleteKpi, isNew, intl }) => {
  const [addKpiOpen, setAddKpiOpen] = useState(false);
  const [features, setFeatures] = useState(() => kpiInfo.features.map(f => ({ ID: f })));
  const [kpiName, setKpiName] = useState(kpiInfo.name);
  const [rank, setRank] = useState(kpiInfo.rank);
  const [miniApp, setMiniApp] = useState(() => kpiInfo.miniApp.map(f => ({ ID: f })));
  const [editKpiInfo, setEditKpiInfo] = useState(false);
  const dispatch = useDispatch();
  const [editorCode, setEditorCode] = useState(kpiInfo.source);
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const confirm = useConfirm();

  const saveCustomCodeOnServer = () => {
    const codeUpdateAction = newKpiCode({
      fileName: isNew ? kpiName + '.js' : kpiInfo.sourceLocation.split('/').reverse()[0],
      codeStr: editorCode,
    },(!editKpiInfo && !isNew));
    dispatch(codeUpdateAction);
    if (editKpiInfo && !isNew) {
      const payload = {
        ...kpiInfo,
        features: features.map(f => f.ID),
        rank: rank,
        name: kpiName,
        miniApp: miniApp.map(m => m.ID),
        source: undefined,
      };
      const attrUpdateAction = updateKpiAttributes(payload);
      dispatch(attrUpdateAction);
    }
    if (isNew) {
      const payload = {
        features: features.map(f => f.ID),
        rank: rank,
        name: kpiName,
        miniApp: miniApp.map(m => m.ID),
        sourceLocation: `/res/file?path=/app/kpi/${kpiName}.js`,
      };
      dispatch(newKpiAttributes(payload));
    }
    setAddKpiOpen(false);
  };

  const [previewPopup, setPreviewPopup] = React.useState(null);

  const previewOpen = event => setPreviewPopup(event.currentTarget);

  const previewClose = () => setPreviewPopup(null);

  const open = !!previewPopup;
  const id = open ? 'simple-popover' : undefined;

  const confirmAndDeleteKpi = () => {
    confirm({
      description: `${intl.formatMessage({ id: 'requestHome.kpi.confirmDeleteMsg' })} ${kpiInfo.name}.`,
    })
      .then(() => deleteKpi(kpiInfo.id))
      .catch(() => console.log('Deletion cancelled.'));
  };
  return (
    <div className={`${isNew ? styles.addIcon : `${styles.editIcons} editIconsdisplay`}`}>
      <div variant="contained" color="primary" className="editIconsDiv">
        {!isNew && (
          <>
            <Icon name="edit" onClick={() => setAddKpiOpen(true)} className={styles.editIcon} color />
            <Icon name="trashDel" onClick={confirmAndDeleteKpi} className={`${styles.editIcon} ${styles.removeIcon}`} />
          </>
        )}
        {isNew && (
          <Button className="d-flex" onClick={() => setAddKpiOpen(true)} tabIndex="0">
            <AddOutlinedIcon className="addButtonColor" fontSize="small" />
            <span className="addKpi">{intl.formatMessage({ id: 'requestHome.kpi.AddKpi' })}</span>{' '}
          </Button>)}
      </div>
      <KPIDialog
        onClose={() => setAddKpiOpen(false)}
        aria-labelledby="customized-dialog-title"
        open={addKpiOpen}

      >
        <DialogTitle id="customized-dialog-title">
          <div className="d-flex justify-between">
          <div>
          {!isNew && <span>
            {intl.formatMessage({ id: 'requestHome.kpi.EDitKpi' })}
          </span>}
          {isNew && <span>
            {intl.formatMessage({ id: 'requestHome.kpi.AddKpi' })}
          </span>}
          </div>
          <div>
          <CloseIcon onClick={() => setAddKpiOpen(false)}/>
          </div>
          </div>
        </DialogTitle>
        <div className={isNew ? styles.newkpiText : styles.textarea}>
          <DialogContent dividers>
            <div className={styles.contentBody}>
              {isNew && (
                <>
                  <div className="d-flex flex-column">
                    <TextField
                      label={intl.formatMessage({ id: 'requestHome.kpi.Name' })}
                      defaultValue=""
                      id={kpiName}
                      value={kpiName}
                      onChange={e => setKpiName(e.target.value)}
                      disabled={!(isNew || editKpiInfo)}
                    />
                    <TextField
                      label={intl.formatMessage({ id: 'requestHome.kpi.Rank' })}
                      defaultValue=""
                      id={rank}
                      value={rank}
                      onChange={e => setRank(e.target.value)}
                      disabled={!(isNew || editKpiInfo)}
                    />

                    <MultiSelectDropDown
                      options={featureValues}
                      placeholder={'select feature name'}
                      defaultValue={features}
                      _handleChange={(_, __, origVal) => setFeatures(origVal)}
                      readOnly={!(isNew || editKpiInfo)}
                    />
                    {/* <MultiSelectDropDown
                  options={miniAppValues}
                  placeholder={'select miniapp'}
                  defaultValue={miniApp}
                  _handleChange={(_, __, origVal) => setMiniApp(origVal)}
                  readOnly={!(isNew || editKpiInfo)}
                /> */}
                  </div>
                </>
              )}
              {!isNew && (
                <>
                  <Accordion
                    className={{ content: styles.summaryBottom }}
                    expanded={expanded}
                    onChange={(e, expanded) => {
                      setExpanded(expanded);
                    }}
                  >
                    <AccordionSummary
                      className={{ content: styles.summaryBottom }}
                      expanded={expanded}
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className={classes.heading}>Other attributes</Typography>
                      {expanded && (
                        <>
                          {!isNew && (
                            <div
                              className="flex justify-content-between ml-auto"
                              onClick={e => e.stopPropagation()}
                            >
                              {/* <span className="h5">Widget -- {kpiInfo.sourceLocation.split('/').reverse()[0]}</span> */}
                              <FormControlLabel
                                control={
                                  <Switch
                                    checked={editKpiInfo}
                                    onChange={e => {
                                      setEditKpiInfo(e.target.checked);
                                      e.stopPropagation();
                                    }}
                                    color="primary"
                                  />
                                }
                                label="Edit"
                              />
                            </div>
                          )}
                        </>
                      )}
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="d-flex flex-column w-100">
                        {/* <div className='d-flex justify-around'>
                <span className="text-sm">KPI Name:</span>
                <input className={`${styles.inputName}  mr-8`} id={kpiName} value={kpiName} onChange={(e) => setKpiName(e.target.value)} disabled={!(isNew || editKpiInfo)}>
                </input>
                </div> */}
                        {/* <div className='d-flex justify-around'>
                <span className="text-sm">KPI Rank:</span>
                <input className={`${styles.inputName}  mt-2 mr-8`} id={rank} value={rank} onChange={(e) => setRank(e.target.value)} disabled={!(isNew || editKpiInfo)}>
                  </input>
                  </div> */}
                        <TextField
                          label={intl.formatMessage({ id: 'requestHome.kpi.Rank' })}
                          defaultValue="Rank"
                          id={rank}
                          value={rank}
                          onChange={e => setRank(e.target.value)}
                          disabled={!(isNew || editKpiInfo)}
                        />
                        <MultiSelectDropDown
                          options={featureValues}
                          placeholder={'select feature name'}
                          defaultValue={features}
                          _handleChange={(_, __, origVal) => setFeatures(origVal)}
                          readOnly={!(isNew || editKpiInfo)}
                        />
                        {/* <MultiSelectDropDown
                  options={miniAppValues}
                  placeholder={'select miniapp'}
                  defaultValue={miniApp}
                  _handleChange={(_, __, origVal) => setMiniApp(origVal)}
                  readOnly={!(isNew || editKpiInfo)}
                /> */}
                      </div>
                    </AccordionDetails>
                  </Accordion>
                  <TextField
                    className="w-100 pl-3"
                    label={intl.formatMessage({ id: 'requestHome.kpi.Name' })}
                    defaultValue="Name"
                    id={kpiName}
                    value={kpiName}
                    onChange={e => setKpiName(e.target.value)}
                  />
                </>
              )}
            </div>
            <hr />
            <span className={styles.scopeObject}>Scope: {Object.keys(scopeObj).join(', ')}</span>
            <div className={styles.bodyTextArea}>
              <LiveProvider code={editorCode} scope={{ NumberTextField }}>
                <LiveEditor
                  onChange={newCode => {
                    setEditorCode(newCode);
                  }}
                ></LiveEditor>
              </LiveProvider>
            </div>
            <Popover
              id={id}
              open={open}
              anchorEl={previewPopup}
              onClose={previewClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              classes={{ paper: classes.popoverContent }}
            >
              <div>
                <div className={`${styles.previewContaner} d-flex justify-between`}>
                  <h6>KPI Sample Preview </h6>
                  <CloseIcon onClick={previewClose} />
                </div>
                <h6 className={styles.previewText}>Overview</h6>
                <div className="d-flex">
                  <div className={styles.requestActionTiles}>
                    <LiveProvider code={editorCode} scope={scopeObj}>
                      <LivePreview />
                    </LiveProvider>
                  </div>
                </div>
              </div>
            </Popover>

          </DialogContent>
          <DialogActions>
            <Button
              autoFocus
              // onClick={() => {
              //   setCodeStr(editorCode);
              //   setAddKpiOpen(false);
              // }}
              onClick={previewOpen}
              color="primary"
            >
              {intl.formatMessage({ id: 'requestHome.kpi.Preview' })}
            </Button>
            <Button onClick={saveCustomCodeOnServer}>
              {intl.formatMessage({ id: 'requestHome.kpi.SaveonServer' })}
            </Button>
          </DialogActions>
        </div>
      </KPIDialog>
    </div>
  );
};

export default injectIntl(KpiSrcEdit);
