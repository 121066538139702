import React, { useEffect, useState, useRef } from 'react';
import './MyTeams.scss';
import { SearchComponent, Carousel, EllipsedLabel } from 'ui-components/src';
import { useDebounce } from 'use-debounce';
import { connect } from 'react-redux';
import { push } from 'react-router-redirect';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { Button, List, ListItem, ListItemText } from '@material-ui/core';
import ArrowDropDownRoundedIcon from '@material-ui/icons/ArrowDropDownRounded';
import { makeStyles } from '@material-ui/core/styles';
import useOnClickOutside from 'use-onclickoutside';
import SvgIcon from './no-recent-item.svg';
import MyTeamsUserImage from './MyTeamsUserImage';
import { requestRecentUserList, requestUserList } from '../redux/requestHome-actions';

const useStyles = makeStyles({
  label: {
    minWidth: '0 !important',
    margin: '0 !important',
    padding: '0 !important',
  },
  search: {
    borderRadius: 3,
    backgroundColor: '#ffffff',
    border: '1px solid transparent',
    margin: '0 !important',
    '&:hover': {
      border: '0',
      backgroundColor: '#ffffff',
    },
  },
  hoverButton: {
    paddingTop: 0,
    paddingBottom: 4,
    '&:hover': {
      backgroundColor: '#EBEDFF',
    },
  },
  iconSize: {
    width: 31,
    height: 38,
  },
});

const MyTeams = ({ userList, requestRecentUserList, requestUserList, recentList, recentloading, intl }) => {
  const classes = useStyles();
  const temp = '__';
  const [recent, setRecent] = useState(false);
  const [teamsUsers, setTeamsUsers] = useState('');
  const OptionValue = ['allRecent', 'recentJoiners', 'recentLeavers', 'recentMovers'];
  const [searchText, setSearchText] = useState('');
  const [debouncedSearchText] = useDebounce(searchText, 500);
  const [recentOption, setRecentOption] = useState(0);
  const [open, setOpen] = useState(true);
  const dropDownRef = useRef(null);

  useEffect(() => {
    requestUserList();
    requestRecentUserList();
  }, []);

  useEffect(() => {
    if (userList) {
      setTeamsUsers(userList);
    }
  }, [userList]);

  useEffect(() => {
    if (recentList) {
      setRecent(recentList.users);
    }
    else {
      setRecent(false);
    }
  }, [recentList]);

  useEffect(() => {
    if (debouncedSearchText) {
      requestUserList(searchText);
    } else {
      requestUserList();
    }
    return () => {};
  }, [debouncedSearchText]);

  const handleClose = () => {
    setOpen(value => !value);
  };

  useOnClickOutside(dropDownRef, () => {
    setOpen(true);
  });

  useEffect(() => {
    if (recentOption) {
      setRecent(recentList.users.filter(e => e.userType === OptionValue[recentOption]));
    } else setRecent(recentList.users);
  }, [recentOption]);

  return (
    <div className="team-content-section">
      <div className="people-count people-count  peopleCount-title" ref={dropDownRef}>
        {intl.formatMessage({ id: `RequestHome.Teams.${OptionValue[recentOption]}` })}
        {recent ? ` (${recent.length})` : ''} 
        {recentList.count ? (
          <Button
            onClick={handleClose}
            classes={{ root: classes.label, label: classes.label, label: "dropDownLabel" }}
            className="m-0 p-0"
          >
            <ArrowDropDownRoundedIcon classes={{root: classes.iconSize}}/>{' '}
          </Button>
        ) : (
          ''
        )}
        <List
          component="nav"
          className={`${open ? 'd-none' : 'd-block'} recent-dropdown`}
          style={{ zIndex: 99 }}
        >
          {OptionValue.map((value, index) => (
            <ListItem
              button
              // selected={recentOption === index}
              onClick={() => {
                setRecentOption(index);
                handleClose();
              }}
              classes={{button: classes.hoverButton}}
            >
              <ListItemText
              className="optionItemText"
                primary={intl.formatMessage({ id: `RequestHome.Teams.${OptionValue[index]}` })}
              />
            </ListItem>
          ))}
        </List>
      </div>
      <div className="people-carousel">
        {recentloading && (
            <div className="recentLoading">
            {[1, 2, 3].map(() => {
              return (
                <div className="recentUserLoading">
               <div className="recentImageIcon mb-1"> 
                </div>
               <div className="gridLoading" />
                <div className="gridLoading mt-1">
                  </div>
                </div>
              );
            })}
          </div>
        )}
        {!recentloading &&  (recent && recent.length === 0 )  ? (
          <div className="d-flex flex-column justify-content-center no-item">
            <img src={SvgIcon} alt="React Logo" />
            <p className="text-center">
              {intl.formatMessage({ id: 'RequestHome.Teams.No.Item.Found' })}
            </p>
          </div>
        ) : (
        <>
         {recent && recent.length > 0 && ( <Carousel
            cardwidth={120}
            cardDist={10}
            style={{ zIndex: 0, marginLeft: '-34px', paddingLeft: '32px' }}
            navOnTop
          >
            {recent &&
              recent.map(user => (
                <div className="recent-section">
                  <div className="people-icon">
                    <div className="icon mb-3">
                      <MyTeamsUserImage name={user.username} />
                    </div>
                    <div className="name">
                      <EllipsedLabel label={user.displayName} maxLength={12} />
                    </div>
                    <div className="status p-1">
                      {intl.formatMessage({ id: `RequestHome.Teams.${user.userType}` })}
                    </div>
                  </div>
                </div>
              ))}
          </Carousel>)}
          </>
        )
        }
      </div>
      <div className="peopleSearchBar">
        <div className="people-count my-2">
          {intl.formatMessage({ id: 'RequestHome.Teams.All.People' })} (
          {teamsUsers && teamsUsers.count})
        </div>
        <div className="border rounded mr-4 search-width">
          <SearchComponent
            defaultValue={searchText}
            onTextChange={setSearchText}
            fixedWidth="100%"
            placeholder={intl.formatMessage({ id: 'RequestHome.Search.Your.Team' })}
          />
        </div>
      </div>
      {teamsUsers &&
        teamsUsers.users.map(user => (
          <>
            <div className="d-flex my-1 py-3 people-container">
              <div className="ml-2">
                <MyTeamsUserImage name={user.username} />
              </div>
              <div className="people-detail">
                {user.displayName ? (
                  <div className="name"><span className="break-all"> {user.displayName}</span></div>
                ) : (
                  <div className="name"><span className="break-all">{`${user.firstname ? user.firstname : ''} ${
                    user.lastname ? user.lastname : ''
                  }`}</span></div>
                )}
                <div className="username my-1"><span className="break-all">{user.username}</span></div>
                <div className="status">#{user.status}</div>
              </div>
              <div className="people-score">
                {user.risk ? (
                  <>
                    <span className="span1">{user.risk}</span>
                  </>
                ) : (
                  ''
                )}
                <Button
                  variant="outlined"
                  color="primary"
                  type="button"
                  classes={{ root: 'manageHover' }}
                  onClick={() =>
                    push(`/request/applicationRequest/manageRequestTeam/${user.username}`)
                  }
                >
                  {intl.formatMessage({ id: 'RequestHome.Teams.View.Details' })}
                </Button>
              </div>
            </div>
          </>
        ))}
      <div className="userButton">
        <Button
          variant="outlined"
          color="primary"
          className="my-4"
          fullWidth
          classes={{ root: 'viewAllUsers' }}
          onClick={() => push('/request/applicationRequest/manageRequestTeam/')}
        >
          {intl.formatMessage({ id: 'RequestHome.Teams.View.All.Team.Members' })}
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  recentList: state.requestHome.initialLoad.recent,
  userList: state.requestHome.initialLoad.userList,
  recentloading: state.requestHome.initialLoad.recentloading,
});

const mapDispatchToProps = {
  requestRecentUserList,
  requestUserList,
};

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(MyTeams)));
