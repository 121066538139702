import React, { useState } from 'react';
import { checkEnvironment } from 'ui-common/src/lib/utils';

const IconImage = props => {
    const [imgNotFound, setImgNotFound] = useState(false);
    const IDWMS_ENDPOINT = checkEnvironment().IDWMS_API_URL;

    const onImgError = e => {
        e.target.style.display = 'none';
        setImgNotFound(true);
    };

    return (
        <>
            <div className={props.className}>
                <svg className="sidebar-icon">
                    <use xlinkHref={`${IDWMS_ENDPOINT}/resource/image/app/${props.name}#V3`} />
                </svg>
            </div>
            {/* {imgNotFound && (
                <div></div>
            )}
            {!imgNotFound && (
                <img
                    className={props.className}
                    src={`${IDWMS_ENDPOINT}/resource/image/app/${props.name}`}
                    alt=""
                    onError={onImgError}
                />
            )} */}
        </>
    );
};

export default IconImage;