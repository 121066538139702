export const ShowLoaderType = 'showloadertype';
export const HideLoaderType = 'hideloadertype';

export const ShowLoader = () => ({
  type: ShowLoaderType,
});

export const HideLoader = () => ({
  type: HideLoaderType,
});
