import React, { useState, useEffect } from "react";
// eslint-disable-next-line import/extensions
import { DateFilterWidget } from "./DateFilterWidget";
import moment from "moment";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import MuiExpansionPanel from "@material-ui/core/ExpansionPanel";
import MuiExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import MuiExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { ToggleButtonComponent } from 'ui-components/src';
import { injectIntl } from 'react-intl';

const ExpansionPanel = withStyles({
  root: {
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
  root: {
    minHeight: 56,
    padding: 0,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "0",
    },
  },
  expanded: {},
})(MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles((theme) => ({
  root: {
    padding: 0,
  },
}))(MuiExpansionPanelDetails);

const DateFilterView = ({
  column,
  appliedFilters,
  setAppliedFilters,
  resetDates,
  setCalendarOpen,
  preAppliedFilters,
  intl,
}) => {
  const [expanded, setExpanded] = useState(false);
  let selected =
    Object.keys(appliedFilters).length === 0 ? "" : appliedFilters[column.key];
  let active = true;
  if (selected !== undefined && selected !== "") {
    const dates = selected.split("/");
    let value;
    let mode;
    if (dates.length > 1) {
      if (dates[0] && dates[1]) {
        value = [
          moment(dates[0], "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD HH:mm:ss"),
          moment(dates[1], "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD HH:mm:ss"),
        ];
        mode = "within";
      }
      if (dates[0] && !dates[1]) {
        value = moment(dates[0], "YYYY-MM-DD HH:mm:ss").format(
          "YYYY-MM-DD HH:mm:ss"
        );
        mode = "after";
      }
      if (!dates[0] && dates[1]) {
        value = moment(dates[1], "YYYY-MM-DD HH:mm:ss").format(
          "YYYY-MM-DD HH:mm:ss"
        );
        mode = "before";
      }
    }
    if (dates.length === 1) {
      value = moment(dates[0], "YYYY-MM-DD HH:mm:ss").format(
        "YYYY-MM-DD HH:mm:ss"
      );
      mode = "after";
    }
    if (dates.length === 0) {
      value = null;
      mode = "within";
    }
    active = false;
    selected = { value, mode };
  } else {
    active = true;
    selected = { value: "", mode: "within" };
  }

  const [selectedDate, setSelectedDate] = useState(selected);
  const [isActive, setIsActive] = useState(active);
  const setSelectedDates = (dateObj, column) => {
    setSelectedDate({ value: dateObj.value, mode: dateObj.mode });
    let dateStr;
    if (dateObj.mode === "within" && dateObj.value !== null) {
      dateStr = `${dateObj.value[0]}/${dateObj.value[1]}`;
    }
    if (dateObj.mode === "before") {
      dateStr = `/${dateObj.value}`;
    }
    if (dateObj.mode === "after") {
      dateStr = dateObj.value;
    }
    setAppliedFilters({ ...appliedFilters, [column]: dateStr });
  };
  const options = [
    { value: "before", label: intl.formatMessage({ id: 'dateFilter.before.label' }) },
    { value: "within", label: intl.formatMessage({ id: 'dateFilter.within.label' }) },
    { value: "after", label: intl.formatMessage({ id: 'dateFilter.after.label' }) },
  ];
  const [value, setToggleValue] = useState(selected.mode);
  const onToggleChange = (value) => {
    setToggleValue(value);
    if (value === "within" && typeof selectedDate.value === "string") {
      setSelectedDate({ ...selectedDate, value: null, mode: value });
    } else {
      setSelectedDate({ ...selectedDate, mode: value });
    }
  };

  useEffect(() => {
    let selected =
      Object.keys(appliedFilters).length === 0
        ? ""
        : appliedFilters[column.key];
    if (selected !== undefined && selected !== "") {
      setExpanded(true);
    }
  }, [preAppliedFilters]);

  const onExpand = (e, expanded) => {
    if (expanded) {
      setExpanded(true);
    } else {
      setExpanded(false);
    }
  };
  return (
    <div
      className={isActive ? "form-group withborder" : "form-group checkboxview"}
    >
      <ExpansionPanel square expanded={expanded} onChange={onExpand}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1d-content"
          id="panel1d-header"
        >
          <div className="advfilter-title">{column.label}</div>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className="d-flex flex-column panel-det-padd">
          <div className="dateButtons">
            <ToggleButtonComponent
              options={options}
              defaultValue={value}
              onToggleChange={onToggleChange}
            />
          </div>
          <div>
            <DateFilterWidget
              dropup
              setAntdComponentOpen={setCalendarOpen}
              selectedDate={selectedDate}
              setSelectedDate={(dateObj) =>
                setSelectedDates(dateObj, column.key)
              }
              resetDates={resetDates}
            />
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  );
};

DateFilterView.propTypes = {
  resetDates: PropTypes.func.isRequired,
  setAppliedFilters: PropTypes.func.isRequired,
  column: PropTypes.objectOf(
    PropTypes.shape({
      key: PropTypes.string,
      label: PropTypes.string,
      filter: PropTypes.objectOf(
        PropTypes.shape({
          filterURL: PropTypes.string,
          type: PropTypes.string,
        })
      ),
    })
  ).isRequired,
  appliedFilters: PropTypes.objectOf(PropTypes.shape({})).isRequired,
  setCalendarOpen: PropTypes.func.isRequired,
  intl: PropTypes.arrayOf(
    PropTypes.shape({
      locale: PropTypes.string.isRequired,
      formatMessage: PropTypes.func.isRequired,
    })
  ).isRequired,
};
export default injectIntl(DateFilterView);
