/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import DateFnsUtils from '@date-io/date-fns';
import { isBefore, addHours } from 'date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import styles from './SaviyntRangePickerStandard.module.scss';
import { SlimSelectStandard } from '../SlimSelectStandard';
import { SaviyntDatePickerStandard } from '../SaviyntDatePickerStandard';
import { Icon } from '../Icon';

const SaviyntRangePicker = ({
  fromDate,
  toDate,
  onChange,
  noTimeRequired,
  disabledFromDateTime,
  disabledToDateTime,
  showError,
  required,
  defaultTimeFrameHRS,
}) => {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  useEffect(() => {
    if (fromDate) {
      if (defaultTimeFrameHRS) {
        setStartDate(fromDate);
      } else {
        let temp;
        if (isBefore(fromDate, new Date())) temp = new Date();
        else temp = new Date(fromDate);
        setStartDate(temp);
      }
    }
    if (toDate) {
      if (defaultTimeFrameHRS) {
        setEndDate(toDate);
      } else {
        const temp = new Date(toDate);
        temp.setHours(23);
        temp.setMinutes(59);
        temp.setMilliseconds(0);
        setEndDate(temp);
      }
    }
  }, [fromDate, toDate]);

  useEffect(() => {
    onChange([startDate, endDate]);
  }, [startDate, endDate]);

  const handleTimeSlot = (value, key) => {
    if (key === 'startTime') {
      if (startDate) {
        const temp = new Date();
        if (startDate.toDateString() === temp.toDateString()) {
          if (temp.getHours() < value.value.hours) return true;
          if (temp.getHours() === value.value.hours && temp.getMinutes() < value.value.minutes)
            return true;
        }
        if (isBefore(temp, startDate)) return true;
      }
    } else if (endDate) {
      const temp = startDate || new Date();
      if (temp.toDateString() === endDate.toDateString()) {
        if (temp.getHours() < value.value.hours) return true;
        if (temp.getHours() === value.value.hours && temp.getMinutes() < value.value.minutes)
          return true;
      } else return true;
    }
    return false;
  };

  const handleTimeChange = (e, key) => {
    if (key === 'startTime') {
      startDate.setMinutes(e.value.minutes);
      startDate.setHours(e.value.hours);
      startDate.setMilliseconds(e.value.seconds);
      if (defaultTimeFrameHRS) setEndDate(addHours(startDate, defaultTimeFrameHRS));
      else if (endDate && startDate.getTime() - endDate.getTime() > 0) setEndDate('');
    } else {
      endDate.setMinutes(e.value.minutes);
      endDate.setHours(e.value.hours);
      endDate.setMilliseconds(e.value.seconds);
    }
  };

  return (
    <div className={`${styles.saviyntRangePicker} w-100`}>
      <Icon className={`${styles.calendarIcon} pl-1`} name="calendar" />
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <SaviyntDatePickerStandard
          attributeKey="startDate"
          defaultValue={startDate}
          _handleChange={value => {
            if (value.toDateString() !== new Date().toDateString()) {
              value.setMilliseconds(0);
              value.setMinutes(0);
              value.setHours(0);
              setStartDate(value);
              if (defaultTimeFrameHRS) setEndDate(addHours(value, defaultTimeFrameHRS));
            } else {
              setStartDate(new Date());
              if (defaultTimeFrameHRS) setEndDate(addHours(new Date(), defaultTimeFrameHRS));
            }
            if (!defaultTimeFrameHRS) {
              const temp = endDate;
              if (temp && value.getTime() - temp.getTime() > 0) setEndDate('');
            }
          }}
          readOnly={disabledFromDateTime}
          minDate={new Date()}
          showError
        />
      </MuiPickersUtilsProvider>
      {!noTimeRequired && (
        <SlimSelectStandard
          attributeKey="startTime"
          defaultValue={startDate}
          disabled={disabledFromDateTime}
          visibleTimeSlot={handleTimeSlot}
          onChange={handleTimeChange}
        />
      )}
      <div className={styles.separator}>-</div>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <SaviyntDatePickerStandard
          attributeKey="endDate"
          defaultValue={endDate}
          error={isBefore(endDate, startDate) || showError}
          _handleChange={value => {
            value.setMilliseconds(0);
            value.setMinutes(59);
            value.setHours(23);
            setEndDate(value);
          }}
          readOnly={disabledToDateTime}
          minDate={startDate}
        />
        {required && <span className="mandatory">&nbsp;*</span>}
      </MuiPickersUtilsProvider>
      {!noTimeRequired && (
        <SlimSelectStandard
          attributeKey="endTime"
          defaultValue={endDate}
          disabled={disabledToDateTime}
          visibleTimeSlot={handleTimeSlot}
          onChange={handleTimeChange}
        />
      )}
    </div>
  );
};

SaviyntRangePicker.defaultProps = {
  onChange: () => {},
  noTimeRequired: false,
  disabledFromDateTime: false,
  disabledToDateTime: false,
  showError: false,
  required: false,
  defaultTimeFrameHRS: 0,
};

SaviyntRangePicker.propTypes = {
  fromDate: PropTypes.objectOf(Date).isRequired,
  toDate: PropTypes.objectOf(Date).isRequired,
  onChange: PropTypes.func,
  noTimeRequired: PropTypes.bool,
  disabledFromDateTime: PropTypes.bool,
  disabledToDateTime: PropTypes.bool,
  showError: PropTypes.bool,
  required: PropTypes.bool,
  defaultTimeFrameHRS: PropTypes.number,
};

export default SaviyntRangePicker;
