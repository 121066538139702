import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import useOnClickOutside from 'use-onclickoutside';
// eslint-disable-next-line import/extensions
import { A } from '../A';
import styles from './PlainDropDown.module.scss';

const PlainDropDown = ({ options, selectedValue, changeValue, dropup,changeValueWithIndex }) => {
  const [showPanel, setShowPanel] = useState(false);
  const ref = useRef(null);
  useOnClickOutside(ref, () => setShowPanel(false));
  useEffect(() => {
    const closePlainDropdown = e => {
      if(e.keyCode === 27){
        setShowPanel(false);
      }
    }
    window.addEventListener('keydown', closePlainDropdown)
    return () => window.removeEventListener('keydown', closePlainDropdown)
  }, [])


 return (
    <div className={dropup ? `${styles.plainDropDown} dropup` : styles.plainDropDown} ref={ref}>
      <A
        className="btn dropdown-toggle"
        href="#"
        role="button"
        tabIndex="1"
        onClick={e => {
          e.preventDefault();
          setShowPanel(!showPanel);
        }}
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        {selectedValue}
      </A>

      {showPanel && (
        <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
          {options.map((item,index) => (
            <A
              tabIndex="1"
              className="dropdown-item pb-0"
              key={item}
              href="#"
              onClick={e => {
                e.preventDefault();
                if(changeValue)
                changeValue(item);
                if(changeValueWithIndex)
                changeValueWithIndex(item,index);
                setShowPanel(false);
              }}
            >
              {item}
            </A>
          ))}
        </div>
      )}
    </div>
  );
};
PlainDropDown.defaultProps = {
  changeValueWithIndex: null,
};

PlainDropDown.propTypes = {
  selectedValue: PropTypes.string.isRequired,
  changeValue: PropTypes.func.isRequired,
  dropup: PropTypes.bool.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
  changeValueWithIndex: PropTypes.func,
};

export default PlainDropDown;
