/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable react/jsx-no-comment-textnodes */
import React, { useState, useRef, useEffect } from 'react';
import useOnClickOutside from 'use-onclickoutside';
import PropTypes from 'prop-types';
import { map, cloneDeep } from 'lodash';
import { A } from '../A';
import styles from './GridBoot.module.scss';
import {
  convertReduxToApi,
  getDistinctColumnValues,
  cssSupported,
  getGridCell
} from 'ui-common/src/lib/utils';
import ColumnSortPanel from './components/ColumnSortPanel';
import PopoverDialog from './components/PopoverDialog';
import { Icon } from '../Icon';
import { DateFilter, UniqueFieldFilter, StringFilter } from './filterpanels';
import './SaviyntGridOld.scss';
import { injectIntl } from 'react-intl';


const SaviyntGrid = ({
  columns,
  data,
  uniqueKey,
  onRowSelected,
  appliedFilters,
  setAppliedFilters,
  reqBodyOptions,
  setReqBodyOptions,
  globalSearchText,
  setGlobalSearchText,
  topOffset,
  isLoading,
  isListView,
  classNames,
  hideRearrangeColumn,
  sendAllRows,
  intl,
  hiddenColumns = [],
}) => {
  const [displayColumns, setDisplayColumns] = useState(columns);
  const [gridData, setGridData] = useState(data);
  const [showColumnReorder, setShowColumnReorder] = useState(false);
  const [showColumnFilter, setShowColumnFilter] = useState('none');
  const [distinctColumnValues, setDistinctColumnValues] = useState({});
  const [filtersCount, setFiltersCount] = useState(0);
  const [filterPanelHeight, setFilterPanelHeight] = useState(0);
  const [fixedHeader, setFixedHeader] = useState(false);
  const [antdComponentOpen, setAntdComponentOpen] = useState(false); // Needed to check if we are clicking on antd component becos antd components are rendered always outside of react.
  const [filteredDistinctColumnValues, setFilteredDistinctColumnValues] = useState({});
  const [sortedColumn, setSortedColumn] = useState({ order: 'asc' });
  const [checkedFilters, setCheckedFilters] = useState(appliedFilters);
  const [unCheckedColumns, setUnCheckedColumns] = useState(hiddenColumns);
  const tableRef = useRef(null);
  const filterPanelRef = useRef(null);
  const [autoComplete, setAutoComplete] = useState(false);
  const [rand, setRand] = useState(Math.floor(Math.random() * 90 + 10));

  const isClickedOnAutocomplete = e => {
    try {
      return (
        e.path.map(t => t.className).filter(c => c && c.indexOf('MuiAutocomplete') > -1).length > 0
      );
    } catch {
      // exception
    }
    return false;
  };

  useOnClickOutside(tableRef, e => {
    if (!antdComponentOpen && !isClickedOnAutocomplete(e) && !autoComplete) setShowColumnFilter('none');
  });

  useEffect(() => {
    setGridData(data);
  }, [data]);
  useEffect(() => {
    let filterCount = 0;
    if (appliedFilters) {
      Object.keys(appliedFilters).forEach(filter => {
        if (Array.isArray(appliedFilters[filter])) {
          filterCount += appliedFilters[filter].length;
        }
        if (typeof appliedFilters[filter] === 'string') filterCount += 1;
      });
    }
    if (reqBodyOptions && (reqBodyOptions.findby || reqBodyOptions.findBy)) {
      filterCount += 1;
    }

    setFiltersCount(filterCount);
    if (filterCount > 0) {
      setFilterPanelHeight(56);
    } else {
      setFilterPanelHeight(0);
    }
  }, [appliedFilters, reqBodyOptions]);
  useEffect(() => {
    if (setReqBodyOptions)
      setReqBodyOptions({
        ...reqBodyOptions,
        sortcolumn: convertReduxToApi('columnvalues')[sortedColumn.columnName],
        sortorder: sortedColumn.order
      });
  }, [sortedColumn]);
  const checkGridPosition = () => {
    setFixedHeader(
      // other browser sticky support should be checked with prefixes
      tableRef.current.getBoundingClientRect().top <= topOffset &&
      !cssSupported('position', 'sticky')
    );
  };
  useEffect(() => {
    window.addEventListener('scroll', checkGridPosition, false);
    return () => {
      window.removeEventListener('scroll', checkGridPosition);
    };
  }, []);
  useEffect(() => {
    setDisplayColumns(columns);
    // setUnCheckedColumns(columns.filter(col => col.hidden).map(item => item.key));
  }, [columns]);

  const getFilterUI = column => {
    if (column.filter.type === 'string') {
      return (
        /* DivTable.com */
        <StringFilter
          column={column}
          appliedFilters={appliedFilters}
          setAppliedFilters={setAppliedFilters}
          checkedFilters={checkedFilters}
          setCheckedFilters={setCheckedFilters}
          distinctColumnValues={distinctColumnValues}
          sortedColumn={sortedColumn}
          setSortedColumn={setSortedColumn}
          showColumnFilter={showColumnFilter}
          setShowColumnFilter={setShowColumnFilter}
          filteredDistinctColumnValues={filteredDistinctColumnValues}
          setFilteredDistinctColumnValues={setFilteredDistinctColumnValues}
        />
      );
    }
    if (column.filter.type === 'unique') {
      return (
        <UniqueFieldFilter
          column={column}
          appliedFilters={appliedFilters}
          setAppliedFilters={setAppliedFilters}
          checkedFilters={checkedFilters}
          setCheckedFilters={setCheckedFilters}
          distinctColumnValues={distinctColumnValues}
          sortedColumn={sortedColumn}
          setSortedColumn={setSortedColumn}
          showColumnFilter={showColumnFilter}
          setShowColumnFilter={setShowColumnFilter}
          setAutoComplete={setAutoComplete}
        />
      );
    }
    if (column.filter.type === 'date') {
      return (
        <DateFilter
          column={column}
          checkedFilters={checkedFilters}
          setCheckedFilters={setCheckedFilters}
          showColumnFilter={showColumnFilter}
          setShowColumnFilter={setShowColumnFilter}
          appliedFilters={appliedFilters}
          setAppliedFilters={setAppliedFilters}
          setAntdComponentOpen={setAntdComponentOpen}
          sortedColumn={sortedColumn}
          setSortedColumn={setSortedColumn}
        />
      );
    }
    return TypeError('filter type should be "date" | "unique" | "string"');
  };

  const showDistinctColumnValues = column => {
    getDistinctColumnValues(column, appliedFilters, reqBodyOptions).then(distinctValues => {
      const { key } = column;
      setDistinctColumnValues({
        ...distinctColumnValues,
        [key]:
          column.filter && column.filter.filterFn
            ? column.filter.filterFn(distinctValues)
            : distinctValues
      });
      setFilteredDistinctColumnValues({
        ...distinctColumnValues,
        [key]:
          column.filter && column.filter.filterFn
            ? column.filter.filterFn(distinctValues)
            : distinctValues
      });
    });
  };

  const removeFilter = (filterId, filterLabel) => {
    let updatedFilters = {};
    if (Array.isArray(appliedFilters[filterId])) {
      updatedFilters = {
        ...appliedFilters,
        [filterId]: appliedFilters[filterId].filter(val => val !== filterLabel)
      };
    }
    if (typeof appliedFilters[filterId] === 'string') {
      updatedFilters = {
        ...appliedFilters,
        [filterId]: undefined
      };
    }
    setAppliedFilters(updatedFilters);
    setCheckedFilters(updatedFilters);
  };
  const showFilterBar = filters =>
    Object.keys(filters).map(filterId => {
      if (Array.isArray(filters[filterId]) && filters[filterId].length) {
        return filters[filterId].map(filterLabel => (
          <li key={filterLabel}>
            {filterLabel.length > 0 ? filterLabel : '(No Value)'}
            <A
              href="#"
              className={styles.remove_icon}
              onClick={e => {
                e.preventDefault();
                removeFilter(filterId, filterLabel);
              }}
            >
              <Icon name="remove" />
            </A>
          </li>
        ));
      }
      if (typeof filters[filterId] === 'string') {
        return (
          <li key={filters[filterId]}>
            {filters[filterId]}
            <A
              href="#"
              className={styles.remove_icon}
              onClick={e => {
                e.preventDefault();
                removeFilter(filterId, filters[filterId]);
              }}
            >
              <Icon name="remove" />
            </A>
          </li>
        );
      }
      return null;
    });
  const showGlobalText = globalSearchText => {
    if (!globalSearchText) return null;
    return (
      <li key={globalSearchText}>
        {globalSearchText}
        <A
          href="#"
          className={styles.remove_icon}
          onClick={e => {
            e.preventDefault();
            setGlobalSearchText(undefined);
          }}
        >
          <Icon name="remove" />
        </A>
      </li>
    );
  };
  const shownColumns = () => displayColumns.filter(col => !unCheckedColumns.includes(col.key));
  const selectAll = e => {
    let newData;
    if (e.target.checked) {
      newData = gridData.map(row => (row.disabled ? row : { ...row, checked: true }));
      setGridData(cloneDeep(newData));
    } else {
      newData = gridData.map(row => (row.disabled ? row : { ...row, checked: false }));
      setGridData(cloneDeep(newData));
    }
    onRowSelected(cloneDeep(newData.filter(row => row.checked)));
    if (sendAllRows) {
      sendAllRows(cloneDeep(newData));
    }
  };
  const selectRow = (e, rowData) => {
    let newData;
    if (e.target.checked) {
      newData = gridData.map(row => {
        if (rowData[uniqueKey] === row[uniqueKey]) {
          return { ...row, checked: true };
        }
        return row;
      });
      setGridData(cloneDeep(newData));
    } else {
      newData = gridData.map(row => {
        if (rowData[uniqueKey] === row[uniqueKey]) {
          return { ...row, checked: false };
        }
        return row;
      });
      setGridData(cloneDeep(newData));
    }
    onRowSelected(cloneDeep(newData.filter(row => row.checked)));
    if (sendAllRows) {
      sendAllRows(cloneDeep(newData));
    }
  };

  return (
    <>
      {filtersCount > 0 && (
        <div
          ref={filterPanelRef}
          className={styles.applied_filter_row}
          style={{
            top: `${topOffset}px`
          }}
        >
          <div className={styles.filter_result}>
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label>{`${intl.formatMessage({ id: 'SaviyntGrid.Filter' })} ${filtersCount}`}</label>
            <ul className={styles.fl_applied_list}>
              {showFilterBar(appliedFilters)}
              {showGlobalText(globalSearchText)}
            </ul>
          </div>
          <div className={styles.fl_btn}>
            <A
              className={styles.clear}
              href="#"
              onClick={e => {
                e.preventDefault();
                setAppliedFilters({});
                setCheckedFilters({});
                setGlobalSearchText(undefined);
              }}
            >
              {intl.formatMessage({ id: 'SaviyntGrid.Clear' })}
            </A>
          </div>
        </div>
      )}
      <div className={`gridData clasicGrid ${rand}`}>
        <div className="divTable table-hover" ref={tableRef}>
          {!isListView && (
            <div className={`divTableHeading ${styles.tableHeader}`}>
              <div className="divTableRow">
                {uniqueKey && (
                  <div className="divTableHead customCheck" style={{
                    zIndex: 25,
                    top: `${filterPanelHeight + topOffset}px`
                  }}>
                    <input
                      type="checkbox"
                      id={`selectAll${rand}`}
                      aria-label={`selectAll${rand}`}
                      onClick={selectAll}
                      checked={gridData.filter(row => row.checked).length === gridData.length}
                    />
                    <label htmlFor={`selectAll${rand}`} />
                  </div>
                )}
                {shownColumns().map((column, i) => (
                  <div
                    key={column.viewKey}
                    className={
                      (appliedFilters &&
                        appliedFilters[column.key] &&
                        appliedFilters[column.key].length > 0) ||
                        sortedColumn.columnName === column.key
                        ? 'active divTableHead'
                        : 'divTableHead'
                    }
                    // uncomment when old browser sticky is supported
                    // style={
                    //   cssSupported("position", "sticky")
                    //     ? {
                    //         position: "sticky",

                    //         top: topOffset + "px",
                    //         zIndex: showColumnFilter === column.key ? 35 : 25
                    //       }
                    //     : {}
                    // }
                    style={{
                      zIndex: showColumnFilter === column.key ? 35 : 25,
                      top: `${filterPanelHeight + topOffset}px`
                    }}
                  >
                    <div
                      className={
                        shownColumns().length - 1 === i ? 'd-flex justify-content-end' : 'd-flex'
                      }
                    >
                      {column.label && (
                        <>
                          <div
                            className={
                              (appliedFilters &&
                                appliedFilters[column.key] &&
                                appliedFilters[column.key].length > 0) ||
                                sortedColumn.columnName === column.key
                                ? `${column.key} active-col active`
                                : `${column.key} active-col`
                            }
                          >
                            {column.label}

                            {column.filter && (
                              <>
                                <A
                                  href="#"
                                  className={
                                    (appliedFilters[column.key] &&
                                      appliedFilters[column.key].length > 0) ||
                                      sortedColumn.columnName === column.key
                                      ? 'filter_dropdown active'
                                      : 'filter_dropdown'
                                  }
                                  onClick={e => {
                                    e.preventDefault();
                                    if (column.filter.type !== 'date') {
                                      showDistinctColumnValues(column);
                                    }
                                    setShowColumnFilter(column.key);
                                  }}
                                >
                                  <Icon name="filter" />
                                </A>
                                {getFilterUI(column)}
                              </>
                            )}
                          </div>
                        </>
                      )}
                      {shownColumns().length - 1 === i && !hideRearrangeColumn && (
                        <PopoverDialog>
                          <ColumnSortPanel
                            displayColumns={displayColumns}
                            setDisplayColumns={setDisplayColumns}
                            unCheckedColumns={unCheckedColumns}
                            setUnCheckedColumns={setUnCheckedColumns}
                            setShowColumnReorder={setShowColumnReorder}
                            defaultColumns={columns}
                          />
                        </PopoverDialog>
                        /*
                        <div
                          className="dropdown"
                          onClick={() => {
                            if (!showColumnReorder) setShowColumnReorder(true);
                          }}
                        >
                          <A
                            role="button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <Icon name="rearrangeColumns" className="manageCol" />
                          </A>
                          {showColumnReorder === true && (
                            <ColumnSortPanel
                              displayColumns={displayColumns}
                              setDisplayColumns={setDisplayColumns}
                              unCheckedColumns={unCheckedColumns}
                              setUnCheckedColumns={setUnCheckedColumns}
                              setShowColumnReorder={setShowColumnReorder}
                              defaultColumns={columns}
                            />
                          )}
                        </div>
*/
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
          <div className="divTableBody">
            {!isLoading &&
              map(gridData, row => (
                <div
                  className={`divTableRow ${row.isError ? 'row-error' : ''}`}
                  key={row[uniqueKey]}
                >
                  {uniqueKey && (
                    <div className="divTableCell customCheck" key={row[uniqueKey]}>
                      <input
                        type="checkbox"
                        id={row[uniqueKey]}
                        aria-label={row[uniqueKey]}
                        onClick={e => selectRow(e, row)}
                        checked={row.checked}
                        value={row.checked}
                        disabled={row.disabled}
                      />
                      <label htmlFor={row[uniqueKey]} />
                    </div>
                  )}
                  {shownColumns().map(column => (
                    <div className={`divTableCell ${column.key}`} key={column.label}>
                      {getGridCell(row, column)}
                    </div>
                  ))}
                </div>
              ))}
            {isLoading &&
              [1, 2, 3].map(row => (
                <div className="divTableRow" key={row}>
                  {shownColumns().map(column => (
                    <div className="divTableCell" key={column.label}>
                      <span className="gridLoading" />
                    </div>
                  ))}
                </div>
              ))}
          </div>
        </div>
        {/* we Need to use condition instead of false here when we implement for older browsers */}
        {false && (
          <div className="divTable1 table-hover" style={{ top: `${topOffset}px` }}>
            {fixedHeader && (
              <div className={`divTableHeading ${styles.tableHeader}`}>
                <div className="divTableRow">
                  {shownColumns().map((column, i) => (
                    <div
                      key={column.viewKey}
                      className={
                        (appliedFilters[column.key] && appliedFilters[column.key].length > 0) ||
                          sortedColumn.columnName === column.key
                          ? 'active divTableHead'
                          : 'divTableHead'
                      }
                    >
                      <div
                        className={
                          shownColumns().length - 1 === i ? 'd-flex justify-content-end' : 'd-flex'
                        }
                      >
                        {column.label && (
                          <>
                            <div
                              className={
                                (appliedFilters[column.key] &&
                                  appliedFilters[column.key].length > 0) ||
                                  sortedColumn.columnName === column.key
                                  ? `${column.key} active-col active`
                                  : `${column.key} active-col`
                              }
                            >
                              {column.label}

                              {column.filter && (
                                <>
                                  <A
                                    href="#"
                                    className={
                                      (appliedFilters[column.key] &&
                                        appliedFilters[column.key].length > 0) ||
                                        sortedColumn.columnName === column.key
                                        ? 'filter_dropdown active'
                                        : 'filter_dropdown'
                                    }
                                    onClick={e => {
                                      e.preventDefault();
                                      if (column.filter.type !== 'date') {
                                        showDistinctColumnValues(column);
                                      }
                                      setShowColumnFilter(column.key);
                                    }}
                                  >
                                    <Icon name="filter" />
                                  </A>
                                  {getFilterUI(column)}
                                </>
                              )}
                            </div>
                          </>
                        )}
                        {shownColumns().length - 1 === i && (
                          <div
                            className="dropdown"
                            onClick={() => setShowColumnReorder(true)}
                            onKeyPress={() => setShowColumnReorder(true)}
                            role="button"
                            tabIndex="0"
                          >
                            <A
                              className="dropdown-toggle"
                              role="button"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <Icon name="rearrangeColumns" className="manageCol" />
                            </A>
                            {showColumnReorder === true && (
                              <ColumnSortPanel
                                displayColumns={displayColumns}
                                setDisplayColumns={setDisplayColumns}
                                unCheckedColumns={unCheckedColumns}
                                setUnCheckedColumns={setUnCheckedColumns}
                                setShowColumnReorder={setShowColumnReorder}
                                defaultColumns={columns}
                              />
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
            <div className="divTableBody">
              {gridData.map(row => (
                <div className="divTableRow">
                  {shownColumns().map(column => (
                    <div className="divTableCell" key={column.label}>
                      {row[column.viewKey]}
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>

    </>
  );
};

SaviyntGrid.propTypes = {
  setReqBodyOptions: PropTypes.func,
  setAppliedFilters: PropTypes.func,
  globalSearchText: PropTypes.string,
  setGlobalSearchText: PropTypes.func,
  uniqueKey: PropTypes.string,
  onRowSelected: PropTypes.func,
  topOffset: PropTypes.number,
  isLoading: PropTypes.bool,
  isListView: PropTypes.bool,
  classNames: PropTypes.string,
  reqBodyOptions: PropTypes.shape({
    findby: PropTypes.string.isRequired,
    findBy: PropTypes.string.isRequired
  }).isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired
    })
  ).isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      viewKey: PropTypes.string.isRequired,
      key: PropTypes.string.isRequired
    })
  ).isRequired,
  appliedFilters: PropTypes.shape({
    id: PropTypes.arrayOf(PropTypes.string.isRequired)
  }).isRequired,
  hideRearrangeColumn: PropTypes.bool,
  sendAllRows: PropTypes.func.isRequired
};
SaviyntGrid.defaultProps = {
  uniqueKey: null,
  onRowSelected: null,
  setReqBodyOptions: null,
  setAppliedFilters: null,
  globalSearchText: null,
  setGlobalSearchText: null,
  topOffset: null,
  isLoading: null,
  isListView: null,
  classNames: null,
  hideRearrangeColumn: null
};

const SaviyntGridIntl = injectIntl(SaviyntGrid);

export { SaviyntGridIntl as SaviyntGrid };