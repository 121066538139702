import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { setFullScreenOpen } from 'ui-common/src/lib/utils';
import { setFullScreenGlobalOpen } from 'ui-common/src/utils/LoginActions';
import { CloseButton } from '../CustomButtons';
import styles from './FullScreen.module.scss';

const FullScreen = ({ closeFullScreen, children, showGlobalClose = true, className }) => {
  const dispatch = useDispatch();
  // set open class after intial render. that would bring the animation.
  const [open, setOpen] = useState('');
  useEffect(() => {
    setFullScreenOpen(true);
    dispatch(setFullScreenGlobalOpen(true)); // global redux state to tell header component that fullscreen open. for accessability
    setTimeout(() => {
      setOpen('open');
    }, 0);
  }, [children]);
  const close = () => {
    setOpen('');
    setFullScreenOpen(false);
    dispatch(setFullScreenGlobalOpen(false));
    setTimeout(() => {
      setTimeout(() => {
        closeFullScreen();
      }, 1000);
    });
  };
  return (
    <div
      id="fullscreencomponent"
      className={`${styles.fullscreenContainer} ${styles.overlayScale} ${styles[open]} ${className}}`}
    >
      {showGlobalClose && <CloseButton handleClose={close} defaultPosition />}
      {children(close)}
    </div>
  );
};

FullScreen.propTypes = {
  closeFullScreen: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  showGlobalClose: PropTypes.bool,
  className: PropTypes.string,
};
FullScreen.defaultProps = {
  showGlobalClose: true,
  className: '',
};
export default FullScreen;
