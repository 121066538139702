import React, { Component } from 'react';
import Autosuggest from 'react-autosuggest';
import './AutoSuggest.css';
import PropTypes from 'prop-types';

class SaviyntAutoSuggest extends Component {
  state = {
    inputValue: '',
    suggestions: [],
    selectedObject: this.props.multi ? [] : {},
  };

  componentDidMount() {
    if (this.props.multi) {
      this.setState({
        selectedObject: this.props.value ? this.props.value : [],
        // inputValue: this.props.value ? this.props.value.label : ""
      });
    } else {
      this.setState({
        selectedObject: this.props.value ? this.props.value : {},
        inputValue: this.props.value ? this.props.value.label : '',
      });
    }
  }

  static getDerivedStateFromProps(newProps, state) {
    if (newProps.multi) {
      return {
        ...state,
        selectedObject: newProps.value,
      };
    }
    return state;
  }

  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: this.getSuggestions(value),
    });
  };

  onSuggestionsClearRequested = () => {
    // this.setState({
    //   // suggestions: []
    // });
  };

  getSuggestions = value => {
    const inputValue = value.trim().toLowerCase();
    if (this.props.multi) {
      return this.props.options.filter(
        item =>
          item.label.toLowerCase().indexOf(inputValue) >= 0 &&
          !this.state.selectedObject.map(item => item.label).includes(item.label)
      );
    }
    return this.props.options.filter(item => item.label.toLowerCase().indexOf(inputValue) >= 0);
  };

  getSuggestionValue = suggestion => {
    if (this.props.multi) {
      const newValue = [...this.state.selectedObject, suggestion];
      const newState = {
        ...this.state,
        selectedObject: newValue,
      };
      this.setState(newState);
      this.props.onSuggestionChanged(newValue);
      return '';
    }
    this.setState({
      selectedObject: suggestion,
    });
    this.props.onSuggestionChanged(suggestion);

    return suggestion.label;
  };

  setStateAfterSelection = () => {};

  clearValue = () => {
    if (!this.props.multi) {
      this.setState({ selectedObject: {}, inputValue: '' });
    }
    if (this.props.onClearValue) this.props.onClearValue();
  };

  // Use your imagination to render suggestions.
  renderSuggestion = suggestion => <div>{suggestion.labelHtml || suggestion.label}</div>;

  onInputChange = (event, { newValue }) => {
    if (!newValue && !this.props.multi) this.props.onSuggestionChanged({});
    this.setState({
      inputValue: newValue,
    });
  };

  removeSelection = selectionLabel => {
    const prunedSelectedObject = this.state.selectedObject.filter(
      selection => selection.label !== selectionLabel
    );
    this.setState({
      selectedObject: prunedSelectedObject,
    });
    this.props.onSuggestionChanged(prunedSelectedObject);
  };

  render() {
    const inputProps = {
      placeholder: this.props.placeholder,
      type: 'search',
      onChange: this.onInputChange,
      onClick: e => this.onSuggestionsFetchRequested({ value: e.target.value }),
      value: this.state.inputValue,
    };
    let inputSelected = false;
    if (!this.props.multi) {
      if (this.state.selectedObject.id) {
        inputSelected = true;
      }
    }
    return (
      <div
        className={
          inputSelected ? 'input_selected autosuggest-maincontainer' : 'autosuggest-maincontainer'
        }
      >
        {this.props.multi && (
          <ul className="capsul_select scrollbar" id="style-3">
            {this.state.selectedObject.map(selection => (
              <li>
                <span className="multi-selection">{selection.label}</span>
                <span
                  className="ses-list float-right"
                  role="button"
                  tabIndex={0}
                  onKeyPress={() => this.removeSelection(selection.label)}
                  onClick={() => this.removeSelection(selection.label)}
                >
                  <svg width="9.193" height="9.191" className="remove-icon">
                    <g
                      fill="none"
                      stroke=""
                      strokeLinecap="round"
                      strokeMiterlimit="10"
                      strokeWidth="1.5"
                    >
                      <path data-name="CANCEL ICON2" d="M1.06 1.061l7.072 7.071" />
                      <path data-name="CANCEL ICON1" d="M8.132 1.061L1.061 8.132" />
                    </g>
                  </svg>
                </span>
              </li>
            ))}
          </ul>
        )}
        <Autosuggest
          suggestions={this.state.suggestions}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          getSuggestionValue={this.props.initialValue || this.getSuggestionValue}
          // onSuggestionSelected={this.onSuggestionsFetchRequested}
          shouldRenderSuggestions={() => true}
          // alwaysRenderSuggestions={true}
          renderSuggestion={this.renderSuggestion}
          inputProps={inputProps}
        />
        {this.state.inputValue && !this.props.multi && (
          <span
            role="button"
            tabIndex={0}
            onKeyPress={() => this.clearValue()}
            className="autosuggest-close pull-right"
            onClick={() => this.clearValue()}
          >
            <svg width="9.193" height="9.191">
              <g
                fill="none"
                stroke="#969696"
                strokeLinecap="round"
                strokeMiterlimit="10"
                strokeWidth="1.5"
              >
                <path data-name="CANCEL ICON2" d="M1.06 1.061l7.072 7.071" />
                <path data-name="CANCEL ICON1" d="M8.132 1.061L1.061 8.132" />
              </g>
            </svg>
          </span>
        )}
      </div>
    );
  }
}

SaviyntAutoSuggest.propTypes = {
  multi: PropTypes.bool.isRequired,
  initialValue: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  onSuggestionChanged: PropTypes.func.isRequired,
  onClearValue: PropTypes.func.isRequired,
  options: PropTypes.arrayOf.isRequired,
  value: PropTypes.string.isRequired,
};

export default SaviyntAutoSuggest;
