import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import styles from '../EllipsedLabelHtmlToolTip/EllipsedLabelHtmlToolTip.module.scss';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { injectIntl } from 'react-intl';


const EllipsedViewMoreToolTip = ({ maxLength, label, title, intl }) => {
  const [open, setOpen] = React.useState(false);


  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };
  const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: '#FFFFFF',
      color: 'rgba(0, 0, 0, 0.87)',
      width: 220,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #DDDDDD',
      borderRadius: `6px 0 0 0 6px 6px 6px`,
      boxShadow: `1px 3px 8px 1px #DFE0EB`,
      paddingLeft: '10px',

    },
    arrow: {
      color: "#d7d0c8",
    }

  }))(Tooltip);

  let viewCount = 0;
  let totalViews = [];
  if (label && label.length > 0) {
    totalViews = label.split(',');
    viewCount = label.split(',').length - 1;
  }

  let items = viewCount > 0 ? label.split(',').map(a => (
    <div>
      <div
        className={`${styles.toolTipMenuItem} d-flex flex-column`}>
        <span className={styles.menuItemText}>{a}</span>
      </div>
    </div>)) : null;
  return viewCount > 0 && items ? (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <HtmlTooltip enterDelay="500"
        PopperProps={{
          disablePortal: true,
        }}
        open={viewCount > 0 ? open : undefined}
        title={
          <div className={styles.toltipScroll}>
            <div className={styles.toolTipTitle}>
              {title}
            </div>
            <div className={styles.scrollInside}>
              {items}
            </div>
          </div>} arrow
        placement='bottom-start' interactive>
        <span>{`${totalViews[0]}...`}

          {viewCount > 0 && (
            <span className={styles.moreAssignee} onClick={handleTooltipOpen}>{`${viewCount} ${intl.formatMessage({ id: 'View.More' })}`}</span>
          )}
        </span>
      </HtmlTooltip>
    </ClickAwayListener>
  ) : (
      <span>{label}</span>
    );
};
EllipsedViewMoreToolTip.propTypes = {
  maxLength: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};
export default injectIntl(EllipsedViewMoreToolTip);
