import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import { CloseButton, Icon, EllipsedLabel } from 'ui-components/src';
import TicketButton from './TicketButton';
import './ManageTicket.scss';

const useStyles = makeStyles(() => ({
  paper: {
    height: 282,
    width: 280,
  },
  input: {
    width: '100%',
    marginTop: 23,
    marginBottom: 23,
  },
  button: {
    height: 26,
    width: 64,
    padding: 0,
  },
}));

const Ticket = props => {
  const { children, handleRemove, handleClick } = props;
  return (
    <div className="ticket-label d-flex align-items-center">
      <div
        onClick={handleClick}
        onKeyDown={handleClick}
        onFocus={() => {}}
        role="button"
        tabIndex={0}
      >
        {children}
      </div>
      {handleRemove && <Icon name="customClose" onClick={handleRemove} />}
    </div>
  );
};

const ManageTicket = ({ selectedTickets, addTicket, removeTicket, cartStyle, intl }) => {
  const classes = useStyles();
  const [popoverEl, setPopoverEl] = React.useState(null);
  const [ticketValue, setTicketValue] = useState('');
  const [hoveredTicket, setHoveredTicket] = useState('');

  const showPopup = e => {
    setPopoverEl(e.currentTarget);
  };
  const handlePopoverClose = () => {
    setTicketValue('');
    setPopoverEl(null);
  };
  const handleInputChange = e => {
    setTicketValue(e.target.value);
  };

  return (
    <div>
      <div className="ticket-area d-flex align-items-end" onMouseLeave={() => setHoveredTicket('')}>
        {selectedTickets.length === 0 && !cartStyle ? (
          <TicketButton handleClick={showPopup} />
        ) : (
          <div className="d-flex justify-content-end">
            {selectedTickets.map(tNum => (
              <div
                onMouseOver={() => setHoveredTicket(tNum)}
                onFocus={() => setHoveredTicket(tNum)}
              >
                <Ticket>
                  <EllipsedLabel label={tNum} maxLength={10} />
                </Ticket>
              </div>
            ))}{' '}
          </div>
        )}
        <div className="selected-tickets d-flex justify-content-end">
          {!cartStyle &&
            selectedTickets.map(
              tNum =>
                tNum === hoveredTicket && (
                  <Ticket handleRemove={() => removeTicket(tNum)}>{tNum}</Ticket>
                )
            )}
        </div>
      </div>
      <Popover
        open={!!popoverEl}
        anchorEl={popoverEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        classes={{ paper: classes.paper }}
        disableTypography
      >
        <div className="popup-container">
          <div className="popup-header">
            <span>{intl.formatMessage({ id: 'pa.reserveSlot.tickets.title' })}</span>
            <CloseButton handleClose={handlePopoverClose} small defaultPosition />
          </div>
          <Divider />
          <div className="popup-content">
            <TextField
              className={classes.input}
              placeholder={
                cartStyle
                  ? 'Add business justification'
                  : intl.formatMessage({ id: 'pa.reserveSlot.tickets.fieldPlaceholder' })
              }
              variant="outlined"
              value={ticketValue}
              onChange={e => {
                if (e.target.value.length <= 20) {
                  handleInputChange(e);
                }
              }}
            />
            {/* todo: 
            <div className="used-tickets">
              <span>{intl.formatMessage({ id: 'pa.reserveSlot.tickets.used' })}</span>
              <div className="ticket-labels d-flex justify-content-start">
                {usedTickets.map(tNum => (
                  <Ticket handleClick={() => setTicketValue(tNum)}>{tNum}</Ticket>
                ))}
              </div>
            </div> */}
          </div>
          <div className="popup-actions">
            <Button
              className={classes.button}
              color="default"
              variant="outlined"
              disabled={ticketValue.trim() === ''}
              onClick={handlePopoverClose}
            >
              {intl.formatMessage({ id: 'Cancel' })}
            </Button>
            <Button
              className={classes.button}
              color="primary"
              variant="outlined"
              disabled={ticketValue.trim() === ''}
              onClick={() => {
                addTicket(ticketValue);
                handlePopoverClose();
              }}
            >
              {intl.formatMessage({ id: 'Add' })}
            </Button>
          </div>
        </div>
      </Popover>
    </div>
  );
};

Ticket.propTypes = {
  children: PropTypes.node.isRequired,
  handleRemove: PropTypes.func,
  handleClick: PropTypes.func,
};
Ticket.defaultProps = {
  handleRemove: null,
  handleClick: null,
};

ManageTicket.propTypes = {
  selectedTickets: PropTypes.arrayOf(PropTypes.string),
  addTicket: PropTypes.func,
  removeTicket: PropTypes.func,
  cartStyle: PropTypes.bool,
  intl: PropTypes.arrayOf(
    PropTypes.shape({
      locale: PropTypes.string.isRequired,
      formatMessage: PropTypes.func.isRequired,
    })
  ).isRequired,
};

ManageTicket.defaultProps = {
  selectedTickets: [],
  cartStyle: false,
  addTicket: null,
  removeTicket: null,
};

export default injectIntl(ManageTicket);
