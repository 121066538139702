import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Dialog, Button, IconButton } from '@material-ui/core';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import CloseIcon from '@material-ui/icons/Close';

const styles = theme => ({
  root: {
    margin: 0,
    padding: '26px 24px 10px',
    backgroundColor: '#ffffff',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  margin: {
    margin: theme.spacing(1),
  },
  title: {
    fontSize: '14px',
    fontWeight: '500',
    color: '#1e1e1e',
  },
});

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(0.5),
  },
  outlinedPrimary: {
    fontWeight: 500,
  },
  outlined: {
    padding: '2px 7px',
  },
  content: {
    fontSize: '14px',
    lineHeight: '1.71',
    color: '#242424',
    paddingTop: '20px',
    paddingRight: '70px',
    fontWeight: 500,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} timeout={1000} {...props} />;
});

const DialogContent = withStyles(() => ({
  root: {
    padding: '0 24px',
    backgroundColor: '#ffffff',
    borderTop: '0.5px solid #dcdcdc',
    borderBottom: 0,
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
    backgroundColor: '#ffffff',
  },
}))(MuiDialogActions);

const DialogTitle = withStyles(styles)(props => {
  const { classes, onClose, title } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <h6 className={classes.title}>{title}</h6>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

DialogTitle.propTypes = {
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

const ConfirmModal = ({
  onDialogConfirm,
  open,
  onDialogClose,
  message,
  title,
  cancelButtonLabel,
  confirmButtonLabel,
}) => {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={onDialogClose}
      TransitionComponent={Transition}
      disableBackdropClick
      PaperProps={{
        style: {
          height: '200px',
        },
      }}
    >
      <DialogTitle id="customized-dialog-title" onClose={onDialogClose} title={title} />
      <DialogContent dividers>
        <p className={classes.content}> {message}</p>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="primary"
          className={clsx(classes.outlinedPrimary, classes.button, classes.outlined)}
          onClick={onDialogClose}
        >
          {cancelButtonLabel}
        </Button>
        <Button
          variant="contained"
          color="primary"
          className={clsx(classes.outlinedPrimary, classes.button, classes.outlined)}
          onClick={onDialogConfirm}
        >
          {confirmButtonLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ConfirmModal.propTypes = {
  onDialogClose: PropTypes.func.isRequired,
  onDialogConfirm: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  confirmButtonLabel: PropTypes.string.isRequired,
  cancelButtonLabel: PropTypes.string.isRequired,
};

export default injectIntl(ConfirmModal);
