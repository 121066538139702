import React, { useCallback, useState, useEffect, useRef } from 'react';
import useOnClickOutside from 'use-onclickoutside';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Icon, ModuleLink } from 'ui-components/src';
import PropTypes from 'prop-types';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { matchRouteToRegExArr } from 'ui-common/src/lib/utils';
import styles from './sidebar.module.scss';
import { Collapse } from '@material-ui/core';
import _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import ClearIcon from '@material-ui/icons/Clear';
import IconImage from './IconImage';

const useStyles = makeStyles(() => ({
  paper: {
    backgroundColor: 'var(--navbar-bg)',
    overflowX: 'hidden',
  },
}));

const Sidebar = props => {
  const classes = useStyles();
  const { IDWMS_API_URL, APPLICATION_LOGO } = useSelector(state => state.user.domainInfo || {});
  const [state, setState] = React.useState({
    top: false,
    left: true,
  });
  process.env.REACT_APP_MODE === 'ECM' ? require('./SidebarCommon.css') : require('./Sidebar.css');
  const [menu, setMenu] = useState([]);
  const [search, setSearch] = useState('');
  const [origMenu, setOrigMenu] = useState([]);
  const navBarRef = useRef(null);
  const allowedRoutes = useSelector(state => state.user.userModules);
  const featureList = useSelector(state => state.user.featureNameList);

  const onSubmenuClick = useCallback((itemId, subItemId, moduleName) => {
    if (moduleName !== null) props.moduleClicked(moduleName);
    setMenu(
      menu.map(item => {
        if (item.id === itemId) {
          /* eslint-disable no-param-reassign */
          item.submenu = item.submenu.map(subItem => {
            if (subItem.id === subItemId) {
              return { ...subItem, active: true };
            }
            return { ...subItem, active: false };
          });
          return item;
        }
        return item;
      })
    );
  });

  const toggleDrawer = (side, open) => event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, [side]: open });
  };

  const drawerClickHandler = () => {
    setTimeout(() => {
      toggleDrawer('left', false);
      props.setToggleStatus(false);
    }, 500);
  };

  useEffect(() => {
    const allowedReactRoutes = allowedRoutes.map(r => {
      if (r) return r.replace('/ECMv6', '');
      return r;
    });
    let newNavbar = [];
    if (props.navbarModule) {
      props.navbarModule.forEach((item, key) => {
        if (item && item.submenu) {
          let count = 0;
          if (item.submenu.length > 0) {
            item.submenu.map(subItem => {
              const url =
                window.location.pathname.split('/')[1] === 'ECM' && subItem.spa
                  ? `/ECMv6/${subItem.link}`
                  : subItem.link[0] === '/'
                  ? subItem.link
                  : `/${subItem.link}`;
              if (
                matchRouteToRegExArr(url, allowedReactRoutes, featureList, subItem.featureName)
                  .length
              ) {
                count += 1;
              }
            });
          }
          if (count === 0) {
            delete props.navbarModule[key];
          }
        }
      });
      newNavbar = props.navbarModule.filter((v)=> { return v !== undefined});
      

    setMenu(
      newNavbar.map(item => {
        if (item.label !== '') return { ...item, open: false };
        else return { ...item, open: true };
      })
    );
    setOrigMenu(
      newNavbar.map(item => {
        if (item.label !== '') return { ...item, open: false };
        else return { ...item, open: true };
      })
    );
    }
  }, [props.navbarModule]);

  const toggleClick = useCallback(id => {
    setMenu(
      menu.map(item => {
        if (item.label === '') {
          return { ...item, open: true };
        } else if (item.id === id) {
          return { ...item, open: !item.open };
        }
        return { ...item, open: false };
      })
    );
  });

  const intlDisplaynamesForLables = str => {
    if (str)
      return props.intl.formatMessage({
        id: str,
      });
    return str;
  };

  const handleSearch = e => {
    const val = e.target.value;
    setSearch(val);
    const tempMenu = _.cloneDeep(origMenu);
    if (val !== '') {
      tempMenu.forEach(group => {
        if (group) {
          let count = 0;
          if (group.label) {
            let groupName = intlDisplaynamesForLables(group.label).replace(/\s+/g, '');
            let valName = val.replace(/\s+/g, '');
            if (groupName.toLowerCase().indexOf(valName.toLowerCase()) !== -1) {
              count += 1;
            }
          }
          if (group.submenu && group.submenu.length > 0) {
            group.submenu.forEach(menu => {
              if (menu.label) {
                let menuName = intlDisplaynamesForLables(menu.label)
                  .replace(/\s+/g, '')
                  .toLowerCase();
                let valName = val.replace(/\s+/g, '').toLowerCase();
                if (menuName.indexOf(valName) !== -1) {
                  count += 1;
                } else if (count !== 1) {
                  menu.hide = true;
                }
              }
            });
            group.submenu = group.submenu.filter(item => !item.hide);
          }
          if (count > 0) {
            group.searchShow = true;
            group.open = true;
          } else {
            group.searchShow = false;
            if (group.label === '') group.open = true;
            else group.open = false;
          }
        }
      });
    }
    let menuVal = [];
    if (val === '') menuVal = origMenu;
    else menuVal = tempMenu.filter(item => item.searchShow);
    setMenu(menuVal);
  };

  const resetSearch = () => {
    setSearch('');
    setMenu(origMenu);
  };

  useOnClickOutside(navBarRef, event => {
    if (event.target.className.includes('MuiPaper-root MuiDrawer-paper')) return;
    setTimeout(() => {
      props.setToggleStatus(false);
    }, 500);
  });

  const sideList = () => (
    <div
      ref={navBarRef}
      className="list-side-section"
      role="presentation"
      // onClick={toggleDrawer(side, false)}
    >
      <div className="grid-navbar-container">
        <div className="grid-navbar-item">
          <Tooltip enterDelay="500" title="Menu">
            <IconButton
              aria-label="menu"
              tabIndex="0"
              className={styles.iconHover}
              onClick={() => drawerClickHandler()}
            >
              <MenuIcon fontSize="medium" />
            </IconButton>
          </Tooltip>
        </div>

        <div className="grid-item saviynt_logo1">
          <a href="/ECMv6/request/requestHome" tabIndex="-1">
            <input
              type="image"
              className="saviynt-logo-size"
              src={`${IDWMS_API_URL}/resource/image/app/${APPLICATION_LOGO}`}
              alt="Application Logo"
              tabIndex="0"
            />
          </a>
        </div>
        <div className="grid-navbar-item divider1">|</div>
        <div className="grid-navbar-item displayName page-sequence nav-title navbar-request-section">
          {sessionStorage.getItem('micro_app')
            ? props.intl.formatMessage({
                id: sessionStorage.getItem('micro_app'),
              })
            : ''}
        </div>
      </div>
        <TextField
          type="text"
          value={search}
          onChange={handleSearch}
          className={'sidebar-searchbar'}
          placeholder={props.intl.formatMessage({ id: 'Sidemenu.search.placeholder' })}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                {/* <SearchIcon /> */}
                <Icon name="searchIcon" />
              </InputAdornment>
            ),
            endAdornment: (
              <>
                {search.length > 0 && (
                  <InputAdornment position="start">
                    <ClearIcon onClick={resetSearch} style={{ fontSize: 15, cursor: 'pointer' }} />
                  </InputAdornment>
                )}
              </>
            ),
            disableUnderline: true,
          }}
        />
      <List className="left-menu left-menu-sidebar" dense="true">
        {menu &&
          menu.map(item => {
            if (item.submenu) {
              if (item.submenu.length > 0) {
                let itemLabel = item.label
                  ? props.intl.formatMessage({
                      id: item.label,
                    })
                  : '';
                let itemLabelShow = (
                  <div className="sidebar-groupname">
                  {item.label !== '' ? (
                    <IconImage name={item.icons} className="sidebar-icon-color" tabIndex="-1" />
                  ) : null}
                  <div className="sidebar-groupname-label" role="button" aria-hidden="true">
                    {itemLabel}
                  </div>
                  {item.label !== '' ? (
                    <IconButton
                      className="sidebar-expand-icon"
                      aria-label={props.intl.formatMessage({
                        id: item.label,
                      })}
                    >
                      {item.open ? <ExpandLess /> : <ExpandMore />}
                    </IconButton>
                  ) : null}
                </div>
                );
                return (
                  <>
                    <ListItem key={item.id} dense="true" onClick={() => toggleClick(item.id)}>
                      <ListItemText
                        className={`item-names ${item.showMenu} ${
                          item.label === '' ? 'remove-orphan-menulabel' : ''
                        }`}
                        // aria-label="header"
                        primary={itemLabelShow}
                        dense="true"
                        secondary={
                          <Collapse in={item.open} timeout="auto" unmountOnExit>
                            <List dense="true">
                              {item &&
                                item.submenu.map(subItem => {
                                  const urlToCheck =
                                    window.location.pathname.split('/')[1] === 'ECM' && subItem.spa
                                      ? `/ECMv6/${subItem.link}`
                                      : subItem.link[0] === '/'
                                      ? subItem.link
                                      : `/${subItem.link}`;
                                  let windowUrl = window.location.pathname + window.location.search;
                                  windowUrl = windowUrl.replace('/ECMv6', '');
                                  const activeClassName =
                                    windowUrl === urlToCheck ? 'active' : 'pb-0';
                                  return (
                                    <ModuleLink
                                      role="menuitem"
                                      className={`sidebarLink ${activeClassName}`}
                                      url={
                                        window.location.pathname.split('/')[1] === 'ECM' &&
                                        subItem.spa
                                          ? `/ECMv6/${subItem.link}`
                                          : subItem.link[0] === '/'
                                          ? subItem.link
                                          : `/${subItem.link}`
                                      }
                                      dense="true"
                                      featureName={subItem.featureName}
                                      onClick={() => {
                                        onSubmenuClick(item.id, subItem.id, subItem.moduleName);
                                        props.setToggleStatus(false);
                                      }}
                                      type={
                                        window.location.pathname.split('/')[1] === 'ECMv6' &&
                                        subItem.spa
                                          ? 'sidebar-sameApp'
                                          : 'sidebar'
                                      }
                                    >
                                      {item.label === '' ? (
                                        <ListItem
                                          button
                                          key={subItem.id}
                                          className={'sidebar-menu-groupitem'}
                                          tabIndex="-1"
                                          aria-label={props.intl.formatMessage({
                                            id: subItem.label,
                                          })}
                                        >
                                          <IconImage
                                            name={item.icons}
                                            className={`sidebar-icon-color ${activeClassName}`}
                                            tabIndex="-1"
                                          />
                                          <span className="navlabel">
                                            {props.intl.formatMessage({
                                              id: subItem.label,
                                            })}
                                          </span>
                                        </ListItem>
                                      ) : (
                                        <ListItem
                                          button
                                          key={subItem.id}
                                          disableGutters="false"
                                          dense="true"
                                          className={styles.sidebarItemHover}
                                          tabIndex="-1"
                                          aria-label={props.intl.formatMessage({
                                            id: subItem.label,
                                          })}
                                        >
                                          <span className={styles.navlabel}>
                                            {props.intl.formatMessage({
                                              id: subItem.label,
                                            })}
                                          </span>
                                        </ListItem>
                                      )}
                                    </ModuleLink>
                                  );
                                })}
                            </List>
                          </Collapse>
                        }
                      />
                    </ListItem>
                  </>
                );
              }
            }
          })}
      </List>
    </div>
  );

  return (
    <div>
      <Drawer
        open={state.left}
        onClose={toggleDrawer('left', false)}
        autoWidth={false}
        classes={{
          paper: classes.paper,
        }}
      >
        {sideList('left')}
      </Drawer>
    </div>
  );
};

Sidebar.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  navbarModule: PropTypes.func.isRequired,
  setToggleStatus: PropTypes.func.isRequired,
  moduleClicked: PropTypes.func.isRequired,
};

export default injectIntl(withRouter(Sidebar));
