import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import styles from './EllipsedLabelHtmlToolTip.module.scss';

const EllipsedLabelHtmlToolTip = ({ maxLength, label, title }) => {
  const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: '#FFFFFF',
      color: 'rgba(0, 0, 0, 0.87)',
      width: 220,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #DDDDDD',
      borderRadius: `6px 0 0 0 6px 6px 6px`,
      boxShadow: `1px 3px 8px 1px #DFE0EB`,
      paddingLeft: '10px',

    },
    arrow: {
      color: "#d7d0c8",
    }

  }))(Tooltip);

  let items = label && label.length > maxLength ? label.split(',').map(a => (<div><div
    className={`${styles.toolTipMenuItem} d-flex flex-column`}>
    <span className={styles.menuItemText}>{a}</span> </div></div>)) : null;

  return label && label.length > maxLength && items ? (
    <HtmlTooltip enterDelay="500"
      title={<div className={styles.toltipScroll}>
        <div className={styles.toolTipTitle}>
          {title}
          </div>
          <div className={styles.scrollInside}>
          {items}
          </div>
          </div>} arrow
      placement='bottom-start' interactive>
      <span>{`${label.substring(0, maxLength)}...`}</span>
    </HtmlTooltip>
  ) : (
      <span>{label}</span>
    );
};
EllipsedLabelHtmlToolTip.propTypes = {
  maxLength: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};
export default EllipsedLabelHtmlToolTip;
