import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import styles from './FileUpload.module.scss';

const UploadedView = props => {
  return (
    <div>
      <h4> {props.uploadedLabel}</h4>
      <div className={styles.downloadFile}>
        <span className={styles.fileIcon}>
          <img src={props.previewImage} width="15" />{' '}
        </span>
        <span className={styles.fileName}>{props.uploadedFile}</span>
        <span className={styles.closeIcon}>
          {' '}
          <CloseIcon onClick={props.click} className={styles.close} />
        </span>
      </div>
    </div>
  );
};

UploadedView.propTypes = {
  uploadedLabel: PropTypes.string.isRequired,
  previewImage: PropTypes.string.isRequired,
  click: PropTypes.func.isRequired,
  uploadedFile: PropTypes.string.isRequired,
};
export default UploadedView;
