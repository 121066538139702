import React, { useState, useRef, useEffect } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import SecureLS from 'secure-ls';
import { withRouter, matchPath } from 'react-router-dom';
import { push } from 'react-router-redirect';
import { injectIntl } from 'react-intl';
import { Cookies } from 'react-cookie';
import useOnClickOutside from 'use-onclickoutside';
import MenuIcon from '@material-ui/icons/Menu';
import AppsIcon from '@material-ui/icons/Apps';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Avatar from '@material-ui/core/Avatar';
import AppBar from '@material-ui/core/AppBar';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  removeReduxState,
  checkEnvironment,
  loadStateFromStorage,
  matchRouteToRegExArr,
  replaceLinksWithCookies,
} from 'ui-common/src/lib/utils';
import Button from '@material-ui/core/Button';
import moment from 'moment';
import Badge from '@material-ui/core/Badge';
import { A, Icon } from 'ui-components/src';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { HideLoader } from 'ui-common/src/utils/Loading-actions';
import _ from 'lodash';
import configData from 'ui-common/src/config';
import {
  logoutAction,
  loginInfoAction,
  appMessages,
  updateMuteEmailAndLanguage,
  ecmLogoutAction,
} from 'ui-common/src/utils/LoginActions';
import AppSwitcher from './AppSwitcher';
import AppLogo from './AppLogo/AppLogo';
import { getModule } from '../../../misc/dynamic-modules';

import Sidebar from '../Navbar';
import styles from './Header.module.scss';

const cookies = new Cookies();
const ls = new SecureLS({ encodingType: 'aes' });

const fullScreenRoutes = [
  '/request/privilegedAccess/selectInstance',
  '/request/privilegedAccess/selection/instanceSelection',
  '/request/privilegedAccess/optionalSelection',
];

const nonFullscreenGriv2routes = [
  '/request/requestHistory',
  '/review/requestApproval',
  '/request/applicationRequest/manageRequestTeam',
];

const useStyles = makeStyles(() => ({
  root: {
    zIndex: 70,
  },
  elevation4: {
    boxShadow: '3px 3px 6px 0 #e1e2e5',
    zIndex: 70,
  },
}));

const style = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(style)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

let gotoLanding = '';

const Header = props => {
  const dispatch = useDispatch();
  const isMaintenancePages = /maintenance\/securityQuestions/gm.test(window.location.href);
  const isECMOnly = /ECM\//.test(window.location.href);
  const headerText = sessionStorage.getItem('micro_app');
  const microApp =
    headerText && headerText !== 'null'
      ? props.intl.formatMessage({
          id: headerText,
        })
      : null;

  const { IDWMS_API_URL, APPLICATION_LOGO, BETA_FEATURES } = useSelector(
    state => state.user.domainInfo || {}
  );
  const securityInfo = useSelector(state => state.user.loginInfo || {});
  const fullscreenGlobalOpen = useSelector(state => state.user.fullscreenGlobalOpen);
  const [showDropdown, setShowDropdown] = useState(false);
  const [chatBot, setChatBotShow] = useState(false);
  const [sidebarMenu, setSidebarMenu] = useState([]);
  const [appSwitcherMenu, setAppSwitcherMenu] = useState([]);
  const [appSwitcherDropdown, setAppSwitcherDropdown] = useState(false);
  const [langPopup, setLangPopup] = useState(false);
  const [toggleStatus, setToggleStatus] = useState(false);
  const [searchActive, setSearchActive] = useState(false);
  const [securityInfoOpen, setSecurityInfoOpen] = React.useState(false);
  const [microAppName, setMicroAppName] = useState(sessionStorage.getItem('micro_app'));
  const [userMenu, setUserMenu] = useState([]);
  const [languageSupport, setLanguageSupport] = useState([]);
  const searchRef = useRef(null);
  const classes = useStyles();

  const tabIndex =
    fullScreenRoutes
      .map(r => matchPath(props.location.pathname, { path: r, exact: true, strict: true }))
      .filter(x => !!x).length > 0
      ? -1
      : nonFullscreenGriv2routes
          .map(r => matchPath(props.location.pathname, { path: r, exact: true, strict: true }))
          .filter(x => !!x).length > 0
      ? 1
      : 0;

  if (process.env.REACT_APP_MODE === 'ECM') {
    // eslint-disable-next-line global-require
    require('./CommonHeader.css');
  } else {
    // eslint-disable-next-line global-require
    require('./Header.css');
  }
  const [imageNotPresent, setImageNotPresent] = useState(false);

  useOnClickOutside(searchRef, () => setSearchActive(false));

  const logout = e => {
    e.preventDefault();
    removeReduxState();
    props.logoutAction();
    dispatch(HideLoader());
    // dispatch(ecmLogoutAction());
    setShowDropdown(!showDropdown);
    cookies.remove('access_token', { path: '/' });
    cookies.remove('refresh_token', { path: '/' });
    cookies.remove('token_type', { path: '/' });
    cookies.remove('MS_REDIRECT_URL', { path: '/' });
    cookies.remove('user_name', { path: '/' });
    cookies.remove('userKey', { path: '/' });
    cookies.remove('first_name', { path: '/' });
    sessionStorage.removeItem('micro_app');
    localStorage.removeItem('micro_app');
    localStorage.removeItem('locale');
    localStorage.removeItem('appConstants');
    ls.removeAll();
    window.location.href = `${checkEnvironment().ECM_URL}/j_spring_security_logout`;
    // push('/logout');
  };

  const changeLanguage = (lang, noLoader) => {
    localStorage.setItem('locale', lang);
    createUserMenu();
    const domainName =
      localStorage.getItem('localDomain') !== null
        ? localStorage.getItem('localDomain')
        : window.location.hostname.split('.')[0];
    const appConstants =
      loadStateFromStorage('appConstants') && loadStateFromStorage('appConstants')[domainName];
    // X-NOLoader should be true during login, so sent extra param
    dispatch(updateMuteEmailAndLanguage({ locale: lang }, !!noLoader));
    if (appConstants && Object.keys(appConstants).length)
      dispatch(appMessages(lang, appConstants.GATEWAY_API_URL));
    setLangPopup(false);
  };

  const openLangPopup = () => {
    setShowDropdown(true);
    setLangPopup(true);
  };

  const handleLangClose = () => {
    setLangPopup(false);
  };

  const openUserMenuLink = url => {
    window.open(url, '_self');
  };

  const fullScreen = () => {
    const el = document.documentElement;
    const rfs = // for newer Webkit and Firefox
      el.requestFullScreen ||
      el.webkitRequestFullScreen ||
      el.mozRequestFullScreen ||
      el.msRequestFullScreen;
    if (typeof rfs !== 'undefined' && rfs) {
      rfs.call(el);
    } else if (typeof window.ActiveXObject !== 'undefined') {
      // for Internet Explorer
      const wscript = new window.ActiveXObject('WScript.Shell');

      if (wscript != null) {
        wscript.SendKeys('{F11}');
      }
    }
  };

  const muteEmail = () => {
    dispatch(updateMuteEmailAndLanguage({ muteEmail: 1 }));
  };

  const helpGuide = () => {
    window.open(props.intl.formatMessage({ id: 'Review.EmptyScreen.RedirectLink' }));
  };

  const [loadedModules, setLoadedModules] = useState({});
  // const [navbarModule, setNavbarModule] = useState(navBarModules);
  // Need to load mavbarmodule json to init the app. TODO have to move it to state for consistancy

  // Function called when left navbar module name is clicked.
  const moduleClicked = moduleName => {
    if (!loadedModules.moduleName) {
      const Module = getModule(moduleName);
      setLoadedModules({ ...loadedModules, [moduleName]: Module });
    }
  };

  const drawerClickHandler = () => {
    setToggleStatus(!toggleStatus);
  };

  const handleAppChange = id => {
    setMicroAppName(id);
    // sessionStorage.setItem('micro_app', id);
  };

  const headerRef = useRef(null);

  const dropDownRef = useRef(null);

  const handleClickOutside = event => {
    if (headerRef.current && !headerRef.current.contains(event.target)) {
      setShowDropdown(false);
    }

    if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, false);
    return () => {
      document.removeEventListener('click', handleClickOutside, false);
    };
  }, []);

  useEffect(() => {
    const { userDetails, userModules } = props;
    if (userDetails) {
      const origSideMenu = userDetails.SIDE_MENU.replace(/\\"/g, '"');
      const origAppSwitcher = userDetails.APPSWITCHER_MENU.replace(/\\"/g, '"');
      const sidebar = JSON.parse(origSideMenu);
      const appswitcher = JSON.parse(origAppSwitcher);
      const allowedRoutes = userModules;
      const url = window.location.pathname;
      const allowedReactRoutes = allowedRoutes.map(r => {
        if (r) return r.replace('/ECMv6', '');
        return r;
      });
      _.forEach(sidebar, miniApps => {
        _.forEach(miniApps, menu => {
          let count = 0;
          menu.submenu.map(subMenu => {
            const url =
              window.location.pathname.split('/')[1] === 'ECM' && subMenu.spa
                ? `/ECMv6/${subMenu.link}`
                : subMenu.link[0] === '/'
                ? subMenu.link
                : `/${subMenu.link}`;
            if (!matchRouteToRegExArr(url, allowedReactRoutes).length) {
              count += 1;
            }
            subMenu.link = replaceLinksWithCookies(subMenu.link);
          });
          if (count === menu.submenu.length) {
            menu.submenu = [];
          }
        });
      });
      setAppSwitcherMenu(appswitcher);
      setSidebarMenu(sidebar);
      // Code for miniappVSurl mapping start
      if (userDetails.URL_MAPPING) {
        let miniApp = '';
        const origUrlMapping = userDetails.URL_MAPPING.replace(/\\"/g, '"');
        const urlList = JSON.parse(origUrlMapping);
        const miniAppAccessList = [];
        const currentMiniApp = sessionStorage.getItem('micro_app');
        _.forEach(urlList, (value, key) => {
          _.forEach(value, eachUrl => {
            const urlFromJson = new URL(window.location.origin + eachUrl);
            if (urlFromJson.pathname === url) {
              miniApp = key;
              miniAppAccessList.push(key);
            } else if (urlFromJson.pathname.includes('**')) {
              const regExStr = urlFromJson.pathname.replace('/**', '');
              if (url.match(regExStr) !== null) {
                miniApp = key;
                miniAppAccessList.push(key);
              }
            } else if (urlFromJson.pathname.includes('*')) {
              const routePathParts = url.split('/');
              const regExStrPathParts = urlFromJson.pathname.split('/');
              // get all indexes of star and delete them, same with route, so you get identical strings to match
              const starIndexes = [];
              regExStrPathParts.forEach((part, i) => {
                if (part === '*') starIndexes.push(i);
              });
              starIndexes.forEach(indexOfStar => {
                delete routePathParts[indexOfStar];
                delete regExStrPathParts[indexOfStar];
              });
              const newRoute = routePathParts.join('/');
              const newRegexStr = regExStrPathParts.join('/');
              if (newRoute === newRegexStr) {
                miniApp = key;
                miniAppAccessList.push(key);
              }
            }
          });
        });
        if (miniApp !== '' && miniAppAccessList.indexOf(currentMiniApp) === -1) {
          sessionStorage.setItem('micro_app', miniApp);
          localStorage.setItem('micro_app', miniApp);
          setMicroAppName(miniApp);
          if (userDetails.APPSWITCHER_MENU) {
            const appSwitcherList = JSON.parse(userDetails.APPSWITCHER_MENU.replace(/\\"/g, '"'));
            _.forEach(appSwitcherList, value => {
              if (miniApp === value.id) gotoLanding = value.link;
            });
          }
        } else if (miniApp === '' && miniAppAccessList.indexOf(currentMiniApp) === -1) {
          // for setting previous tab's mini app to current on right click or ctrl + click
          const localMiniApp = localStorage.getItem('micro_app');
          sessionStorage.setItem('micro_app', localMiniApp);
          setMicroAppName(localMiniApp);
          // for default mini app if url isn't present in any mini app
          // let defaultMiniApp = '';
          // if (userDetails.APPSWITCHER_MENU) {
          //   const appSwitcherList = JSON.parse(userDetails.APPSWITCHER_MENU.replace(/\\"/g, '"'));
          //   _.forEach(appSwitcherList, value => {
          //     defaultMiniApp = value.id;
          //     gotoLanding = value.link;
          //     return false;
          //   });
          // }
          // sessionStorage.setItem('micro_app', defaultMiniApp);
          // setMicroAppName(defaultMiniApp);
        }
      }
      // miniappVSurl End
    }
  }, [props.userDetails, props.userModules]);

  useEffect(() => {
    if (props.userInfo) {
      if (props.userInfo.userSavRoleMap) {
        if (props.userInfo.userSavRoleMap.locale) {
          if (localStorage.getItem('locale') === null)
            changeLanguage(props.userInfo.userSavRoleMap.locale, true);
        }
      }
    }
  }, [props.userInfo]);

  const createUserMenu = () => {
    const userMenuBasic = [
      {
        id: 'fileDownload',
        show: true,
        label: props.intl.formatMessage({ id: 'RequestHome.MyDownloads' }),
        icon: 'downloadMenu',
        clickHandler: () => openUserMenuLink('/ECM/jbpmworkflowmanagement/downloadfiles'),
      },
      {
        id: 'fullScreen',
        show: true,
        label: props.intl.formatMessage({ id: 'RequestHome.Fullscreen' }),

        icon: 'fullScreenMenu',
        clickHandler: fullScreen,
      },
      {
        id: 'muteEmailCheckBox',
        show: true,
        label: props.intl.formatMessage({ id: 'RequestHome.MuteEmail' }),

        icon: 'muteEmailMenu',
        clickHandler: muteEmail,
      },
      {
        id: 'languagesSupported',
        show: true,
        label: props.intl.formatMessage({ id: 'RequestHome.Language' }),

        icon: 'languageMenu',
        clickHandler: openLangPopup,
      },
      {
        id: 'changePassword',
        show: true,
        label: props.intl.formatMessage({ id: 'RequestHome.ChangePassword' }),
        icon: 'passwordMenu',
        clickHandler: () => openUserMenuLink('/ECM/maintenance/changeselfpassword'),
      },
      {
        id: 'resetSecurityQuestions',
        show: true,
        label: props.intl.formatMessage({ id: 'RequestHome.ResetSecurityQuestions' }),
        icon: 'resetMenu',
        clickHandler: () => openUserMenuLink('/ECM/maintenance/validatePassword'),
      },
      {
        id: 'recentSecurityActivity',
        show: true,
        label: props.intl.formatMessage({ id: 'RequestHome.RecentSecurity.Activity' }),
        subTitle:
          securityInfo &&
          securityInfo.lastLoginTime &&
          moment
            .utc(securityInfo.lastLoginTime, 'YYYY-MM-DD HH:mm:ss')
            .local()
            .format('MMM DD YYYY hh:mm A'),
        icon: 'resetMenu',
        clickHandler: () => {
          setSecurityInfoOpen(true);
        },
      },
      {
        id: 'helpGuide',
        show: true,
        label: props.intl.formatMessage({ id: 'RequestHome.HelpGuide' }),
        icon: 'resetMenu',
        clickHandler: helpGuide,
      },
      {
        id: 'logoutLink',
        show: true,
        label: props.intl.formatMessage({ id: 'RequestHome.Logout' }),
        icon: 'signoutMenu',
        clickHandler: logout,
      },
    ];

    const userMenuForMaintenancePages = userMenuBasic.filter(
      f => f.id === 'languagesSupported' || f.id === 'logoutLink'
    );

    const menuItems = isMaintenancePages ? userMenuForMaintenancePages : userMenuBasic;
    menuItems.map(menu => {
      for (const [menuAPIkey, menuAPIVal] of Object.entries(props.headerMenu)) {
        if (menu.id === menuAPIkey) {
          if (menu.id !== 'languagesSupported') {
            menu.show = menuAPIVal;
          } else {
            menu.show = menuAPIVal !== null ? Object.keys(menuAPIVal).length > 0 : false;
            if (menu.show) {
              const langs = [];
              Object.keys(menuAPIVal).map(function(key) {
                langs.push({ code: key, value: menuAPIVal[key] });
              });
              setLanguageSupport(langs);
            }
          }
        }
      }
      return menu;
    });
    setUserMenu(menuItems);
  };

  useEffect(() => {
    createUserMenu();
  }, [props.messages]);

  useEffect(() => {
    createUserMenu();
  }, [props.headerMenu]);

  function removeCharacter(str) {
    if (str) {
      return (str.match(/[a-zA-Z0-9]/) || []).pop();
    }
    return null;
  }
  const chatBotsOpen = () => {
    setChatBotShow(!chatBot);
  };

  const navBar = item => {
    return sidebarMenu[item];
  };

  // hardcoded for now as per discussion
  const homeLink = '/ECMv6/request/requestHome';

  return (
    <AppBar
      color="inherit"
      className={`${(classes.root, classes.elevation4)} ${
        window.location.pathname.split('/')[1] === 'ECM'
          ? 'top-bar-box top-bar-box-ecm'
          : 'top-bar-box'
      }`}
    >
      {toggleStatus ? (
        <Sidebar
          navbarModule={navBar(microAppName)}
          moduleClicked={moduleClicked}
          open={toggleStatus}
          setToggleStatus={setToggleStatus}
        />
      ) : null}
      <div className="container-fluid" ref={headerRef}>
        <div className="top-bar bd-highlight mb-3">
          {!isMaintenancePages && (
            <div className="side_drawer_icon">
              <Tooltip
                enterDelay="500"
                title={props.intl.formatMessage({ id: 'Header.tooltip.Menu' })}
              >
                <IconButton
                  aria-label="menu"
                  className={styles.iconHover}
                  // tabIndex={fullscreenGlobalOpen ? '-1' : '0'}
                  tabIndex={tabIndex}
                  onClick={() => drawerClickHandler()}
                >
                  <MenuIcon fontSize="medium" />
                </IconButton>
              </Tooltip>
            </div>
          )}
          <div className="d-flex app_name align-items-center">
            {/* why anchor href?. why not modulelink-- sagar */}
            {isMaintenancePages ? (
              <div className="saviynt_logo" tabIndex={tabIndex}>
                <AppLogo src={`${IDWMS_API_URL}/resource/image/app/${APPLICATION_LOGO}`} />
              </div>
            ) : (
              <a href={homeLink} className="saviynt_logo" tabIndex={tabIndex}>
                <AppLogo src={`${IDWMS_API_URL}/resource/image/app/${APPLICATION_LOGO}`} />
              </a>
            )}
            {microApp && (
              <>
                <div className="divider"> </div>
                <span className="page-sequence displayName displayNameAlign">{microApp}</span>
              </>
            )}
          </div>
          <div className="top-side-box" />
          <div className="user-profile ml-auto p-2 mt-1 bd-highlight">
            {!isMaintenancePages && (
              <>
                <div className={searchActive ? 'searchfilter active' : 'searchfilter'} />
                <div className="ml-auto bd-highlight">
                  <Tooltip
                    enterDelay="500"
                    title={props.intl.formatMessage({ id: 'Header.tooltip.Apps' })}
                  >
                    <IconButton
                      aria-label="apps"
                      className={`${styles.iconHover} nav-icon`}
                      // tabIndex={fullscreenGlobalOpen ? '-1' : '0'}
                      tabIndex={tabIndex}
                      onClick={e => {
                        e.preventDefault();
                        setAppSwitcherDropdown(!appSwitcherDropdown);
                      }}
                    >
                      <AppsIcon fontSize="medium" />
                    </IconButton>
                  </Tooltip>
                  {appSwitcherDropdown && (
                    <AppSwitcher
                      setAppSwitcherDropdown={setAppSwitcherDropdown}
                      setAppName={handleAppChange}
                      appSwitcher={appSwitcherMenu}
                    />
                  )}
                </div>
                <div className="notification  ml-auto p-2 bd-highlight">
                  {checkEnvironment().DEMO_MODE !== 'PROD' && (
                    <Tooltip
                      enterDelay="500"
                      title={props.intl.formatMessage({ id: 'Header.tooltip.Notifications' })}
                    >
                      <IconButton
                        color="#ffffff"
                        className={`${styles.iconHover} nav-icon icon`}
                        // tabIndex={fullscreenGlobalOpen ? '-1' : '0'}
                        tabIndex={tabIndex}
                        aria-label="notifications"
                        onClick={e => {
                          e.preventDefault();
                        }}
                      >
                        <Badge color="primary" variant="dot">
                          <Icon name="notification" className="notificationIcon icon" />
                        </Badge>
                      </IconButton>
                    </Tooltip>
                  )}
                </div>
                <div className="notification  ml-auto bd-highlight" />
              </>
            )}
            <div
              className="dropdownHeader"
              role="presentation"
              onClick={e => {
                e.preventDefault();
                setShowDropdown(!showDropdown);
              }}
            >
              {BETA_FEATURES && BETA_FEATURES.includes('SHOW_USER_IMAGE') ? (
                <A
                  className="d-flex dropdown-link"
                  id="logoutDD"
                  data-toggle="dropdown"
                  // tabIndex="0"
                  tabIndex={tabIndex}
                  aria-label="logout"
                  innerRef={dropDownRef}
                  fontSize="small"
                  src={`${IDWMS_API_URL}/userImage/${cookies.get('user_name')}`}
                  style={{ borderRadius: '50%', height: 29, width: 29 }}
                >
                  {cookies.get('first_name') ? (
                    <div className="user-image">{removeCharacter(cookies.get('first_name'))}</div>
                  ) : null}
                </A>
              ) : (
                <Avatar
                  className="d-flex dropdown-link"
                  id="logoutDD"
                  data-toggle="dropdown"
                  // tabIndex="0"
                  tabIndex={tabIndex}
                  aria-label="logout"
                  ref={dropDownRef}
                  fontSize="small"
                  style={{ borderRadius: '50%', height: 29, width: 29 }}
                >
                  {cookies.get('first_name') ? (
                    <div className="user-image">{removeCharacter(cookies.get('first_name'))}</div>
                  ) : null}
                </Avatar>
              )}
              {(!BETA_FEATURES ||
                (BETA_FEATURES && !BETA_FEATURES.includes('MANAGE_IDENTITY_ACCESS'))) &&
                showDropdown && (
                  <ul className="dropdown-menu" aria-labelledby="logoutDD">
                    {userMenu.map(
                      menutItem =>
                        menutItem.show && (
                          <li
                            className="dropdown-item"
                            onClick={menutItem.clickHandler}
                            role="presentation"
                          >
                            <Icon name={menutItem.icon} className="userMenuIcon" tabIndex="-1" />
                            <A className="dropdown-item-link">{menutItem.label}</A>
                          </li>
                        )
                    )}
                  </ul>
                )}
              {BETA_FEATURES && BETA_FEATURES.includes('MANAGE_IDENTITY_ACCESS') && showDropdown && (
                <div className="dropdown-menu">
                  <div className="user-username">{`${
                    props.userInfo && props.userInfo.firstname ? props.userInfo.firstname : ''
                  } ${
                    props.userInfo && props.userInfo.lastname ? props.userInfo.lastname : ''
                  }`}</div>
                  <div className="user-designation">{props.userInfo && props.userInfo.title}</div>
                  <Button
                    primary
                    color="primary"
                    variant="contained"
                    className="user-manage"
                    onClick={() => push('/request/manageRequest')}
                  >
                    {' '}
                    {props.intl.formatMessage({
                      id: 'RequestHome.userMenu.manageMyAccount.label',
                    })}{' '}
                  </Button>
                  <ul aria-labelledby="logoutDD" className="user-option">
                    {userMenu.map(
                      menutItem =>
                        menutItem.show && (
                          <li
                            className="dropdown-item-new"
                            onClick={menutItem.clickHandler}
                            role="presentation"
                          >
                            <Icon name={menutItem.icon} className="userMenuIcon" tabIndex="-1" />
                            <div>
                              <A className="dropdown-item-link-new">{menutItem.label}</A>
                              {menutItem.subTitle && (
                                <div className="dropdown-item-sublink">{menutItem.subTitle}</div>
                              )}
                            </div>
                          </li>
                        )
                    )}
                  </ul>
                </div>
              )}

              {/* secuirtyinfo dialog  */}
              <Dialog
                onClose={() => setSecurityInfoOpen(false)}
                aria-labelledby="customized-dialog-title"
                open={securityInfoOpen}
              >
                <DialogTitle
                  id="customized-dialog-title"
                  onClose={() => {
                    setSecurityInfoOpen(false);
                  }}
                >
                  {props.intl.formatMessage({ id: 'RequestHome.RecentSecurity.Activity' })}
                </DialogTitle>

                <DialogContent>
                  <div className="recetnActivite">
                    {securityInfo.lastLoginTime && (
                      <div>
                        {' '}
                        <div className="d-flex justify-content-between w-100 tableActivite">
                          <div className="userLoginText">
                            <h6>
                              {props.intl.formatMessage({
                                id: 'RequestHome.Dialog.RecentSecurityActivity.LastSuccessLogin',
                              })}
                            </h6>
                          </div>
                          <div className="timeLoginText">
                            <div>
                              <h6>
                                {securityInfo.lastLoginTime &&
                                  moment
                                    .utc(securityInfo.lastLoginTime, 'YYYY-MM-DD HH:mm:ss')
                                    .local()
                                    .format('MMM DD YYYY hh:mm A')}
                              </h6>
                              <span className="lastLoginIP">ip:{securityInfo.lastLoginIP}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}{' '}
                  </div>
                </DialogContent>
                <DialogActions>
                  <Button autoFocus onClick={() => setSecurityInfoOpen(false)} color="primary">
                    {props.intl.formatMessage({
                      id: 'RequestHome.Dialog.RecentSecurityActivity.Close',
                    })}
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
            <Dialog
              onClose={handleLangClose}
              aria-labelledby="simple-dialog-title"
              open={langPopup}
            >
              <DialogTitle id="simple-dialog-title">
                {props.intl.formatMessage({ id: 'RequestHome.Dialog.Title.Select.Language' })}
              </DialogTitle>
              <List>
                {languageSupport.map(langObj => {
                  return (
                    <ListItem button onClick={() => changeLanguage(langObj.code)}>
                      {props.intl.formatMessage({
                        id: `RequestHome.Dialog.Language.Option.${langObj.value}`,
                      })}
                    </ListItem>
                  );
                })}
              </List>
            </Dialog>
            {configData.isChatBots && (
              <div className="chat-bot-icon-section">
                <img
                  src="/ECMv6/assets/images/cb-logo-1.jpg"
                  alt="Chat bots"
                  height="20"
                  width="20"
                  role="presentation"
                  onClick={chatBotsOpen}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      {/* {chatBot ? <ChatBots /> : null} */}
    </AppBar>
  );
};

const mapDispatchToProps = {
  logoutAction,
};

const mapStateToProps = state => ({
  userDetails: state.user.domainInfo,
  headerMenu: state.user.headerMenu,
  userModules: state.user.userModules,
  userInfo: state.user.userInfo,
  messages: state.user.messages,
});

Header.propTypes = {
  logoutAction: PropTypes.func.isRequired,
  userDetails: PropTypes.arrayOf.isRequired,
  userModules: PropTypes.arrayOf.isRequired,
  headerMenu: PropTypes.arrayOf.isRequired,
  userInfo: PropTypes.arrayOf.isRequired,
  intl: PropTypes.arrayOf.isRequired,
};

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(Header)));
