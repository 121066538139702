export const REQUEST_ACTIONS_LIST = 'REQUEST_ACTIONS_LIST';
export const REQUEST_ACTIONS_LIST_SUCCESS = 'REQUEST_ACTIONS_LIST_SUCCESS';
export const REQUEST_ACTIONS_LIST_FAILURE = 'REQUEST_ACTIONS_LIST_FAILURE';
export const REQUEST_FEATURES_LIST = 'REQUEST_FEATURES_LIST';
export const REQUEST_FEATURES_LIST_SUCCESS = 'REQUEST_FEATURES_LIST_SUCCESS';
export const REQUEST_FEATURES_LIST_FAILURE = 'REQUEST_FEATURES_LIST_FAILURE';
export const REQUEST_ACTIVITY_LIST = 'REQUEST_ACTIVITY_LIST';
export const REQUEST_ACTIVITY_LIST_SUCCESS = 'REQUEST_ACTIVITY_LIST_SUCCESS';
export const REQUEST_ACTIVITY_LIST_FAILURE = 'REQUEST_ACTIVITY_LIST_FAILURE';
export const REQUEST_IMPORTANT_ACTIONS_LIST = 'REQUEST_IMPORTANT_ACTIONS_LIST';
export const REQUEST_IMPORTANT_ACTIONS_LIST_SUCCESS = 'REQUEST_IMPORTANT_ACTIONS_LIST_SUCCESS';
export const REQUEST_IMPORTANT_ACTIONS_LIST_FAILURE = 'REQUEST_IMPORTANT_ACTIONS_LIST_FAILURE';
export const ADD_KPI_DATA = 'ADD_KPI_DATA';
export const REQUEST_TEAM_USER_LIST_SUCCESS = 'REQUEST_TEAM_USER_LIST_SUCCESS';
export const REQUEST_TEAM_USER_LIST = 'REQUEST_TEAM_USER_LIST';
export const REQUEST_RECENT_USER_LIST_SUCCESS = 'REQUEST_USER_RECENT_LIST_SUCCESS';
export const REQUEST_RECENT_USER_LIST_FAILURE = 'REQUEST_RECENT_USER_LIST_FAILURE';
export const REQUEST_RECENT_USER_LIST = 'REQUEST_RECENT_USER_LIST';
export const REQUESTHOME_PENDING_ACTIONS = 'REQUESTHOME_PENDING_ACTIONS';
export const REQUESTHOME_PENDING_ACTIONS_SUCCESS = 'REQUESTHOME_PENDING_ACTIONS_SUCCESS';
export const RESET_REFRESH_KPI_FLAG = 'RESET_REFRESH_KPI_FLAG';


export const requestRecentUserListSuccess = response => ({
  type: REQUEST_RECENT_USER_LIST_SUCCESS,
  response,
});

export const requestRecentUserList = () => ({
  type: REQUEST_RECENT_USER_LIST,
});

export const requestRecentUserListFailure = () => ({
   type: REQUEST_RECENT_USER_LIST_FAILURE,
})

export const requestUserListSuccess = (response, searchText) => ({
  type: REQUEST_TEAM_USER_LIST_SUCCESS,
  response,
  searchText,
});

export const requestUserList = searchText => ({
  type: REQUEST_TEAM_USER_LIST,
  searchText,
});

export const requestActionsList = () => ({
  type: REQUEST_ACTIONS_LIST,
});

export const requestActionsListSuccess = response => ({
  type: REQUEST_ACTIONS_LIST_SUCCESS,
  response,
});

export const requestActionsListFailure = error => ({
  type: REQUEST_ACTIONS_LIST_FAILURE,
  error,
});

export const requestFeaturesList = () => ({
  type: REQUEST_FEATURES_LIST,
});

export const requestFeaturesListSuccess = response => ({
  type: REQUEST_FEATURES_LIST_SUCCESS,
  response,
});

export const requestFeaturesListFailure = error => ({
  type: REQUEST_FEATURES_LIST_FAILURE,
  error,
});

export const requestActivityList = () => ({
  type: REQUEST_ACTIVITY_LIST,
});

export const requestActivityListSuccess = response => ({
  type: REQUEST_ACTIVITY_LIST_SUCCESS,
  response,
});

export const requestActivityListFailure = error => ({
  type: REQUEST_ACTIVITY_LIST_FAILURE,
  error,
});

export const requestImportantActionsList = () => ({
  type: REQUEST_IMPORTANT_ACTIONS_LIST,
});

export const requestImportantActionsListSuccess = response => ({
  type: REQUEST_IMPORTANT_ACTIONS_LIST_SUCCESS,
  response,
});
export const requestImportantActionsListFailure = response => ({
  type: REQUEST_IMPORTANT_ACTIONS_LIST_FAILURE,
  response,
});
export const getPendingActions = (requestType, maxRecords) => ({
  type: REQUESTHOME_PENDING_ACTIONS,
  requestType,
  maxRecords,
});
export const requestPendingActionsSuccess = (responseJson, requestType) => ({
  type: REQUESTHOME_PENDING_ACTIONS_SUCCESS,
  responseJson,
  requestType,
});
export const resetRefreshKPIFlag = () => ({
  type: RESET_REFRESH_KPI_FLAG,
});
