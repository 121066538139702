import React from 'react';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import CloseIcon from '@material-ui/icons/Close';
import './RequestDetailsModal.scss';
import RequestDetailsTabs from '../RequestDetailsTabs/RequestDetailsTabs';

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  rolesDetailsHeader: {
    height: 2,
    fontFamily: 'Rubik',
    fontSize: 18,
    fontWeight: 500,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#000000',
  },
  rolesDetailsSection: {
    height: 2,
    fontFamily: 'Rubik',
    fontSize: 18,
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#000000',
  },
});

const DialogTitle = withStyles(styles)(props => {
  const { classes, onClose, name } = props; // children,
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <h6 className={classes.rolesDetailsHeader}>
        {name} <span className={classes.rolesDetailsSection}>Role Detail</span>
      </h6>

      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});
const RequestDetailsModal = ({ handleClose }) => (
  <Dialog
    onClose={handleClose}
    aria-labelledby="customized-dialog-title"
    open
    PaperProps={{
      style: {
        maxHeight: '100%',
        height: '100%',
        borderRadius: 0,
        minWidth: '43%',
      },
    }}
  >
    <div className="backToDetailsPageSection">
      <ArrowBackIosIcon onClick={() => handleClose(false)} />
      <span
        className="backButton backButton-selection"
        onClick={() => handleClose(false)}
        role="button"
        tabIndex={0}
        onKeyPress=""
      >
        Back to Selection
      </span>
    </div>
    <DialogTitle onClose={handleClose} id="customized-dialog-title" name="Cloud DB Administrator" />
    <RequestDetailsTabs />
  </Dialog>
);

RequestDetailsModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
};

export default injectIntl(RequestDetailsModal);
