import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';

import './FormComponents.scss';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    alignContent: 'flex-end',
    marginTop: '-19px',
    maxWidth: '24.25rem',
    width: '14.25rem',
  },
  field: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    alignSelf: 'flex-end',
  },
  formControl: {
    marginBottom: 0,
    display: 'flex',
    wrap: 'nowrap',
    fullWidth: true,
  },
  selected: {
    // height: 35,
  },
  outlined: {
    transform: 'translate(14px, 13px) scale(1)',
    // fontSize: 12,
  },
  label: {
    display: 'flex',
    flexWrap: 'wrap',
    alignSelf: 'flex-end',
  },
  helperText: {
    margin: '10px 0 0',
    fontSize: '12px',
    lineHeight: 1.4,
  },
  shrink: {
    transform: 'translate(14px, 12px) scale(1) !important',
  },
}));

const SingleSelectDropDown = ({
  options,
  placeholder,
  _handleChange,
  attributeKey,
  defaultValue,
  required,
  onOpen,
  onClose,
  showError,
  readOnly,
  placeholderCenter,
  noneOptionNotRequired,
  empty,
}) => {
  const classes = useStyles();
  const inputLabel = React.useRef(null);
  const labelWidth = 0;
  const [value, setValue] = React.useState(defaultValue);

  useEffect(() => {
    if (empty) setValue('');
  }, [empty]);

  const handleChange = event => {
    setValue(event.target.value);
    _handleChange(event.target.value, attributeKey);
  };

  const emptyfunc = () => {};

  return (
    <div className={classes.root}>
      {!placeholderCenter && (
        <div className={classes.label}>
          <FormHelperText className={classes.helperText}>
            {placeholder}
            {required && <span className="mandatory">&nbsp;*</span>}
          </FormHelperText>
        </div>
      )}
      <div className={classes.field}>
        <FormControl
          variant={placeholderCenter && 'standard'}
          className={classes.formControl}
          margin="dense"
          fullWidth
          error={required ? showError : false}
          disabled={readOnly}
        >
          {placeholderCenter && (
            <InputLabel
              key={inputLabel}
              ref={inputLabel}
              id="demo-simple-select-outlined-label"
            >
              {placeholder}
            </InputLabel>
          )}
          <Select
            key={inputLabel}
            displayEmpty
            value={value}
            onChange={handleChange}
            labelWidth={labelWidth}
            className={classes.select}
            onOpen={onOpen || emptyfunc}
            onClose={onClose || emptyfunc}
            inputProps={{ 'aria-label': 'Without label' }}
            MenuProps={{
              getContentAnchorEl: null,
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
            }}
          >
            {!noneOptionNotRequired && (
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
            )}
            {options.map(item => {
              return (
                <MenuItem key={item.id} value={item.id}>
                  {item.displayName}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </div>
    </div>
  );
};
SingleSelectDropDown.defaultProps = {
  onOpen: () => {},
  onClose: () => {},
  placeholderCenter: false,
  noneOptionNotRequired: false,
};

SingleSelectDropDown.propTypes = {
  _handleChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  defaultValue: PropTypes.string.isRequired,
  attributeKey: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  required: PropTypes.bool.isRequired,
  readOnly: PropTypes.bool.isRequired,
  showError: PropTypes.bool.isRequired,
  placeholderCenter: PropTypes.bool,
  noneOptionNotRequired: PropTypes.bool,
  empty: PropTypes.bool.isRequired,
};

export default SingleSelectDropDown;
