import Moment from 'moment';

const getTimeFromMoment = momentDate => ({
  hours: momentDate.get('hours'),
  minutes: momentDate.get('minutes'),
  seconds: momentDate.get('seconds'),
});

const getInstanceRange = instance => {
  const defaultFromDateTime = new Moment();
  const defaultToDateTime = new Moment();
  const startTimeMinutes =
    defaultFromDateTime.get('minutes') + 2 >= 60
      ? defaultFromDateTime.get('minutes') + 2 - 60
      : defaultFromDateTime.get('minutes') + 2;
  const startTimeHours =
    defaultFromDateTime.get('minutes') + 2 >= 60
      ? defaultFromDateTime.get('hours') + 1
      : defaultFromDateTime.get('hours');

  const endTimeMinutes =
    defaultToDateTime.get('minutes') + 32 >= 60
      ? defaultToDateTime.get('minutes') + 32 - 60
      : defaultToDateTime.get('minutes') + 32;
  const endTimeHours =
    defaultToDateTime.get('minutes') + 32 >= 60
      ? defaultToDateTime.get('hours') + 1
      : defaultToDateTime.get('hours');

  const dateSelected = instance && instance.startTime && instance.endTime;

  const instanceFromDate = dateSelected
    ? instance.startTime
    : defaultFromDateTime.clone().set({
        hours: 0,
        minutes: 0,
        seconds: 0,
      });
  const instanceFromTime = dateSelected
    ? getTimeFromMoment(instance.startTime)
    : {
        hours: startTimeHours,
        minutes: startTimeMinutes,
        seconds: defaultFromDateTime.get('seconds'),
      };
  const instanceToDate = dateSelected
    ? instance.endTime
    : defaultToDateTime.clone().set({
        hours: 0,
        minutes: 0,
        seconds: 0,
      });
  const instanceToTime = dateSelected
    ? getTimeFromMoment(instance.endTime)
    : {
        hours: endTimeHours,
        minutes: endTimeMinutes,
        seconds: defaultToDateTime.get('seconds'),
      };
  return {
    instanceFromDate,
    instanceFromTime,
    instanceToDate,
    instanceToTime,
  };
};

export { getInstanceRange, getTimeFromMoment };
