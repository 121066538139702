import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import { makeStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import styles from './ColumnSortPanel.module.scss';
import { injectIntl } from 'react-intl';
import { Icon } from 'ui-components/src';

const useStyles = makeStyles(theme => ({
  formLabel: {
    margin: 0,
  },
  label: {
    fontSize: 12,
    marginLeft: 15,
  },
  closeButton: {
    padding: 5,
    color: theme.palette.text.secondary,
  },
  checkbox: {
    color: theme.palette.text.secondary,
    margin: 0,
    padding: 5,
  },
  checked: {},
}));

const ColumnSortPanel = ({
  setDisplayColumns,
  displayColumns,
  unCheckedColumns,
  setUnCheckedColumns,
  setShowColumnReorder,
  defaultColumns,
  handleClose,
  intl,
}) => {
  const classes = useStyles();
  let arrangedItems;
  const reorderRef = useRef(null);
  // useOnClickOutside(reorderRef, () => setShowColumnReorder(false));
  const viewColumns = displayColumns
    .map((col, i) => ({ column: col, index: i }))
    .filter(item => !item.column.key);
  const keyColumns = displayColumns.filter(col => col.key);
  const onSortEnd = ({ oldIndex, newIndex }) => {
    arrangedItems = arrayMove(keyColumns, oldIndex, newIndex);
    viewColumns.forEach(viewCol => arrangedItems.splice(viewCol.index, 0, viewCol.column));
    setDisplayColumns(arrangedItems);
  };

  const DragHandle = sortableHandle(() => <Icon className="more" name="ReOrderIcon" />); // for draggable menu in filter columns

  const SortableItem = sortableElement(({ value }) => {
    return (
      <li className={styles.sortable} tabIndex="1">
        <FormControlLabel
          classes={{
            root: classes.formLabel,
            label: classes.label,
          }}
          control={
            <input
            type="checkbox"
            tabIndex="0"
            className={styles.columnCheck}
              edge="start"
              id={value.key}
              onClick={e => {
                if (!e.target.checked) {
                  if (!unCheckedColumns.includes(value.key)) {
                    const newCheckedCols = [...unCheckedColumns, value.key];
                    setUnCheckedColumns(newCheckedCols);
                  }
                } else {
                  setUnCheckedColumns(unCheckedColumns.filter(key => key !== value.key));
                }
              }}
              checked={!unCheckedColumns.includes(value.key)}
              disabled={value.isFixedColumn}
              // classes={{
              //   root: classes.checkbox,
              //   checked: classes.checked,
              // }}
              color="primary"
            />
          }
          label={value.label}
        />
        {!value.isFixedColumn?<DragHandle  />:null}
      </li>
    );
  });

  const SortableContainer = sortableContainer(({ children }) => (
    <ul className="col-list">{children}</ul>
  ));
  return (
    // <div className="dropdown-menu dropdown-menu-right" ref={reorderRef}>
    <div className={styles.container} ref={reorderRef}>
      <div className={styles.header}>
        <div className={styles.title}>
          {intl.formatMessage({ id: 'ColumnSortPanel.text' })}
          {/* Columns */}
        </div>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={e => {
            if(setShowColumnReorder)
            setShowColumnReorder(false);
            if(handleClose)
            handleClose();
            e.stopPropagation();
          }}
        >
          <CloseIcon />
        </IconButton>
      </div>
      <Divider />
      <form action="" className="form-horizontal">
        <SortableContainer onSortEnd={onSortEnd} useDragHandle lockAxis="y">
          {displayColumns
            .filter(col => col.key && col.label && col.label.trim().length)
            .map((value, index) => (
              <SortableItem
                key={`item-${index}`}
                index={index}
                value={value}
                disableCheckBox={value.isFixedColumn}
              />
            ))}
        </SortableContainer>
        <Button
          className={styles.button}
          onClick={e => {
            e.preventDefault();
            setUnCheckedColumns([]);
            // setDisplayColumns(JSON.parse(JSON.stringify(defaultColumns)));
          }}
        >
          {/* Reset */}
          {intl.formatMessage({ id: 'ColumnSortPanel.ResetButton' })}
        </Button>
      </form>
    </div>
  );
};

ColumnSortPanel.propTypes = {
  setDisplayColumns: PropTypes.func.isRequired,
  setUnCheckedColumns: PropTypes.func.isRequired,
  setShowColumnReorder: PropTypes.func.isRequired,
  defaultColumns: PropTypes.arrayOf(
    PropTypes.shape({
      viewKey: PropTypes.string.isRequired,
      key: PropTypes.string.isRequired,
    })
  ).isRequired,
  displayColumns: PropTypes.arrayOf(
    PropTypes.shape({
      viewKey: PropTypes.string.isRequired,
      key: PropTypes.string.isRequired,
    })
  ).isRequired,
  unCheckedColumns: PropTypes.arrayOf(
    PropTypes.shape({
      viewKey: PropTypes.string.isRequired,
      key: PropTypes.string.isRequired,
    })
  ).isRequired,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default injectIntl(ColumnSortPanel);
