import { takeLatest, call, put } from 'redux-saga/effects';
import { checkEnvironment } from 'ui-common/src/lib/utils';
import {
  FEATURE_LIST,
  featureListSuccess,
  UPDATE_FEATURE,
  UPDATE_THEME_CONFIG_LIST,
  updateThemeConfigListSuccess,
  updateThemeConfigListFailure,
  DOWNLOAD_ATTACHEMENT,
  UPLOAD_AS_FILE,
} from './adminHome-actions';

function* getFeatureList() {
  try {
    const { GATEWAY_API_URL } = checkEnvironment();
    let url = null;
    if (window.DEMO_MODE) {
      url = `http://${window.location.hostname}:3100/api/usermssecurityfeatures`;
    } else {
      url = `${GATEWAY_API_URL}/api/userms/security/features`;
    }
    const response = yield call(fetch, url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (response.status === 200) {
      const json = yield call([response, response.json]);
      yield put(featureListSuccess(json));
    }
  } catch (err) {
    console.log(err);
  }
}

function* updateFeature(action) {
  const { GATEWAY_API_URL } = checkEnvironment();
  let url = null;
  if (window.DEMO_MODE) {
    url = `http://${window.location.hostname}:3100/api/usermssecurityfeatures/${action.payload.id}`;
  } else {
    url = `${GATEWAY_API_URL}/api/userms/security/features/${action.payload.id}`;
  }
  const response = yield call(fetch, url, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(action.payload),
  });
  if (response.status === 200) {
    yield call([response, response.json]);
    yield put({ type: FEATURE_LIST });
  }
}

function* updateThemeConfig(action) {
  const SERVICE_ENDPOINT_IDWMS = checkEnvironment().IDWMS_API_URL;
  try {
    const defaultBody = {
      fileName: 'theme.json',
      folderPath: '/app/usr',
      resourceBody: JSON.stringify(action.configThemeObj),
    };
    const url = `${SERVICE_ENDPOINT_IDWMS}/res/content`;
    const response = yield call(fetch, url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(defaultBody),
    });
    if (response && response.status === 200) {
      const json = yield call([response, response.json]);
      if (json) {
        yield put(updateThemeConfigListSuccess(json));
        action.handleSuccess();
      }
    }
  } catch {
    yield put(updateThemeConfigListFailure());
  }
}
function* downloadAttachment(action) {
  let response = '';
  try {
    const url = action.urlType;
    if (action.method === 'POST') {
      response = yield call(fetch, url, {
        method: action.method,
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(action.data),
      });
    } else {
      response = yield call(fetch, url, {
        method: action.method,
        headers: {
          'Content-Type': 'application/json',
        },
      });
    }
    if (response.status === 200) {
      response.arrayBuffer().then(function(buffer) {
        const responseUrl = window.URL.createObjectURL(new Blob([buffer]));

        action.handleDownloadResponse(responseUrl, action.fileName);
      });
    }
  } catch (err) {
    console.log(err);
  }
}

function* uploadAsFile(action) {
  try {
    const formData = new FormData();
    formData.append('file', action.fileData);
    formData.append('path', action.path);

    const url = action.urlType;
    const response = yield call(fetch, url, {
      method: 'POST',
      headers: {
        Authorization: `${localStorage.getItem('token_type')} ${localStorage.getItem(
          'access_token'
        )}`,
      },
      body: formData,
    });
    if (response.status === 200) {
      action.handleUploadResponse(action.fileData.name, action.fileData);
    } else {
      action.handleFailureResponse(`Failed to upload, response code: ${response.status}`);
    }
  } catch (err) {
    action.handleFailureResponse(err);
    console.log(err);
  }
}

export default function* adminHomeSaga() {
  yield takeLatest(FEATURE_LIST, getFeatureList);
  yield takeLatest(UPDATE_FEATURE, updateFeature);
  yield takeLatest(UPDATE_THEME_CONFIG_LIST, updateThemeConfig);
  yield takeLatest(DOWNLOAD_ATTACHEMENT, downloadAttachment);
  yield takeLatest(UPLOAD_AS_FILE, uploadAsFile);
}
