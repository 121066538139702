import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { Dialog, Button, IconButton } from '@material-ui/core';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(3),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(2),
    color: 'var(--black)',
  },
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography>{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: `0 ${theme.spacing(3)}px !important`,
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(3),
  },
}))(MuiDialogActions);

const ModalPopup = ({ intl, modalOpen, modalHeading, handleClose, modalBody, modalSave }) => {
  const [open, setOpen] = useState();
  useEffect(() => {
    setOpen(modalOpen);
  }, [modalOpen]);

  return (
    <div>
      <Dialog
        aria-labelledby="customized-dialog-title"
        open={open}
        onClose={handleClose}
        PaperProps={{
          square: true,
          style: {
            borderRadius: '0px !important',
            height: '80vh',
            maxHeight: 'none',
            width: '100%',
          },
        }}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          <h5 className="confirm-title mb-0">{modalHeading}</h5>
        </DialogTitle>
        <DialogContent dividers>
          <div className="modalBody d-flex justify-content-center flex-column">{modalBody}</div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleClose();
            }}
            variant="outlined"
            color="primary"
          >
            {intl.formatMessage({ id: 'Cancel' })}
          </Button>
          <Button
            onClick={() => {
              modalSave(modalBody);
            }}
            variant="contained"
            color="primary"
          >
            {intl.formatMessage({ id: 'Save' })}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default injectIntl(ModalPopup);
