import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { MessageModal } from 'ui-components/src';
import { resetError } from 'ui-common/src/utils/ResponseHandler-actions';
import configData from 'ui-common/src/config';

const ResponseMessageHandling = ({ ...props }) => {
  const [messageModal, setMessageModal] = useState(false);

  useEffect(() => {
    if (!!props.responseMsg && props.responseMsg.message !== '') {
      setMessageModal(true);
      setTimeout(() => {
        props.resetError();
      }, configData.errorMessageTimeOut);
    } else {
      setMessageModal(false);
    }
  }, [props.responseMsg.message]);

  const onDismissModal = e => {
    e.preventDefault();
    setMessageModal(false);
  };

  return (
    <div>
      <div className="response-msg">
        {messageModal ? (
          <MessageModal
            onDismiss={onDismissModal}
            mesgToDisplay={props.responseMsg.message}
            encrypted={props.responseMsg.encrypted}
            MesgType={props.responseMsg.status === 'success' ? 'success' : 'failure'}
            data={props.responseMsg.data}
          />
        ) : null}
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  responseMsg: state.message.responseMsg,
});

const mapDispatchToProps = {
  resetError,
};

ResponseMessageHandling.propTypes = {
  resetError: PropTypes.func.isRequired,
  responseMsg: PropTypes.shape({
    status: PropTypes.string,
    message: PropTypes.string,
  }).isRequired,
};

const ConnectedResponseMessageHandling = connect(
  mapStateToProps,
  mapDispatchToProps
)(ResponseMessageHandling);

export default ConnectedResponseMessageHandling;
