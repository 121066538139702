import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Cookies } from 'react-cookie';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
// import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import configData from 'ui-common/src/config';
import { push } from 'react-router-redirect';
import style from './CreateTemplateModal.module.scss';

const cookies = new Cookies();

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2, 3),
    borderBottom: '1px solid var(--grey-border)',
    color: 'var(--black-li)',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    fontSize: '12px',
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1.5, 2.25),
    borderTop: '1px solid var(--grey-border)',
    fontSize: 12,
  },
}))(MuiDialogActions);

const CreateTemplateModal = ({ accountId, endpoint, privilegeId, remoteApp, ...props }) => {
  const [templateName, setTemplateName] = useState();

  const [businessJustification, setBusinessJustification] = useState('');
  const valiDateForm = value => {
    setBusinessJustification(value);
  };

  const createTemplateSubmit = () => {
    const finalActiveSessionsSelected = {
      name: templateName,
      ownerkey: parseInt(cookies.get('userKey'), 10),
      requestedFor: parseInt(cookies.get('userKey'), 10),
      type: 1,
      reqestDetails: {
        endpoint,
        account: accountId,
        startDate: '',
        endDate: '',
        comments: 'hello',
        businessJustification,
        accontName: privilegeId,
        remoteApp,
      },
    };

    props.createTemplateAction({
      finalActiveSessionsSelected,
      typeOfAction: 'createtemplate',
    });

    props.showTemplateModal(false);
  };

  return (
    <Dialog
      onClose={() => props.showTemplateModal(false)}
      aria-labelledby="customized-dialog-title"
      open
      classes={{ paperScrollPaper: style.templateDialog }}
    >
      <DialogTitle
        classes={{ root: style.templateHead }}
        onClose={() => props.showTemplateModal(false)}
        id="customized-dialog-title"
      >
        {props.intl.formatMessage({
          id: 'activeSessions.CreateNewTemplate',
        })}
      </DialogTitle>
      <DialogContent classes={{ root: style.createTemplatelDialog }}>
        <p>
          {props.intl.formatMessage({ id: 'EditTemplate.description' })}{' '}
          <span
            className={style.requestLink}
            target="_blank"
            role="presentation"
            onClick={() => {
              push('/request/privilegedAccess');
            }}
          >
            “Request / Privileged Access”
          </span>{' '}
          {props.intl.formatMessage({ id: 'EditTemplate.section' })}
        </p>
        <div className={style.newTemplate}>
          <div className={style.cardBody}>
            <div className={style.cardLeft}>
              <div className="temp-blk ">
                <span className={style.labelTxt}>
                  {props.intl.formatMessage({
                    id: 'CreateTemplateModal.ResourceID',
                  })}
                </span>
                <span className={style.dataTxt}>{endpoint}</span>
              </div>
              <div className="temp-blk mt-4">
                <span className={style.labelTxt}>
                  {props.intl.formatMessage({
                    id: 'CreateTemplateModal.PrivilegeID',
                  })}
                </span>
                <span className={style.dataTxt}>{privilegeId}</span>
              </div>
            </div>
            <div className={style.cardRight}>
              <form action="">
                <div className="form-group">
                  <div>
                    {props.intl.formatMessage({
                      id: 'activeSessions.TemplateLabel',
                    })}
                    <sup className={style.mandateField}>*</sup>
                  </div>
                  <div className="filter-item">
                    <TextField
                      className="input-element effect-2"
                      placeholder={props.intl.formatMessage({
                        id: 'activeSessions.TemplateNamePlaceHolder',
                      })}
                      type="text"
                      margin="dense"
                      variant="outlined"
                      fullWidth
                      onChange={e => {
                        setTemplateName(e.target.value);
                      }}
                    />

                    <span className="focus-border" />
                  </div>
                </div>{' '}
                <div>
                  <div className="row m-0 popover-justification">
                    {/* <div className="form-group last row m-0"> */}
                    <div>
                      {props.intl.formatMessage({
                        id: 'activeSessions.BusinessJustificationLabel',
                      })}
                      <sup className={style.mandateField}>*</sup>
                    </div>
                    <TextField
                      placeholder={props.intl.formatMessage({
                        id: 'CreateTemplateModal.BusinessJustification.placeholder',
                      })}
                      type="text"
                      margin="dense"
                      variant="outlined"
                      fullWidth
                      multiline
                      inputProps={{ maxLength: 1000 }}
                      rows={7}
                      className={style.sesTerminate}
                      onChange={e => {
                        if (e.target.value.length <= 1000) {
                          valiDateForm(e.target.value);
                        }
                      }}
                      error={
                        businessJustification &&
                        businessJustification.length < configData.commentsMinChars
                      }
                      helperText={
                        businessJustification &&
                        businessJustification.length < configData.commentsMinChars
                          ? 'Minimum length of the character is 3.'
                          : ' '
                      }
                    />
                  </div>

                  <div className="text-right" id="count_message">
                    {' '}
                    {businessJustification.length}
                    /1000
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </DialogContent>
      <DialogActions className={style.templateFooter}>
        <Button
          className={style.closeTemplate}
          autoFocus
          onClick={() => props.showTemplateModal(false)}
        >
          {props.intl.formatMessage({
            id: 'activeSessions.CancelTerminateBtn',
          })}
        </Button>
        <Button
          autoFocus
          variant="outlined"
          color="primary"
          className={style.btnCreate}
          onClick={() => createTemplateSubmit()}
          disabled={!(templateName && businessJustification && businessJustification.length > 2)}
        >
          {props.intl.formatMessage({
            id: 'activeSessions.CreateTemplateBtn',
          })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

CreateTemplateModal.propTypes = {
  intl: PropTypes.arrayOf(
    PropTypes.shape({
      locale: PropTypes.string.isRequired,
      formatMessage: PropTypes.func.isRequired,
    })
  ).isRequired,
  captureSelectedPrivilegedSessionParams: PropTypes.func.isRequired,
  showTemplateModal: PropTypes.func.isRequired,
  endpoint: PropTypes.string.isRequired,
  privilegeId: PropTypes.string.isRequired,
  accountId: PropTypes.number.isRequired,
  createTemplateAction: PropTypes.func.isRequired,
  remoteApp: PropTypes.string.isRequired,
};
export default injectIntl(CreateTemplateModal);
