export const AdminHomeLoaded = 'adminHome/initialLoaded';
export const FEATURE_LIST_SUCCESS = 'adminHome/FEATURE_LIST_SUCCESS';
export const FEATURE_LIST = 'adminHome/FEATURE_LIST';
export const UPDATE_FEATURE = 'adminHome/UPDATE_FEATURE';
export const VIEW_PREVIEW_THEME = 'VIEW_PREVIEW_THEME';
export const SHOWV_PREVIEW_MODAL = 'SHOWV_PREVIEW_MODAL';
export const FETCH_THEME_CONFIG_LIST = 'FETCH_THEME_CONFIG_LIST';
export const FETCH_THEME_CONFIG_LIST_SUCCESS = 'FETCH_THEME_CONFIG_LIST_SUCCESS';
export const FETCH_THEME_CONFIG_LIST_FAILURE = 'FETCH_THEME_CONFIG_LIST_FAILURE';
export const UPDATE_THEME_CONFIG_LIST = 'UPDATE_THEME_CONFIG_LIST';
export const UPDATE_THEME_CONFIG_LIST_SUCCESS = 'UPDATE_THEME_CONFIG_LIST_SUCCESS';
export const UPDATE_THEME_CONFIG_LIST_FAILURE = 'UPDATE_THEME_CONFIG_LIST_FAILURE';
export const DOWNLOAD_ATTACHEMENT = 'adminHome/DOWNLOAD_ATTACHEMENT';
export const UPLOAD_AS_FILE = 'adminHome/UPLOAD_AS_FILE';

export const adminHomeLoaded = adminHomeData => ({
  type: AdminHomeLoaded,
  payload: adminHomeData,
});

export const getFeatureList = () => ({
  type: FEATURE_LIST,
});

export const featureListSuccess = featureList => ({
  type: FEATURE_LIST_SUCCESS,
  payload: featureList,
});

export const updateFeature = feature => ({
  type: UPDATE_FEATURE,
  payload: feature,
});

export const viewPreviewTheme = themeObj => ({
  type: VIEW_PREVIEW_THEME,
  themeObj,
});

export const showPreviewModal = show => ({
  type: SHOWV_PREVIEW_MODAL,
  show,
});

export const fetchThemeConfigList = () => ({
  type: FETCH_THEME_CONFIG_LIST,
});

export const fetchThemeConfigListSuccess = response => ({
  type: FETCH_THEME_CONFIG_LIST_SUCCESS,
  response,
});

export const fetchThemeConfigListFailure = error => ({
  type: FETCH_THEME_CONFIG_LIST_FAILURE,
  error,
});

export const updateThemeConfigList = (configThemeObj, handleSuccess) => ({
  type: UPDATE_THEME_CONFIG_LIST,
  configThemeObj,
  handleSuccess,
});

export const updateThemeConfigListSuccess = response => ({
  type: UPDATE_THEME_CONFIG_LIST_SUCCESS,
  response,
});

export const updateThemeConfigListFailure = error => ({
  type: UPDATE_THEME_CONFIG_LIST_FAILURE,
  error,
});
export const downloadAttachment = (urlType, data, method, fileName, handleDownloadResponse) => ({
  type: DOWNLOAD_ATTACHEMENT,
  urlType,
  data,
  method,
  fileName,
  handleDownloadResponse,
});

export const uploadAsFile = (
  urlType,
  fileData,
  path,
  handleUploadResponse,
  handleFailureResponse
) => ({
  type: UPLOAD_AS_FILE,
  urlType,
  fileData,
  path,
  handleUploadResponse,
  handleFailureResponse,
});
