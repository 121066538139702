import React, { useRef, useEffect } from 'react';
// import { useLocation } from 'react-router-dom';

function useSmartForm(numberOfFields = 1) {
  const defaultList = Array(numberOfFields).fill(true);
  const [disabledList, setDisabledList] = React.useState(defaultList);
  const onChangeHandlers = [];
  for (let index = 0; index < numberOfFields; index++) {
    onChangeHandlers.push(event => {
      const disabled = event.target.value.length === 0;

      setDisabledList(disabledList => {
        disabledList[index] = disabled;
        return disabledList;
      });
    });
  }

  let globalDisabled = false;
  if (disabledList.find(value => value === true)) {
    globalDisabled = true;
  }
  return [globalDisabled, ...onChangeHandlers];
}
function useQuery() {
  return new URLSearchParams(new URL(window.location).search);
}
export { useSmartForm, useQuery };
export default null;
