import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '../Icon';
import { GridSearch } from '../GridSearch';
import { Pagination } from '../Pagination';
import { SaviyntGrid } from './SaviyntGrid';
import { AdvancedFilter } from '../AdvancedFilter';
import { hideBackgroundScroll } from 'ui-common/src/lib/utils';
import styles from './GridBoot.module.scss';
import Drawer from '@material-ui/core/Drawer';

const Grid = ({
  columns,
  data,
  uniqueKey,
  onRowSelected,
  totalCount,
  advancedFilterColumns,
  appliedFilters,
  setAppliedFilters,
  reqBodyOptions,
  setReqBodyOptions,
  globalSearchText,
  setGlobalSearchText,
  topOffset,
  isLoading,
  disableAdvancedFilters,
  hiddenColumns,
  hideRearrangeColumn,
}) => {
  const [showAdvancedFilter, setShowAdvancedFilter] = useState(false);
  useEffect(() => {
    hideBackgroundScroll(showAdvancedFilter);
    return () => {
      hideBackgroundScroll(false);
    };
  }, [showAdvancedFilter]);

  const toggleDrawer = (side, open) => event => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    if (side === 'right' && open) {
      setShowAdvancedFilter(true);
    } else if (side === 'right' && !open) setShowAdvancedFilter(false);
  };

  return (
    <>
      <div className={styles.gridFilter} style={{ top: `${topOffset}px` }}>
        <div className={styles.gridFilterArea}>
          <div className="grid-filterBlk d-flex">
            <div className="grid_search">
              <GridSearch
                globalSearchText={globalSearchText}
                setGlobalSearchText={setGlobalSearchText}
              />
              {!disableAdvancedFilters && (
                <div
                  className="AdFilter-FullScreen"
                  href=""
                  onClick={() => {
                    // toggleDrawer('right', true);
                    setShowAdvancedFilter(true);
                  }}
                  role="presentation"
                >
                  <Icon name="advFilter" />
                </div>
              )}
            </div>
          </div>
          {!!totalCount && (
            <Pagination
              totalRecords={totalCount}
              recordsPerPage={reqBodyOptions.max}
              offset={reqBodyOptions.offset}
              reqBodyOptions={reqBodyOptions}
              setReqBodyOptions={setReqBodyOptions}
            />
          )}
        </div>
      </div>
      <SaviyntGrid
        columns={columns}
        data={data}
        uniqueKey={uniqueKey}
        onRowSelected={onRowSelected}
        setAppliedFilters={setAppliedFilters}
        appliedFilters={appliedFilters}
        reqBodyOptions={reqBodyOptions}
        setReqBodyOptions={setReqBodyOptions}
        globalSearchText={globalSearchText}
        setGlobalSearchText={setGlobalSearchText}
        topOffset={topOffset + 50}
        isLoading={isLoading}
        hiddenColumns={hiddenColumns}
        hideRearrangeColumn={hideRearrangeColumn}
      />

      <Drawer
        anchor="right"
        open={showAdvancedFilter}
        classes={{ modal: styles.advanceFilterModal }}
        onClose={() => setShowAdvancedFilter(false)}
      >
        <AdvancedFilter
          hideAdvanceFilter={() => setShowAdvancedFilter(false)}
          appliedFilters={appliedFilters}
          setAppliedFilters={setAppliedFilters}
          columns={advancedFilterColumns}
          requestBody={reqBodyOptions}
        />
      </Drawer>
    </>
  );
};

Grid.propTypes = {
  topOffset: PropTypes.number.isRequired,
  totalCount: PropTypes.number.isRequired,
  isLoading: PropTypes.bool.isRequired,
  globalSearchText: PropTypes.string.isRequired,
  setReqBodyOptions: PropTypes.func.isRequired,
  setAppliedFilters: PropTypes.func.isRequired,
  setGlobalSearchText: PropTypes.func.isRequired,
  advancedFilterColumns: PropTypes.node.isRequired,
  reqBodyOptions: PropTypes.shape({
    offset: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
  }).isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
    })
  ).isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      viewKey: PropTypes.string.isRequired,
      key: PropTypes.string.isRequired,
    })
  ).isRequired,
  appliedFilters: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
    })
  ).isRequired,
  uniqueKey: PropTypes.string.isRequired,
  onRowSelected: PropTypes.func.isRequired,
  disableAdvancedFilters: PropTypes.bool.isRequired,
};

export { Grid };
