import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { useDebounce } from 'use-debounce';
import { validateRegex } from 'ui-common/src/lib/utils';
import { injectIntl } from 'react-intl';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    alignContent: 'flex-end',
    width: '100%',
  },
  field: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    alignSelf: 'flex-end',
  },
  label: {
    display: 'flex',
    flexWrap: 'wrap',
    alignSelf: 'flex-end',
  },
  helperText: {
    margin: '10px 0 0',
    fontSize: '12px',
    lineHeight: 1.4,
  },
  marginDense: {
    marginBottom: 0,
  },
}));

const TextFieldComponent = ({
  name,
  placeholder,
  required,
  _handleChange,
  attributeKey,
  multiline,
  defaultValue,
  _onFocus,
  showError,
  readOnly,
  rows,
  intl,
  variant,
  value,
  onBlur,
  label,
  setInvalidInput,
}) => {
  const classes = useStyles();
  const [invalidRegex, setInvalidRegex] = useState(false);

  useEffect(() => {
    setInvalidRegex(!validateRegex(null, value, true));
  }, [value]);

  const handleChange = event => {
    if (!validateRegex(null, event.target.value, true)) {
      setInvalidRegex(true);
      if (setInvalidInput)
        setInvalidInput(true);
    }
    else {
      setInvalidRegex(false);
      if (setInvalidInput)
        setInvalidInput(false);
    }
    if (_handleChange)
      _handleChange(event.target.value, attributeKey);
  };

  return (
    <div className={classes.root}>
      <div className={classes.field}>
        <TextField
          label={label}
          rows={rows}
          variant={variant ? variant : 'standard'}
          disabled={readOnly}
          className={classes.marginDense}
          placeholder={placeholder}
          id={name}
          error={invalidRegex ? true : showError ? showError : false}
          type="text"
          name={name}
          defaultValue={defaultValue}
          value={value}
          margin="dense"
          multiline={multiline}
          helperText={invalidRegex ? intl.formatMessage({
            id: 'SecurityValidation.InvalidInput',
          }) : ''}
          fullWidth
          onChange={handleChange}
          onFocus={() => (_onFocus ? _onFocus(attributeKey) : {})}
          onBlur={onBlur}
        />
      </div>
    </div>
  );
};
TextFieldComponent.defaultProps = {
  setInvalidInput: null,
  rows: "1",
};
TextFieldComponent.propTypes = {
  _handleChange: PropTypes.func.isRequired,
  _onFocus: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  required: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  attributeKey: PropTypes.string.isRequired,
  multiline: PropTypes.bool.isRequired,
  defaultValue: PropTypes.string.isRequired,
  showError: PropTypes.bool.isRequired,
  readOnly: PropTypes.bool.isRequired,
  rows: PropTypes.string,
  setInvalidInput: PropTypes.func,
};

export default injectIntl(TextFieldComponent);
