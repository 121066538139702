/* eslint func-names: ["error", "never"] */

import React, { useState, useEffect } from 'react';

import { withRouter } from 'react-router-dom';
import * as qs from 'query-string';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Cookies } from 'react-cookie';
import Loading from '../misc/Loading/Loading';
import { useQuery } from '../misc/hooks';
import {
  userModulesAction,
  userDetailsAction,
  loginInfoAction,
  getHeaderDetails,
  appMessages,
} from 'ui-common/src/utils/LoginActions';
import { checkEnvironment } from 'ui-common/src/lib/utils';
import { fetchThemeConfigList } from '../features/AdminHome/redux/adminHome-actions';

const cookies = new Cookies();

const AuthRedirect = props => {
  const parsed = qs.parse(props.location.search);
  const [loading, setLoading] = useState(false);
  const allowedRoutes = useSelector(state => state.user.userModules);
  const domainInfo = useSelector(state => state.user.domainInfo);
  const dispatch = useDispatch();
  const query = useQuery();

  useEffect(() => {
    if (allowedRoutes.length > 0) props.history.push(parsed.redirect);
  }, [allowedRoutes]);
  useEffect(() => {
    const { GATEWAY_API_URL, ECM_URL } = domainInfo || {};
    if (!GATEWAY_API_URL) return;
    if (cookies.get('access_token') === undefined) {
      setLoading(true);

      fetch(`${GATEWAY_API_URL}/api/auth/token`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${parsed.jwt}`,
          // TENANT_ID: 'TEST',
        },
      })
        .then(response => {
          if (response.status === 200) return response.json();
          if (response.status === 419) {
            window.location.href = `${ECM_URL}/maintenance/passwordReset`;
            return null;
          }
        })
        .then(function(json) {
          cookies.set('token_type', json.tokenType, { path: '/' });
          cookies.set('access_token', json.accessToken, { path: '/' });
          cookies.set('user_name', json.username, { path: '/' });

          fetch(`${GATEWAY_API_URL}/api/auth/refresh-token`, {
            method: 'POST',
            headers: {
              Authorization: `${cookies.get('token_type')} ${cookies.get('access_token')}`,
              // TENANT_ID: 'TEST',
            },
          })
            .then(response => {
              if (response.status === 200) return response.json();
            })
            .then(function(refreshTokenResponse) {
              cookies.set('refresh_token', refreshTokenResponse.refreshToken, { path: '/' });
              dispatch(userDetailsAction());
              dispatch(getHeaderDetails(domainInfo.GATEWAY_API_URL));
              dispatch(userModulesAction());
              dispatch(
                appMessages(
                  query.get('locale') || localStorage.getItem('locale') || 'en',
                  domainInfo.GATEWAY_API_URL
                )
              );
              dispatch(fetchThemeConfigList());
              // const formdata = new FormData();
              // formdata.append('username', cookies.get('user_name'));

              // fetch(`${GATEWAY_API_URL}/api/user/details`, {
              //   method: 'POST',
              //   headers: {
              //     Authorization: `${cookies.get('token_type')} ${cookies.get('access_token')}`,
              //     // TENANT_ID: 'TEST',
              //   },
              //   body: formdata,
              // })
              //   .then(response => response.json())
              //   .then(function(userjson) {
              //     cookies.set('first_name', userjson.firstname, {
              //       path: '/',
              //     });
              //     cookies.set('userKey', userjson.id, { path: '/' });
              //     // TODO : check if usermodules is already loaded.else load usermodules and setloading to false.
              //     if (allowedRoutes.length === 0 && domainInfo) {
              //       setLoading(true);
              //       dispatch(userModulesAction());
              //     } else {
              //       props.history.push(parsed.redirect);
              //       setLoading(false);
              //     }
              //   })
              //   .catch(() => {
              //     props.history.push('/somethingwentwrong');
              //   });
            })
            .catch(() => {
              console.log('refreshTokenResponse Failure');
            });
        })
        .catch(() => {
          console.log('access token problem');
        });
    } else {
      const parsed = qs.parse(props.location.search);
      props.history.push(parsed.redirect);
    }
  }, [domainInfo]);

  return <div>{loading ? <Loading /> : ''}</div>;
};

AuthRedirect.propTypes = {
  location: PropTypes.string.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default withRouter(AuthRedirect);
