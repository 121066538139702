import React from 'react';
import dualball from './dualball68px.gif';
const AppLoading = () => {
  return (
    <div style={{ height: '100vh', width: '100vw', height: '100vh' }}>
      <img
        style={{
          margin: '0 auto',
          display: 'block',
          top: '45%',
          left: '47%',
          position: 'absolute',
        }}
        src={dualball}
      />
    </div>
  );
};
export default AppLoading;
