import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@material-ui/core';
import { injectIntl } from 'react-intl';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import styles from './EntitlementPrivileges.module.scss';

const EntitlementPrivileges = ({ privileges, intl }) => {
  const [showPrivileges, setShowPrivileges] = useState(false);
  return (
    <div>
      {privileges && privileges.length > 0 && (
        <div className={styles.privilegeLayout}>
          <div className={styles.privilegeTitle}>
            {' '}
            {intl.formatMessage({ id: 'Request.Privilege.title' })}
          </div>
          <div className={styles.listpriLayout}>
            <div className={`col-4 ${styles.entLayout}`}>
              <div className={styles.entlistlabel}>
                {privileges[0].attributeLabel
                  ? privileges[0].attributeLabel.toLowerCase()
                  : privileges[0].attributeName
                    ? privileges[0].attributeName.toLowerCase()
                    : ''}
              </div>
              <div className={styles.entlistVal}>{privileges[0].attributeValue}</div>
            </div>
            {privileges.length > 1 && (
              <div className={`col-4 ${styles.entLayout}`}>
                <div className={styles.entlistlabel}>
                  {privileges[1].attributeLabel
                    ? privileges[1].attributeLabel.toLowerCase()
                    : privileges[1].attributeName
                      ? privileges[1].attributeName.toLowerCase()
                      : ''}
                </div>
                <div className={styles.entlistVal}>{privileges[1].attributeValue}</div>
              </div>
            )}
            {privileges.length > 2 && (
              <div className={`col-4 ${styles.entLayout}`}>
                <div className={styles.entlistlabel}>
                  {privileges[2].attributeLabel
                    ? privileges[2].attributeLabel.toLowerCase()
                    : privileges[2].attributeName
                      ? privileges[2].attributeName.toLowerCase()
                      : ''}
                </div>
                <div className={styles.entlistVal}>{privileges[2].attributeValue}</div>
              </div>
            )}
          </div>
          {showPrivileges && (
            <div className={styles.listpriLayout}>
              {privileges.map((privilege, index) => {
                if (index === 0 || index === 1 || index === 2) return null;
                return (
                  <div className={`col-4 ${styles.entLayout}`}>
                    <div className={styles.entlistlabel}>
                      {privilege.attributeLabel
                        ? privilege.attributeLabel.toLowerCase()
                        : privilege.attributeName
                          ? privilege.attributeName.toLowerCase()
                          : ''}
                    </div>
                    <div className={styles.entlistVal}>
                      {privilege.attributeValue ? privilege.attributeValue : ''}
                    </div>
                  </div>
                );
              })}
            </div>
          )}
          {privileges.length > 3 && (
            <div
              onClick={e => {
                e.stopPropagation();
                setShowPrivileges(!showPrivileges);
              }}
              className={styles.viewmoreLayout}
            >
              <div className={styles.viewmoreTexts}>
                {showPrivileges
                  ? intl.formatMessage({ id: 'Request.Privilege.viewless' })
                  : intl.formatMessage({ id: 'Request.Privilege.viewmore' })}
              </div>

              <IconButton
                color="primary"
                className={`${styles.viewmore} ${showPrivileges ? styles.viewless : ''}`}
              >
                <ExpandMoreIcon />
              </IconButton>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
EntitlementPrivileges.propTypes = {
  privileges: PropTypes.arrayOf(
    PropTypes.shape({
      attributName: PropTypes.string,
      attributLabel: PropTypes.string,
      attributValue: PropTypes.string,
    })
  ).isRequired,
  intl: PropTypes.arrayOf(
    PropTypes.shape({
      locale: PropTypes.string.isRequired,
      formatMessage: PropTypes.func.isRequired,
    })
  ).isRequired,
};
export default injectIntl(EntitlementPrivileges);
