import { combineReducers } from 'redux';
import { loadUIConfigFromStorage } from 'ui-common/src/lib/utils';
import { logoutActionType } from 'ui-common/src/utils/LoginActions';
import { defaultTheme } from '../views/UIBrandConfiguration/ConfigurationTypes/defaultTheme';

import {
  AdminHomeLoaded,
  FEATURE_LIST_SUCCESS,
  VIEW_PREVIEW_THEME,
  SHOWV_PREVIEW_MODAL,
  FETCH_THEME_CONFIG_LIST_SUCCESS,
  FETCH_THEME_CONFIG_LIST_FAILURE,
} from './adminHome-actions';

export const adminHomeReducer = (adminHomeState = {}, action) => {
  switch (action.type) {
    case AdminHomeLoaded: {
      return { ...adminHomeState, loadedMsg: action.payload.message };
    }

    default: {
      return adminHomeState;
    }
  }
};

const featureListReducer = (featureListState = [], action) => {
  switch (action.type) {
    case FEATURE_LIST_SUCCESS: {
      return action.payload;
    }
    default: {
      return featureListState;
    }
  }
};

const checkforThemeinLocalStorage = () => {
  if (
    JSON.parse(localStorage.getItem('uiConfigTheme')) &&
    JSON.parse(localStorage.getItem('uiConfigTheme')).previewTheme
  ) {
    return JSON.parse(localStorage.getItem('uiConfigTheme')).previewTheme;
  }
  return {};
};
const initialUIConfigtate = {
  previewTheme: checkforThemeinLocalStorage(),
  showPreviewTheme: false,
  defaultThemeConfig: {},
};

const UIConfigReducer = (state = initialUIConfigtate, action) => {
  switch (action.type) {
    case VIEW_PREVIEW_THEME: {
      const serializedUIConfigState = JSON.stringify({
        previewTheme: action.themeObj,
      });

      const uiConfigThemeModal = JSON.stringify({
        showPreviewTheme: true,
      });

      localStorage.setItem('uiConfigTheme', serializedUIConfigState);
      localStorage.setItem('uiConfigThemeModal', uiConfigThemeModal);

      return {
        ...state,
        previewTheme: action.themeObj,
        showPreviewTheme: true,
      };
    }

    case SHOWV_PREVIEW_MODAL: {
      return {
        ...state,
        showPreviewTheme: action.show,
      };
    }
    case FETCH_THEME_CONFIG_LIST_SUCCESS: {
      const updateTheme = Object.assign({}, action.response);
      return {
        ...state,
        defaultThemeConfig: updateTheme,
      };
    }
    case FETCH_THEME_CONFIG_LIST_FAILURE: {
      return {
        ...state,
        defaultThemeConfig: defaultTheme,
      };
    }
    case logoutActionType: {
      return {
        ...state,
        previewTheme: {},
        showPreviewTheme: false,
        defaultThemeConfig: {},
      };
    }
    // case '@@Internal/ModuleManager/ModuleAdded': {
    //   if (action.payload === 'adminHome') {
    //     const storedAppReqInitialState = loadUIConfigFromStorage('uiConfig')
    //       ? loadUIConfigFromStorage('uiConfig').uiConfig
    //       : undefined;
    //     const initialState = storedAppReqInitialState || initialUIConfigtate;
    //     return initialState;
    //   }
    //   return initialUIConfigtate;
    // }
    default: {
      return initialUIConfigtate;
    }
  }
};

const rootReducer = combineReducers({
  initialLoad: adminHomeReducer,
  featureList: featureListReducer,
  uiConfig: UIConfigReducer,
});
export default rootReducer;
