import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Chip from '@material-ui/core/Chip';
import { useDebounce } from 'use-debounce';
import './FormComponents.scss';
import { validateRegex, useIsMount } from 'ui-common/src/lib/utils';
import { injectIntl } from 'react-intl';

// const nameRegex = /^[a-zA-Z]+$/;
// const nameRegex = ([A-Za-z]){4,6}([0-9]){1,3};

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    alignContent: 'flex-end',
  },
  field: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    alignSelf: 'flex-end',
  },
  label: {
    display: 'flex',
    flexWrap: 'wrap',
    alignSelf: 'flex-end',
  },
  helperText: {
    margin: '10px 0 0',
    fontSize: '12px',
    lineHeight: 1.4,
  },
  marginDense: {
    marginBottom: 0,
  },
}));

const InputTextField = ({
  name,
  placeholder,
  hidePlaceholder,
  required,
  _handleChange,
  attributeKey,
  multiLine,
  defaultValue,
  _onFocus,
  showError,
  readOnly,
  regRex,
  intl,
  scrollToError,
  errorRequired,
  supportsHtmlTags,
  rootProps,
  inputProps,
  InputProps,
  InputLabelProps,
}) => {
  const classes = useStyles();
  const [value, setValue] = useState(defaultValue);
  const [invalidRegex, setInvalidRegex] = useState(false);
  const inputTextFieldRef = useRef(null);
  //const [scrollError, setScrollError] = useState(false);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);
  useEffect(() => {
    if (scrollToError === 'true') {
      inputTextFieldRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [scrollToError]);

  const [debouncedText] = useDebounce(value, 500);
  const isMount = useIsMount();
  useEffect(() => {
    // if (!isMount && debouncedText) {
    if (debouncedText) {
      if (!isMount) _handleChange(value, attributeKey);
    } else {
      setValue('');
    }
    return () => {};
  }, [debouncedText]);

  // useEffect(() => {
  //   if (showError)
  //     inputTextFieldRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  // }, [showError]);
  // const [val, setValidation] = useState(required);

  const handleChange = event => {
    if (!validateRegex(regRex, event.target.value, true)) setInvalidRegex(true);
    else setInvalidRegex(false);

    setValue(event.target.value);
    if (!event.target.value) _handleChange('', attributeKey);
  };

  return (
    <div className={classes.root} ref={inputTextFieldRef}>
      <div className={classes.field}>
        {supportsHtmlTags && (
          <div className="support-html">
            <span dangerouslySetInnerHTML={{ __html: placeholder }} />
            {required && <span className="mandatory">&nbsp;*</span>}
          </div>
        )}
        <TextField
          disabled={readOnly}
          className={classes.marginDense}
          autoComplete="off"
          placeholder={supportsHtmlTags || hidePlaceholder ? '' : placeholder}
          id={name}
          error={
            required || errorRequired
              ? showError
                ? showError
                : invalidRegex
              : invalidRegex
              ? true
              : false
          }
          type="text"
          name={name}
          value={value}
          margin="dense"
          multiline={multiLine}
          helperText={
            invalidRegex
              ? `${
                  regRex
                    ? `${intl.formatMessage({
                        id: 'DynamicAttribute.RegexValidationError',
                      })} '${regRex}'`
                    : `${intl.formatMessage({
                        id: 'DynamicAttribute.HtmlValidationError',
                      })}`
                }`
              : ''
          }
          fullWidth
          onChange={handleChange}
          onFocus={() => (_onFocus ? _onFocus(attributeKey) : {})}
          label={
            supportsHtmlTags ? (
              ''
            ) : (
              <>
                <span dangerouslySetInnerHTML={{ __html: placeholder }} />
                {required && <span className="mandatory">&nbsp;*</span>}
              </>
            )
          }
          {...rootProps}
          inputProps={inputProps}
          InputLabelProps={InputLabelProps}
          InputProps={InputProps}
        />
      </div>
    </div>
  );
};
InputTextField.defaultProps = {
  errorRequired: false,
  supportsHtmlTags: false,
};

InputTextField.propTypes = {
  _handleChange: PropTypes.func.isRequired,
  _onFocus: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  required: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  attributeKey: PropTypes.string.isRequired,
  multiLine: PropTypes.bool.isRequired,
  defaultValue: PropTypes.string.isRequired,
  showError: PropTypes.bool.isRequired,
  readOnly: PropTypes.bool.isRequired,
  errorRequired: PropTypes.bool,
  supportsHtmlTags: PropTypes.bool,
};

export default injectIntl(InputTextField);
