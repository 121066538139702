import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/extensions
import { A } from '../A';
import { injectIntl } from 'react-intl';
import styles from './Pagination.module.scss';
import { PlainDropDown } from '../PlainDropDown';

const Pagination = ({
  offset,
  recordsPerPage,
  totalRecords,
  reqBodyOptions,
  setReqBodyOptions,
  intl,
  countLoading,
  setReqBodyOptionsPaginaton,
}) => {
  const recordsPerPageNumbObj = recordsPerPage ? new Number(recordsPerPage) : new Number(25);
  const [numberOfRecordsPerPage, setNumberOfRecordsPerPage] = useState(recordsPerPageNumbObj);
  const recordsFromPage = [25, 50, 100];
  useEffect(() => {
    //on intial usestate, dont allow setreqbodyoptions to run.
    if (numberOfRecordsPerPage === recordsPerPageNumbObj) return;
    const reqBody = {
      ...reqBodyOptions,
      max: numberOfRecordsPerPage,
      offset,
    };
    setReqBodyOptions(reqBody);
  }, [numberOfRecordsPerPage]);
  const fetchNextPage = e => {
    e.preventDefault();
    if (offset + numberOfRecordsPerPage >= totalRecords) {
      return;
    }
    const reqBody = {
      ...reqBodyOptions,
      max: numberOfRecordsPerPage,
      offset: offset + numberOfRecordsPerPage,
    };
    if (setReqBodyOptionsPaginaton)
      setReqBodyOptionsPaginaton(reqBody);
    setReqBodyOptions(reqBody);
  };
  const fetchPreviousPage = e => {
    e.preventDefault();
    if (offset === 0) {
      return;
    }
    const reqBody = {
      ...reqBodyOptions,
      max: numberOfRecordsPerPage,
      offset: offset - numberOfRecordsPerPage >= 0 ? offset - numberOfRecordsPerPage : 0,
    };
    if (setReqBodyOptionsPaginaton)
      setReqBodyOptionsPaginaton(reqBody);
    setReqBodyOptions(reqBody);
  };
  return (
    <div className={styles.paginationComponent}>
      <span>{intl.formatMessage({ id: 'pa.pagination.recordspage' })}</span>

      <PlainDropDown
        options={recordsFromPage}
        selectedValue={parseInt(numberOfRecordsPerPage)}
        changeValue={(val) => {
          setNumberOfRecordsPerPage(val);

        }}
      />
      {!countLoading && <span>
        {offset + 1} -{' '}
        {offset + numberOfRecordsPerPage > totalRecords
          ? totalRecords
          : offset + parseInt(numberOfRecordsPerPage)}{' '}
        of {totalRecords}
      </span>}
      {countLoading &&
        <span className={`d-flex justify-center ${styles.pagiLoader}`}>
          <img src="/ECMv6/assets/images/Double Ring-1s-80px.gif" /></span>}
      {!countLoading && <div className="prev-next-buttons">
        <A
          href="#"
          className={`${offset === 0 ? styles.disabled : ''} ${styles.goPrevious}`}
          onClick={fetchPreviousPage}
          className="mr-3 ml-3"
          tabIndex="1"
        >
          {'<'}
        </A>
        <A
          href="#"
          tabIndex="1"
          className={`${offset + numberOfRecordsPerPage >= totalRecords ? styles.disabled : ''} ${styles.goNext
            }`}
          onClick={fetchNextPage}
        >
          {'>'}
        </A>
      </div>}
    </div>
  );
};

Pagination.propTypes = {
  offset: PropTypes.number.isRequired,
  recordsPerPage: PropTypes.number.isRequired,
  totalRecords: PropTypes.number.isRequired,
  setReqBodyOptions: PropTypes.func.isRequired,
  intl: PropTypes.arrayOf(
    PropTypes.shape({
      locale: PropTypes.string.isRequired,
      formatMessage: PropTypes.func.isRequired,
    })
  ).isRequired,
  reqBodyOptions: PropTypes.shape({
    offset: PropTypes.number.isRequired,
  }).isRequired,
};

export default injectIntl(Pagination);
