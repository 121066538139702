import React from 'react';
import { injectIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import IconButton from '@material-ui/core/IconButton';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';

const PopoverDialog = ({ children, intl,tabIndex }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const useStyles = makeStyles(theme => ({
    iconButton: {
      padding: 0,
      '&:hover, &:focus, &:active': {
        backgroundColor: 'transparent',
      },
    },
    icon: {
      fontSize: 22,
      color: anchorEl ? theme.palette.primary.main : '#a5abb4',
      '&:hover, &:focus': {
        color: theme.palette.primary.main,
        cursor: 'pointer',
      },
    },
  }));
  const classes = useStyles();

  const handleClick = e => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        role="button"
        tabIndex={tabIndex}
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        aria-label={`${intl.formatMessage({ id: 'PopoverDialog.IconButton.arialLabel' })}`}
        variant="contained"
        onClick={handleClick}
        className={classes.iconButton}
      >
        <Tooltip enterDelay="300" title={intl.formatMessage({ id: 'PopoverDialog.IconButton.ManageColumns' })} >
          <ControlPointIcon className={classes.icon} name="rearrangeColumns" />
        </Tooltip>
      </IconButton>

      <Popover
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        modalProps={{
          'aria-hidden': 'false',
        }}
      >
        {React.cloneElement(children, {
          handleClose,
        })}
      </Popover>
    </div>
  );
};

PopoverDialog.propTypes = {
  children: PropTypes.element.isRequired,
  intl: PropTypes.arrayOf(
    PropTypes.shape({
      locale: PropTypes.string.isRequired,
      formatMessage: PropTypes.func.isRequired,
    })
  ).isRequired,
};

export default injectIntl(PopoverDialog);
