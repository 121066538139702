import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import './FormComponents.scss';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  label: {
    fontFamily: 'Rubik',
    fontSize: '12px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#999999',
  },
  checkBoxStyle: {
    color: '#999999',
  },
});
const CatalogCheckboxField = ({
  placeholder,
  options,
  defaultValue,
  required,
  showError,
  readOnly,
}) => {
  const [newOption, setOptions] = React.useState([]);
  const classes = useStyles();

  useEffect(() => {
    const newObj = [];
    const newOptions = Object.assign([], options);

    newOptions.map(key => {
      newObj.push({ name: key, checked: false });
      return true;
    });

    if (defaultValue) {
      defaultValue.forEach((val, i) => {
        newObj[i].checked = val === true || val === 'true';
      });
      // newObj.map(option => {
      //   defaultValue.map(value => {
      //     const optionValue = option;
      //     if (optionValue.name === value) {
      //       optionValue.checked = true;
      //     }
      //     return true;
      //   });
      //   return false;
      // });
    }

    setOptions(newObj);
  }, [options]);

  const handleChange = (event, name) => {
    const optionsSelected = newOption.map(item => {
      const obj = Object.assign({}, item);
      if (obj.name === name) obj.checked = !obj.checked;
      return obj;
    });
    setOptions(optionsSelected);
  };

  return (
    <div>
      {/* <span className="form-label-material">{placeholder}</span> */}
      <span>{required && <span className="mandatory">&nbsp;*</span>}</span>
      <div>
        {newOption.map(item => {
          return (
            <FormControlLabel
              classes={{
                label: classes.label,
              }}
              disabled={readOnly}
              control={
                <Checkbox
                  onChange={e => {
                    handleChange(e, item.name);
                  }}
                  value={item.name}
                  checked={item.checked}
                  color="primary"
                  className={classes.checkBoxStyle}
                  error={required ? showError : false}
                />
              }
              label={placeholder}
            />
          );
        })}
      </div>
      <span className="error-message">
        {required || showError ? 'Please select an option' : ''}
      </span>
    </div>
  );
};

CatalogCheckboxField.propTypes = {
  placeholder: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  defaultValue: PropTypes.arrayOf(PropTypes.string).isRequired,
  showError: PropTypes.bool.isRequired,
  readOnly: PropTypes.bool.isRequired,
  required: PropTypes.bool.isRequired,
};

export default CatalogCheckboxField;
