import React, { useRef, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import './SOD.scss';
import { Icon } from '../Icon';


const SODItem = ({
  ele,
  index,
  sodData,
  setSelectedRowIndex,
  setcurrDisplayData,
  selectedRowIndex,
  showError,
  showMitigated,
  intl,
}) => {
  const soditem = useRef(null);
  useEffect(() => {
    if (showError && showError.showError && showError.scroll && showError.index === index) {
      soditem.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [showError]);

  return (
    <div
      className={
        (index === selectedRowIndex)
          ? `sod-multiple-content-header select`
          : `sod-multiple-content-header`
      }
      role="menuitem"
      tabIndex="0"
      onKeyPress={() => {
        setSelectedRowIndex(index);
        setcurrDisplayData(sodData[index]);
      }}
      onClick={() => {
        setSelectedRowIndex(index);
        setcurrDisplayData(sodData[index]);
        //soditem.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }}
      ref={soditem}
    >
      <div className={`risktype ${ele.riskPriority ? ele.riskPriority : 'High'}`}>
        {ele.riskPriority ? ele.riskPriority : 'High'}
      </div>
      <div className="sod-namesection">
        <div className="name">{`${ele.ruleSetName ? `${ele.ruleSetName} - ` : ''}${ele.Risk}`}</div>
        <div className="subname">{ele.Description}</div>
      </div>
      {showMitigated && <div className="mitigated d-flex">
        <Icon className="mitigatedicon" name="accept" selected height={"13px"} tooltip={''} />
        <div className="mitigated-text">{intl.formatMessage({
          id: 'SOD.PreMitigated.MitigatedLabel',
        })}</div>
      </div>}
    </div>
  );
};
SODItem.propTypes = {
  intl: PropTypes.arrayOf(
    PropTypes.shape({
      locale: PropTypes.string.isRequired,
      formatMessage: PropTypes.func.isRequired,
    })
  ).isRequired,
};

export default injectIntl(SODItem);
