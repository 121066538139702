const mockDataRoleSummary = {
  syscritical: 'None',
  privileged: 'None',
  updatedate: '2017-08-14T16:24:01.000+0000',
  rolename: 'ADFS-FFRole',
  confidentiality: 'None',
  rolekey: '23',
  soxcritical: 'None',
  roletype: 'None',
  risk: 'None',
  status: 1,
  updateuser: 1,
};

const mockRequestDetailsData = {
  data: [
    {
      entitlementvalue: [
        {
          entitlementvalue: 'ADFS-FFRole',
          entitlementtype: 'AWSRole',
          systemname: 'AWS-661222050851-Gryffindor',
          description: null,
        },
        {
          entitlementvalue: 'ADFS-FFRole',
          entitlementtype: 'AWSRole',
          systemname: 'AWS-661222050851-Gryffindor',
          description: null,
        },
        {
          entitlementvalue: 'ADFS-FFRole',
          entitlementtype: 'AWSRole',
          systemname: 'AWS-661222050851-Gryffindor',
          description: null,
        },
        {
          entitlementvalue: 'ADFS-FFRole',
          entitlementtype: 'AWSRole',
          systemname: 'AWS-661222050851-Gryffindor',
          description: null,
        },
        {
          entitlementvalue: 'ADFS-FFRole',
          entitlementtype: 'AWSRole',
          systemname: 'AWS-661222050851-Gryffindor',
          description: null,
        },
      ],
    },
  ],
};

export { mockDataRoleSummary, mockRequestDetailsData };
