import React, { useState, useRef, useEffect } from 'react';
import useOnClickOutside from 'use-onclickoutside';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import styles from './SlimSelectStandard.module.scss';

const timeOptions = [
  { display: '00:00', value: { hours: 0, minutes: 0, seconds: 0 } },
  { display: '00:30', value: { hours: 0, minutes: 30, seconds: 0 } },
  { display: '01:00', value: { hours: 1, minutes: 0, seconds: 0 } },
  { display: '01:30', value: { hours: 1, minutes: 30, seconds: 0 } },
  { display: '02:00', value: { hours: 2, minutes: 0, seconds: 0 } },
  { display: '02:30', value: { hours: 2, minutes: 30, seconds: 0 } },
  { display: '03:00', value: { hours: 3, minutes: 0, seconds: 0 } },
  { display: '03:30', value: { hours: 3, minutes: 30, seconds: 0 } },
  { display: '04:00', value: { hours: 4, minutes: 0, seconds: 0 } },
  { display: '04:30', value: { hours: 4, minutes: 30, seconds: 0 } },
  { display: '05:00', value: { hours: 5, minutes: 0, seconds: 0 } },
  { display: '05:30', value: { hours: 5, minutes: 30, seconds: 0 } },
  { display: '06:00', value: { hours: 6, minutes: 0, seconds: 0 } },
  { display: '06:30', value: { hours: 6, minutes: 30, seconds: 0 } },
  { display: '07:00', value: { hours: 7, minutes: 0, seconds: 0 } },
  { display: '07:30', value: { hours: 7, minutes: 30, seconds: 0 } },
  { display: '08:00', value: { hours: 8, minutes: 0, seconds: 0 } },
  { display: '08:30', value: { hours: 8, minutes: 30, seconds: 0 } },
  { display: '09:00', value: { hours: 9, minutes: 0, seconds: 0 } },
  { display: '09:30', value: { hours: 9, minutes: 30, seconds: 0 } },
  { display: '10:00', value: { hours: 10, minutes: 0, seconds: 0 } },
  { display: '10:30', value: { hours: 10, minutes: 30, seconds: 0 } },
  { display: '11:00', value: { hours: 11, minutes: 0, seconds: 0 } },
  { display: '11:30', value: { hours: 11, minutes: 30, seconds: 0 } },
  { display: '12:00', value: { hours: 12, minutes: 0, seconds: 0 } },
  { display: '12:30', value: { hours: 12, minutes: 30, seconds: 0 } },
  { display: '13:00', value: { hours: 13, minutes: 0, seconds: 0 } },
  { display: '13:30', value: { hours: 13, minutes: 30, seconds: 0 } },
  { display: '14:00', value: { hours: 14, minutes: 0, seconds: 0 } },
  { display: '14:30', value: { hours: 14, minutes: 30, seconds: 0 } },
  { display: '15:00', value: { hours: 15, minutes: 0, seconds: 0 } },
  { display: '15:30', value: { hours: 15, minutes: 30, seconds: 0 } },
  { display: '16:00', value: { hours: 16, minutes: 0, seconds: 0 } },
  { display: '16:30', value: { hours: 16, minutes: 30, seconds: 0 } },
  { display: '17:00', value: { hours: 17, minutes: 0, seconds: 0 } },
  { display: '17:30', value: { hours: 17, minutes: 30, seconds: 0 } },
  { display: '18:00', value: { hours: 18, minutes: 0, seconds: 0 } },
  { display: '18:30', value: { hours: 18, minutes: 30, seconds: 0 } },
  { display: '19:00', value: { hours: 19, minutes: 0, seconds: 0 } },
  { display: '19:30', value: { hours: 19, minutes: 30, seconds: 0 } },
  { display: '20:00', value: { hours: 20, minutes: 0, seconds: 0 } },
  { display: '20:30', value: { hours: 20, minutes: 30, seconds: 0 } },
  { display: '21:00', value: { hours: 21, minutes: 0, seconds: 0 } },
  { display: '21:30', value: { hours: 21, minutes: 30, seconds: 0 } },
  { display: '22:00', value: { hours: 22, minutes: 0, seconds: 0 } },
  { display: '22:30', value: { hours: 22, minutes: 30, seconds: 0 } },
  { display: '23:00', value: { hours: 23, minutes: 0, seconds: 0 } },
  { display: '23:30', value: { hours: 23, minutes: 30, seconds: 0 } },
];

const validateTimeInput = timeStr => {
  const regex = /^\d{0,2}:\d{0,2}/;
  return regex.test(timeStr);
};

const SlimSelect = ({
  defaultValue,
  onChange,
  disabled,
  visibleTimeSlot,
  reduceHeight,
  attributeKey,
  intl,
}) => {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState('');
  const [inputValue, setInputValue] = useState('');
  const dropDownRef = useRef(null);

  useEffect(() => {
    if (defaultValue) {
      const DateInstance = new Date(defaultValue);
      const temp = {
        display: `${
          defaultValue.getHours() < 10 ? `0${defaultValue.getHours()}` : defaultValue.getHours()
        }:${
          defaultValue.getMinutes() < 10
            ? `0${defaultValue.getMinutes()}`
            : defaultValue.getMinutes()
        }`,
        value: {
          hours: DateInstance.getHours(),
          minutes: DateInstance.getMinutes(),
          seconds: DateInstance.getMilliseconds(),
        },
      };
      setValue(temp);
      setInputValue(temp.display);
    } else {
      setValue('');
      setInputValue('');
    }
  }, [defaultValue]);

  useOnClickOutside(dropDownRef, () => {
    setOpen(false);
  });

  useEffect(() => {
    setInputValue(value.display);
  }, [value]);

  useEffect(() => {
    if (!open) {
      setInputValue(value.display ? value.display : '');
    }
  }, [open]);

  const onManualChange = e => {
    if (validateTimeInput(e.target.value)) {
      setInputValue(e.target.value);
    }
  };

  return (
    <div className={styles.container} ref={dropDownRef}>
      {!open && (
        <div onClick={() => setOpen(true)} className={styles.displayValue} role="presentation">
          {value.display
            ? value.display
            : intl.formatMessage({ id: 'CredentialsPopup.button.select' })}
        </div>
      )}
      {open && (
        <input
          className={styles.manualInput}
          value={inputValue}
          style={{ fontWeight: 600 }}
          onChange={onManualChange}
          disabled
        />
      )}
      {open && !disabled && (
        <ul className={reduceHeight ? styles.reducedHeight : null}>
          {timeOptions
            .filter(option => visibleTimeSlot(option, attributeKey))
            .map(option => (
              <li
                role="presentation"
                onClick={() => {
                  setValue(option);
                  setInputValue(option.display);
                  onChange(option, attributeKey);
                  setOpen(false);
                }}
              >
                {option.display}
              </li>
            ))}
        </ul>
      )}
    </div>
  );
};

SlimSelect.propTypes = {
  visibleTimeSlot: PropTypes.func.isRequired,
  onChange: PropTypes.string.isRequired,
  defaultValue: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  reduceHeight: PropTypes.bool,
  attributeKey: PropTypes.string.isRequired,
  intl: PropTypes.func.isRequired,
};

SlimSelect.defaultProps = {
  reduceHeight: false,
};

export default injectIntl(SlimSelect);
