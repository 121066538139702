import React, { useState, useRef, useEffect } from 'react';
import useOnClickOutside from 'use-onclickoutside';
import PropTypes from 'prop-types';
import { prepTimeObjFromTime, validateTimeInput } from 'ui-common/src/lib/utils';
import { injectIntl } from 'react-intl';
import styles from './SlimSelect.module.scss';

const SlimSelect = ({ defaultValue, options, onChange, disabled, reduceHeight, intl }) => {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(
    defaultValue === null ? '' : prepTimeObjFromTime(defaultValue)
  );
  const [inputValue, setInputValue] = useState(defaultValue === null ? '' : value.display);
  const dropDownRef = useRef(null);
  useOnClickOutside(dropDownRef, () => {
    setOpen(false);
  });
  useEffect(() => {
    setInputValue(value.display);
  }, [value]);
  useEffect(() => {
    if (!open) {
      let time;
      let timeValue;
      if (inputValue.indexOf('am') >= 0) {
        time = inputValue.split('am')[0];
        const hours = time.split(':')[0].trim();
        const minutes = time.split(':')[1].trim();
        timeValue = {
          display: `${hours}:${
            minutes.length === 1 ? `0${minutes}` : minutes
            // (parseInt(minutes) >= 10 || parseInt(minutes) === 0
            //   ? minutes
            //   : "0" + minutes) +
          }am`,
          value: {
            hours: hours === '12' ? 0 : parseInt(hours),
            minutes: parseInt(minutes),
            seconds: 0,
          },
        };
        setValue(timeValue);
        onChange(timeValue.value);
      } else if (inputValue.indexOf('pm') >= 0) {
        time = inputValue.split('pm')[0];
        const hours = time.split(':')[0];
        const minutes = time.split(':')[1];
        timeValue = {
          display: `${hours}:${
            minutes.length === 1 ? `0${minutes}` : minutes
            // (parseInt(minutes) >= 10 || parseInt(minutes) === 0
            //   ? minutes
            //   : "0" + minutes) +
          }pm`,
          value: {
            hours:
              parseInt(time.split(':')[0].trim()) === 12
                ? parseInt(time.split(':')[0].trim())
                : parseInt(time.split(':')[0].trim()) + 12,
            minutes: parseInt(time.split(':')[1].trim()),
            seconds: 0,
          },
        };
        setValue(timeValue);
        onChange(timeValue.value);
      } else {
        setInputValue(value.display ? value.display : '');
      }
    }
  }, [open]);
  const onManualChange = e => {
    if (validateTimeInput(e.target.value)) {
      setInputValue(e.target.value);
    }
  };
  return (
    <div className={styles.container} ref={dropDownRef}>
      {!open && (
        <div onClick={() => setOpen(true)} className={styles.displayValue} role="presentation">
          {value.display ? value.display : intl.formatMessage({ id: 'CredentialsPopup.button.select' })}
        </div>
      )}
      {open && (
        <input
          className={styles.manualInput}
          value={inputValue}
          style={{ fontWeight: 600 }}
          onChange={onManualChange}
        />
      )}
      {open && (
        <ul className={reduceHeight ? styles.reducedHeight : null}>
          {options.map(option => (
            <li
              className={disabled(option.value) ? styles.disabled : ''}
              role="presentation"
              onClick={() => {
                if (!disabled(option.value)) {
                  setValue(option);
                  setInputValue(option.display);
                  onChange(option);
                  setOpen(false);
                }
              }}
            >
              {option.display}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

SlimSelect.propTypes = {
  onChange: PropTypes.string.isRequired,
  defaultValue: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
  disabled: PropTypes.bool.isRequired,
  reduceHeight: PropTypes.bool,
};

SlimSelect.defaultProps = {
  reduceHeight: false,
};

export default injectIntl(SlimSelect);
