function easeInOut(currTime, start, change, duration) {
  let currentTime = currTime;
  currentTime /= duration / 2;
  if (currentTime < 1) {
    return (change / 2) * currentTime * currentTime + start;
  }
  currentTime -= 1;
  return (-change / 2) * (currentTime * (currentTime - 2) - 1) + start;
}

function scrollTo({ element, to, duration, scrollDirection, callback, context }) {
  return new Promise(res => {
    const start = element[scrollDirection];
    const change = to - start;
    const increment = 1000 / 60;

    const animateScroll = baseTime => {
      let elapsedTime = baseTime;
      elapsedTime += increment;
      const position = easeInOut(elapsedTime, start, change, duration);
      // eslint-disable-next-line no-param-reassign
      element[scrollDirection] = position;
      if (elapsedTime < duration) {
        window.requestAnimationFrame(animateScroll.bind(null, elapsedTime));
      } else {
        callback.call(context);
        res();
      }
    };

    window.requestAnimationFrame(animateScroll.bind(null, 0));
  });
}

export default scrollTo;
