import SecureLS from 'secure-ls';
import { createStore } from 'redux-dynamic-modules';
import { getSagaExtension } from 'redux-dynamic-modules-saga';
import LoadingReducer from './Loading/Loading-reducer';
import ResponseMessageHandlingReducer from './ResponseHandler/ResponseHandler-reducer';
import throttle from 'lodash/throttle';
import { routerReducer } from 'react-router-redux';
import LoginReducer from '../features/MainLayout/Login/LoginReducer';
import loginSaga from '../features/MainLayout/Login/LoginSagas';
import RequestHomeReducer from '../features/MainLayout/RequestHome/redux/requestHome-reducer';
import RequestHomeSaga from '../features/MainLayout/RequestHome/redux/requestHome-saga';
import AdminReducer from '../features/AdminHome/redux/adminHome-reducer';
import adminHomeSaga from '../features/AdminHome/redux/adminHome-saga';

const saveApplicationRequestStateToStorage = (keyName, state) => {
  try {
    if (state.applicationRequest) {
      const serializedState = JSON.stringify(state.applicationRequest);
      localStorage.setItem('ar', serializedState);
    }
    if (state.controlCenter) {
      const serializedControlCenterState = JSON.stringify(state.controlCenter);
      localStorage.setItem('cc', serializedControlCenterState);
    }
    // if (state.adminHome) {
    //   const serializedUIConfigState = JSON.stringify(state.adminHome);
    //   localStorage.setItem('uiConfig', serializedUIConfigState);
    // }
    localStorage.setItem('storedTime', new Date());
  } catch (e) {
    // Ignore Error for now
  }
};

const defaultModule = {
  id: 'default',
  reducerMap: {
    user: LoginReducer,
    loader: LoadingReducer,
    message: ResponseMessageHandlingReducer,
    // mfa: Mfa,
    routing: routerReducer,
    requestHome: RequestHomeReducer,
    adminHome: AdminReducer,
  },
  sagas: [loginSaga, RequestHomeSaga, adminHomeSaga],
};
const store = createStore({}, [], [getSagaExtension()], defaultModule);
store.subscribe(
  throttle(() => saveApplicationRequestStateToStorage('applicationRequest', store.getState())),
  1000
);
window.store = store // Its ok to put store in global. sometimes other mfe's can use it.
export default store;
