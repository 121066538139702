import React, { useState, useEffect } from 'react';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/extensions
// eslint-disable-next-line import/extensions
import moment from 'moment';
import DateFnsUtils from '@date-io/date-fns';
import { SlimSelect } from '../SlimSelect';
import { Icon } from '../Icon';
import styles from './SaviyntDatePicker.module.scss';

const SaviyntDatePicker = ({
  instanceFromDate,
  instanceFromTime,
  startToday,
  onChange,
  dateFormat,
  iconRightSide,
  hideIcon,
  readOnly,
  updateExternally,
  minDate,
  onOpen,
}) => {
  const timeOptions = [
    { display: '12:00am', value: { hours: 0, minutes: 0, seconds: 0 } },
    { display: '12:30am', value: { hours: 0, minutes: 30, seconds: 0 } },
    { display: '1:00am', value: { hours: 1, minutes: 0, seconds: 0 } },
    { display: '1:30am', value: { hours: 1, minutes: 30, seconds: 0 } },
    { display: '2:00am', value: { hours: 2, minutes: 0, seconds: 0 } },
    { display: '2:30am', value: { hours: 2, minutes: 30, seconds: 0 } },
    { display: '3:00am', value: { hours: 3, minutes: 0, seconds: 0 } },
    { display: '3:30am', value: { hours: 3, minutes: 30, seconds: 0 } },
    { display: '4:00am', value: { hours: 4, minutes: 0, seconds: 0 } },
    { display: '4:30am', value: { hours: 4, minutes: 30, seconds: 0 } },
    { display: '5:00am', value: { hours: 5, minutes: 0, seconds: 0 } },
    { display: '5:30am', value: { hours: 5, minutes: 30, seconds: 0 } },
    { display: '6:00am', value: { hours: 6, minutes: 0, seconds: 0 } },
    { display: '6:30am', value: { hours: 6, minutes: 30, seconds: 0 } },
    { display: '7:00am', value: { hours: 7, minutes: 0, seconds: 0 } },
    { display: '7:30am', value: { hours: 7, minutes: 30, seconds: 0 } },
    { display: '8:00am', value: { hours: 8, minutes: 0, seconds: 0 } },
    { display: '8:30am', value: { hours: 8, minutes: 30, seconds: 0 } },
    { display: '9:00am', value: { hours: 9, minutes: 0, seconds: 0 } },
    { display: '9:30am', value: { hours: 9, minutes: 30, seconds: 0 } },
    { display: '10:00am', value: { hours: 10, minutes: 0, seconds: 0 } },
    { display: '10:30am', value: { hours: 10, minutes: 30, seconds: 0 } },
    { display: '11:00am', value: { hours: 11, minutes: 0, seconds: 0 } },
    { display: '11:30am', value: { hours: 11, minutes: 30, seconds: 0 } },
    { display: '12:00pm', value: { hours: 12, minutes: 0, seconds: 0 } },
    { display: '12:30pm', value: { hours: 12, minutes: 30, seconds: 0 } },
    { display: '1:00pm', value: { hours: 13, minutes: 0, seconds: 0 } },
    { display: '1:30pm', value: { hours: 13, minutes: 30, seconds: 0 } },
    { display: '2:00pm', value: { hours: 14, minutes: 0, seconds: 0 } },
    { display: '2:30pm', value: { hours: 14, minutes: 30, seconds: 0 } },
    { display: '3:00pm', value: { hours: 15, minutes: 0, seconds: 0 } },
    { display: '3:30pm', value: { hours: 15, minutes: 30, seconds: 0 } },
    { display: '4:00pm', value: { hours: 16, minutes: 0, seconds: 0 } },
    { display: '4:30pm', value: { hours: 16, minutes: 30, seconds: 0 } },
    { display: '5:00pm', value: { hours: 17, minutes: 0, seconds: 0 } },
    { display: '5:30pm', value: { hours: 17, minutes: 30, seconds: 0 } },
    { display: '6:00pm', value: { hours: 18, minutes: 0, seconds: 0 } },
    { display: '6:30pm', value: { hours: 18, minutes: 30, seconds: 0 } },
    { display: '7:00pm', value: { hours: 19, minutes: 0, seconds: 0 } },
    { display: '7:30pm', value: { hours: 19, minutes: 30, seconds: 0 } },
    { display: '8:00pm', value: { hours: 20, minutes: 0, seconds: 0 } },
    { display: '8:30pm', value: { hours: 20, minutes: 30, seconds: 0 } },
    { display: '9:00pm', value: { hours: 21, minutes: 0, seconds: 0 } },
    { display: '9:30pm', value: { hours: 21, minutes: 30, seconds: 0 } },
    { display: '10:00pm', value: { hours: 22, minutes: 0, seconds: 0 } },
    { display: '10:30pm', value: { hours: 22, minutes: 30, seconds: 0 } },
    { display: '11:00pm', value: { hours: 23, minutes: 0, seconds: 0 } },
    { display: '11:30pm', value: { hours: 23, minutes: 30, seconds: 0 } },
    { display: '12:00am', value: { hours: 24, minutes: 0, seconds: 0 } },
  ];
  const [fromDate, setFromDate] = useState(instanceFromDate);
  const startDate = minDate;
  const [fromTime, setFromTime] = useState(instanceFromTime);
  useEffect(() => {
    if (fromDate !== '' && fromDate !== null) {
      let fromDateNew = fromDate;
      if (typeof fromDate === 'string') {
        fromDateNew = moment(fromDate);
      }
      const fromDateTime = fromDateNew.set(fromTime);
      onChange(fromDateTime);
    }
  }, [fromDate, fromTime]);

  useEffect(() => {
    if (updateExternally !== undefined) {
      if (updateExternally === '') {
        setFromDate(null);
      } else {
        setFromDate(moment(updateExternally).startOf('day'));
        const fromDateTime = moment(updateExternally).startOf('day');
        onChange(fromDateTime);
      }
    }
  }, [updateExternally]);

  const calendarIcon = <Icon className={`${styles.calendarIcon} pl-1`} name="calendar" />;

  return (
    <div className={styles.saviyntRangePicker}>
      {hideIcon === undefined && !iconRightSide && !readOnly && calendarIcon}
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <DatePicker
          defaultValue={fromDate}
          autoOk
          disableToolbar
          disabled={readOnly}
          variant="inline"
          style={{ width: '4.4rem', marginTop: 3, marginRight: 3 }}
          format={dateFormat}
          value={fromDate}
          onChange={value => {
            const momentFromdate = moment(value);
            momentFromdate.set({ hours: 0, minutes: 0, seconds: 0 });
            setFromDate(momentFromdate);
            // const dd = { fromDate };
            // momentFromdate.set('hour', 13);
          }}
          InputProps={{
            style: {
              fontSize: 11, // dateFontSize,
              fontWeight: 600,
              // marginTop: 3,
            },
            disableUnderline: readOnly,
          }}
          minDate={startToday ? moment(fromDate) : startDate}
          onOpen={() => {
            setTimeout(() => {
              onOpen(true);
            }, 1000);
          }}
          onClose={() => {
            setTimeout(() => {
              onOpen(false);
            }, 1000);
          }}
          // minDateMessage={false}
          // shouldDisableDate={disabledFromDateTime}
        />
      </MuiPickersUtilsProvider>
      {instanceFromTime && (
        <SlimSelect
          options={timeOptions}
          defaultValue={fromTime}
          disabled={value =>
            moment(instanceFromDate) &&
            value.hours * 60 + value.minutes <=
              moment(instanceFromDate).get('hours') * 60 + moment(instanceFromDate).get('minutes')
          }
          onChange={option => setFromTime(option)}
        />
      )}
      {hideIcon === undefined && iconRightSide && !readOnly && calendarIcon}
    </div>
  );
};
SaviyntDatePicker.defaultProps = {
  startToday: false,
  onOpen: null,
};

SaviyntDatePicker.propTypes = {
  instanceFromDate: PropTypes.objectOf(
    PropTypes.shape({
      hours: PropTypes.number,
      minutes: PropTypes.number,
    })
  ).isRequired,
  instanceFromTime: PropTypes.string,
  startToday: PropTypes.bool,
  dateFormat: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  iconRightSide: PropTypes.bool,
  readOnly: PropTypes.bool,
  updateExternally: PropTypes.string.isRequired,
  hideIcon: PropTypes.bool.isRequired,
  // disabledFromDateTime: PropTypes.string.isRequired,
};

SaviyntDatePicker.defaultProps = {
  instanceFromTime: null,
  dateFormat: 'MMM, dd yyyy',
  iconRightSide: false,
  readOnly: false,
};

export default SaviyntDatePicker;
