import React, { useState, useEffect, useRef, Children, cloneElement, createRef } from 'react';
import PropTypes from 'prop-types';
import styles from './Carousel.module.scss';
import ArrowNavigator from './ArrowNav';
import scrollTo from './scrollHelper';

const Carousel = ({ children, stepSize, cardDist, navOnTop, navTitle, useArrowOpacity, ...otherProps }) => {
  const viewport = useRef(null);
  const leftArrowRef = useRef(null);
  const rightArrowRef = useRef(null);
  const cardRefs = useRef([...Array(1)].map(() => createRef()));
  const [showLeftArrow, setShowLeftArrow] = useState(useArrowOpacity ? true : false);
  const [showRightArrow, setShowRightArrow] = useState(true);
  const [expanded, setExpanded] = useState(false);

  const cardWidth = () => cardRefs.current[0].current.clientWidth;
  // const cardWidth = () => (cardRefs[0].current ? cardRefs[0].current.clientWidth : cardwidth);

  const checkIfAtEdge = () => {
    const scrollLength = viewport.current.scrollLeft;
    const viewportLength = viewport.current.clientWidth;
    const totalLength = viewport.current.scrollWidth;
    if (rightArrowRef.current) {
      if (scrollLength + viewportLength === totalLength) {
        if (showRightArrow && !useArrowOpacity) setShowRightArrow(false);
        if (useArrowOpacity) {
          rightArrowRef.current.firstChild['tabIndex']= -1;
          rightArrowRef.current.style.opacity = 0;}
      } else {
        if (!showRightArrow && !useArrowOpacity) setShowRightArrow(true);
        if (useArrowOpacity) {
          rightArrowRef.current.firstChild['tabIndex']= 0;
          rightArrowRef.current.style.opacity = 1;}
      }
    }
    if (leftArrowRef.current) {
      if (scrollLength === 0) {
        if (showLeftArrow && !useArrowOpacity) setShowLeftArrow(false);
        if (useArrowOpacity) {
          leftArrowRef.current.firstChild['tabIndex']= -1;
          leftArrowRef.current.style.opacity = 0;}
      } else {
        if (!showLeftArrow && !useArrowOpacity) setShowLeftArrow(true);
        if (useArrowOpacity) {
          leftArrowRef.current.firstChild['tabIndex']= 0;
          leftArrowRef.current.style.opacity = 1;}
      }
    }
  };

  const getScrollParams = steps => {
    const durationUnit = 300;
    return {
      scrollWidth: steps * cardWidth(),
      scrollTime: Math.min(steps * durationUnit, 600),
    };
  };

  const handleScroll = isForward => {
    const { scrollWidth, scrollTime } = getScrollParams(stepSize);
    const newPosition = viewport.current.scrollLeft + (isForward ? scrollWidth : -scrollWidth);
    return scrollTo({
      element: viewport.current,
      to: newPosition,
      duration: scrollTime,
      scrollDirection: 'scrollLeft',
      callback: checkIfAtEdge,
      context: this,
    });
  };
  const handleGoForward = e => {
    e.preventDefault();
    handleScroll(true);
  };
  const handleGoBack = e => {
    e.preventDefault();
    handleScroll(false);
  };
  const toggleExpansion = () => {
    setExpanded(!expanded);
  };

  const wrapCard = (baseCard, index) => {
    return (
      <div
        className={styles.card}
        ref={cardRefs.current[index]}
        style={index === children.length - 1 ? null : { paddingRight: `${cardDist}px` }}
      // ref={cardRefs[index]}
      // style={index === children.length - 1 ?  {  position:'relative' } : { paddingRight: `${cardDist}px`, position:'relative' }}
      >
        {baseCard}
      </div>
    );
  };

  useEffect(() => {
    checkIfAtEdge();
    window.addEventListener('resize', checkIfAtEdge);
    window.addEventListener('load', checkIfAtEdge);
  }, []);

  useEffect(() => {
    checkIfAtEdge();
  }, [children]);

  const ArrowNavPair = () => (
    <div className={styles.arrows}>
      <ArrowNavigator
        domRef={leftArrowRef}
        handleClick={handleGoBack}
        backward
        className={`${styles.arrow} ${styles.left} ${showLeftArrow ? '' : styles.hide}`}
      />
      <ArrowNavigator
        domRef={rightArrowRef}
        handleClick={handleGoForward}
        className={`${styles.arrow} ${styles.right} ${showRightArrow ? '' : styles.hide}`}
      />
    </div>
  );

  return (
    <div className={`${styles.container} ${navOnTop && styles.extendToEdge}`} {...otherProps}>
      <div className={styles.titlebar}>
        {navOnTop && <ArrowNavPair />}
        {navTitle()}
      </div>

      <div className={`${styles.slider}`}>
        {!navOnTop && <ArrowNavPair />}
        <div className={styles.cardContainer}>
          <div className={styles.scrollable} ref={viewport} onScroll={checkIfAtEdge}>
            {Children.map(children, child =>
              cloneElement(child, {
                expanded,
                setExpanded: toggleExpansion,
              })
            ).map(wrapCard)}
          </div>
        </div>
      </div>
    </div>
  );
};

Carousel.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  stepSize: PropTypes.number,
  cardDist: PropTypes.number,
  navOnTop: PropTypes.bool,
  navTitle: PropTypes.func,
};

Carousel.defaultProps = {
  children: [],
  stepSize: 1,
  cardDist: 13,
  navOnTop: false,
  navTitle: () => { },
};

export default Carousel;
