import React from 'react';
// eslint-disable-next-line import/extensions
import { A } from '../../A';
// eslint-disable-next-line import/extensions
import { EllipsedLabel } from '../../EllipsedLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { PropTypes } from 'prop-types';
import { filterObj } from 'ui-common/src/lib/utils';
import { ColumnType } from 'ui-common/src/lib/commonPropTypes';
import { injectIntl } from 'react-intl';


const StringFilter = ({
  column,
  appliedFilters,
  setAppliedFilters,
  checkedFilters,
  setCheckedFilters,
  showColumnFilter,
  setShowColumnFilter,
  sortedColumn,
  setSortedColumn,
  filteredDistinctColumnValues,
  setFilteredDistinctColumnValues,
  distinctColumnValues,
  intl,
}) => {
  // realKey functionality shud be added.
  const { key } = column;
  return (
    <div
      className={
        column.key === showColumnFilter ? 'd-show col-filter-box' : 'd-none col-filter-box'
      }
    >
      <div className="sorting-btn">
        <button
          type="submit"
          className={
            sortedColumn.columnName === key && sortedColumn.order === 'asc'
              ? 'btn btn-outline-primary active'
              : 'btn btn-outline-primary'
          }
          onClick={() =>
            setSortedColumn({
              columnName: column.key,
              order: 'asc',
            })
          }
        >
          {intl.formatMessage({ id: 'DateFilter.A-Z' })}
        </button>
        <button
          type="submit"
          className={
            sortedColumn.columnName === key && sortedColumn.order === 'desc'
              ? 'btn btn-outline-primary active'
              : 'btn btn-outline-primary'
          }
          onClick={() =>
            setSortedColumn({
              columnName: column.key,
              order: 'desc',
            })
          }
        >

          {intl.formatMessage({ id: 'DateFilter.Z-A' })}
        </button>
      </div>
      <input
        type="text"
        className="form-control"
        placeholder= {intl.formatMessage({ id: 'DateFilter.Filter' })}
        onChange={e => {
          // if (e.target.value.length > 1) {
          setFilteredDistinctColumnValues({
            ...filteredDistinctColumnValues,
            [key]: filterObj(distinctColumnValues[key], e.target.value),
          });
          // }
        }}
      />
      <form action="">
        <ul className="col-filter-list">
          {filteredDistinctColumnValues[key] && // let distinctField = value.length > 0 ? value : "Empty";
            Object.keys(filteredDistinctColumnValues[key]).map(distinctField => (
              <li key={distinctField}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        checkedFilters[column.key]
                          ? checkedFilters[column.key].includes(distinctField)
                          : false
                      }
                      onChange={e => {
                        if (e.target.checked) {
                          setCheckedFilters({
                            ...checkedFilters,
                            [key]: checkedFilters[key]
                              ? [...checkedFilters[key], distinctField]
                              : [distinctField],
                          });
                        } else {
                          setCheckedFilters({
                            ...checkedFilters,
                            [key]: checkedFilters[key]
                              ? checkedFilters[key].filter(filter => filter !== distinctField)
                              : [],
                          });
                        }
                      }}
                      color="primary"
                    />
                  }
                  label={
                    distinctField.length > 0 ? (
                      <EllipsedLabel label={distinctField} maxLength={20} />
                    ) : (
                      '(No Value)'
                    )
                  }
                />

                <span className=" pull-right count mt-2 pt-1">
                  {distinctColumnValues[column.key][distinctField]}
                </span>
              </li>
            ))}
        </ul>
        <div className="action">
          <A
            href="#"
            className="btn-reset"
            onClick={e => {
              e.preventDefault();
              setAppliedFilters({ ...checkedFilters, [column.key]: [] });
              setCheckedFilters({ ...checkedFilters, [column.key]: [] });
              if (column.key === sortedColumn.columnName) {
                setSortedColumn({ order: 'asc' });
              }
            }}
          >
            {intl.formatMessage({ id: 'DateFilter.Reset' })}
          </A>
          <button
            type="button"
            className="btn btn-outline-primary btn-apply"
            onClick={e => {
              e.preventDefault();
              setAppliedFilters({ ...appliedFilters, ...checkedFilters });
              setShowColumnFilter('none');
            }}
          >
            {intl.formatMessage({ id: 'DateFilter.Apply' })}
          </button>
        </div>
      </form>
    </div>
  );
};

StringFilter.propTypes = {
  column: ColumnType.isRequired,
  showColumnFilter: PropTypes.func.isRequired,
  setShowColumnFilter: PropTypes.func.isRequired,
  sortedColumn: PropTypes.shape({
    columnName: PropTypes.string,
    order: PropTypes.string,
  }).isRequired,
  setSortedColumn: PropTypes.func.isRequired,
  distinctColumnValues: PropTypes.shape({
    key: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  checkedFilters: PropTypes.shape({
    key: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  setCheckedFilters: PropTypes.func.isRequired,
  appliedFilters: PropTypes.shape({
    key: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  setAppliedFilters: PropTypes.func.isRequired,
  filteredDistinctColumnValues: PropTypes.arrayOf.isRequired,
  setFilteredDistinctColumnValues: PropTypes.func.isRequired,
};
// eslint-disable-next-line import/prefer-default-export

const StringFilterIntl = injectIntl(StringFilter);

export {  StringFilterIntl as StringFilter };
