import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { injectIntl } from 'react-intl';
import IconPath from './iconspath';
import css from './Icon.module.scss';

const Icon = props => {
  const cls = cx(css.icon, props.className);
  return (
    <i {...props} className={cls}>
      {IconPath[props.name]
        ? IconPath[props.name]({ ...props, name: null, className: null })
        : null}
    </i>
  );
};

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  className: PropTypes.string.isRequired,
};
Icon.defaultProps = {
  size: 20,
};
export default injectIntl(Icon);
