import React, { useState, useEffect, useRef } from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Menu, MenuItem, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import './SOD.scss';
import Button from '@material-ui/core/Button';
import { TextFieldComponent } from 'ui-components/src';
import Popover from '@material-ui/core/Popover';
import KeyboardTabIcon from '@material-ui/icons/KeyboardTab';
import moment from 'moment';
import RefreshIcon from '@material-ui/icons/Refresh';
import ExportColumnModal from './ExportColumnModal';
import { MitigatingControlDialog } from '../MitigatingControlDialog';
import { Carousel as Carousal } from '../Carousel';
import { Icon } from '../Icon';

const useStyles = makeStyles(theme => ({
  label: {
    fontSize: '12px !important',
    fontFamily: 'Rubik !important',
    top: '19px',
    position: 'relative',
    paddingLeft: '10px',
  },
  button: {
    margin: theme.spacing(1),
    maxHeight: 36,
  },
  chip: {
    marginRight: theme.spacing(0.5),
  },
  root: {
    backgroundColor: '#ffffff',
    width: '98%',
  },
  notchedOutline: {
    borderRadius: '3px',
    border: '1px solid #dcdee1',
  },
  icon: {
    width: '1.4em',
    height: '1.4em',
  },
  exportIcon: {
    width: '1.1em',
    height: '1.1em',
    fill: 'var(--primary-main)',
    transform: 'rotate(90deg)',
    fontSize: '1.5rem',
  },
}));

const SODSingle = ({
  sodData,
  setSodDetails,
  commentsRequired,
  mitigationRequired,
  addMitigatingCtrl,
  addbusinessJustiToRisk,
  recalculateSOD,
  activeIndex,
  commentsMandatory,
  enableExportSOD,
  getExportSODColumns,
  sodExportData,
  downLoadSODReport,
  enableRecalculateSOD,
  ...props
}) => {
  const classes = useStyles();
  const carousalLayoutRef = useRef(null);
  const [anim, setAnim] = useState('');
  const [mitigating, setMitigating] = useState(false);
  const [comments, setComments] = useState(sodData ? sodData.riskComments : '');
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorE2, setAnchorE2] = useState(null);
  const [showExportColumnDialog, setShowExportColumnDialog] = useState(false);
  const open = Boolean(anchorE2);
  const popoverOpen = event => {
    setAnchorEl(event.currentTarget);
  };
  const [reportType, setReportType] = useState('');
  const handleClose = () => {
    setAnchorE2(null);
  };
  const handleClick = e => {
    setAnchorE2(e.currentTarget);
  };
  const popoverClose = () => {
    setAnchorEl(null);
  };

  const droppopover = Boolean(anchorEl);
  const id = droppopover ? 'simple-popover' : undefined;

  useEffect(() => {
    setAnim('sod-anim');
  }, []);

  const datas =
    sodData.Functions &&
    [...sodData.Functions].map(item => (
      <div className="carousal-item-container">
        <div className="function-name-layout">
          <div className="function-bg">
            <div className="function-dot" />
            <div className="function-name">{`${item.FunctionName}${item.Description ? ` (${item.Description})` : ''
              }`}</div>
          </div>
        </div>
        {item.Entitlements.map(ele => {
          if (ele.sapApplication) {
            let violatingEntitlement = ele['VIOLATING ENTITLEMENT'];
            if (!Array.isArray(ele['VIOLATING ENTITLEMENT'])) {
              violatingEntitlement = ele['VIOLATING ENTITLEMENT']
                ? ele['VIOLATING ENTITLEMENT'].split(',')
                : [];
            }
            return (
              <div className="entitilement-item">
                <div className="entitilement-layout">
                  <div className="iconlayout">
                    <img className="sapicon" src="/ECMv6/assets/images/app_req_icons/sap.png" alt="sap" />
                    <div className="sapicontext">
                      {props.intl.formatMessage({ id: 'SOD.appReq.SAP.label' })}
                    </div>
                  </div>

                  <div className="entitlementlabelsap">
                    {props.intl.formatMessage({ id: 'SOD.appReq.ParEntitlement.sap' })}
                  </div>
                  <div className="entitlementval sapval">{ele['PARENT ENTITLEMENT']}</div>
                </div>
                <div className="entitilement-layout">
                  <div className="entitlementlabelsap">
                    {props.intl.formatMessage({ id: 'SOD.appReq.VioEntitlement.sap' })}
                  </div>
                  <div className="entitlementvalsaplayout">
                    {violatingEntitlement.map(ent => {
                      return (
                        <div
                          className={`entitlementvalsap ${violatingEntitlement.length > 3
                            ? 'col-3'
                            : violatingEntitlement.length === 1
                              ? 'col-12'
                              : violatingEntitlement.length === 2
                                ? 'col-6'
                                : 'col-4'
                            }`}
                        >
                          {ent}
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="entitilement-layout">
                  {ele['VIOLATING ROLE'] && (
                    <div className="entitlementlabelsap">
                      {props.intl.formatMessage({ id: 'SOD.appReq.VioRole' })}
                    </div>
                  )}
                  <div className="entitlementval">{ele['VIOLATING ROLE']}</div>
                </div>
              </div>
            );
          }
          let violatingEntitlement = ele['VIOLATING ENTITLEMENT'];
          if (
            Array.isArray(ele['VIOLATING ENTITLEMENT']) &&
            ele['VIOLATING ENTITLEMENT'].length > 0
          ) {
            violatingEntitlement = ele['VIOLATING ENTITLEMENT'].join(', ');
          }
          return (
            <div className="entitilement-item">
              <div className="entitilement-layout">
                <div className="entitlementlabel">
                  {props.intl.formatMessage({ id: 'SOD.appReq.ParEntitlement' })}
                </div>
                <div className="entitlementval">{ele['PARENT ENTITLEMENT']}</div>
              </div>
              <div className="entitilement-layout">
                <div className="entitlementlabel">
                  {props.intl.formatMessage({ id: 'SOD.appReq.VioEntitlement' })}
                </div>
                <div className="entitlementval">{violatingEntitlement}</div>
              </div>
              <div className="entitilement-layout">
                {ele['VIOLATING ROLE'] && (
                  <div className="entitlementlabel">
                    {props.intl.formatMessage({ id: 'SOD.appReq.VioRole' })}
                  </div>
                )}
                <div className="entitlementval">{ele['VIOLATING ROLE']}</div>
              </div>
            </div>
          );
        })}
      </div>
    ));

  const mitigatedMCtrl =
    props.mitigatedMC &&
      ((props.mitigatedMC.length > 0 && props.mitigatedMC[0].RiskId === sodData.exceptionKey) || (props.mitigatedMC.length === 0 && props.updatedMC))
      ? props.mitigatedMC
      : sodData &&
        sodData.Mitigated_MitigatingControls &&
        sodData.Mitigated_MitigatingControls.length > 0
        ? sodData.Mitigated_MitigatingControls
        : [];

  return (
    <div className={`sod-single-container ${anim}`}>
      {!props.isDetailed ? (
        <React.Fragment>
          <div className="violation-header">
            <div className="violation-left">
              <Icon name="violation" size={32} />
              <h4 className="sod-single-header">
                {' '}
                {props.intl.formatMessage({ id: 'SOD.appReq.singlesodtitle' })}{' '}
              </h4>
            </div>
            {enableRecalculateSOD && (
              <Icon name="sod_recalculate" className="icon" onClick={() => recalculateSOD()} />
            )}
            {enableExportSOD && (
              <Icon name="sod_downloadreport" className="icon mr-4" onClick={e => handleClick(e)} />
            )}
            <Menu
              id="long-menu"
              anchorEl={anchorE2}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              open={open}
              onClose={handleClose}
              MenuListProps={{
                onMouseLeave: handleClose,
                disablePadding: true,
              }}
              classes={{
                paper: classes.menuPaper,
              }}
            >
              <MenuItem
                onClose={handleClose}
                classes={{
                  root: classes.menuItemRoot,
                }}
                onClick={() => {
                  setReportType('auth');
                  setShowExportColumnDialog(true);
                  getExportSODColumns('auth');
                  handleClose();
                }}
              >
                {props.intl.formatMessage({
                  id: 'SOD.Export.AuthReport.label',
                })}
              </MenuItem>

              <MenuItem
                onClose={handleClose}
                classes={{
                  root: classes.menuItemRoot,
                }}
                onClick={() => {
                  setReportType('tcode');
                  setShowExportColumnDialog(true);
                  getExportSODColumns('tcodedetail');
                  handleClose();
                }}
              >
                {props.intl.formatMessage({
                  id: 'SOD.Export.TcodeReport.label',
                })}
              </MenuItem>
            </Menu>
          </div>
          <div className="sod-single-content-header">
            <div className={`risktype ${sodData.riskPriority ? sodData.riskPriority : 'High'}`}>
              {sodData.riskPriority ? sodData.riskPriority : 'High'}
            </div>
            <div className="sod-namesection">
              <div className="name">{`${sodData.ruleSetName ? `${sodData.ruleSetName} - ` : ''}${sodData.Risk
                }`}</div>
              <div className="subname">{sodData.Description}</div>
              {((props.from === 'review' &&
                sodData.mitigated &&
                sodData.mitigated.mitigatingcontrol) ||
                (props.from === 'approval' &&
                  props.enableMitigation &&
                  sodData.Mitigated_MitigatingControls &&
                  sodData.Mitigated_MitigatingControls.length > 0)) && (
                  <div className="mitigated d-flex">
                    <Icon className="mitigatedicon" name="accept" selected height="13px" tooltip="" />
                    <div className="mitigated-text">
                      {props.intl.formatMessage({
                        id: 'SOD.PreMitigated.MitigatedLabel',
                      })}
                    </div>
                  </div>
                )}
            </div>

            {sodData.Available_MitigatingControls && sodData.Available_MitigatingControls.length > 0 &&
              mitigatedMCtrl.length === 0 &&
              <div className="recommended">{props.intl.formatMessage({
                id: 'SOD.recommended.MC.available',
              })}</div>}
            {mitigationRequired && props.enableMitigation && (
              <Button
                variant="outlined"
                color="primary"
                type="button"
                classes={{ outlined: classes.button }}
                onClick={() => {
                  props.fetchAvailableMC(sodData.RiskId, '0');
                  setMitigating(true);
                }}
              >
                {props.intl.formatMessage({ id: 'SODMitigatingCtrlText' })}
              </Button>
            )}
            {sodData && sodData.busnessJustification && sodData.busnessJustification.length > 0 && (
              <div className="previous-control" aria-describedby={id} onClick={popoverOpen}>
                {mitigationRequired && props.enableMitigation && (
                  <div className="separator" />
                )}
                <div className="d-flex align-items-center historylayout">
                  <Icon name="requesthistory" size={20} />
                  {props.intl.formatMessage({ id: 'SOD.SeeHistory.label' })}
                </div>
              </div>
            )}
            {mitigating && (
              <MitigatingControlDialog
                onClose={setMitigating}
                open={mitigating}
                addMitigatingCtrl={addMitigatingCtrl}
                risk={sodData}
                fetchAvailableMC={type => props.fetchAvailableMC(sodData.RiskId, type)}
                mitigationData={props.availableMC}
              />
            )}
            <ExportColumnModal
              onClose={() => setShowExportColumnDialog(false)}
              open={showExportColumnDialog}
              sodExportData={sodExportData}
              downLoadSODReport={(selectedValues, rowMappingKey) =>
                downLoadSODReport(selectedValues, rowMappingKey, reportType)
              }
            />
            <Popover
              id={id}
              open={droppopover}
              anchorEl={anchorEl}
              onClose={popoverClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <div className="busijusti-history">
                <div className="history">
                  {' '}
                  {props.intl.formatMessage({ id: 'SOD.SeeHistory.label' })}
                </div>
                {sodData &&
                  sodData.busnessJustification &&
                  sodData.busnessJustification.map(item => {
                    let letter = 'A';
                    if (item) {
                      const arr = item.split('-');
                      if (arr[1]) letter = arr[1].slice(0, 1);
                    }
                    return (
                      <div className="iconLayout">
                        <div className="iconLetter">{letter}</div>
                        {item}
                      </div>
                    );
                  })}
              </div>
            </Popover>
          </div>{' '}
        </React.Fragment>
      ) : (
          <React.Fragment>
            {' '}
            <div className="sod-single-content-header multi">
              <div className="high-layout">
                <div className={`risktype ${sodData.riskPriority ? sodData.riskPriority : 'High'}`}>
                  {sodData.riskPriority ? sodData.riskPriority : 'High'}
                </div>
                <div className="sod-namesection">
                  <div className="name">{`${sodData.ruleSetName ? `${sodData.ruleSetName} - ` : ''}${sodData.Risk
                    }`}</div>
                  <div className="subname">{sodData.Description}</div>
                  {((props.from === 'review' &&
                    sodData.mitigated &&
                    sodData.mitigated.mitigatingcontrol) ||
                    (props.from === 'approval' &&
                      props.enableMitigation &&
                      sodData.Mitigated_MitigatingControls &&
                      sodData.Mitigated_MitigatingControls.length > 0)) && (
                      <div className="mitigated d-flex">
                        <Icon
                          className="mitigatedicon"
                          name="accept"
                          selected
                          height="13px"
                          tooltip=""
                        />
                        <div className="mitigated-text">
                          {props.intl.formatMessage({
                            id: 'SOD.PreMitigated.MitigatedLabel',
                          })}
                        </div>
                      </div>
                    )}
                </div>
              </div>
              <div className="mitigation-layout">
                {' '}
                {sodData.Available_MitigatingControls && sodData.Available_MitigatingControls.length > 0 &&
                  mitigatedMCtrl.length === 0 &&
                  <div className="recommended">{props.intl.formatMessage({
                    id: 'SOD.recommended.MC.available',
                  })}</div>}

                {mitigationRequired && props.enableMitigation && (
                  <Button
                    variant="outlined"
                    color="primary"
                    type="button"
                    classes={{ outlined: classes.button }}
                    onClick={() => {
                      props.fetchAvailableMC(sodData.RiskId, '0');
                      setMitigating(true);
                    }}
                  >
                    {props.intl.formatMessage({ id: 'SODMitigatingCtrlText' })}
                  </Button>
                )}
                {sodData && sodData.busnessJustification && sodData.busnessJustification.length > 0 && (
                  <div className="previous-control" aria-describedby={id} onClick={popoverOpen}>
                    {mitigationRequired && props.enableMitigation && (
                      <div className="separator" />
                    )}
                    <div className="d-flex align-items-center historylayout">
                      <Icon name="requesthistory" size={20} />
                      {props.intl.formatMessage({ id: 'SOD.SeeHistory.label' })}
                    </div>
                  </div>
                )}
                {mitigating && (
                  <MitigatingControlDialog
                    sodSingle
                    onClose={setMitigating}
                    open={mitigating}
                    addMitigatingCtrl={addMitigatingCtrl}
                    risk={sodData}
                    fetchAvailableMC={type => props.fetchAvailableMC(sodData.RiskId, type)}
                    mitigationData={props.availableMC}
                  />
                )}
                <IconButton
                  aria-label="back"
                  fontSize="small"
                  className="back"
                  onClick={() => {
                    setAnim('');
                    if (addbusinessJustiToRisk) addbusinessJustiToRisk(sodData.Risk, comments);
                    if (props.updateMitigatingCtrlForMultiSOD && props.mitigatedMC && props.mitigatedMC.length > 0
                      && props.mitigatedMC[0].RiskId === sodData.exceptionKey)
                      props.updateMitigatingCtrlForMultiSOD(sodData.exceptionKey, props.mitigatedMC);

                    setTimeout(() => {
                      setSodDetails({
                        showSOD: true,
                        value: 'multi',
                        detailData: {},
                        selectedRowIndex: activeIndex,
                      });
                      if (
                        props.showError &&
                        props.showError.showError &&
                        props.showError.index !== ''
                      ) {
                        props.setShowError({ showError: true, index: activeIndex, scroll: false });
                      }
                    }, 1000);
                  }}
                >
                  <ArrowBackIcon />
                </IconButton>
                <Popover
                  id={id}
                  open={droppopover}
                  anchorEl={anchorEl}
                  onClose={popoverClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                >
                  <div className="busijusti-history">
                    <div className="history">
                      {' '}
                      {props.intl.formatMessage({ id: 'SOD.SeeHistory.label' })}
                    </div>
                    {sodData &&
                      sodData.busnessJustification &&
                      sodData.busnessJustification.map(item => {
                        let letter = 'A';
                        if (item) {
                          const arr = item.split('-');
                          if (arr[1]) letter = arr[1].slice(0, 1);
                        }
                        return (
                          <div className="iconLayout">
                            <div className="iconLetter">{letter}</div>
                            {item}
                          </div>
                        );
                      })}
                  </div>
                </Popover>
              </div>
            </div>{' '}
          </React.Fragment>
        )}
      {props.enableMitigation && mitigatedMCtrl.length > 0 && (
        <div className="mitigated-layout">
          <div className="mitigated-titlelayout">
            <div className="mitigated-title">
              {props.intl.formatMessage({ id: 'SOD.MC.title' })}
            </div>
            {props.editMitigationEnabled && (
              <div className="mitigated-editlayout">
                <div
                  className="label"
                  onClick={() =>
                    addMitigatingCtrl(mitigatedMCtrl[0], sodData.exceptionKey, null, 'Remove', true)
                  }
                >
                  {props.intl.formatMessage({ id: 'SOD.MC.Remove' })}
                </div>
                <div
                  className="label"
                  onClick={() => {
                    props.fetchAvailableMC(sodData.RiskId, '0');
                    setMitigating(true);
                  }}
                >
                  {props.intl.formatMessage({ id: 'SOD.MC.Modify' })}
                </div>
              </div>
            )}
          </div>
          <div className="mitigated-sublayout d-flex">
            <div className="control-layout">
              <div className="controlname-label">
                {props.intl.formatMessage({ id: 'SOD.MC.label' })}
              </div>
              <div className="controlname-value">{mitigatedMCtrl[0].MITIGATINGCONTROL}</div>
            </div>

            <div className="control-layout">
              <div className="controlname-label">
                {props.intl.formatMessage({ id: 'SOD.MC.Timeframe' })}
              </div>
              <div className="controlname-value">{`${mitigatedMCtrl[0].STARTDATE
                ? moment(moment.utc(mitigatedMCtrl[0].STARTDATE))
                  .local()
                  .format('YYYY-MM-DD')
                : ''
                }  ${mitigatedMCtrl[0].ENDDATE
                  ? ` - ${moment(moment.utc(mitigatedMCtrl[0].ENDDATE))
                    .local()
                    .format('YYYY-MM-DD')}`
                  : ''
                }`}</div>
            </div>
          </div>
        </div>
      )}
      {commentsRequired && (
        <div className="sod-single-coments">
          <div className="row m-0">
            <div className="commentwidth">
              <TextFieldComponent
                showError={
                  props.showError && props.showError.showError
                    ? !!(commentsMandatory && !comments)
                    : false
                }
                onBlur={e => {
                  if (!props.isDetailed) addbusinessJustiToRisk(sodData.Risk, e.target.value);
                }}
                attributeKey="Business Justification"
                name="Business Justification"
                placeholder={props.intl.formatMessage({ id: 'SOD.BusinessJusti.PlaceHolder' })}
                _handleChange={value => {
                  setComments(value);
                }}
                multiline
                label={props.intl.formatMessage({ id: 'SOD.BusinessJusti.Label' })}
                value={comments}
                rows="4"
                variant="outlined"
              />
            </div>
            {commentsMandatory && <span className="commentsmandatory">{` *`}</span>}
          </div>
        </div>
      )}
      <div className="sod-single-carousal-container" ref={carousalLayoutRef}>
        <Carousal
          cardwidth={327}
          cardDist={16}
          navOnTop
          navTitle={() => (
            <div className="carousal_title">
              {' '}
              <Icon name="violation" size={25} />
              <span className="subtext">{`${datas.length} ${datas.length > 1
                ? props.intl.formatMessage({
                  id: 'SOD.appReq.carousalsodtitlecont',
                })
                : props.intl.formatMessage({
                  id: 'SOD.appReq.carousalsodtitlecont.single',
                })
                }`}</span>
            </div>
          )}
        >
          {datas}
        </Carousal>
        {/* <Carousal
          arrowOnTop
          data={datas}
          title={`Total of ${[...sodData.Functions].length} functions violation`}
          responsive={responsive}
          itemWidth={350}
          itemsPerSlide={3}
        /> */}
      </div>
    </div>
  );
};

SODSingle.defaultProps = {
  commentsRequired: false,
  mitigationRequired: false,
  addMitigatingCtrl: null,
  addbusinessJustiToRisk: null,
  recalculateSOD: null,
  activeIndex: 0,
  commentsMandatory: false,
};
SODSingle.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  intl: PropTypes.arrayOf(
    PropTypes.shape({
      locale: PropTypes.string.isRequired,
      formatMessage: PropTypes.func.isRequired,
    })
  ).isRequired,
  sodData: PropTypes.shape({
    riskComments: PropTypes.string,
    riskPriority: PropTypes.string,
    Risk: PropTypes.string,
    Functions: PropTypes.arrayOf(PropTypes.shape({ FunctionName: PropTypes.string })),
    Available_MitigatingControls: PropTypes.arrayOf(PropTypes.shape({})),
    Assigned_MitigatingControls: PropTypes.arrayOf(PropTypes.shape({})),
    selectedMitigationDetails: PropTypes.shape({ MitigatingControl: PropTypes.string }),
  }).isRequired,
  isDetailed: PropTypes.bool.isRequired,
  setSodDetails: PropTypes.func.isRequired,
  commentsRequired: PropTypes.bool,
  mitigationRequired: PropTypes.bool,
  addMitigatingCtrl: PropTypes.func,
  addbusinessJustiToRisk: PropTypes.func,
  recalculateSOD: PropTypes.func,
  activeIndex: PropTypes.number,
  commentsMandatory: PropTypes.bool,
};

export default injectIntl(withRouter(SODSingle));
