import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import './ListSearch.scss';

const ListSearch = ({ listSearchText, setListSearchText, intl }) => {
  const [searchText, setSearchText] = useState(listSearchText);
  const searchRef = useRef(null);
  const inputRef = useRef();

  const clearValue = () => {
    setSearchText('');
    setListSearchText('');
  };
  const onChangeSearchText = e => {
    setSearchText(e.target.value);
    if (e.target.value.length > 2) {
      if (e.key === 'Enter') {
        setListSearchText(e.target.value);
      }
    } else if (e.target.value.length === 0) {
      clearValue();
    }
  };

  return (
    <div ref={searchRef} className="d-flex">
      <div className="list-searchfilter">
        <svg width="14" height="14" className="list-searchIcon">
          <g data-name="search icon" fill="none" stroke="#bcc0c8" strokeMiterlimit="10">
            <path
              data-name="search icon2"
              d="M9.9 5.4A4.5 4.5 0 1 1 5.4.9a4.5 4.5 0 0 1 4.5 4.5z"
              strokeWidth="1.8"
            />
            <path
              data-name="search icon1"
              d="M9.4 9.4l1.941 1.941"
              strokeLinecap="round"
              strokeWidth="2"
            />
          </g>
        </svg>
        <input
          className="list-searchBox"
          aria-label="search-privileged-lbl"
          type="text"
          value={searchText}
          name="search"
          placeholder={intl.formatMessage({
            id: 'ListSearch.search.placeholder',
          })}
          onChange={onChangeSearchText}
          onKeyPress={onChangeSearchText}
          ref={inputRef}
        />
        {searchText.length > 0 && (
          <span
            role="button"
            tabIndex={0}
            onKeyPress={() => clearValue()}
            className="list-close pull-right"
            onClick={() => clearValue()}
          >
            <svg width="9.193" height="9.191">
              <g
                fill="none"
                stroke="#969696"
                strokeLinecap="round"
                strokeMiterlimit="10"
                strokeWidth="1.5"
              >
                <path data-name="CANCEL ICON2" d="M1.06 1.061l7.072 7.071" />
                <path data-name="CANCEL ICON1" d="M8.132 1.061L1.061 8.132" />
              </g>
            </svg>
          </span>
        )}
      </div>
    </div>
  );
};

ListSearch.propTypes = {
  setListSearchText: PropTypes.func.isRequired,
  listSearchText: PropTypes.string.isRequired,
};

export default ListSearch;
