import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import './FormComponents.scss';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    alignSelf: 'flex-end',
  },
  field: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    alignSelf: 'flex-end',
  },
  labelText: {
    display: 'flex',
    flexWrap: 'wrap',
    alignSelf: 'flex-end',
  },
  helperText: {
    margin: '10px 0 0',
    fontSize: '12px',
    lineHeight: 1.4,
  },
  marginDense: {
    marginBottom: 0,
  },
}));

const RadioButtonField = ({ placeholder, _handleChange, attributeKey, defaultValue, readOnly, labels=["True", "False"] }) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(defaultValue);
  const label1 = labels[0] || "True";
  const label2 = labels[1] || "False";

  const handleChange = event => {
    setValue(event.target.value);
    _handleChange(event.target.value, attributeKey);
  };
  return (
    <div className={classes.root}>
      <div className={classes.labelText}>
        <FormHelperText className={classes.helperText}>{placeholder}</FormHelperText>
      </div>
      <div className={classes.field}>
        <FormControl component="fieldset">
          <RadioGroup
            row
            aria-label={placeholder}
            name={placeholder}
            value={value}
            onChange={handleChange}
          >
            <FormControlLabel
              value={label1}
              classes={classes}
              disabled={readOnly}
              control={
                <Radio
                  color="primary"
                  icon={<RadioButtonUncheckedIcon fontSize="small" />}
                  checkedIcon={<RadioButtonCheckedIcon fontSize="small" />}
                />
              }
              label={label1}
            />
            <FormControlLabel
              value={label2}
              classes={classes}
              disabled={readOnly}
              control={
                <Radio
                  color="primary"
                  icon={<RadioButtonUncheckedIcon fontSize="small" />}
                  checkedIcon={<RadioButtonCheckedIcon fontSize="small" />}
                />
              }
              label={label2}
            />
          </RadioGroup>
        </FormControl>
      </div>
    </div>
  );
};

RadioButtonField.propTypes = {
  _handleChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  attributeKey: PropTypes.string.isRequired,
  defaultValue: PropTypes.string.isRequired,
  readOnly: PropTypes.bool.isRequired,
};

export default RadioButtonField;
