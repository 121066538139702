/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable no-use-before-define */
import React, { useState, useEffect, useRef } from 'react';
import useAutocomplete from '@material-ui/lab/useAutocomplete';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import NoSsr from '@material-ui/core/NoSsr';
import { useDebounce } from 'use-debounce';
import { Icon } from '../Icon';
import OptionsWrapper from '../ScrollPaginationDropdown/OptionsWrapper';
import './AutocompleteDropdown.scss';

const useStyles = makeStyles(theme => ({
  listbox: {
    width: '100% !important',
    margin: 0,
    padding: 0,
    zIndex: 1,
    paddingLeft: '20px',
    listStyle: 'none',
    maxHeight: 200,
    border: '1px solid rgba(0,0,0,.25)',
    backgroundColor: theme.palette.common.white,
    borderRadius: '4px',
    boxShadow: '2px 10px 13px 1px rgba(0,0,0,0.19)',
    color: `${getComputedStyle(document.body).getPropertyValue('--black-li')} !important`,
    '& .checked svg': {
      color: 'transparent',
    },
    '& li[data-focus="true"]': {
      backgroundColor: `${getComputedStyle(document.body).getPropertyValue(
        '--primary-bg-li'
      )} !important`,
      color: `${getComputedStyle(document.body).getPropertyValue('--black-li')} !important`,
      cursor: 'pointer',
    },

    '& li[aria-selected="true"]': {
      backgroundColor: '#fafafa',
      fontWeight: 600,

      '& .checked svg': {
        color: '#1890ff',
      },
    },
    '& li:active': {
      backgroundColor: `${getComputedStyle(document.body).getPropertyValue(
        '--primary-bg-li'
      )} !important`,
      color: `${getComputedStyle(document.body).getPropertyValue('--black-li')} !important`,
    },
    '& ul li': {
      fontSize: '13px',
      paddingLeft: '10px',
      paddingRight: '10px',
      paddingTop: '4px',
      display: 'flex',
      justifyContent: 'space-between',
    },
  },
}));

const ScrollPaginationDropdown = ({
  multiSelect,
  placeholder,
  fetchItems,
  items,
  totalCount,
  hasNext = false,
  getLabel = option => option.ID,
  getId = option => option.ID,
  offset,
  _handleChange,
  attributeKey,
  defaultValue,
  required,
  showError,
  readOnly,
  descriptionType,
  scrollToError,
  intl,
  name,
  supportsHtmlTags,
  ...props
}) => {
  const inputTextFieldRef = useRef(null);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [isNextPageLoading, setIsNextPageLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [searchValue, setValue] = useState(null);
  const [displayValue, setDisplayValue] = useState('');
  const [debouncedText] = useDebounce(searchValue, 500);

  const classes = useStyles();

  const loadNextPage = (...args) => {
    setIsNextPageLoading(true);
    fetchItems(page + offset, searchValue);
    setPage(page + offset);
    setHasNextPage(items.length < totalCount || hasNext); // It should total records which API is sending
    setIsNextPageLoading(false);
  };

  const fetchInitialItems = () => {
    setPage(0);
    setValue(debouncedText);
    fetchItems(0, debouncedText || '', true);
  };

  useEffect(() => {
    if (debouncedText || debouncedText === '') {
      fetchInitialItems();
    }
    return () => {};
  }, [debouncedText]);

  const handleFocus = () => {
    if ((!items || !items.length) && hasNextPage) {
      fetchInitialItems();
    }
  };

  useEffect(() => {
    if (scrollToError === 'true') {
      inputTextFieldRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [scrollToError]);

  useEffect(() => {
    if (!defaultValue) setDisplayValue('');
    else if (typeof defaultValue === 'string') setDisplayValue(defaultValue);
    else if (typeof defaultValue === 'object') setDisplayValue(getLabel(defaultValue));
    setValue('');
  }, [defaultValue]);

  const [prevURL, setPrevURL] = useState('');
  useEffect(() => {
    if (props.apiURL) {
      if (props.apiURL !== prevURL) {
        fetchInitialItems();
      }
      setPrevURL(props.apiURL);
    }
  }, [props.apiURL]);

  const searchChange = (e, val) => {
    if (e) {
      e.preventDefault();
      setDisplayValue('');
      setValue(e.target.value);
    }
  };

  const handleChange = (e, value) => {
    e.preventDefault();
    // setValue(value);
    _handleChange(value, attributeKey);
    if (!value) searchChange(e, value);
    // setDisplayValue(getLabel(value));
    // if (hideValue) setValue('');
  };

  const {
    getRootProps,
    getInputLabelProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    getTagProps,
    groupedOptions,
    value,
    focused,
    setAnchorEl,
  } = useAutocomplete({
    id: 'use-autocomplete-demo',
    options: items,
    multiple: multiSelect,
    clearOnBlur: false,
    clearOnEscape: false,
    getOptionLabel: getLabel,
    onInputChange: searchChange,
    // defaultValue: defaultValue || [],
    value: searchValue,
    onChange: handleChange,
    openOnFocus: true,
    onOpen: handleFocus,
  });

  const inputProps = getInputProps();
  const showMenu = groupedOptions.length > 0;

  return (
    <NoSsr>
      <div className="dropdown-container">
        <div {...getRootProps()}>
          <div ref={setAnchorEl} className={`input-wrapper ${focused ? 'focused' : ''}`}>
            <input
              {...inputProps}
              placeholder={placeholder}
              disabled={readOnly}
              value={searchValue || displayValue}
              onFocus={handleFocus}
            />
            <Icon
              name="arrowDownForInput"
              className={readOnly ? 'disabled' : ''}
              onMouseDown={readOnly ? null : inputProps.onMouseDown}
            />
          </div>
        </div>
        {showMenu ? (
          <OptionsWrapper
            hasNextPage={hasNextPage}
            isNextPageLoading={isNextPageLoading}
            items={items}
            loadNextPage={loadNextPage}
            wrapperClassName={classes.listbox}
            getOptionProps={getOptionProps}
            groupedOptions={groupedOptions}
            listboxProps={getListboxProps()}
            getLabel={getLabel}
            menuStyle={props.menuStyle}
          />
        ) : null}
      </div>
      {/* <div className={classes.root} ref={inputTextFieldRef}>
        <div className={classes.field}>
          <div className="pagination-scroll-dropdown">
            <div {...getRootProps()}>
              <TextField
                name="pagination-dropdown"
                autoComplete="off"
                margin="dense"
                className={classes.input}
                fullWidth
                label={supportsHtmlTags ? '' :
                  <>
                    <span dangerouslySetInnerHTML={{ __html: placeholder }} />
                    {required && <span className="mandatory">&nbsp;*</span>}
                  </>
                }
                error={required ? showError : false}
                {...getInputProps()}
              />
            </div>
            {groupedOptions.length > 0 ? (
              <>
                <OptionsWrapper
                  hasNextPage={hasNextPage}
                  isNextPageLoading={isNextPageLoading}
                  items={items}
                  loadNextPage={loadNextPage}
                  wrapperClassName={classes.listbox}
                  getOptionProps={getOptionProps}
                  groupedOptions={groupedOptions}
                  listboxProps={getListboxProps()}
                  getLabel={getLabel}
                  menuStyle={props.menuStyle}
                />
              </>
            ) : null}
          </div>
        </div>
      </div> */}
    </NoSsr>
  );
};

ScrollPaginationDropdown.propTypes = {
  multiSelect: PropTypes.bool.isRequired,
  placeholder: PropTypes.string.isRequired,
  fetchItems: PropTypes.func.isRequired,
  items: PropTypes.arrayOf({}).isRequired,
  _handleChange: PropTypes.func.isRequired,
  totalCount: PropTypes.number.isRequired,
  hasNext: PropTypes.bool.isRequired,
  getLabel: PropTypes.func.isRequired,
  getId: PropTypes.func.isRequired,
  offset: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  attributeKey: PropTypes.string.isRequired,
  defaultValue: PropTypes.string.isRequired,
  required: PropTypes.bool.isRequired,
  showError: PropTypes.bool.isRequired,
  readOnly: PropTypes.bool.isRequired,
  descriptionType: PropTypes.string.isRequired,
  supportsHtmlTags: PropTypes.bool,
};

export default ScrollPaginationDropdown;
