import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { RequestStatusTimeline } from 'ui-components/src';
import style from './CommentsView.module.scss';
import Button from '@material-ui/core/Button';
import FileUpload from '../FileUpload';

const styles = theme => ({
  root: {
    margin: 0,
    padding: 24,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});
const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      {children}
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: 24,
    paddingTop: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
    paddingRight: 20,
    minHeight: 63,
  },
}))(MuiDialogActions);

const CommentsView = ({ requestDetails: d, fileList, setFileList, setComments,
  prepareUpload, open, intl, handleClose, from }) => {
  const [invalidInput, setInvalidInput] = useState(false);
  return <Dialog
    classes={{ paperWidthSm: style.popupMoreComments, paperScrollPaper: style.popupMoreComments }}
    onClose={handleClose}
    aria-labelledby="customized-dialog-title"
    open={open}
  >
    <DialogTitle id="customized-dialog-title" onClose={handleClose}>
      <div className={style.commentsTitle}>{intl.formatMessage({ id: 'RequestHistory.AllComments' })}</div>
      <h6 className={style.commentsSubTitle}>
        {intl.formatMessage({ id: 'RequestHistory.Readall.comments' })}
      </h6>
    </DialogTitle>
    <DialogContent>
      <RequestStatusTimeline
        request={d}
        statusIndicator="comments"
        commentsRequired
        fileList={fileList}
        setFileList={setFileList}
        setComments={setComments}
        setInvalidInput={setInvalidInput}
      />

      {(from === 'approval' || d.status === 1)  && (
        <div className={`d-flex flex-column`}>
          <div className={style.fileseparator}></div>
          <div className="d-flex flex-column w-100">
            <FileUpload
              fileList={fileList}
              setFileList={setFileList}
              setComments={setComments}
              title={intl.formatMessage({ id: 'FileUpload.Title' })}
              setInvalidInput={setInvalidInput}
              placeholder={`${intl.formatMessage({
                id: 'FileUpload.Placeholder',
              })}`}
              fileInfo={d.attachmentConfiguration ?
                d.attachmentConfiguration : {}}
              attachmentsPresent={d.attachments ? d.attachments.length : 0}
              commentsDisabled={from !== 'approval'}
            />
          </div>
        </div>
      )}
    </DialogContent>
    <DialogActions className={style.commentsModalfooter}>
      <Button variant="outlined"
        color="primary" onClick={handleClose}>
        {intl.formatMessage({ id: 'RequestHistory.Cancel' })}
      </Button>
      {(from === 'approval' || d.status === 1)  && (
       <Button
        variant="outlined"
        color="primary"
        onClick={() => {
          if (!invalidInput) {
            prepareUpload();
          }
        }}
      >
        {intl.formatMessage({ id: 'RequestHistory.Save' })}
      </Button>)}
    </DialogActions>
  </Dialog>
};
CommentsView.propTypes = {
  maxLength: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
};
export default injectIntl(CommentsView);
