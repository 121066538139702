import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActionArea from '@material-ui/core/CardActionArea';
import { Button, Slide } from '@material-ui/core';
import { EllipsedLabel } from '../EllipsedLabel';
import { Icon } from '../Icon';
import './ApplicationCard.scss';

const useStyles = makeStyles(() => ({
  card: {
    width: 174,
    height: 194,
    margin: '0 0 18px 0',
    borderRadius: 6,
    cursor: 'pointer',
    boxShadow: '1px 1px 5px 0 rgba(205, 206, 217, 0.5)',
    '&:hover': {
      boxShadow:
        '0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 3px 5px -1px rgba(0, 0, 0, 0.2)',
    },
  },
  cardContent: {
    padding: '11.5px 17px 0',
    background: '#ffffff',
    position: 'relative',
    overflow: 'hidden',
    height: '100%',
    '&:last-child': {
      paddingBottom: 0,
    },
  },
}));

const ApplicationCard = ({ item, disabled, handleClick, hoverButtons }) => {
  const [imgError, setImgError] = useState(false);
  const [hovered, setHovered] = useState(false);
  const classes = useStyles();

  const HoveredApplications = (
    <Slide
      direction="up"
      in={hovered}
      timeout={{
        enter: 1000,
        exit: 1000,
      }}
    >
      <div className="hover-content">
        {hoverButtons &&
          hoverButtons.map(btn => (
            <Button
              fullWidth
              variant="outlined"
              color="primary"
              size="small"
              disableElevation
              className={classes.button}
              onClick={btn.handleClick}
            >
              {btn.name}
            </Button>
          ))}
      </div>
    </Slide>
  );
  return (
    <Card
      className={classes.card}
      variant="outlined"
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <CardActionArea onClick={handleClick}>
        <CardContent className={classes.cardContent}>
          {disabled && <div className="disable-tag">{item.PAM_ENABLED}</div>}
          <div className="item-body single-enpoint">
            <div className={`item-img ${imgError && 'img-error'}`}>
              <img
                className="w-100"
                name="default"
                src={`/ECMv6/assets/images/app_req_icons/${item.icon}.png`}
                alt={item.title}
                onError={() => setImgError(true)}
              />
              <p className="app-initial">{item.title ? item.title.slice(0, 1) : ''}</p>
            </div>
            <h6>
              {item.title}
              <span className="entilement-match d-none">
                <span className="icon-section">
                  <Icon name="entitlementIcon" className="search-entilement-icon" />
                </span>
              </span>
            </h6>
            <div className="item-footer">
              <p className="footer-content item-description">
                {item.description ? (
                  <EllipsedLabel label={item.description} maxLength={20} />
                ) : (
                  <EllipsedLabel label={item.title} maxLength={30} />
                )}
              </p>
            </div>
            {hoverButtons && HoveredApplications}
          </div>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

ApplicationCard.propTypes = {
  item: PropTypes.shape({
    title: PropTypes.string,
    icon: PropTypes.string,
    description: PropTypes.string,
    PAM_ENABLED: PropTypes.string,
  }).isRequired,
  hoverButtons: PropTypes.arrayOf({
    name: PropTypes.string,
    handleClick: PropTypes.func,
  }),
  disabled: PropTypes.bool,
  handleClick: PropTypes.func.isRequired,
};

ApplicationCard.defaultProps = {
  disabled: false,
  hoverButtons: null,
};

export default withRouter(ApplicationCard);
