import { ShowMessageActionType, ResetErrorActionType } from 'ui-common/src/utils/ResponseHandler-actions';

export const ResponseMessageHandlingReducer = (
  state = { responseMsg: { message: '', status: '' } },
  action
) => {
  switch (action.type) {
    case ShowMessageActionType: {
      return {
        ...state,
        responseMsg: action.payload,
      };
    }
    case ResetErrorActionType: {
      return {
        ...state,
        responseMsg: { status: '', message: '' },
      };
    }
    default: {
      return state;
    }
  }
};

export default ResponseMessageHandlingReducer;
