/* tslint:disable */
import React from 'react';
import PropTypes from 'prop-types';
import UploadView from './UploadView';
import UploadedView from './UploadedView';
import DownloadView from './DownloadView';

const FileUploadPanel = ({
  isUploaded,
  downloadPanelTitle,
  uploadPanelTitle,
  dragDropLabel,
  uploadNote,
  uploadErrorMsg,
  uploadBtnText,
  onUpload,
  className,
  uploadedLabel,
  onCloseUploadedPanel,
  uploadedFileName,
  previewImagePath,
  onDownload,
  downloadFileName,
}) => {
  return (
    <div className={className}>
      {isUploaded ? (
        <UploadedView
          uploadedLabel={uploadedLabel}
          uploadedFile={uploadedFileName}
          previewImage={previewImagePath}
          click={onCloseUploadedPanel}
        />
      ) : (
        <div>
          <DownloadView
            downloadLabel={downloadPanelTitle}
            downloadFile={downloadFileName}
            downloadClick={onDownload}
          />
          <hr /> <br />
          <UploadView
            uploadLabel={uploadPanelTitle}
            dragDropLabel={dragDropLabel}
            uploadSubmit={uploadBtnText}
            noteDescription={uploadNote}
            errorDescription={uploadErrorMsg}
            uploadedFiles={onUpload}
          />
        </div>
      )}
    </div>
  );
};

FileUploadPanel.propTypes = {
  isUploaded: PropTypes.string.isRequired,
  downloadPanelTitle: PropTypes.string.isRequired,
  uploadPanelTitle: PropTypes.string.isRequired,
  dragDropLabel: PropTypes.string.isRequired,
  uploadNote: PropTypes.string.isRequired,
  uploadErrorMsg: PropTypes.string.isRequired,
  uploadBtnText: PropTypes.string.isRequired,
  onUpload: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  uploadedLabel: PropTypes.string.isRequired,
  onCloseUploadedPanel: PropTypes.string.isRequired,
  uploadedFileName: PropTypes.string.isRequired,
  previewImagePath: PropTypes.string.isRequired,
  onDownload: PropTypes.string.isRequired,
  downloadFileName: PropTypes.string.isRequired,
};

export default FileUploadPanel;
