import React, { useState, useRef, useEffect } from 'react';
import { A } from '../../A';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { PropTypes, shape } from 'prop-types';
import { ColumnType } from 'ui-common/src/lib/commonPropTypes';
import { injectIntl } from 'react-intl';
import './UniqueFieldFilter.scss';

import Chip from '@material-ui/core/Chip';
import _ from 'lodash';
import PaginatedUniqueFieldFilter from './PaginatedUniqueFieldFilter';
import styles from '../GridBoot.module.scss';

const UniqueFieldFilter = ({
  column,
  tableId,
  showColumnFilter,
  setShowColumnFilter,
  sortedColumn,
  setSortedColumn,
  distinctColumnValues,
  checkedFilters,
  setCheckedFilters,
  appliedFilters,
  setAppliedFilters,
  intl,
  reqBodyOptions,
  isArchive,
  resultCount,
  columnPaginationEnabled,
}) => {
  const { key } = column;
  const filterOptions = createFilterOptions({
    limit: 200,
  });
  const [open, setOpen] = useState(false);
  const filterRef = useRef();
  const onOpen = e => {
    e.stopPropagation();
    setOpen(true);
  };

  const onClose = e => {
    e.stopPropagation();
    setOpen(false);
  };

  const hideFilterOnFocusOut = e => {
    setTimeout(() => {
      if (filterRef.current === e.target || filterRef.current.contains(document.activeElement)) {
        // still in filter popup.
      } else {
        setShowColumnFilter({ filterId: 'none' });
      }
    }, 100);
  };
  useEffect(() => {
    const { x: filterLeft, width: filterWidth } = filterRef.current.getBoundingClientRect();
    const fixedTable2Start = document.querySelector('.fixedTable2').getBoundingClientRect().x;
    const tabindex1ElDimentions = document
      .querySelector('.divTable .divTableHead .tabbable[tabindex="1"]')
      .getBoundingClientRect();
    const top = tabindex1ElDimentions.y + 30;
    filterRef.current.style.top = `${top}px`;
    if (filterLeft + filterWidth > fixedTable2Start) {
      filterRef.current.style.left = `${fixedTable2Start - filterWidth}px`;
    }
  }, []);

  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  const isFirefox = navigator.userAgent.includes('Firefox');

  const getOptionTitle = field => {
    if (!column.filter || !column.filter.intlSupportRequired) return field;
    if (field) return intl.formatMessage({ id: field });
    return field;
  };
  const defaultOptionFn = {
    formatOptions: data =>
      Object.keys(data).map(field => ({
        id: field,
        title: getOptionTitle(field),
      })),
    getId: op => op.id,
    getTitle: op => op.title,
    saveSelected: op => op.id,
    getSelectedId: op => op,
    getSelectedOption: op => ({
      id: op,
      title: getOptionTitle(op),
    }),
  };
  const { formatOptions, getId, getTitle, saveSelected, getSelectedId, getSelectedOption } = {
    ...defaultOptionFn,
    ...column.filter.optionFunctions,
  };

  const convertOptions = data => {
    if (!data) return [];
    const optionNotSelected = op => {
      if (!checkedFilters[key]) return true;
      const match = checked => getId(op) === getSelectedId(checked);
      return checkedFilters[key].findIndex(match) === -1;
    };
    return formatOptions(data).filter(optionNotSelected);
  };
  const getCheckedValue = checked => {
    if (!checked) return [];
    return checked.map(getSelectedOption);
  };

  return (
    <div
      tabIndex="1"
      ref={filterRef}
      className={`columnFilterBox ${key === showColumnFilter.filterId ? 'd-show' : 'd-none'} fixed
        ${(isSafari || isFirefox) && open ? 'columnFilterBoxSafariOpen' : ''}`}
      onBlur={hideFilterOnFocusOut}
    >
      <div className={column.sortDisabled ? 'd-none' : 'sorting-btn'}>
        <button
          type="submit"
          tabIndex="1"
          className={
            sortedColumn.columnName === key && sortedColumn.order === 'asc'
              ? 'sortingBtn active'
              : 'sortingBtn'
          }
          onClick={e => {
            setSortedColumn({
              columnName: key,
              order: 'asc',
            });
            e.stopPropagation();
          }}
        >
          {/* {intl.formatMessage({ id: 'DateFilter.A-Z' })} */}
          {column.sortButtonLatest
            ? intl.formatMessage({ id: 'Sorting.Oldest' })
            : intl.formatMessage({ id: 'DateFilter.A-Z' })}
        </button>
        <button
          type="submit"
          tabIndex="1"
          className={
            sortedColumn.columnName === key && sortedColumn.order === 'desc'
              ? 'sortingBtn  active'
              : 'sortingBtn '
          }
          onClick={e => {
            setSortedColumn({
              columnName: column.key,
              order: 'desc',
            });
            e.stopPropagation();
          }}
        >
          {/* {intl.formatMessage({ id: 'DateFilter.Z-A' })} */}
          {column.sortButtonLatest
            ? intl.formatMessage({ id: 'Sorting.Latest' })
            : intl.formatMessage({ id: 'DateFilter.Z-A' })}
        </button>
      </div>
      {showColumnFilter.isLoading ? (
        <div className="d-flex justify-center">
          <img src="/ECMv6/assets/images/Double Ring-1s-80px.gif" />
        </div>
      ) : columnPaginationEnabled ? (
        <PaginatedUniqueFieldFilter
          isArchive={isArchive}
          reqBodyOptions={reqBodyOptions}
          column={column}
          options={convertOptions(distinctColumnValues[key])}
          value={getCheckedValue(checkedFilters[key])}
          onChange={selections => {
            const temp = {
              ...checkedFilters,
              [key]: _.uniq(selections),
            };
            setCheckedFilters(temp);
          }}
          getOptionLabel={getTitle}
          resultCount={resultCount}
          appliedFilters={appliedFilters}
        />
      ) : (
        <form action="">
          {isSafari || isFirefox ? (
            <div className="filterWidth">
              <Autocomplete
                noOptionsText={intl.formatMessage({ id: 'ColumnFilter.NoOptionsText' })}
                multiple
                disablePortal
                onOpen={onOpen}
                onClose={onClose}
                id={column.viewKey}
                filterOptions={filterOptions}
                options={convertOptions(distinctColumnValues[key])}
                value={getCheckedValue(checkedFilters[key])}
                onChange={(e, selections) => {
                  setCheckedFilters({
                    ...checkedFilters,
                    [key]: selections.map(saveSelected),
                  });
                }}
                closeIcon={
                  <div
                    onClick={e => {
                      e.preventDefault();
                    }}
                  >
                    x
                  </div>
                }
                renderTags={(tagValue, getTagProps) => {
                  return tagValue.map((option, index) => {
                    const tagProps = getTagProps(index);
                    tagProps.onDelete = undefined;
                    return (
                      <Chip
                        {...tagProps}
                        onClick={e => {
                          e.preventDefault();
                        }}
                        label={getTitle(option)}
                      />
                    );
                  });
                }}
                getOptionLabel={getTitle}
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="standard"
                    placeholder={`Select ${column.label}'s`}
                    fullWidth
                  />
                )}
              />
            </div>
          ) : (
            <div className="filterWidth">
              <Autocomplete
                noOptionsText={intl.formatMessage({ id: 'ColumnFilter.NoOptionsText' })}
                multiple
                id={column.viewKey}
                filterOptions={filterOptions}
                options={convertOptions(distinctColumnValues[key])}
                value={getCheckedValue(checkedFilters[key])}
                onChange={(e, selections) => {
                  setCheckedFilters({
                    ...checkedFilters,
                    [key]: selections.map(saveSelected),
                  });
                }}
                getOptionLabel={getTitle}
                renderInput={params => (
                  <TextField
                    tabIndex="1"
                    {...params}
                    variant="standard"
                    placeholder={`Select ${column.label}'s`}
                    fullWidth
                  />
                )}
              />
            </div>
          )}
        </form>
      )}
      <div className={`${'action '}${styles.actionmargin}`}>
        <A
          href="#"
          className="btn-reset"
          onClick={e => {
            e.preventDefault();
            setAppliedFilters({ ...checkedFilters, [key]: [] });
            setCheckedFilters({ ...checkedFilters, [key]: [] });
            if (column.key === sortedColumn.columnName) {
              setSortedColumn({ order: 'asc' });
            }
            setShowColumnFilter({ filterId: 'none' });
          }}
          tabIndex="1"
        >
          {intl.formatMessage({ id: 'DateFilter.Reset' })}
        </A>
        <button
          tabIndex="1"
          type="button"
          className={
            checkedFilters[key] && checkedFilters[key].length > 0
              ? ` ${styles.applymargin}`
              : ` ${styles.applymargin}`
          }
          onClick={e => {
            e.preventDefault();
            setAppliedFilters({
              ...appliedFilters,
              [column.key]: checkedFilters[column.key],
            });
            setShowColumnFilter({ filterId: 'none' });
          }}
        >
          {intl.formatMessage({ id: 'DateFilter.Apply' })}
        </button>
      </div>
    </div>
  );
};

UniqueFieldFilter.propTypes = {
  column: ColumnType.isRequired,
  showColumnFilter: PropTypes.func.isRequired,
  setShowColumnFilter: PropTypes.func.isRequired,
  sortedColumn: PropTypes.shape({
    columnName: PropTypes.string,
    order: PropTypes.string,
  }).isRequired,
  setSortedColumn: PropTypes.func.isRequired,
  distinctColumnValues: PropTypes.shape({
    key: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  checkedFilters: PropTypes.shape({
    key: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  setCheckedFilters: PropTypes.func.isRequired,
  appliedFilters: PropTypes.shape({
    key: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  setAppliedFilters: PropTypes.func.isRequired,
};
UniqueFieldFilter.defaultProps = {
  // column: DefaultColumnType,
};

const UniqueFieldFilterIntl = injectIntl(UniqueFieldFilter);

export { UniqueFieldFilterIntl as UniqueFieldFilter };

export default null;
