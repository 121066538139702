import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import NumberFormat from 'react-number-format';
import { useDebouncedCallback } from 'use-debounce';
import './FormComponents.scss';
import { validateRegex } from 'ui-common/src/lib/utils';
import { injectIntl } from 'react-intl';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    alignContent: 'flex-end',
  },
  field: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    alignSelf: 'flex-end',
  },
  label: {
    display: 'flex',
    flexWrap: 'wrap',
    alignSelf: 'flex-end',
  },
  helperText: {
    margin: '10px 0 0',
    fontSize: '12px',
    lineHeight: 1.4,
  },
  marginDense: {
    marginBottom: 0,
  },
}));

function NumberFormatCustom(props) {
  const { inputRef, onChange, defaultValue, ...other } = props;

  return (
    <NumberFormat
      {...other}
      defaultValue={typeof defaultValue !== 'object' ? defaultValue : ''}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

const NumberTextField = ({
  name,
  placeholder,
  _handleChange,
  attributeKey,
  defaultValue,
  required,
  showError,
  readOnly,
  regRex,
  intl,
  scrollToError,
  supportsHtmlTags,
}) => {
  const classes = useStyles();
  const [invalidRegex, setInvalidRegex] = useState(false);
  const inputTextFieldRef = useRef(null);
  useEffect(() => {
    if (scrollToError === "true") {
      inputTextFieldRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [scrollToError]);
  const [debouncedFunction] = useDebouncedCallback(value => {

    if (!validateRegex(regRex, value, false))
      setInvalidRegex(true);
    else
      setInvalidRegex(false);

    if (value) {
      _handleChange(value, attributeKey);
    } else {
      _handleChange('', attributeKey);
    }
  }, 500);

  return (
    <div className={classes.root} ref={inputTextFieldRef}>
      <div className={classes.field}>
        {supportsHtmlTags && <div className="support-html">
          <span dangerouslySetInnerHTML={{ __html: placeholder }} />
          {required && <span className="mandatory">&nbsp;*</span>}
        </div>}
        <TextField
          id={name}
          className={classes.marginDense}
          error={required ? showError ? showError : invalidRegex : invalidRegex ? true : false}
          key={defaultValue}
          name={name}
          autoComplete="off"
          fullWidth
          margin="dense"
          defaultValue={defaultValue}
          disabled={readOnly}
          // onChange={handleChange('numberformat')}
          onChange={e => debouncedFunction(e.target.value)}
          InputProps={{
            inputComponent: NumberFormatCustom,
          }}
          label={supportsHtmlTags ? '' :
            <>
              <span dangerouslySetInnerHTML={{ __html: placeholder }} />
              {required && <span className="mandatory">&nbsp;*</span>}
            </>
          }
          helperText={invalidRegex ? `${regRex ? `${intl.formatMessage({
            id: 'DynamicAttribute.RegexValidationError',
          })} '${regRex}'` : `${intl.formatMessage({
            id: 'DynamicAttribute.HtmlValidationError',
          })}`}` : ''}
        />
      </div>
    </div>
  );
};

NumberTextField.defaultProps = {
  supportsHtmlTags: false,
};

NumberTextField.propTypes = {
  _handleChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  attributeKey: PropTypes.string.isRequired,
  defaultValue: PropTypes.string.isRequired,
  required: PropTypes.bool.isRequired,
  showError: PropTypes.bool.isRequired,
  readOnly: PropTypes.bool.isRequired,
  supportsHtmlTags: PropTypes.bool,
};

export default injectIntl(NumberTextField);
