import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { TextField, Checkbox } from '@material-ui/core';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";


const MultiSelectWithSelectAll = ({ options,
  itemkey,
  setSelectedValues,
  selectAllLabel,
  selectedValues,
  defaultValues,
  intl }) => {
  const [selectedOptions, setSelectedOptions] = useState(defaultValues);
  const getOptionLabel = option => `${option.label}`;

  const handleToggleOption = selectedOptions => {
    setSelectedOptions(selectedOptions);
    setSelectedValues({ ...selectedValues, [itemkey]: selectedOptions.map(item => item.value) });
  }
  const handleClearOptions = () => {
    setSelectedOptions([]);
    setSelectedValues({ ...selectedValues, [itemkey]: [] });
  }
  const handleSelectAll = isSelected => {
    if (isSelected) {
      setSelectedOptions(options);
      setSelectedValues({ ...selectedValues, [itemkey]: options.map(item => item.value) });

    } else {
      handleClearOptions();
    }
  };
  const allSelected = options.length === selectedOptions.length;
  const handleToggleSelectAll = () => {
    handleSelectAll && handleSelectAll(!allSelected);
  };
  const handleChange = (event, selectedOptions, reason) => {
    if (reason === "select-option" || reason === "remove-option") {
      if (selectedOptions.find(option => option.value === "select-all")) {
        handleToggleSelectAll();
      } else {
        handleToggleOption && handleToggleOption(selectedOptions);
      }
    } else if (reason === "clear") {
      handleClearOptions && handleClearOptions();
    }
  };
  const optionRenderer = (option, { selected }) => {
    const selectAllProps =
      option.value === "select-all" // To control the state of 'select-all' checkbox
        ? { checked: allSelected }
        : {};
    return (
      <>
        <Checkbox
          color="primary"
          icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
          checkedIcon={<CheckBoxIcon fontSize="small" />}
          style={{ marginRight: 8 }}
          checked={selected}
          {...selectAllProps}
        />
        {getOptionLabel(option)}
      </>
    );
  };

  const inputRenderer = params => (
    <TextField {...params} variant="outlined" />
  );
  const getOptionSelected = (option, anotherOption) =>
    option.value === anotherOption.value;
  const filter = createFilterOptions();

  return (
    <Autocomplete
      multiple
      size="small"
      variant={'outlined'}
      options={options}
      value={selectedOptions}
      defaultValue={selectedOptions}
      disableCloseOnSelect
      getOptionLabel={getOptionLabel}
      getOptionSelected={getOptionSelected}
      noOptionsText={intl.formatMessage({ id: 'ColumnFilter.NoOptionsText' })}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        return [{ label: selectAllLabel, value: "select-all" }, ...filtered];
      }}
      onChange={handleChange}
      renderOption={optionRenderer}
      renderInput={inputRenderer}
    />
  );
};

MultiSelectWithSelectAll.propTypes = {
  intl: PropTypes.shape({
    locale: PropTypes.string.isRequired,
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
};

export default injectIntl(MultiSelectWithSelectAll);
