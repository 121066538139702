import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import './FormComponents.scss';

const useStyles = makeStyles(theme => ({
  chip: {
    margin: theme.spacing(0.5),
  },
}));

const InputTagField = ({ name, placeholder, multiLine, _handleChange, indexKey, defaultValue }) => {
  const classes = useStyles();

  const [inputList, setinputList] = useState(defaultValue || []);

  const textInput = useRef(null);

  const onAddNewInformationValue = value => {
    const info = [...inputList, value];
    setinputList(info);
    textInput.current.value = '';
    _handleChange(info, indexKey);
  };

  const onRemoveInformationValue = index => {
    const newinputList = [...inputList];
    newinputList.splice(index, 1);
    setinputList(newinputList);
    _handleChange(newinputList, indexKey);
  };

  const handleChange = event => {
    if (event.key === 'Enter') {
      onAddNewInformationValue(event.target.value);
    }
  };

  return (
    <div>
      <TextField
        id={name}
        label={placeholder}
        type="text"
        name={name}
        inputRef={textInput}
        margin="dense"
        multiline={multiLine}
        variant="outlined"
        fullWidth
        onKeyDown={handleChange}
      />
      <div>
        {inputList &&
          inputList.map((infoValue, index) => {
            return (
              <Chip
                key={infoValue}
                label={infoValue}
                className={classes.chip}
                onDelete={() => {
                  onRemoveInformationValue(index);
                }}
              />
            );
          })}
      </div>
    </div>
  );
};

InputTagField.propTypes = {
  placeholder: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  multiLine: PropTypes.bool.isRequired,
  _handleChange: PropTypes.func.isRequired,
  indexKey: PropTypes.string.isRequired,
};

export default InputTagField;
