import React from 'react';
import './role_summary_tab.scss';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import moment from 'moment';


const RoleSummaryTab = ({ intl, roledata, selectedRoleItem }) => {
  const cpkeys = Object.keys(roledata).filter(item =>
    item.toLowerCase().includes('customproperty')
  );
  let desc = '';
  if (selectedRoleItem.description) desc = selectedRoleItem.description;
  if (roledata.description) desc = roledata.description;
  let updatedDate = '';
  if (roledata.updatedate) {
    updatedDate = moment(roledata.updatedate).format('YYYY-MM-DD HH:mm:ss')
  }

  return (
    <div className="rolesummary-tab-container">
      {Object.keys(roledata).length > 0 ?
        (<div className="tab-body">
          <div className="basics-container">
            <h6 className="basics-label">{intl.formatMessage({ id: 'Basics' })}</h6>
            <div className="d-flex body-content">
              <div className="role-name col-4">
                <span className="label-key">{intl.formatMessage({ id: 'FullName' })}</span>
                <span className="label-value mt-1">{roledata.rolename}</span>
              </div>
              <div className="d-flex flex-column pl-3 col-8">
                <span className="label-key description">
                  {intl.formatMessage({ id: 'Description' })}
                </span>
                <span className="label-value mt-1">{desc}</span>
              </div>
            </div>

            <div className="d-flex body-content">
              <div className="role-name col-4">
                <span className="label-key">{intl.formatMessage({ id: 'RoleType' })}</span>
                <span className="label-value mt-1">{roledata.roletype}</span>
              </div>
              <div className="d-flex flex-column  pl-3 col-8">
                <span className="label-key">{intl.formatMessage({ id: 'Status' })}</span>
                <span className="label-value mt-1">{roledata.status}</span>
              </div>
            </div>

            <div className="d-flex body-content">
              <div className="role-name col-4">
                <span className="label-key">{intl.formatMessage({ id: 'UpdateUser' })}</span>
                <span className="label-value mt-1">{roledata.updateuser}</span>
              </div>
              <div className="d-flex flex-column pl-3 col-8">
                <span className="label-key">{intl.formatMessage({ id: 'UpdateDate' })}</span>
                <span className="label-value mt-1">
                  {updatedDate}
                </span>
              </div>
            </div>
          </div>
          <div className="basics-container basics-risk">
            <h6 className="basics-label">{intl.formatMessage({ id: 'RiskLevel' })}</h6>
            <div className="d-flex body-content">
              <div className="role-name col-4">
                <span className="label-key">{intl.formatMessage({ id: 'Risk' })}</span>
                <span
                  className={`label-value mt-1 ${roledata.risk ? roledata.risk.toLowerCase() : ''}`}
                >
                  {roledata.risk}
                </span>
              </div>
              <div className="d-flex flex-column pl-3 col-8">
                <span className="label-key ">{intl.formatMessage({ id: 'SoxCritical' })}</span>
                <span
                  className={`label-value mt-1 ${roledata.soxcritical ? roledata.soxcritical.toLowerCase() : ''
                    }`}
                >
                  {roledata.soxcritical}
                </span>
              </div>
            </div>

            <div className="d-flex body-content justify-content-between syscritical-padding">

              <div className="d-flex flex-column">
                <span className="label-key">{intl.formatMessage({ id: 'SysCritical' })}</span>
                <span
                  className={`label-value mt-1 ${roledata.syscritical ? roledata.syscritical.toLowerCase() : ''
                    }`}
                >
                  {roledata.syscritical}
                </span>
              </div>
              <div className="d-flex flex-column">
                <span className="label-key">{intl.formatMessage({ id: 'Privileged' })}</span>
                <span
                  className={`label-value mt-1 ${roledata.privileged ? roledata.privileged.toLowerCase() : ''
                    }`}
                >
                  {roledata.privileged}
                </span>
              </div>
              <div className="d-flex flex-column">
                <span className="label-key">{intl.formatMessage({ id: 'Confidentiality' })}</span>
                <span
                  className={`label-value mt-1 ${roledata.confidentiality ? roledata.confidentiality.toLowerCase() : ''
                    }`}
                >
                  {roledata.confidentiality}
                </span>
              </div>
            </div>
          </div>
          {cpkeys.length > 0 ? (
            <div className="basics-container">
              <h6 className="basics-label">{intl.formatMessage({ id: 'CustomProperty' })}</h6>
              <div className="d-flex body-content mt-4">
                {cpkeys.map(item => {
                  return (
                    <div className="d-flex flex-column col-6 pl-0">
                      <span className="label-key">{item.toUpperCase()}</span>
                      <span className="label-value mt-1">{roledata[item]}</span>
                    </div>
                  );
                })}
              </div>
            </div>
          ) : null}
        </div>) :
        (<div className="rolesFound">{intl.formatMessage({ id: 'RoleRequestDetails.EmptySummary.label' })}
        </div>)
      }
    </div>
  );
};
RoleSummaryTab.defaultProps = {
  selectedRoleItem: null,
};
RoleSummaryTab.propTypes = {
  roledata: PropTypes.shape({
    confidentiality: PropTypes.string,
    privileged: PropTypes.string,
    syscritical: PropTypes.string,
    soxcritical: PropTypes.string,
    risk: PropTypes.string,
    rolename: PropTypes.string,
    roletype: PropTypes.string,
    status: PropTypes.string,
    updatedate: PropTypes.string,
    updateuser: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
  intl: PropTypes.arrayOf(
    PropTypes.shape({
      locale: PropTypes.string.isRequired,
      formatMessage: PropTypes.func.isRequired,
    })
  ).isRequired,
  selectedRoleItem: PropTypes.shape({
    description: PropTypes.string,
  }),
};

export default injectIntl(RoleSummaryTab);
