import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { useDebouncedCallback } from 'use-debounce';
import FormHelperText from '@material-ui/core/FormHelperText';
import CircularProgress from '@material-ui/core/CircularProgress';
import './FormComponents.scss';
import { validateRegex } from 'ui-common/src/lib/utils';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    alignContent: 'flex-end',
    position: 'relative',
  },
  field: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    alignSelf: 'flex-end',
  },
  label: {
    display: 'flex',
    flexWrap: 'wrap',
    alignSelf: 'flex-end',
  },
  helperText: {
    fontSize: '12px',
    lineHeight: 1.4,
    position: 'absolute',
    bottom: '-18px',
    right: '0',
    zIndex: '99999',
    margin: '0',
  },
  marginDense: {
    marginBottom: 0,
  },
}));

const PasswordTextField = ({
  placeholder,
  required,
  _handleChange,
  defaultValue,
  attributeKey,
  showError,
  readOnly,
  generatePassword,
  intl,
  showGeneratePassword,
  loading,
  regRex,
  passwordAttributeKey,
  scrollToError,
  supportsHtmlTags,
}) => {
  const classes = useStyles();
  const [values, setValues] = React.useState({
    password: defaultValue,
    showPassword: false,
  });
  const inputTextFieldRef = useRef(null);
  const [invalidRegex, setInvalidRegex] = useState(false);
  useEffect(() => {
    setValues({ password: defaultValue });
  }, [defaultValue]);
  useEffect(() => {
    if (scrollToError === 'true') {
      inputTextFieldRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [scrollToError]);
  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const [debouncedFunction] = useDebouncedCallback(value => {
    if (value) {
      _handleChange(value, attributeKey);
      setValues({ ...values, password: value });

      if (!validateRegex(regRex, value, true)) setInvalidRegex(true);
      else setInvalidRegex(false);
    } else {
      _handleChange('', attributeKey);
      setValues({ ...values, password: '' });
    }
  }, 500);

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  const handleGeneratePassword = () => {
    generatePassword(attributeKey);
  };

  return (
    <div className={classes.root} ref={inputTextFieldRef}>
      <div className={classes.field}>
        {supportsHtmlTags && <div className="support-html">
          <span dangerouslySetInnerHTML={{ __html: placeholder }} />
          {required && <span className="mandatory">&nbsp;*</span>}
        </div>}
        <form autoComplete="off" className={classes.field}>
          <TextField
            id="outlined-adornment-password"
            type={values.showPassword ? 'text' : 'password'}
            className={classes.marginDense}
            key={values.password}
            margin="dense"
            defaultValue={values.password}
            fullWidth
            helperText={
              invalidRegex
                ? `${
                regRex
                  ? `${intl.formatMessage({
                    id: 'DynamicAttribute.RegexValidationError',
                  })} '${regRex}'`
                  : `${intl.formatMessage({
                    id: 'DynamicAttribute.HtmlValidationError',
                  })}`
                }`
                : ''
            }
            disabled={readOnly}
            onChange={e => debouncedFunction(e.target.value)}
            error={required ? showError || invalidRegex : !!invalidRegex}
            label={supportsHtmlTags ? '' :
              <React.Fragment>
                <span dangerouslySetInnerHTML={{ __html: placeholder }} />
                {required && <span className="mandatory">&nbsp;*</span>}
              </React.Fragment>
            }
            InputProps={{
              autocomplete: 'new-password',
              endAdornment: (
                <React.Fragment>
                  {loading && passwordAttributeKey === attributeKey ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : (
                      <InputAdornment position="end">
                        <IconButton
                          edge="end"
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {values.showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    )}
                </React.Fragment>
              ),
            }}
          />
        </form>
      </div>
      {showGeneratePassword && (
        <FormHelperText className={classes.helperText} onClick={handleGeneratePassword}>
          <span className="autogenerate-passowrd">
            {`${intl.formatMessage({
              id: 'AutogeneratePasswordMessage',
            })}`}
          </span>
        </FormHelperText>
      )}
    </div>
  );
};

PasswordTextField.defaultProps = {
  supportsHtmlTags: false,
};
PasswordTextField.propTypes = {
  _handleChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  required: PropTypes.bool.isRequired,
  attributeKey: PropTypes.string.isRequired,
  showError: PropTypes.bool.isRequired,
  readOnly: PropTypes.bool.isRequired,
  defaultValue: PropTypes.string.isRequired,
  generatePassword: PropTypes.func.isRequired,
  showGeneratePassword: PropTypes.bool.isRequired,
  intl: PropTypes.shape({
    locale: PropTypes.string.isRequired,
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
  passwordAttributeKey: PropTypes.string.isRequired,
  supportsHtmlTags: PropTypes.bool,
};

export default injectIntl(PasswordTextField);
