import React from 'react';
import PropTypes from 'prop-types';
import { IconButton, Menu, MenuItem } from '@material-ui/core';
import MoreIcon from '@material-ui/icons/MoreVert';
import { makeStyles } from '@material-ui/core/styles';
import { AutoComplete } from 'antd';

const useStyles = makeStyles(theme => ({
  iconRoot: {
    '&:hover, &:focus': {
      backgroundColor: 'transparent',
      '& .icon': {
        color: props => (props.listMode ? theme.palette.primary.main : 'white'),
        opacity: 1,
      },
    },
    '& .icon': {
      color: props => (props.listMode ? '#b4b4b4' : 'white'),
      opacity: props => (props.listMode ? 1 : 0.4),
    },
  },
  gridPosition: {
    float: 'right',
    margin: '-.5rem -1rem 0 0',
  },
  listPosition: {
    padding: 0,
    margin: '0 1rem',
  },
  menuPaper: {
    width: 'auto',
    padding: '4px 0',
    borderRadius: 3,
  },
  button: {
    fontWeight: 500,
    lineHeight: 1.5,
    tabIndex: 0,
    color: 'black',
    padding: '0 24px 0 12px',
    height: '2.5rem',
    '&:hover, &:active': {
      // backgroundColor: theme.palette.text.disabled,
      backgroundColor: '#ececec',
    },
  },
  lightButton: {
    fontWeight: 'normal',
    lineHeight: 2.17,
    color: '#414141',
    height: 24,
    '&:hover, &:active': {
      backgroundColor: 'rgba(55, 81, 255, 0.05)',
    },
  },
}));

const IconMenu = ({ actionMenu, gridMode, listMode, lightTheme }) => {
  const classes = useStyles({ listMode });
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const menuRef = React.createRef();

  const handleClick = e => {
    setAnchorEl(e.currentTarget);
    menuRef.current.focus();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        disableRipple
        onClick={handleClick}
        className={[
          classes.iconRoot,
          gridMode ? classes.gridPosition : null,
          listMode ? classes.listPosition : null,
        ].join(' ')}
      >
        <MoreIcon className="icon" />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        keepMounted
        open={open}
        onClose={handleClose}
        MenuListProps={{
          onMouseLeave: handleClose,
          disablePadding: true,
        }}
        classes={{
          paper: classes.menuPaper,
        }}
        ref={menuRef}
      >
        {actionMenu.map(item => (
          <MenuItem
            onClose={handleClose}
            onClick={() => {
              item.handleClick();
              handleClose();
            }}
            className={`${classes.button} ${lightTheme && classes.lightButton}`}
          >
            {item.title}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

IconMenu.propTypes = {
  actionMenu: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      handleClick: PropTypes.func.isRequired,
    })
  ).isRequired,
  gridMode: PropTypes.bool,
  listMode: PropTypes.bool,
  lightTheme: PropTypes.bool,
};

IconMenu.defaultProps = {
  gridMode: false,
  listMode: false,
  lightTheme: false,
};

export default IconMenu;
