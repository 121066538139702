import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(theme => ({
  close: {
    padding: theme.spacing(0.5),
  },
}));

const SnackbarComponent = ({
  open,
  message,
  onSnackBarClose,
  verticalPosition,
  horizontalPosition,
  children,
  ...props
}) => {
  const classes = useStyles();

  return (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: verticalPosition,
          horizontal: horizontalPosition,
        }}
        open={open}
        autoHideDuration={5000}
        onClose={onSnackBarClose}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        message={<span id="message-id">{message}</span>}
        {...props}
        action={[
          <IconButton
            key="close"
            aria-label="close"
            color="inherit"
            className={classes.close}
            onClick={onSnackBarClose}
          >
            <CloseIcon />
          </IconButton>,
        ]}
      >
        {children}
      </Snackbar>
    </div>
  );
};

SnackbarComponent.propTypes = {
  open: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  onSnackBarClose: PropTypes.func.isRequired,
  verticalPosition: PropTypes.string.isRequired,
  horizontalPosition: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default SnackbarComponent;
