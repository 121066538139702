import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import useOnClickOutside from 'use-onclickoutside';
import { Cookies } from 'react-cookie';
import { Icon, ModuleLink } from 'ui-components/src';
import { checkEnvironment } from 'ui-common/src/lib/utils';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import styles from './AppSwitcher.module.scss';

// TODO use router Link instead of <a> for SPA routes.

const AppSwitcher = props => {
  const cookies = new Cookies();
  if (checkEnvironment().DEMO_MODE === 'PROD' || process.env.REACT_APP_MODE === 'ECM') {
    require('./AppSwitcherCommon.css');
  } else {
    require('./AppSwitcher.css');
  }
  const advancedFilterRef = useRef(null);

  const [menuItems, setmenuItems] = useState([]);

  useEffect(() => {
    setmenuItems(props.appSwitcher);
  }, []);

  useOnClickOutside(advancedFilterRef, () => {
    props.setAppSwitcherDropdown(false);
  });

  return (
    <div
      ref={advancedFilterRef}
      className="app-swithcer-menu"
      data-toggle="tooltip"
      data-placement="app-swithcer-menu"
    >
      <div className="row popup-switchermenu pl-3">
        {menuItems &&
          menuItems.map(subItem => (
            <ModuleLink
              className="link"
              url={subItem.link}
              id={subItem.id}
              setAppName={props.setAppName}
              onClick={() => {
                // sessionStorage.setItem('micro_app', subItem.id);
                props.setAppName(subItem.id);
                props.setAppSwitcherDropdown(false);
              }}
              type="appSwitcher"
            >
              <div className={`apps-menu  ${styles.appsItemHover}`}>
                {!subItem.useCustomIcon ? (
                  <Icon name={subItem.appIcon} className="nav-icon icon" />
                ) : (
                  <i className="la la-user" style={{ fontSize: '32px' }} />
                )}
                <div className="icon app-label">
                  {subItem.id
                    ? props.intl.formatMessage({
                        id: subItem.id,
                      })
                    : ''}
                </div>
              </div>
            </ModuleLink>
          ))}
      </div>
    </div>
  );
};

AppSwitcher.propTypes = {
  setAppSwitcherDropdown: PropTypes.func.isRequired,
  setAppName: PropTypes.func.isRequired,
  appSwitcher: PropTypes.arrayOf.isRequired,
};

export default injectIntl(AppSwitcher);
