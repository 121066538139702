import React from 'react';
import PropTypes from 'prop-types';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    width: '1.3em',
    height: '1.3em',
    color: '#b4b4b4',
  },
  smallIcon: {
    width: '1em',
    height: '1em',
    color: '#b4b4b4',
  },
}));

const MoreIcon = ({ small }) => {
  const classes = useStyles();
  return (
    <div className="icon-section">
      <MoreVertIcon className={small ? classes.smallIcon : classes.root} />
    </div>
  );
};

MoreIcon.propTypes = {
  small: PropTypes.bool,
};

MoreIcon.defaultProps = {
  small: false,
};

export default MoreIcon;
