import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { withStyles } from '@material-ui/core/styles';
import { CloseButton } from 'ui-components/src';

const FullHeightDialog = withStyles({
  paper: {
    height: '100vh',
    width: '100%',
    maxHeight: 'none',
    borderRadius: 0,
    padding: '30px 24px',
  },
})(Dialog);

const DialogTitle = withStyles({
  root: {
    margin: 0,
    padding: 0,
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
  },
})(props => {
  const { classes, onClose, children } = props;
  return (
    <MuiDialogTitle {...props} disableTypography className={classes.root}>
      <div>{children}</div>
      {onClose && <CloseButton handleClose={onClose} />}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles({
  root: {
    padding: 0,
  },
})(MuiDialogContent);

const DialogActions = withStyles({
  root: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    margin: 0,
    padding: '16px 24px',
    borderTop: '1px solid rgba(225, 226, 229, 0.5)',
    boxShadow: '0 -2px 4px 0 rgba(225, 226, 229, 0.5)',
    backgroundColor: '#ffffff',
    fontSize: 12,
    fontWeight: 500,
    color: '#b4b4b4',
  },
})(MuiDialogActions);

export { FullHeightDialog, DialogTitle, DialogContent, DialogActions };
