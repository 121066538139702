import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
// eslint-disable-next-line import/extensions
import PropTypes from 'prop-types';
import { A } from '../../A';
import styles from '../GridBoot.module.scss';
import { DateFilterWidget } from './DateFilterWidget';
import { injectIntl } from 'react-intl';
import './UniqueFieldFilter.scss';



const DateFilter = ({
  column,
  tableId,
  showColumnFilter,
  setShowColumnFilter,
  appliedFilters = {},
  setAppliedFilters,
  setAntdComponentOpen,
  antdComponentOpen,
  setSortedColumn,
  sortedColumn,
  intl,
}) => {
  const filterRef = useRef()
  const { key } = column;
  const [selectedDate, setSelectedDate] = useState({
    // mode: "within",
    // value: "2019-04-08 00:00:00/2019-04-09 23:59:00" //range format '2019-04-08 00:00:00/2019-04-09 23:59:00' , date format '2019-04-08 00:00:00'
  });
  const isCalendarOpen = false;
  useEffect(() => {
    //position filter properly
    adjustFilterPosition();
    
    const dates = appliedFilters[column.key] ? appliedFilters[column.key].split('/') : [];
    let value;
    let mode;
    if (dates.length > 1) {
      if (dates[0] && dates[1]) {
        value = [moment(dates[0], 'YYYY-MM-DD HH:mm:ss'), moment(dates[1], 'YYYY-MM-DD HH:mm:ss')];
        mode = 'within';
      }
      if (dates[0] && !dates[1]) {
        value = moment(dates[0], 'YYYY-MM-DD HH:mm:ss');
        mode = 'after';
      }
      if (!dates[0] && dates[1]) {
        value = moment(dates[1], 'YYYY-MM-DD HH:mm:ss');
        mode = 'before';
      }
    }
    if (dates.length === 1) {
      value = moment(dates[0], 'YYYY-MM-DD HH:mm:ss');
      mode = 'after';
    }
    if (dates.length === 0) {
      value = null;
      mode = 'within';
    }
    setSelectedDate({
      value,
      mode
    });
  }, []);
const adjustFilterPosition  = ()=>{
  const {x:filterLeft, width: filterWidth} = filterRef.current.getBoundingClientRect();
  const fixedTable2Start = document.querySelector('.fixedTable2').getBoundingClientRect().x;
  const tabindex1ElDimentions = document.querySelector('.divTable .divTableHead .tabbable[tabindex="1"]').getBoundingClientRect();
  const top = tabindex1ElDimentions.y+30;
  filterRef.current.style.top = top+'px';
  if(filterLeft+filterWidth>fixedTable2Start){
    filterRef.current.style.left = fixedTable2Start-(filterWidth) + 'px';
  }
};

  const hideFilterOnFocusOut = e => {
    setTimeout(()=>{ if (filterRef.current === e.target || filterRef.current.contains(document.activeElement) || antdComponentOpen) {
       //still in filter popup.
     } else {
       setShowColumnFilter({filterId:'none'})
     }},100)
   }

  return (
    <div
        tabIndex="1"
        ref={filterRef}
      className={`${key === showColumnFilter.filterId || isCalendarOpen
          ? 'd-show'
          : 'd-none'
        } columnFilterBox fixed`}
      onBlur={hideFilterOnFocusOut}
    >
      <div className={column.sortDisabled ? "d-none" : "sorting-btn"}>
        <button
        tabIndex="1"
          type="submit"
          className={
            sortedColumn.columnName === key && sortedColumn.order === 'asc'
              ? 'sortingBtn active'
              : 'sortingBtn'
          }
          onClick={() =>
            setSortedColumn({
              columnName: column.key,
              order: 'asc',
            })
          }
        >
          {/* {intl.formatMessage({ id: 'DateFilter.A-Z' })} */}

          {column.sortButtonLatest ? intl.formatMessage({ id: 'Sorting.Oldest' }) : intl.formatMessage({ id: 'DateFilter.A-Z' })}


        </button>
        <button
        tabIndex="1"
          type="submit"
          className={
            sortedColumn.columnName === key && sortedColumn.order === 'desc'
              ? 'sortingBtn active'
              : 'sortingBtn'
          }
          onClick={() =>
            setSortedColumn({
              columnName: column.key,
              order: 'desc',
            })
          }
        >
          {/* {intl.formatMessage({ id: 'DateFilter.Z-A' })} */}
          {column.sortButtonLatest ? intl.formatMessage({ id: 'Sorting.Latest' }) : intl.formatMessage({ id: 'DateFilter.Z-A' })}

        </button>
      </div>
      <form action="">
        <DateFilterWidget
          setAntdComponentOpen={setAntdComponentOpen}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          resetDates={false}
        />

        <div className={`${"action "}${styles.actionmargin}`}>
          <A
          tabIndex="1"
            href="#"
            className={`${"btn-reset"} `}
            onClick={e => {
              e.preventDefault();
              setAppliedFilters({ ...appliedFilters, [key]: undefined });
              if (column.key === sortedColumn.columnName) {
                setSortedColumn({ order: 'asc' });
              }
              //   setCheckedFilters({ ...checkedFilters, [key]: [] });
            }}
          >
            {intl.formatMessage({ id: 'DateFilter.Reset' })}
          </A>
          <button
            type="button"
            tabIndex="1"
            className={`${styles.applymargin}`}
            onClick={e => {
              e.preventDefault();
              let dateStr;
              if (selectedDate.mode === 'within') {
                dateStr = `${selectedDate.value[0]}/${selectedDate.value[1]}`;
              }
              if (selectedDate.mode === 'before') {
                dateStr = `/${selectedDate.value}`;
              }
              if (selectedDate.mode === 'after') {
                dateStr = selectedDate.value;
              }
              setAppliedFilters({
                ...appliedFilters,
                [column.key]: dateStr,
              });
              setShowColumnFilter({ filterId: 'none' });
            }}
          >
            {intl.formatMessage({ id: 'DateFilter.Apply' })}
          </button>
        </div>
      </form>
    </div>
  );
};

DateFilter.propTypes = {
  column: PropTypes.shape({
    key: PropTypes.string.isRequired
  }).isRequired,
  sortedColumn: PropTypes.arrayOf(
    PropTypes.shape({
      columnName: PropTypes.number.isRequired,
      order: PropTypes.number.isRequired
    })
  ).isRequired,
  showColumnFilter: PropTypes.bool.isRequired,
  setShowColumnFilter: PropTypes.func.isRequired,
  appliedFilters: PropTypes.arrayOf.isRequired,
  setAppliedFilters: PropTypes.func.isRequired,
  setAntdComponentOpen: PropTypes.func.isRequired,
  setSortedColumn: PropTypes.func.isRequired
};

// eslint-disable-next-line import/prefer-default-export
const DateFilterIntl = injectIntl(DateFilter);

export { DateFilterIntl as DateFilter };
