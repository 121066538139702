/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable react/jsx-no-comment-textnodes */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Table  from './Table';
import './SaviyntGrid.scss';
import { injectIntl } from 'react-intl';

const SaviyntGrid = ({
  columns,
  data,
  uniqueKey,
  onRowSelected,
  appliedFilters,
  setAppliedFilters,
  reqBodyOptions,
  setReqBodyOptions,
  topOffset,
  tableRef = null,
  transparentTableRef = null,
  isLoading,
  classNames,
  hideRearrangeColumn,
  intl,
  hiddenColumns = [],
  id,
  displayColumns,
  setDisplayColumns,
  unCheckedColumns,
  setUnCheckedColumns,
  shownColumns,
  checkedFilters,
  setCheckedFilters,
  setFiltersCount,
  width,
  requestColCacheEnabled,
  isArchive,
  columnPaginationEnabled,
  columnHeaderAlignment,
}) => {
  //displayColumns is order of columns. when rearranged displayColumns differ the columns
  const [gridData, setGridData] = useState(data);
  const [filterPanelHeight, setFilterPanelHeight] = useState(0);

  // when unchecked, the columns will not be part of shownColumns. we can uncheck some coilumsn by default with help of hidden columns



  useEffect(() => {
    setGridData(data);

  }, [data]);
  
  useEffect(() => {
    let filterCount = 0;
    if (appliedFilters) {
      Object.keys(appliedFilters).forEach(filter => {
        if (Array.isArray(appliedFilters[filter])) {
          filterCount += appliedFilters[filter].length;
        }
        if (typeof appliedFilters[filter] === 'string') filterCount += 1;
        if (typeof appliedFilters[filter] === 'object' && Object.keys(appliedFilters[filter]).length) {
          Object.keys(appliedFilters[filter]).forEach(key => {
            if (Array.isArray(appliedFilters[filter][key])) {
              filterCount += appliedFilters[filter][key].length;
            }
          });

        };
      });
    }
    if (reqBodyOptions && (reqBodyOptions.findby || reqBodyOptions.findBy)) {
      filterCount += 1;
    }

    setFiltersCount(filterCount);
    if (filterCount > 0) {
      setFilterPanelHeight(56);
    } else {
      setFilterPanelHeight(0);
    }
  }, [appliedFilters, reqBodyOptions]);






  return (
    <>
      <Table
        id={`${id} transparentTable`}
        tableRef={transparentTableRef}
        columns={columns}
        gridData={gridData}
        setGridData={setGridData}
        onRowSelected={onRowSelected}
        appliedFilters={appliedFilters}
        setAppliedFilters={setAppliedFilters}
        checkedFilters={checkedFilters}
        setCheckedFilters={setCheckedFilters}
        reqBodyOptions={reqBodyOptions}
        setReqBodyOptions={setReqBodyOptions}
        shownColumns={shownColumns}
        uniqueKey={uniqueKey}
        classNames={classNames}
        isLoading={isLoading}
        filterPanelHeight={filterPanelHeight}
        topOffset={topOffset}
        hideRearrangeColumn={hideRearrangeColumn}
        displayColumns={displayColumns}
        setDisplayColumns={setDisplayColumns}
        unCheckedColumns={unCheckedColumns}
        setUnCheckedColumns={setUnCheckedColumns}
        width={width}
        requestColCacheEnabled={requestColCacheEnabled}
        isArchive={isArchive}
        columnPaginationEnabled={columnPaginationEnabled}
        columnHeaderAlignment={columnHeaderAlignment}
      />
      <Table
        id={id}
        tableRef={tableRef}
        columns={columns}
        gridData={gridData}
        setGridData={setGridData}
        onRowSelected={onRowSelected}
        appliedFilters={appliedFilters}
        setAppliedFilters={setAppliedFilters}
        checkedFilters={checkedFilters}
        setCheckedFilters={setCheckedFilters}
        reqBodyOptions={reqBodyOptions}
        setReqBodyOptions={setReqBodyOptions}
        shownColumns={shownColumns}
        uniqueKey={uniqueKey}
        classNames={classNames}
        isLoading={isLoading}
        filterPanelHeight={filterPanelHeight}
        topOffset={topOffset}
        hideRearrangeColumn={hideRearrangeColumn}
        displayColumns={displayColumns}
        setDisplayColumns={setDisplayColumns}
        unCheckedColumns={unCheckedColumns}
        setUnCheckedColumns={setUnCheckedColumns}
        width={width}
        requestColCacheEnabled={requestColCacheEnabled}
        isArchive={isArchive}
        columnPaginationEnabled={columnPaginationEnabled}
        columnHeaderAlignment={columnHeaderAlignment}
      />
    </>
  );
};

SaviyntGrid.propTypes = {
  setReqBodyOptions: PropTypes.func,
  setAppliedFilters: PropTypes.func,
  globalSearchText: PropTypes.string,
  setGlobalSearchText: PropTypes.func,
  uniqueKey: PropTypes.string,
  onRowSelected: PropTypes.func,
  topOffset: PropTypes.number,
  isLoading: PropTypes.bool,
  isListView: PropTypes.bool,
  classNames: PropTypes.string,
  reqBodyOptions: PropTypes.shape({
    findby: PropTypes.string.isRequired,
    findBy: PropTypes.string.isRequired,
  }).isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
    })
  ).isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      viewKey: PropTypes.string.isRequired,
      key: PropTypes.string.isRequired,
    })
  ).isRequired,
  appliedFilters: PropTypes.shape({
    id: PropTypes.arrayOf(PropTypes.string.isRequired),
  }).isRequired,
  hideRearrangeColumn: PropTypes.bool,
  sendAllRows: PropTypes.func.isRequired,
};
SaviyntGrid.defaultProps = {
  uniqueKey: null,
  onRowSelected: null,
  setReqBodyOptions: null,
  setAppliedFilters: null,
  globalSearchText: null,
  setGlobalSearchText: null,
  topOffset: null,
  isLoading: null,
  isListView: null,
  classNames: null,
  hideRearrangeColumn: null,
};

const SaviyntGridIntl = injectIntl(SaviyntGrid);

export { SaviyntGridIntl as SaviyntGrid };
