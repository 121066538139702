/* eslint-disable no-use-before-define */
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
// import FormHelperText from '@material-ui/core/FormHelperText';
import './FormComponents.scss';
import { injectIntl } from 'react-intl';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: '8px',
    alignContent: 'flex-end',
    '& > * + *': {
      marginTop: theme.spacing(3),
    },
  },
  label: {
    display: 'flex',
    flexWrap: 'wrap',
    alignSelf: 'flex-end',
  },
  helperText: {
    margin: '10px 0 0',
    fontSize: '12px',
    lineHeight: 1.4,
  },
  field: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    alignSelf: 'flex-end',
  },
}));

const MultiSelectDropDown = ({
  options,
  placeholder,
  _handleChange,
  attributeKey,
  defaultValue,
  required,
  showError,
  single,
  readOnly,
  scrollToError,
  intl,
  supportsHtmlTags,
  onFocusHandler,
  formDetails,
  freeSolo,
  disableClearable
}) => {
  const classes = useStyles();
  const inputTextFieldRef = useRef(null);
  const handleChange = (event, val) => {
    if (single) {
      if (!val) _handleChange([], attributeKey, val);
      else if (val && val.ID) _handleChange([val.ID], attributeKey, val);
      else if (freeSolo) _handleChange([val], attributeKey, val);
    } else {
      _handleChange([...val.map(v => v.ID)], attributeKey, val);
    }
  };
  useEffect(() => {
    if (scrollToError === 'true') {
      inputTextFieldRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [scrollToError]);
  return (
    <div className={classes.root} ref={inputTextFieldRef}>
      <div className={classes.field}>
        {supportsHtmlTags && (
          <div className="support-html">
            <span dangerouslySetInnerHTML={{ __html: placeholder }} />
            {required && <span className="mandatory">&nbsp;*</span>}
          </div>
        )}
        <Autocomplete
          freeSolo={freeSolo}
          autoSelect
          disabled={readOnly}
          multiple={!single}
          className={classes.root}
          id="size-small-outlined-multi"
          size="small"
          options={options}
          getOptionLabel={option => (typeof option === 'string' ? option : option.ID)}
          renderOption={option => (
            <React.Fragment>
              {option.description ? (
                <Tooltip title={option.description} placement="top" arrow>
                  <div className="option-tolltip w-100"> {option.ID} </div>
                </Tooltip>
              ) : (
                option.ID
              )}
            </React.Fragment>
          )}
          filterSelectedOptions
          getOptionSelected={(option, value) => (option && option.ID ? option.ID === value.ID : false)}
          onChange={handleChange}
          value={defaultValue}
          noOptionsText={intl.formatMessage({ id: 'ColumnFilter.NoOptionsText' })}
          onFocus={() => {
            onFocusHandler(formDetails);
          }}
          renderInput={params => (
            <TextField
              {...params}
              error={required ? showError : false}
              fullWidth
              label={
                supportsHtmlTags ? (
                  ''
                ) : (
                  <>
                    <span dangerouslySetInnerHTML={{ __html: placeholder }} />
                    {required && <span className="mandatory">&nbsp;*</span>}
                  </>
                )
              }
            />
          )}
          disableClearable={disableClearable}
        />
      </div>
    </div>
  );
};

MultiSelectDropDown.propTypes = {
  _handleChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  attributeKey: PropTypes.string.isRequired,
  defaultValue: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  required: PropTypes.bool.isRequired,
  showError: PropTypes.bool.isRequired,
  single: PropTypes.bool,
  readOnly: PropTypes.bool.isRequired,
  supportsHtmlTags: PropTypes.bool,
  freeSolo: PropTypes.bool,
};

MultiSelectDropDown.defaultProps = {
  single: false,
  onFocusHandler: () => {},
  supportsHtmlTags: false,
  freeSolo: false,
};

export default injectIntl(MultiSelectDropDown);
