import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import FormHelperText from '@material-ui/core/FormHelperText';
import './FormComponents.scss';

const useStyles = makeStyles(theme => ({
  label: {
    color: '#646464',
  },
  switch: {
    '&:hover, &:focus': {
      color: theme.palette.primary.main,
    },
  },
  errorText: {
    color: '#F06A60',
  },
}));

const SwitchLabel = ({
  attributeKey,
  placeholder,
  defaultValue,
  _handleChange,
  showError,
  required,
  _handleChangePam,
  readOnly,
}) => {
  const classes = useStyles();
  const convertBoolean = val => {
    if (typeof val === 'boolean') return val;
    if (!val) return null;
    if (val.toUpperCase() === 'TRUE') return true;
    if (val.toUpperCase() === 'FALSE') return false;
    return null;
  };

  const [toggled, setToggled] = useState(convertBoolean(defaultValue));

  useEffect(() => {
    setToggled(convertBoolean(defaultValue));
  }, [defaultValue]);


  const handleChange = () => {
    setToggled(!toggled);
    if (_handleChangePam) _handleChangePam(!toggled, attributeKey);
    else _handleChange((!toggled).toString().toUpperCase(), attributeKey);
  };

  return (
    <>
      <FormControlLabel
        control={
          <Switch
            checked={toggled}
            onChange={handleChange}
            value={placeholder}
            color="primary"
            error={required ? showError : false}
            classes={{ switchBase: classes.switch }}
          />
        }
        disabled={readOnly}
        classes={{
          label: classes.label,
        }}
        label={
          <div>
            <span dangerouslySetInnerHTML={{ __html: placeholder }} />
            {required && <span className="mandatory">&nbsp;*</span>}
          </div>
        }
      />
      <FormHelperText className={classes.errorText}>
        {required && showError ? 'Please select an option' : ''}
      </FormHelperText>
    </>
  );
};
SwitchLabel.defaultProps = {
  readOnly: false,
};

SwitchLabel.propTypes = {
  _handleChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  attributeKey: PropTypes.string.isRequired,
  defaultValue: PropTypes.arrayOf(PropTypes.string).isRequired,
  showError: PropTypes.bool.isRequired,
  required: PropTypes.bool.isRequired,
  _handleChangePam: PropTypes.func.isRequired,
  readOnly: PropTypes.func,
};

export default SwitchLabel;
