/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import moment from 'moment';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import { setFullScreenOpen } from 'ui-common/src/lib/utils';
import styles from './SaviyntRangePicker.module.scss';
import { SlimSelect } from '../SlimSelect';
import { Icon } from '../Icon';

const SaviyntRangePicker = ({
  instanceFromDate,
  instanceFromTime,
  instanceToDate,
  instanceToTime,
  onChange,
  showErrorMessage,
  startToday,
  disableProgress,
  setCalendarOpen,
  noTimeRequired,
  disabledFromDateTime,
  disabledToDateTime,
  allowPreviousDates,
  fullwidth,
  showError,
  validateEndDate,
  required,
  intl,
}) => {
  const timeOptions = [
    { display: '12:00am', value: { hours: 0, minutes: 0, seconds: 0 } },
    { display: '12:30am', value: { hours: 0, minutes: 30, seconds: 0 } },
    { display: '1:00am', value: { hours: 1, minutes: 0, seconds: 0 } },
    { display: '1:30am', value: { hours: 1, minutes: 30, seconds: 0 } },
    { display: '2:00am', value: { hours: 2, minutes: 0, seconds: 0 } },
    { display: '2:30am', value: { hours: 2, minutes: 30, seconds: 0 } },
    { display: '3:00am', value: { hours: 3, minutes: 0, seconds: 0 } },
    { display: '3:30am', value: { hours: 3, minutes: 30, seconds: 0 } },
    { display: '4:00am', value: { hours: 4, minutes: 0, seconds: 0 } },
    { display: '4:30am', value: { hours: 4, minutes: 30, seconds: 0 } },
    { display: '5:00am', value: { hours: 5, minutes: 0, seconds: 0 } },
    { display: '5:30am', value: { hours: 5, minutes: 30, seconds: 0 } },
    { display: '6:00am', value: { hours: 6, minutes: 0, seconds: 0 } },
    { display: '6:30am', value: { hours: 6, minutes: 30, seconds: 0 } },
    { display: '7:00am', value: { hours: 7, minutes: 0, seconds: 0 } },
    { display: '7:30am', value: { hours: 7, minutes: 30, seconds: 0 } },
    { display: '8:00am', value: { hours: 8, minutes: 0, seconds: 0 } },
    { display: '8:30am', value: { hours: 8, minutes: 30, seconds: 0 } },
    { display: '9:00am', value: { hours: 9, minutes: 0, seconds: 0 } },
    { display: '9:30am', value: { hours: 9, minutes: 30, seconds: 0 } },
    { display: '10:00am', value: { hours: 10, minutes: 0, seconds: 0 } },
    { display: '10:30am', value: { hours: 10, minutes: 30, seconds: 0 } },
    { display: '11:00am', value: { hours: 11, minutes: 0, seconds: 0 } },
    { display: '11:30am', value: { hours: 11, minutes: 30, seconds: 0 } },
    { display: '12:00pm', value: { hours: 12, minutes: 0, seconds: 0 } },
    { display: '12:30pm', value: { hours: 12, minutes: 30, seconds: 0 } },
    { display: '1:00pm', value: { hours: 13, minutes: 0, seconds: 0 } },
    { display: '1:30pm', value: { hours: 13, minutes: 30, seconds: 0 } },
    { display: '2:00pm', value: { hours: 14, minutes: 0, seconds: 0 } },
    { display: '2:30pm', value: { hours: 14, minutes: 30, seconds: 0 } },
    { display: '3:00pm', value: { hours: 15, minutes: 0, seconds: 0 } },
    { display: '3:30pm', value: { hours: 15, minutes: 30, seconds: 0 } },
    { display: '4:00pm', value: { hours: 16, minutes: 0, seconds: 0 } },
    { display: '4:30pm', value: { hours: 16, minutes: 30, seconds: 0 } },
    { display: '5:00pm', value: { hours: 17, minutes: 0, seconds: 0 } },
    { display: '5:30pm', value: { hours: 17, minutes: 30, seconds: 0 } },
    { display: '6:00pm', value: { hours: 18, minutes: 0, seconds: 0 } },
    { display: '6:30pm', value: { hours: 18, minutes: 30, seconds: 0 } },
    { display: '7:00pm', value: { hours: 19, minutes: 0, seconds: 0 } },
    { display: '7:30pm', value: { hours: 19, minutes: 30, seconds: 0 } },
    { display: '8:00pm', value: { hours: 20, minutes: 0, seconds: 0 } },
    { display: '8:30pm', value: { hours: 20, minutes: 30, seconds: 0 } },
    { display: '9:00pm', value: { hours: 21, minutes: 0, seconds: 0 } },
    { display: '9:30pm', value: { hours: 21, minutes: 30, seconds: 0 } },
    { display: '10:00pm', value: { hours: 22, minutes: 0, seconds: 0 } },
    { display: '10:30pm', value: { hours: 22, minutes: 30, seconds: 0 } },
    { display: '11:00pm', value: { hours: 23, minutes: 0, seconds: 0 } },
    { display: '11:30pm', value: { hours: 23, minutes: 30, seconds: 0 } },
    { display: '12:00am', value: { hours: 24, minutes: 0, seconds: 0 } },
  ];
  const [fromDate, setFromDate] = useState(instanceFromDate);
  const [startDate, setStartDate] = useState(instanceFromDate);
  const [fromTime, setFromTime] = useState(instanceFromTime);
  const [toDate, setToDate] = useState(instanceToDate);
  const [toTime, setToTime] = useState(instanceToTime);

  useEffect(() => {
    const fromDateTime = fromDate.set(fromTime);
    const toDateTime = toDate !== null ? toDate.set(toTime) : null;
    onChange([fromDateTime, toDateTime]);
  }, [fromDate, fromTime, toDate, toTime]);

  return (
    <div className={`${styles.saviyntRangePicker} ${fullwidth ? 'w-100' : ''}`}>
      <Icon className={`${styles.calendarIcon} pl-1`} name="calendar" />
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <DatePicker
          tabIndex="1"
          className={styles.dateFocused}
          defaultValue={fromDate}
          autoOk
          disableToolbar
          onOpen={() => {
            if (setCalendarOpen) setCalendarOpen(true);
          }}
          onClose={() => {
            if (setCalendarOpen) setCalendarOpen(false);
          }}
          variant="inline"
          style={{ width: '4.8rem', marginTop: 3, marginRight: 3 }}
          format="MMM dd, yyyy"
          value={fromDate}
          onChange={value => {
            const momentFromdate = moment(value);
            // momentFromdate.set({ hours: 0, minutes: 0, seconds: 0 });
            setFromDate(momentFromdate);
            if (disableProgress !== undefined) {
              if (
                moment(toDate).isBefore(momentFromdate) ||
                moment(toDate).isSame(momentFromdate)
              ) {
                disableProgress(true);
              } else {
                disableProgress(false);
              }
            }
            // const dd = { fromDate };
            // momentFromdate.set('hour', 13);
          }}
          InputProps={{
            style: {
              fontSize: 11, // dateFontSize,
              fontWeight: 600,
              // marginTop: 3,
            },
          }}
          minDate={allowPreviousDates ? undefined : startToday !== undefined ? moment() : startDate}
          disabled={disabledFromDateTime}
        />
      </MuiPickersUtilsProvider>
      {!noTimeRequired && (
        <SlimSelect
          options={timeOptions}
          defaultValue={fromTime}
          disabled={value =>
            (fromDate.isSame(moment(), 'day') &&
              value.hours * 60 + value.minutes <
              moment().get('hours') * 60 + moment().get('minutes')) ||
            disabledFromDateTime
          }
          onChange={option => setFromTime(option)}
        />
      )}
      <div className={styles.separator}>-</div>

      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <DatePicker
          tabIndex="1"
          autoOk
          disableToolbar
          variant="inline"
          onOpen={() => {
            if (setCalendarOpen) setCalendarOpen(true);
          }}
          onClose={() => {
            if (setCalendarOpen) setCalendarOpen(false);
          }}
          format="MMM dd, yyyy"
          style={{ width: '4.8rem', marginTop: 0, marginRight: 3 }}
          value={toDate}
          onChange={value => {
            const momentTodate = moment(value);
            setToDate(momentTodate);
            if (disableProgress !== undefined) {
              if (
                moment(momentTodate).isBefore(fromDate) ||
                moment(momentTodate).isSame(fromDate)
              ) {
                disableProgress(true);
              } else {
                disableProgress(false);
              }
            }
            validateEndDate(value.toDateString());
          }}
          error={moment(toDate).isBefore(fromDate) || showError}
          helperText={
            showErrorMessage !== undefined
              ? null
              : moment(toDate).isBefore(fromDate)
                ? `${intl.formatMessage({ id: 'BusinessJustification' })}`
                : null
          }
          InputProps={{
            style: {
              fontSize: 11, // dateFontSize,
              fontWeight: 600,
              marginTop: 3,
            },
          }}
          disabled={disabledToDateTime}
          minDate={allowPreviousDates ? undefined : fromDate}
        />
        {required && <span className="mandatory">&nbsp;*</span>}
      </MuiPickersUtilsProvider>
      {!noTimeRequired && (
        <SlimSelect
          options={timeOptions}
          defaultValue={toTime}
          disabled={value =>
            (value !== null &&
              toDate !== null &&
              toDate.isSame(moment(), 'day') &&
              value.hours * 60 + value.minutes <
              moment().get('hours') * 60 + moment().get('minutes')) ||
            disabledToDateTime
          }
          onChange={option => setToTime(option)}
        />
      )}
    </div>
  );
};
SaviyntRangePicker.defaultProps = {
  noTimeRequired: false,
  allowPreviousDates: false,
  showErrorMessage: false,
  validateEndDate: () => { },
  showError: false,
};
SaviyntRangePicker.propTypes = {
  instanceFromDate: PropTypes.objectOf(
    PropTypes.shape({
      hours: PropTypes.number,
      minutes: PropTypes.number,
    })
  ).isRequired,
  instanceToDate: PropTypes.objectOf(
    PropTypes.shape({
      hours: PropTypes.number,
      minutes: PropTypes.number,
    })
  ).isRequired,
  instanceFromTime: PropTypes.string.isRequired,
  showErrorMessage: PropTypes.bool,
  startToday: PropTypes.bool.isRequired,
  instanceToTime: PropTypes.string.isRequired,
  disableProgress: PropTypes.func.isRequired,
  // dateFormat: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  noTimeRequired: PropTypes.bool,
  // disabledFromDateTime: PropTypes.string.isRequired,
  disabledFromDateTime: PropTypes.bool.isRequired,
  disabledToDateTime: PropTypes.bool.isRequired,
  allowPreviousDates: PropTypes.bool,
  showError: PropTypes.bool,
  validateEndDate: PropTypes.func,
  required: PropTypes.bool.isRequired,
};
export default injectIntl(SaviyntRangePicker);
