import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import './A.css';

const A = ({ href, onClick, className, children, intl, ...rest }) => (
  <button
    type="button"
    className={`anchorButton ${className}`}
    onClick={onClick}
    aria-label={`${intl.formatMessage({ id: 'A.button.ariaLabel' })}`}
    {...rest}
  >
    {children}
  </button>
);

A.propTypes = {
  href: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  intl: PropTypes.arrayOf(
    PropTypes.shape({
      locale: PropTypes.string.isRequired,
      formatMessage: PropTypes.func.isRequired,
    })
  ).isRequired,
};

export default injectIntl(A);
