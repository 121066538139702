import React, { useState } from 'react';
// import { DatePicker, Popover } from 'antd';
import PropTypes from 'prop-types';
import moment from 'moment';
import { injectIntl } from 'react-intl';
// import '../PrivilegedSessions.scss';
import { A } from '../A';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { SaviyntDatePicker } from '../SaviyntDatePicker';
import style from './ExtendSessionModal.module.scss';
// import { style } from '@material-ui/system';
// import configData from '../../config/config';

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const ExtendSessionModal = ({ activeSessionsSelected, ...props }) => {
  const [enddate, setEndDate] = useState();
  const [businessJustification, setBusinessJustification] = useState('');
  // const [calendarOpen, setCalendarOpen] = useState(false);
  // const [errorMsg, setErrorMsg] = useState(false);
  // const [showExtendModal, setShowExtendModal] = useState('open');
  let maxDateArr = null;
  if (props.latestDate.length > 0) {
    maxDateArr = props.latestDate.reduce((a, b) => (moment(a) > moment(b) ? a : b));
  }

  const fromDateTime = maxDateArr ? moment(maxDateArr) : moment();
  const instanceFromTime = {
    hours: fromDateTime.get('hours'),
    minutes: fromDateTime.get('minutes'),
    seconds: fromDateTime.get('seconds'),
  };

  const valiDateForm = event => {
    const { value } = event.target;
    setBusinessJustification(value);
    // if (value.length < configData.commentsMinChars) {
    //   setErrorMsg(true);
    // } else {
    //   setErrorMsg(false);
    // }
  };

  const requestExtendSessions = () => {
    const finalActiveSessionsSelected = [];
    activeSessionsSelected.map(session =>
      finalActiveSessionsSelected.push({
        accesskey: session.requestAccessKey,
        enddate,
        businessjustification: businessJustification,
        username: session.username,
      })
    );
    props.captureSelectedPrivilegedSessionParams({
      finalActiveSessionsSelected,
      typeOfAction: 'extendsession',
    });
    props.showExtendModal(false);
    props.setShowSmallModal(false);
    props.setActiveSessionsSelected([]);
  };

  const selectedAccountView = (
    <ul className="capsul_select">
      {activeSessionsSelected &&
        activeSessionsSelected.map(item => (
          // <li key={index}><A>{item.requestId}</A></li>
          <li>
            <A>{item.requestId}</A>
          </li>
        ))}
    </ul>
  );
  // Can not select days before today and today

  return (
    <Dialog
      classes={{ paperScrollPaper: style.extendSessionDialog }}
      aria-labelledby="customized-dialog-title"
      open
    >
      <DialogTitle
        onClose={() => {
          props.showExtendModal(false);
          props.setShowSmallModal(false);
          props.setActiveSessionsSelected([]);
        }}
        className={style.extendTitle}
        id="customized-dialog-title"
      >
        <h6 className={style.titleText}>
          {' '}
          {activeSessionsSelected.length}{' '}
          {props.intl.formatMessage({
            id: 'activeSessions.SessionsSelectedForExtension',
          })}
        </h6>
      </DialogTitle>
      <DialogContent className={style.extendContent}>
        <span className={style.fontSelection}>
          {' '}
          {props.intl.formatMessage({ id: 'activeSessions.YourSelection' })}
        </span>
        <div>
          <div>
            <span className={style.fontSelection}>{selectedAccountView}</span>
          </div>
          <div className={`form-group ${style.cal}`}>
            <div>
              <span className={style.extendText}>
                {props.intl.formatMessage({
                  id: 'activeSessions.NewEndDateLabel',
                })}
              </span>
            </div>
            <div>
              <SaviyntDatePicker
                instanceFromDate={fromDateTime}
                instanceFromTime={instanceFromTime}
                dateFormat="MMM dd, yyyy"
                onChange={date => {
                  setEndDate(
                    moment.utc(moment(date, 'YYYY-MM-DD HH:mm')).format('YYYY-MM-DD HH:mm:ss')
                  );
                }}
              />
            </div>
          </div>
          <div className="d-flex flex-column">
            <span className={style.extendText}>
              {props.intl.formatMessage({
                id: 'activeSessions.BusinessJustificationLabel',
              })}
            </span>
            <TextareaAutosize
              onChange={e => {
                if (e.target.value.length <= 1000) {
                  valiDateForm(e);
                }
              }}
              value={businessJustification}
              aria-label="minimum height"
              rowsMin={5}
              placeholder=""
              className={style.extendTextArea}
            />
            <div className="text-right" id="count_message">
              {' '}
              {businessJustification.length}
              /1000
            </div>
          </div>
        </div>
      </DialogContent>
      <DialogActions className={style.extendFooter}>
        <Button
          autoFocus
          variant="outlined"
          color="primary"
          className={style.closeextend}
          onClick={() => {
            props.setUncheckCheckbox(false);
            props.setShowSmallModal(false);
            props.showExtendModal(false)
          }}
        >
          {props.intl.formatMessage({
            id: 'activeSessions.CancelTerminateBtn',
          })}
        </Button>
        <Button
          autoFocus
          variant="outlined"
          color="primary"
          type="button"
          disabled={!(enddate && businessJustification && businessJustification.length > 2)}
          onClick={() => requestExtendSessions()}
        >
          {props.intl.formatMessage({
            id: 'activeSessions.ExtendSessionBtn',
          })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
ExtendSessionModal.defaultProps = {
  activeSessionsSelected: [],
};
ExtendSessionModal.propTypes = {
  intl: PropTypes.arrayOf(
    PropTypes.shape({
      locale: PropTypes.string.isRequired,
      formatMessage: PropTypes.func.isRequired,
    })
  ).isRequired,
  activeSessionsSelected: PropTypes.arrayOf(PropTypes.activeSessionsSelected),
  showExtendModal: PropTypes.func.isRequired,
  captureSelectedPrivilegedSessionParams: PropTypes.func.isRequired,
};
export default injectIntl(ExtendSessionModal);
