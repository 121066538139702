import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import { Icon as AntIcon, Popover, Upload } from 'antd';
// eslint-disable-next-line import/extensions
import { Icon } from '../Icon';
import moment from 'moment';
import { injectIntl } from 'react-intl';
// eslint-disable-next-line import/extensions
import { SaviyntGrid } from '../Grid';
import { SelectDateModal } from '../SelectDatesModal';
// import configData from '../../config/config';

const columns = [
  {
    viewKey: 'instanceId',
    key: 'instanceId',
    label: 'INSTANCE ID',
  },
  {
    viewKey: 'startDate',
    key: 'startDate',
    label: 'STATE DATE',
  },
  {
    viewKey: 'endDate',
    key: 'endDate',
    label: 'END DATE',
  },
  {
    viewKey: 'selectedPrivilegedId',
    key: 'selectedPrivilegedId',
    label: 'PRIVILEGED ID',
  },
  {
    viewKey: 'businessJustification',
    key: 'businessJustification',
    label: 'BUSINESS JUSTIFICATION',
  },
  {
    viewKey: 'edit',
    label: '',
  },
];
const SecondStage = ({
  secondStageDataFromState,
  onSubmit,
  removeSelectData,
  updateSelectData,
  secondStageColumns,
  requestFor,
  intl,
  commentsMinChars=3
}) => {
  const commentsTextarea = useRef(null);
  const [formValid, setFormValid] = useState(true);
  const [fileList, setFileList] = useState([]);
  const [showEdit, setShowEdit] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const prepareSecondStageGridData = secondStageInfo =>
    secondStageInfo.map(d => ({
      ...d,
      // selectedPrivilegedId: d.selectedRole ? d.selectedRole.label : '',
      startDate: d.fromDateTime.format('MMM DD, YYYY hh:mmA'),
      endDate: d.toDateTime.format('MMM DD, YYYY hh:mmA'),
      businessJustification: d.businessJustification,
      edit: (
        <button
          type="button"
          className="btn btn-outline-primary btn-ingrid btn-sm"
          onClick={() => {
            setSelectedRows([d]);
            setShowEdit(true);
            // checkEditedRow(d.instanceId);
          }}
        >
          Edit
        </button>
      )
    }));
  const [secondStageGridData, setSecondStageGridData] = useState(
    prepareSecondStageGridData(secondStageDataFromState)
  );
  const prepareGridDataWithErrors = () => {
    // let selectedIds = selectedRows.map();
    const griDataWithErrors = secondStageDataFromState.map(d => {
      let isError = false;
      // const selectedPrivilegedIdValue = d.selectedRole
      //   ? d.selectedRole.label
      //   : (isError = true && { errorText: 'Role is Required' });

      let startDateValue;
      if (!d.fromDateTime) {
        isError = true;
        startDateValue = {
          errorText: 'Start Date cannot be empty'
        };
      } else if (d.fromDateTime < moment()) {
        isError = true;
        startDateValue = {
          errorText: 'Start date should be future date',
          value: d.fromDateTime.format('MMM DD, YYYY hh:mmA')
        };
      } else if (d.fromDateTime > d.toDateTime) {
        isError = true;
        startDateValue = {
          errorText: 'Start date should before the End Date',
          value: d.fromDateTime.format('MMM DD, YYYY hh:mmA')
        };
      } else {
        startDateValue = d.fromDateTime.format('MMM DD, YYYY hh:mmA');
      }
      let endDateValue;
      if (!d.toDateTime) {
        isError = true;
        endDateValue = {
          errorText: 'End Date cannot be empty'
        };
      } else if (d.toDateTime < moment()) {
        isError = true;
        endDateValue = {
          errorText: 'End date should be future date',
          value: d.toDateTime.format('MMM DD, YYYY hh:mmA')
        };
      } else {
        endDateValue = d.toDateTime.format('MMM DD, YYYY hh:mmA');
      }
      return {
        ...d,
        isError,
        // selectedPrivilegedId: selectedPrivilegedIdValue,
        startDate: startDateValue,
        endDate: endDateValue,
        edit: (
          <button
            type="button"
            className="btn btn-outline-primary btn-ingrid btn-sm"
            onClick={() => {
              setSelectedRows([d]);
              setShowEdit(true);
            }}
          >
            Edit
          </button>
        )
      };
    });
    setSecondStageGridData(griDataWithErrors);
  };

  const onSelectDateAndRole = ({
    fromDateTime,
    toDateTime,
    // selectedRole,
    businessJustification
  }) => {
    if (selectedRows.length === 1) {
      updateSelectData({
        ...selectedRows[0],
        fromDateTime,
        toDateTime,
        // selectedRole,
        businessJustification
      });
    }
    if (selectedRows.length > 1) {
      selectedRows.forEach(row => {
        updateSelectData({
          ...row,
          // edit: null,
          fromDateTime,
          toDateTime,
          // selectedRole,
          businessJustification
        });
      });
    }
    setTimeout(() => setSelectedRows([]), 0);

    prepareGridDataWithErrors(secondStageGridData);
  };
  useEffect(() => {
    prepareSecondStageGridData(secondStageGridData);
    const selectedIds = selectedRows.map(selrow => selrow.instanceId);
    setSecondStageGridData(
      secondStageGridData.map(row => {
        if (selectedIds.includes(row.instanceId)) return { ...row, checked: true };
        return { ...row, checked: false };
      })
    );
  }, [selectedRows]);

  useEffect(() => {
    setSecondStageGridData(prepareSecondStageGridData(secondStageDataFromState));
  }, [secondStageDataFromState]);

  const content = error => {
    if (error === 'minCommentLength') {
      return (
        <div className="antpopoveralign">
          <p>Minimum length of the character is 3</p>
        </div>
      );
    }
    return null;
  };
  const valiDateForm = value => {
    if (value.length < commentsMinChars) {
      setFormValid(false);
    } else {
      setFormValid(true);
    }
  };
  const propsupload = {
    multiple: true,
    onRemove: file => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
      return {
        fileList: newFileList
      };
    },
    beforeUpload: file => {
      let filealreaydpresent = false;
      fileList.forEach(element => {
        if (file.name === element.name) {
          filealreaydpresent = true;
        }
      });
      if (!filealreaydpresent) {
        setFileList([...fileList, file]);
      }

      return false;
    },
    fileList
  };
  const removeFile = (name, e) => {
    setFileList(fileList.filter(file => file.name !== name));
    e.stopPropagation();
  };
  // const validateGridData = () => {
  //   let isGridDataValid = true;
  //   secondStageDataFromState.forEach(instance => {
  //     if (
  //       instance.fromDateTime < moment() ||
  //       instance.fromDateTime >= instance.toDateTime // ||
  //       // !instance.selectedRole
  //     ) {
  //       isGridDataValid = false;
  //     }
  //   });
  //   return isGridDataValid;
  // };
  const submitClick = () => {
    if (commentsTextarea.current.value.length < commentsMinChars) {
      valiDateForm(commentsTextarea.current.value);
      return;
    }
    // if (!validateGridData()) {
    //   prepareGridDataWithErrors();
    //   setSelectedRows([]);
    //   return;
    // }
    onSubmit(commentsTextarea.current.value, fileList, secondStageDataFromState);
  };
  return (
    <div className="container">
      {showEdit &&
        selectedRows[0] &&
        ReactDOM.createPortal(
          <>
            <div className="modal-backdrop fade show" />
            <SelectDateModal
              showModal={setShowEdit}
              instances={selectedRows}
              setSelectedRow={setSelectedRows}
              onDone={onSelectDateAndRole}
            />
          </>,
          document.getElementById('overlaypopup')
        )}
      <div className="card custom-card">
        <div>
          <Popover
            placement="topLeft"
            align={{ offset: [100, 40] }}
            visible={!formValid}
            content={content('minCommentLength')}
          >
            <div className="form-group row">
              <div htmlFor="" className="col-form-label">
                {intl.formatMessage({
                  id: 'selInstSecStage.Comment'
                })}{' '}
              </div>{' '}
              <span className="mandate-field-comment">*</span>
              <Upload className="dropzone" {...propsupload}>
                <textarea
                  onChange={e => valiDateForm(e.target.value)}
                  style={
                    formValid
                      ? {}
                      : {
                          borderColor: '#ff0000',
                          boxShadow: 'none'
                        }
                  }
                  ref={commentsTextarea}
                  name=""
                  id=""
                  cols="30"
                  rows="8"
                  className="form-control instanceCheckoutComment mt-1 pt-1"
                  placeholder={intl.formatMessage({
                    id: 'selInstSecStage.commentsplaceholder'
                  })}
                  onFocus="this.placeholder=''"
                  onBlur={intl.formatMessage({
                    id: 'selInstSecStage.commentsplaceholder.onblur'
                  })}
                  onClick={e => e.stopPropagation()}
                />
                <div className="d-flex">
                  <AntIcon type="paper-clip" />
                </div>
                <div className="attached-file-list">
                  {fileList.map(file => (
                    <div className="attached-file d-flex justify-content-between">
                      <Icon
                        name="attachedFile"
                        fileType={file.name.split('.')[1].substring(0, 3)}
                      />

                      <div className="d-flex flex-column attached-text">
                        <span className="d-flex flex-start">{file.name}</span>
                        <span className="d-flex flex-start">
                          {file.size > 1000000
                            ? `${(file.size / 1000000).toFixed(2)} mb`
                            : `${(file.size / 1000).toFixed(2)} kb`}
                        </span>
                      </div>
                      <Icon
                        name="close"
                        onClick={e => removeFile(file.name, e)}
                        className="close-attachement"
                      />
                    </div>
                  ))}
                </div>
              </Upload>
            </div>
          </Popover>
          {/* <div className="form-group row mb-0">
            <div htmlFor="" className="col-form-label">
              {intl.formatMessage({
                id: 'selInstSecStage.Attachments',
              })}
            </div>
          </div> */}
          <div className="row mb-1">
            {selectedRows.length > 0 ? (
              <>
                <span className="mt-1">{selectedRows.length} selected</span>
                <button
                  type="button"
                  className="btn btn-outline-primary btn-ingrid ml-2 mt-1"
                  onClick={() => {
                    const selectedInstanceIds = selectedRows.map(row => row.instanceId);
                    removeSelectData(selectedInstanceIds);
                    setSecondStageGridData(
                      secondStageGridData.filter(
                        row => !selectedInstanceIds.includes(row.instanceId)
                      )
                    );
                    setSelectedRows([]);
                  }}
                >
                  Remove
                </button>
                {selectedRows.length > 1 && (
                  <button
                    type="button"
                    className="btn btn-outline-primry btn-ingrid ml-2 mt-1"
                    onClick={() => {
                      setShowEdit(true);
                    }}
                  >
                    Edit Multiple
                  </button>
                )}
              </>
            ) : (
              <span>
                {' '}
                {secondStageGridData.length} {requestFor}{' '}
              </span>
            )}
            {/* {secondStageGridData.filter(row => row.isError).length > 0 && (
              <span className="errorText inst-error ml-1">
                Some values are incorrect or missing. Please refer to the&nbsp;{' '}
                <Icon name="gridError" />
                &nbsp;indicators below.
              </span>
            )} */}
          </div>
          <SaviyntGrid
            data={secondStageGridData}
            columns={secondStageColumns}
            uniqueKey="instanceId"
            onRowSelected={rows => setSelectedRows(rows)}
            topOffset={0}
            isLoading={false}
            classNames="form-group row"
          />
        </div>
      </div>
      <button
        type="button"
        id="submit-button"
        onClick={submitClick}
        className="btn btn-primary stepBtn instancesSubmit"
      >
        {intl.formatMessage({
          id: 'selInstSecStage.Submit'
        })}
      </button>
    </div>
  );
};

SecondStage.propTypes = {
  secondStageDataFromState: PropTypes.arrayOf().isRequired,
  onSubmit: PropTypes.func.isRequired,
  removeSelectData: PropTypes.func.isRequired,
  updateSelectData: PropTypes.func.isRequired,
  secondStageColumns: PropTypes.arrayOf(),
  requestFor: PropTypes.string.isRequired,
  intl: PropTypes.arrayOf(
    PropTypes.shape({
      locale: PropTypes.string.isRequired,
      formatMessage: PropTypes.func.isRequired,
    })
  ).isRequired,
};

SecondStage.defaultProps = {
  secondStageColumns: columns,
};

export default injectIntl(SecondStage);
