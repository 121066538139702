import React, { useState, useEffect, useRef, createRef } from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { IconButton, Menu, MenuItem } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from '@material-ui/core/Button';
import RefreshIcon from '@material-ui/icons/Refresh';
import KeyboardTabIcon from '@material-ui/icons/KeyboardTab';
import { MitigatingControlDialog } from '../MitigatingControlDialog';
import { Icon } from '../Icon';
import './SOD.scss';
import { TextFieldComponent } from 'ui-components/src'
import SODItem from './SODItem';
import moment from 'moment';

import Popover from '@material-ui/core/Popover';
import ExportColumnModal from './ExportColumnModal';

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: '#ffffff',
    marginLeft: 22,
    marginRight: 5,
    marginBottom: 23,
    width: '90%',
  },
  notchedOutline: {
    borderRadius: '3px',
    border: '1px solid #dcdee1',
  },
  icon: {
    width: '1.4em',
    height: '1.4em',
  },
  exportIcon: {
    width: '1.1em',
    height: '1.1em',
    fill: 'var(--primary-main)',
    transform: 'rotate(90deg)',
    fontSize: '1.5rem',
  },
  expandIcon: {
    position: 'absolute',
    left: 0,
  },
  panelRoot: {
    padding: '13px !important',
    backgroundColor: 'var(--primary-bg)',
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: '0px !important',
  },
  menuPaper: {
    marginTop: 35,
  },
  button: {
    border: 'none',
    marginLeft: 'auto',
    '&:hover': {
      backgroundColor: 'var(--primary-bg)',
      border: 'none',
    }
  },
}));
const ExpansionPanelSummary = withStyles({
  root: {
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: 0,
    },
  },

  expanded: {},
})(MuiExpansionPanelSummary);

const SODMultiple = ({
  commentsRequired,
  mitigationRequired,
  addMitigatingCtrl,
  addbusinessJustiToRisk,
  recalculateSOD,
  activeIndex,
  commentsMandatory,
  showError,
  enableExportSOD,
  getExportSODColumns,
  sodExportData,
  downLoadSODReport,
  enableRecalculateSOD,
  ...props
}) => {
  const [selectedRowIndex, setSelectedRowIndex] = useState(activeIndex || 0);
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const [mitigating, setMitigating] = React.useState(false);
  const [currDisplayData, setcurrDisplayData] = React.useState(activeIndex ? props.sodData[activeIndex] : props.sodData[0] ? props.sodData[0] : {});
  //const [sodItems, setSODItems] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorE2, setAnchorE2] = useState(null);
  const [showExportColumnDialog, setShowExportColumnDialog] = useState(false);
  const open = Boolean(anchorE2);
  const popoverOpen = event => {
    setAnchorEl(event.currentTarget);
  };
  const [reportType, setReportType] = useState('');
  const handleClose = () => {
    setAnchorE2(null);
  };
  const handleClick = e => {
    setAnchorE2(e.currentTarget);
  };

  const popoverClose = () => {
    setAnchorEl(null);
  };

  const droppopover = Boolean(anchorEl);
  const id = droppopover ? 'simple-popover' : undefined;


  useEffect(() => {
    setSelectedRowIndex(activeIndex);
    setcurrDisplayData(props.sodData[activeIndex]);
  }, [activeIndex]);

  useEffect(() => {
    props.sodData.forEach(element => {
      if (currDisplayData.Risk === element.Risk) setcurrDisplayData(element);
    });
  }, [props.sodData]);

  useEffect(() => {
    if (showError && showError.showError && (showError.index || showError.index === 0)) {
      setSelectedRowIndex(showError.index);
      setcurrDisplayData(props.sodData[showError.index]);
    }
  }, [showError]);

  const sodItems = props.sodData.map((ele, index) => {
    return (
      <SODItem ele={ele} index={index}
        sodData={props.sodData}
        setSelectedRowIndex={setSelectedRowIndex}
        setcurrDisplayData={setcurrDisplayData}
        selectedRowIndex={selectedRowIndex}
        showError={showError}
        showMitigated={(props.from === 'review' && ele.mitigated && ele.mitigated.mitigatingcontrol) ||
          (props.from === 'approval' && props.enableMitigation && ele.Mitigated_MitigatingControls &&
            ele.Mitigated_MitigatingControls.length > 0)}>
      </SODItem>
    );
  }
  );

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className="sod-multiple-container">
      <div className="violation-header multiple">
        <div className="violation-left">
          <Icon name="violation" size={32} />
          <h4 className="sod-single-header">{`${props.intl.formatMessage({
            id: 'SOD.appReq.carousalsodtitlemulti',
          })} ${props.sodData.length} ${props.intl.formatMessage({
            id: 'SOD.appReq.carousalsodtitlemulticont',
          })}`}</h4>
        </div>
        {enableRecalculateSOD && (
          <Icon name="sod_recalculate" className="icon" onClick={() => recalculateSOD()} />
        )}

        {enableExportSOD && (
          <Icon name="sod_downloadreport" className="icon mr-4" onClick={e => handleClick(e)} />

        )}
        <Menu
          id="long-menu"
          anchorEl={anchorE2}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          open={open}
          onClose={handleClose}
          MenuListProps={{
            onMouseLeave: handleClose,
            disablePadding: true,
          }}
          classes={{
            paper: classes.menuPaper,
          }}
        >
          <MenuItem
            onClose={handleClose}
            classes={{
              root: classes.menuItemRoot,
            }}
            onClick={() => {
              setReportType('auth');
              setShowExportColumnDialog(true);
              getExportSODColumns('auth');
              handleClose();
            }}
          >
            {props.intl.formatMessage({
              id: 'SOD.Export.AuthReport.label',
            })}
          </MenuItem>

          <MenuItem
            onClose={handleClose}
            classes={{
              root: classes.menuItemRoot,
            }}
            onClick={() => {
              setReportType('tcode');
              setShowExportColumnDialog(true);
              getExportSODColumns('tcodedetail');
              handleClose();
            }}
          >
            {props.intl.formatMessage({
              id: 'SOD.Export.TcodeReport.label',
            })}
          </MenuItem>

        </Menu>
      </div>
      <div className="d-flex">
        <div className="left-pane">
          <div>{sodItems}</div>
        </div>
        <div className="right-pane">
          <div className="header">
            <Icon
              name="fullscreen"
              className="zoom"
              onClick={() =>
                props.setSodDetails({
                  showSOD: true,
                  value: 'single_multi',
                  detailData: currDisplayData,
                  selectedRowIndex,
                })
              }
            />

            <div className="header-layout">
              <div
                className={`risktype ${currDisplayData.riskPriority ? currDisplayData.riskPriority : 'High'
                  }`}
              >
                {currDisplayData.riskPriority ? currDisplayData.riskPriority : 'High'}
              </div>
              <div className="name">{`${currDisplayData.ruleSetName ? `${currDisplayData.ruleSetName} - ` : ''}${currDisplayData.Risk}`}</div>
              <div className="subname">{currDisplayData.Description}</div>

              {currDisplayData.Available_MitigatingControls && currDisplayData.Available_MitigatingControls.length > 0 &&
                (!currDisplayData.Mitigated_MitigatingControls ||
                  currDisplayData.Mitigated_MitigatingControls.length === 0) &&
                <div className="recommended">{props.intl.formatMessage({
                  id: 'SOD.recommended.MC.available',
                })}</div>}
              <div className="mitigation-layout">
                {' '}

                {mitigationRequired && props.enableMitigation && (
                  <Button
                    variant="outlined"
                    color="primary"
                    type="button"
                    classes={{ root: classes.button }}
                    onClick={() => {
                      props.fetchAvailableMC(currDisplayData.RiskId, '0');
                      setMitigating(true);
                    }}
                  >
                    {props.intl.formatMessage({ id: 'SODMitigatingCtrlText' })}
                  </Button>
                )}
                {currDisplayData &&
                  currDisplayData.busnessJustification &&
                  currDisplayData.busnessJustification.length > 0 && (

                    <div className="previous-control col-6" aria-describedby={id}
                      onClick={popoverOpen}>
                      {mitigationRequired && props.enableMitigation &&
                        <div className="separator" />}
                      <div className="d-flex align-items-center historylayout">
                        <Icon name="requesthistory" size={20} />
                        {props.intl.formatMessage({ id: 'SOD.SeeHistory.label' })}
                      </div>

                    </div>
                  )}
                {mitigating && (
                  <MitigatingControlDialog
                    mitigationData={props.availableMC}
                    onClose={setMitigating}
                    open={mitigating}
                    addMitigatingCtrl={addMitigatingCtrl}
                    risk={currDisplayData}
                    fetchAvailableMC={(type) => props.fetchAvailableMC(currDisplayData.RiskId, type)}
                  />
                )}
              </div>
            </div>
          </div>
          {props.enableMitigation &&
            currDisplayData.Mitigated_MitigatingControls &&
            currDisplayData.Mitigated_MitigatingControls.length > 0 && (
              <div className="mitigated-layout">
                {<div className="mitigated-titlelayout">
                  <div className="mitigated-title">{props.intl.formatMessage({ id: 'SOD.MC.title' })}</div>
                  {props.editMitigationEnabled && <div className="mitigated-editlayout">
                    <div className="label" onClick={() =>
                      addMitigatingCtrl(currDisplayData.Mitigated_MitigatingControls[0], currDisplayData.exceptionKey, null, "Remove")}>
                      {props.intl.formatMessage({ id: 'SOD.MC.Remove' })}</div>
                    <div className="label" onClick={() => {
                      props.fetchAvailableMC(currDisplayData.RiskId, '0');
                      setMitigating(true);
                    }}> {props.intl.formatMessage({ id: 'SOD.MC.Modify' })}</div>
                  </div>}
                </div>}
                <div className="mitigated-sublayout d-flex">
                  <div className="control-layout">
                    <div className="controlname-label">{props.intl.formatMessage({ id: 'SOD.MC.label' })}</div>
                    <div className="controlname-value">{currDisplayData.Mitigated_MitigatingControls[0].MITIGATINGCONTROL}</div>
                  </div>

                  <div className="control-layout">
                    <div className="controlname-label">{props.intl.formatMessage({ id: 'SOD.MC.Timeframe' })}</div>
                    <div className="controlname-value">{`${currDisplayData.Mitigated_MitigatingControls[0].STARTDATE ?
                      moment(moment.utc(currDisplayData.Mitigated_MitigatingControls[0].STARTDATE)).local().format('YYYY-MM-DD') : ''}  ${currDisplayData.Mitigated_MitigatingControls[0].ENDDATE ?
                        ` - ${moment(moment.utc(currDisplayData.Mitigated_MitigatingControls[0].ENDDATE)).local().format('YYYY-MM-DD')}` : ''}`}</div>
                  </div>
                </div>
              </div>
            )
          }
          <ExportColumnModal
            onClose={() => setShowExportColumnDialog(false)}
            open={showExportColumnDialog}
            sodExportData={sodExportData}
            downLoadSODReport={(selectedValues, rowMappingKey) =>
              downLoadSODReport(selectedValues, rowMappingKey, reportType)}
          />
          <Popover
            id={id}
            open={droppopover}
            anchorEl={anchorEl}
            onClose={popoverClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <div className="busijusti-history">
              <div className="history">{props.intl.formatMessage({ id: 'SOD.SeeHistory.label' })}</div>
              {currDisplayData &&
                currDisplayData.busnessJustification &&
                currDisplayData.busnessJustification.map(item => {
                  let letter = "A";
                  if (item) {
                    const arr = item.split('-');
                    if (arr[1])
                      letter = arr[1].slice(0, 1);
                  }
                  return <div className="iconLayout">
                    <div className="iconLetter">
                      {letter}
                    </div>
                    {item}</div>;
                })}
            </div>
          </Popover>
          {
            commentsRequired && (
              <div className="row busjust-layout">
                <div className="busjust-layout-text">  <TextFieldComponent
                  showError={showError && showError.showError ? commentsMandatory && !currDisplayData.riskComments ? true : false : false}
                  attributeKey='Business Justification'
                  name={currDisplayData.Risk}
                  placeholder={props.intl.formatMessage({ id: 'SOD.BusinessJusti.PlaceHolder' })}
                  _handleChange={value => {
                    if (addbusinessJustiToRisk)
                      addbusinessJustiToRisk(currDisplayData.Risk, value);
                  }}
                  multiline
                  label={props.intl.formatMessage({ id: 'SOD.BusinessJusti.Label' })}
                  value={currDisplayData.riskComments}
                  defaultValue={currDisplayData.riskComments}
                  rows="4"
                  variant="outlined"
                />
                </div>
                {commentsMandatory && <span className='commentsmandatory'>{` * `}</span>}
              </div>
            )
          }
          {
            currDisplayData && currDisplayData.Functions && (<div className="function-layout">
              <div className="function-header">
                {/* <IconButton aria-label="zoom" className={`${classes.icon} funcicon`} fontSize="small">
                <ZoomOutMapIcon />
              </IconButton> */}
                <Icon name="violation" />
                <h4 className="funcname">{`${currDisplayData.Functions.length} ${props.intl.formatMessage({ id: 'SOD.appReq.carousalsodtitlecont' })}`}</h4>
              </div>
              <div className="function-collapse">
                {currDisplayData.Functions.map((item, index) => (
                  <ExpansionPanel expanded={expanded === index} onChange={handleChange(index)}>
                    <ExpansionPanelSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                      classes={{ expandIcon: classes.expandIcon }}
                    >
                      <div className="function-bg">
                        <div className="function-dot" />
                        <div className="function-name">{`${item.FunctionName}${item.Description ? ` (${item.Description})` : ''
                          }`}</div>
                      </div>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails classes={{ root: classes.panelRoot }}>
                      {item.Entitlements.map(ele => {
                        if (ele.sapApplication) {
                          let violatingEntitlement = ele['VIOLATING ENTITLEMENT'];
                          if (!Array.isArray(ele['VIOLATING ENTITLEMENT'])) {
                            violatingEntitlement = ele['VIOLATING ENTITLEMENT'] ? ele['VIOLATING ENTITLEMENT'].split(',') : [];
                          }
                          return (
                            <div className="entitilement-item">
                              <div className="entitilement-layout">

                                <div className="iconlayout">
                                  <img
                                    className="sapicon"
                                    src="/ECMv6/assets/images/app_req_icons/sap.png"
                                    alt="sap"
                                  />
                                  <div className="sapicontext">{props.intl.formatMessage({ id: 'SOD.appReq.SAP.label' })}</div>
                                </div>

                                <div className="entitlementlabelsap">
                                  {props.intl.formatMessage({ id: 'SOD.appReq.ParEntitlement.sap' })}
                                </div>
                                <div className="entitlementval sapval">{ele['PARENT ENTITLEMENT']}</div>
                              </div>
                              <div className="entitilement-layout">
                                <div className="entitlementlabelsap">
                                  {props.intl.formatMessage({ id: 'SOD.appReq.VioEntitlement.sap' })}
                                </div>
                                <div className="entitlementvalsaplayout">{
                                  violatingEntitlement.map(ent => {
                                    return (<div className={`entitlementvalsap ${violatingEntitlement.length > 3 ? 'col-3' :
                                      violatingEntitlement.length === 1 ? 'col-12' : violatingEntitlement.length === 2 ? 'col-6' : 'col-4'}`}>{ent}</div>)
                                  })
                                }</div>
                              </div>
                              <div className="entitilement-layout">
                                {ele['VIOLATING ROLE'] && <div className="entitlementlabelsap">
                                  {props.intl.formatMessage({ id: 'SOD.appReq.VioRole' })}
                                </div>}
                                <div className="entitlementval">{ele['VIOLATING ROLE']}</div>
                              </div>
                            </div>
                          )
                        }
                        let violatingEntitlement = ele['VIOLATING ENTITLEMENT'];
                        if (Array.isArray(ele['VIOLATING ENTITLEMENT']) && ele['VIOLATING ENTITLEMENT'].length > 0) {
                          violatingEntitlement = ele['VIOLATING ENTITLEMENT'].join(', ');
                        }

                        return (
                          <div className="entitilement-item">
                            <div className="entitilement-layout">
                              <div className="entitlementlabel">
                                {props.intl.formatMessage({ id: 'SOD.appReq.ParEntitlement' })}
                              </div>
                              <div className="entitlementval">{ele['PARENT ENTITLEMENT']}</div>
                            </div>
                            <div className="entitilement-layout">
                              <div className="entitlementlabel">
                                {props.intl.formatMessage({ id: 'SOD.appReq.VioEntitlement' })}
                              </div>
                              <div className="entitlementval">{violatingEntitlement}</div>
                            </div>
                            <div className="entitilement-layout">
                              {ele['VIOLATING ROLE'] && <div className="entitlementlabel">
                                {props.intl.formatMessage({ id: 'SOD.appReq.VioRole' })}
                              </div>}
                              <div className="entitlementval">{ele['VIOLATING ROLE']}</div>
                            </div>
                          </div>
                        )
                      })}
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                ))}
              </div>
            </div>)
          }
        </div >
      </div >
    </div >
  );
};
SODMultiple.defaultProps = {
  commentsRequired: false,
  mitigationRequired: false,
  recalculateSOD: null,
  addMitigatingCtrl: null,
  addbusinessJustiToRisk: null,
  activeIndex: 0,
  commentsMandatory: false,
  availableMC: [],
  enableExportSOD: false,
  getExportColumns: null,
};
SODMultiple.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  sodData: PropTypes.arrayOf(
    PropTypes.shape({
      Risk: PropTypes.string,
      Functions: PropTypes.arrayOf(PropTypes.shape({ FunctionName: PropTypes.string })),
      riskComments: PropTypes.string,
    })
  ).isRequired,
  intl: PropTypes.arrayOf(
    PropTypes.shape({
      locale: PropTypes.string.isRequired,
      formatMessage: PropTypes.func.isRequired,
    })
  ).isRequired,
  setSodDetails: PropTypes.func.isRequired,
  commentsRequired: PropTypes.bool,
  mitigationRequired: PropTypes.bool,
  recalculateSOD: PropTypes.func,
  addMitigatingCtrl: PropTypes.func,
  addbusinessJustiToRisk: PropTypes.func,
  activeIndex: PropTypes.number,
  commentsMandatory: PropTypes.bool,
  availableMC: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string })),
  exportSOD: PropTypes.bool,
  getExportColumns: PropTypes.func,
};

export default injectIntl(withRouter(SODMultiple));
