/* eslint-disable no-use-before-define */
import React, { useEffect, useRef } from 'react'; // jsx needed in few util methods
import { useSelector } from 'react-redux';
import ReactDOM from 'react-dom';
// import store from 'misc/store';
import queryString from 'query-string';
import moment from 'moment';
import { intersectionBy, uniqBy, flatten } from 'lodash';
import { Cookies } from 'react-cookie';
// import { put } from 'redux-saga/effects';
import get from 'lodash/get';
import {
  Icon,
  EllipsedLabelHtmlToolTip,
  EllipsedLabel,
  EllipsedViewMoreToolTip,
} from 'ui-components/src';
// import { userModulesAction, loginInfoAction } from 'ui-common/src/utils/LoginActions';
import SecureLS from 'secure-ls';
// import config from '../config/constants';

// const demoModeUrl = 'https://ssm-pocpreview.saviyntcloud.com/ECM';
// const ecmUrl = 'https://ssm-pocpreview.saviyntcloud.com/ECM';

const cookies = new Cookies();
const propertyMappings = {
  list: {},
  columnvalues: {},
};
const requestTypeValues = {
  REQ_TYPE_ENABLE_ACCOUNT: 29,
  REQ_TYPE_DISABLE_ACCOUNT: 30,
  REQ_TYPE_LOCK_ACCOUNT: 31,
  REQ_TYPE_UNLOCK_ACCOUNT: 32,
};
const supportedRequestTypesForModifyRequests = {
  REQ_TYPE_NEW_ACCOUNT_REQUEST: 3,
  REQ_TYPE_GRANT_ACCESS: 1,
  REQ_TYPE_MODIFY_ACCOUNT: 1,
  REQ_TYPE_UPDATE_ACCOUNT: 12,
  REQ_ENTERPRISE_ROLE: 4,
  REQ_REMOVE_ACCESS: 2,
  REQ_ALL: [3, 1, 12, 4, 2],
};
const RequestTypeList = {
  'New Account Request': 1,
  'Modify Account': 1,
  'Grant Access': 1,
  'Revoke Access': 1,
  'Remove Account': 1,
  'Update Account': 1,
  'Update Access End Date': 1,
  'Role Modify': 1,
  'Enterprise Role Request': 1,
  'Proposed Accounts': 1,
  'Proposed Entitlement': 1,
  'Privileged Role Request': 1,
  'Create Badge': 1,
  'Replace Badge': 1,
  'Update Badge': 1,
  'Remove Badge': 1,
  'Organization Modify': 1,
  'Create User': 1,
  'Update User': 1,
  'Create Rule': 1,
  'Update Rule Request': 1,
  'Disable Rule': 1,
  'Create Entitlement Request': 1,
  'Create Role Request': 1,
  'Update Entitlement Request': 1,
  'Modify Role Request': 1,
  'Privilege ID Request': 1,
  'Privilege ID Assignment': 1,
  'Transport': 1,
};
const RequestStatusList = {
  'REQUEST.STATUS.APPROVED': 1,
  'REQUEST.STATUS.COMPLETED': 1,
  'REQUEST.STATUS.DISCONTINUED': 1,
  'REQUEST.STATUS.EXPIRED': 1,
  'REQUEST.STATUS.PARTIALLY.APPROVED': 1,
  'REQUEST.STATUS.PENDING': 1,
  'REQUEST.STATUS.REJECTED': 1,
};
const supportedRequestTypes = [
  'New Account Request',
  'Enterprise Role Request',
  'Privilege ID Request',
  'Remove Access',
  'Grant Access',
  'Update Account',
  'Privileged Role Request',
  'Remove Account',
  'Privilege ID Assignment',
  'Revoke Access',
  'Modify Account',
  'Update Access End Date',
  'Enable Account',
  'Disable Account',
  'Unlock Account',
  'Lock Account',
  'Add Access',
];
const approvalStatusPending = 'REQUEST.ACCESS.STATUS.PENDING';
const approvalStatusMapping = {
  New: 'Pending',
  Escalate: 'Pending',
  Reassigned: 'Pending',
  Approved: 'Approved',
  Rejected: 'Rejected',
  Expire: 'Rejected',
  Discontinued: 'Discontinued',
  PartiallyApproved: 'Partially Approved',
  Pending: 'Pending',
};
const getStatusClass = (status, fromApprovals) => {
  switch (status) {
    case 'REQUEST.STATUS.PARTIALLY.APPROVED':
    case 'REQUEST.STATUS.APPROVED':
    case 'REQUEST.ACCESS.STATUS.PARTIALLY.APPROVED':
    case 'REQUEST.ACCESS.STATUS.APPROVED':
    case 'status.accessGranted':
    case 'status.accessExisted':
    case 'status.partialAccessGranted':
    case 'status.sessionAvailable':
      return fromApprovals ? 'PendingApproval' : 'Approved';
    case 'REQUEST.STATUS.EXPIRED':
    case 'REQUEST.STATUS.DISCONTINUED':
    case 'REQUEST.STATUS.REJECTED':
    case 'REQUEST.ACCESS.STATUS.DISCONTINUED':
    case 'REQUEST.ACCESS.STATUS.REJECTED':
    case 'REQUEST.ACCESS.STATUS.EXPIRED':
    case 'status.accessFailed':
    case 'status.sessionFailed':
    case 'status.requestDiscontinued':
    case 'status.requestExpired':
    case 'status.requestRejected':
    case 'status.discontinued':
      return 'Rejected';
    default:
      return 'Pending';
  }
};
const statusMapping = {
  'Request Completed, Task Pending': 'Pending',
  'In Process': 'Pending',
  Open: 'Pending',
  Draft: 'Pending',
  'Request Completed, Task Completed': 'Approved',
  Approved: 'Approved',
  Completed: 'Approved',
  Discontinued: 'Discontinued',
  Expired: 'Expired',
  Pending: 'Pending',
  'Partially Approved': 'Partially Approved',
  Rejected: 'Rejected',
};
const userAttributePrefix = 'Users.';
const userAttributePostfix = '.label';
const accountAttributePrefix = 'Accounts.';
const accountAttributePostfix = '.label';

const statusMappingToApi = {
  Pending: 'Request Completed, Task Pending:In Process:Open:Draft',
  Approved: 'Request Completed, Task Completed:Approved:Completed',
  Rejected: 'Discontinued:Expired',
};
const isJson = obj => {
  try {
    JSON.parse(obj);
    return true;
  } catch (e) {
    return false;
  }
};

const filterObj = (obj, matchKey) => {
  if (!matchKey) {
    return obj;
  }

  const filteredKeys = Object.keys(obj).filter(
    key => key.toLowerCase().indexOf(matchKey.toLowerCase()) >= 0
  );
  const result = {};
  filteredKeys.forEach(filteredKey => {
    result[filteredKey] = obj[filteredKey];
  });
  return result;
};
function tagStringToObj(tagString) {
  const obj = {};
  if (tagString && tagString.length > 0) {
    tagString.split(',').forEach(pair => {
      const [a, b] = pair.split(':');
      obj[a] = b;
    });
  }
  return obj;
}
const convertApiToRedux = (apiName, dataArr) => {
  if (apiName === 'selectInstances') {
    const conversion = {
      endpointname: 'instanceId',
      customproperty17: 'awsAccount',
      customproperty10: 'awsRegion',
      customproperty16: 'instanceOS',
      customproperty5: 'environment',
      accontName: 'userId',
      endpointCustomproperty10: 'region',
      customproperty11: 'vpc',
      customproperty2: 'tags',
      customproperty9: 'privateIP',
      customproperty7: 'DNS',
      customproperty15: 'publicDNS',
      displayName: 'displayName',
      endpointKey: 'endpointKey',
      remoteAppDetails: 'remoteAppDetails',
      exclusiveAccess: 'exclusiveAccess',
      createDate: 'createDate',
      description: 'description',
      customproperty19: 'applicationType',
    };
    const returnArr = [];
    dataArr.forEach(data => {
      const returnObj = {};
      Object.keys(conversion).forEach(apiKey => {
        returnObj[conversion[apiKey]] = data[apiKey];
      });
      returnArr.push(returnObj);
    });
    return returnArr;
  }
  if (apiName === 'instanceConfiguration') {
    const conversion = {
      endpointname: 'instanceId',
      customproperty17: 'accountName',
      customproperty10: 'instanceRegion',
      customproperty16: 'instanceOS',
      customproperty5: 'environment',
      displayName: 'displayName',
      endpointKey: 'endpointKey',
      pamState: 'status',
      pamErrorDescription: 'errorDescription',
    };
    const returnArr = [];
    dataArr.forEach(data => {
      const returnObj = {};
      // for customproperty2 we need to convert to obj since tags are coming as strings.
      Object.keys(conversion).forEach(apiKey => {
        returnObj[conversion[apiKey]] =
          apiKey === 'customproperty2' ? tagStringToObj(data[apiKey]) : data[apiKey];
      });
      returnArr.push(returnObj);
    });
    return returnArr;
  }
  if (apiName === 'mySessions') {
    const conversion = {
      accontName: 'privilegeId',
      endpointName: 'instanceIdSession',
      endpointCustomproperty10: 'region',
      endpointCustomproperty9: 'hostIp',
      endpointCustomproperty15: 'publicIpSession',
      application: 'application',
      requestId: 'requestId',
      startDate: 'startDate',
      endDate: 'enddate',
      endPointDisplayname: 'instanceId',
      requestAccessKey: 'requestAccessKey',
      username: 'username',
      accountId: 'accountKey',
      pamType: 'pamType',
      endpointCustomproperty16: 'osType',
      requestAccessStatus: 'reqStatus',
      // arsTaskstatus: 'taskStatus',
      status: 'taskStatus',
      automatedProvisioning: 'automatedProvisioning',
      entitlementName: 'entitlementName',
      credentialType: 'credentialtype',
      connectionType: 'connectionType',
      remoteApp: 'remoteApp',
      endpointCustomproperty35: 'port',
      accountType: 'accountType',
      remoteAppMetadata: 'remoteAppMetadata',
      ffidEndDate: 'ffidEndDate',
      ffidStartDate: 'ffidStartDate',
      requestType: 'requestType',
      authenticationType: 'authenticationType',
      codes: 'codes',
      accountKey: 'accountId',
      endpointKey: 'endpointKey',
      sapHost: 'sapHost',
      sapInstanceId: 'sapInstanceId',
      sapSystemId: 'sapSystemId',
      awsAccountId: 'awsAccountKey',
    };
    const returnArr = [];
    dataArr.forEach(data => {
      const returnObj = {};
      Object.keys(conversion).forEach(apiKey => {
        returnObj[conversion[apiKey]] = data[apiKey];
      });
      returnArr.push(returnObj);
    });

    return returnArr;
  }
  if (apiName === 'roles') {
    const conversion = {
      columnname: 'viewKey',
      // columnname: 'key',
      displayname: 'label',
    };
    const returnArr = [];
    dataArr.forEach(data => {
      const returnObj = {};
      Object.keys(conversion).forEach(apiKey => {
        returnObj[conversion[apiKey]] = data[apiKey];
      });
      returnArr.push(returnObj);
    });

    return returnArr;
  }
  if (apiName === 'credential' || apiName === 'connection') {
    const conversion = {
      columnname: 'viewKey',
      // columnname: 'key',
      displayname: 'label',
    };
    const returnArr = [];
    dataArr.forEach(data => {
      const returnObj = {};
      Object.keys(conversion).forEach(apiKey => {
        returnObj[conversion[apiKey]] = data[apiKey];
      });
      returnArr.push(returnObj);
    });

    return returnArr;
  }

  return {};
};

/**
 *
 *  Base64 encode / decode
 *  http://www.webtoolkit.info
 *
 * */
var Base64 = {
  // private property
  _keyStr: 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=',

  // public method for encoding
  encode(input) {
    let output = '';
    let chr1;
    let chr2;
    let chr3;
    let enc1;
    let enc2;
    let enc3;
    let enc4;
    let i = 0;

    input = Base64._utf8_encode(input);

    while (i < input.length) {
      chr1 = input.charCodeAt(i++);
      chr2 = input.charCodeAt(i++);
      chr3 = input.charCodeAt(i++);

      enc1 = chr1 >> 2;
      enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
      enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
      enc4 = chr3 & 63;

      if (isNaN(chr2)) {
        enc3 = enc4 = 64;
      } else if (isNaN(chr3)) {
        enc4 = 64;
      }

      output =
        output +
        this._keyStr.charAt(enc1) +
        this._keyStr.charAt(enc2) +
        this._keyStr.charAt(enc3) +
        this._keyStr.charAt(enc4);
    } // Whend

    return output;
  }, // End Function encode

  // public method for decoding
  decode(input) {
    let output = '';
    let chr1;
    let chr2;
    let chr3;
    let enc1;
    let enc2;
    let enc3;
    let enc4;
    let i = 0;

    input = input.replace(/[^A-Za-z0-9\+\/\=]/g, '');
    while (i < input.length) {
      enc1 = this._keyStr.indexOf(input.charAt(i++));
      enc2 = this._keyStr.indexOf(input.charAt(i++));
      enc3 = this._keyStr.indexOf(input.charAt(i++));
      enc4 = this._keyStr.indexOf(input.charAt(i++));

      chr1 = (enc1 << 2) | (enc2 >> 4);
      chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
      chr3 = ((enc3 & 3) << 6) | enc4;

      output += String.fromCharCode(chr1);

      if (enc3 != 64) {
        output += String.fromCharCode(chr2);
      }

      if (enc4 != 64) {
        output += String.fromCharCode(chr3);
      }
    } // Whend

    output = Base64._utf8_decode(output);

    return output;
  }, // End Function decode

  // private method for UTF-8 encoding
  _utf8_encode(string) {
    let utftext = '';
    string = string.replace(/\r\n/g, '\n');

    for (let n = 0; n < string.length; n++) {
      const c = string.charCodeAt(n);

      if (c < 128) {
        utftext += String.fromCharCode(c);
      } else if (c > 127 && c < 2048) {
        utftext += String.fromCharCode((c >> 6) | 192);
        utftext += String.fromCharCode((c & 63) | 128);
      } else {
        utftext += String.fromCharCode((c >> 12) | 224);
        utftext += String.fromCharCode(((c >> 6) & 63) | 128);
        utftext += String.fromCharCode((c & 63) | 128);
      }
    } // Next n

    return utftext;
  }, // End Function _utf8_encode

  // private method for UTF-8 decoding
  _utf8_decode(utftext) {
    let string = '';
    let i = 0;
    let c;
    let c1;
    let c2;
    let c3;
    c = c1 = c2 = 0;

    while (i < utftext.length) {
      c = utftext.charCodeAt(i);

      if (c < 128) {
        string += String.fromCharCode(c);
        i++;
      } else if (c > 191 && c < 224) {
        c2 = utftext.charCodeAt(i + 1);
        string += String.fromCharCode(((c & 31) << 6) | (c2 & 63));
        i += 2;
      } else {
        c2 = utftext.charCodeAt(i + 1);
        c3 = utftext.charCodeAt(i + 2);
        string += String.fromCharCode(((c & 15) << 12) | ((c2 & 63) << 6) | (c3 & 63));
        i += 3;
      }
    } // Whend

    return string;
  }, // End Function _utf8_decode
};

const getDisplayName = name => {
  const displayName = `${name.firstName ? name.firstName : ''} ${name.lastName ? name.lastName : ''
    } 
  ${name.userName ? `(${name.userName})` : ''}`;
  return displayName;
};

const convertReduxToApi = apiName => {
  if (apiName === 'selectInstances') {
    return {
      instanceId: 'endpointname',
      awsAccount: 'customproperty17',
      awsRegion: 'customproperty10',
      region: 'endpointCustomproperty10',
      instanceOS: 'customproperty16',
      environment: 'customproperty5',
      instanceName: 'displayName',
      publicIp: 'customproperty15',
      privateIp: 'customproperty9',
      tags: 'customproperty2',
      displayName: 'displayName',
    };
  }
  if (apiName === 'mySessions') {
    return {
      privilegeId: 'accontName',
      instanceIdSession: 'endpointName',
      region: 'region',
      application: 'application',
      requestId: 'requestId',
      startDate: 'startDate',
      enddate: 'enddate',
      instanceId: 'endPointDisplayname',
      requestAccessKey: 'requestAccessKey',
      username: 'username',
      accountId: 'accountId',
      resourceId: 'endPointDisplayname',
    };
  }
  if (apiName === 'columnvalues') {
    return {
      instanceId: 'endpointname',
      awsAccount: 'customproperty17',
      awsRegion: 'customproperty10',
      instanceOS: 'customproperty16',
      environment: 'customproperty5',
      instanceName: 'displayName',
      publicIp: 'customproperty15',
      privateIp: 'customproperty9',
      tags: 'customproperty2',
      displayName: 'displayName',
      region: 'endpointCustomproperty10',
      requestId: 'requestId',
      resourceId: 'endPointDisplayname',
      application: 'application',
      userId: 'accountName',
      privilegeId: 'accontName',
      requestAccessKey: 'requestAccessKey',
      username: 'username',
      instanceIdSession: 'endpointName',
      instanceOSSession: 'endpointCustomproperty16',
      publicIpSession: 'endpointCustomproperty15',
      privateIpSession: 'endpointCustomproperty9',
      tagsSession: 'endpointCustomproperty2',
      awsAccountSession: 'endpointCustomproperty17',
      startDate: 'startdate',
      enddate: 'enddate',
      dueDate: 'dueDate',
      requestType: 'requestType',
      requestDate: 'requestDate',
      requestedFor: 'requestedFor',
      requestor: 'requestor',
      assignee: 'assignee',
      requestKey: 'requestKey',
      reqId: 'reqId',
      status: 'status',
      requestHistoryId: 'requestId',
      email: 'email',
      manager: 'manager',
      endpoints: 'endpoints',
      createDate: 'createDate',
      description: 'description',
      endpoint: 'endpoint',
      user: 'user',
      account: 'account',
      sessiontype: 'sessiontype',
      sourceIp: 'sourceIp',
      endDate: 'enddate',
      credentialType: 'credentialType',
      connectionType: 'connectionType',
      applicationType: 'customproperty19',
    };
  }
  return {};
};

// window.envConfig = {};
export function initialiseEnv(env) {
  if (env) {
    window.envConfig = env;
  }
}
export function checkEnvironment() {
  return window.envConfig || {};
}
async function getUIMappedStatusValues(values, fromApiToUi) {
  const mappedStatusValues = {};
  if (fromApiToUi) {
    Object.keys(values).forEach(key => {
      mappedStatusValues[statusMapping[key]] = 1;
    });
  }
  return mappedStatusValues;
}
async function getDistinctColumnValues(column, appliedFilters, reqBodyOptions, internalCacheEnabled) {
  // Do ajax and get distinct column values.
  const { key } = column;
  if (internalCacheEnabled && column.isCacheEnabled) {
    if (column.key === 'requestType')
      return RequestTypeList;
    if (column.key === 'status')
      return RequestStatusList;
  }
  const apiFiltersObj = {};
  Object.keys(appliedFilters).forEach(key => {
    if (Array.isArray(appliedFilters[key])) {
      const value = appliedFilters[key].length
        ? column.filter.filterURL.includes(`requests/`)
          ? appliedFilters[key].join(';')
          : appliedFilters[key].join()
        : undefined;
      apiFiltersObj[convertReduxToApi('columnvalues')[key] || key] = value;
    }
    if (typeof appliedFilters[key] === 'string') {
      const value = appliedFilters[key].length ? appliedFilters[key] : undefined;

      apiFiltersObj[convertReduxToApi('columnvalues')[key] || key] = value;
    }
  });
  const defaultRequestBody = {
    connectiontype: 'AWS',
    accounttype: 'FIREFIGHTERID',
    userKey: cookies.get('userKey'),
    orderBy: 'asc',
    filterby: {
      ...apiFiltersObj,
      [convertReduxToApi('columnvalues')[key] || key]: undefined,
    },
    column: convertReduxToApi('columnvalues')[key] || key,
  };
  let requestBody = {
    ...defaultRequestBody,
    ...column.options,
    ...reqBodyOptions,
  };
  if (column.filter && column.filter.defaultReqBody) {
    requestBody = { ...requestBody, ...column.filter.defaultReqBody };
    if (column.filter.defaultReqBody.filterBy) {
      requestBody.filterBy = requestBody.filterby;
    }
  }
  if (column.filter.filterURL.includes(`requests/`)) {
    requestBody = {
      offset: 0,
      max: 25,
      filterBy: {
        ...apiFiltersObj,
        [convertReduxToApi('columnvalues')[key] || key]: undefined,
      },
    };
    requestBody.column = convertReduxToApi('columnvalues')[key] || key;
    requestBody.username = cookies.get('user_name');
  }

  const response = await fetch(column.filter.filterURL, {
    method: 'POST',
    body: JSON.stringify(requestBody),
    headers: {
      'Content-Type': 'application/json',
      'X-NOLOADER': true,
    },
  });
  try {
    const res = await response.json();
    // if (column.filter.filterURL.includes(`requests/`) && requestBody.column === 'status') {
    //   res = await getUIMappedStatusValues(res, true);
    // }
    return res;
  } catch (error) {
    // error
  }
  return null;
}

async function testConnection(payload) {
  const SERVICE_ENDPOINT_IDWMS = checkEnvironment().IDWMS_API_URL;
  const url = `${SERVICE_ENDPOINT_IDWMS}/admin/applications/testconnection`;
  // const url = '/ECM/api/v5/testConnection';
  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify({
      systemname: 'AOB', // application name that user input
      saveconnection: 'Y',
      ...payload,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
  });
  try {
    return await response.json();
  } catch (error) {
    // error
  }
  return null;
}

async function getSessionModalDetails(data) {
  // Do ajax
  const SERVICE_ENDPOINT = checkEnvironment().PAMMS_API_URL;
  const userkey = cookies.get('userKey');
  let url = `${SERVICE_ENDPOINT}/privilegedinstance/sessions/${data.requestAccessKey}?userkey=${userkey}`;
  if (data.credentialtype === 'credential') {
    url = `${SERVICE_ENDPOINT}/privilegedinstance/sessions/${data.requestAccessKey}/credential?userkey=${userkey}`;
  }
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  });
  try {
    // const resp = {
    //   accountId: '661222050851',
    //   password: 'Myaccount123#',
    //   userName: 'ec2-user',
    //   accessKey1: 'Test',
    //   accessKey2: 'test',
    // };
    return await response.json();
  } catch (error) {
    // error
  }
  return null;
}
// 2018-12-01 16:50:00
async function getAvailableSlots(data, startDate, endDate) {
  const SERVICE_ENDPOINT = checkEnvironment().PAMMS_API_URL;
  const url = `${SERVICE_ENDPOINT}/privilegedsession/slots?accountName=${data}
  &fromDate=${startDate}&toDate=${endDate}`;

  // const response = [
  //   {
  //     requestAccessKey: 262,
  //     requestId: 'FFIDmanagerapproval.100001',
  //     requestType: 23,
  //     connectionType: 'AWS',
  //     startDate: '2019-10-01T14:15:00.000+0000',
  //     requestAccessStatus: 3,
  //     endpointName: 'i-0ede0da519c3d56cd',
  //     endPointDisplayname: 'i-0ede0da519c3d56cd',
  //     endpointCustomproperty9: '172.31.21.118',
  //     endpointCustomproperty10: 'us-east-1',
  //     endpointCustomproperty15: '52.71.231.2',
  //     endpointCustomproperty16: 'Linux',
  //     username: 'admin',
  //     accontName: 'ec2-user',
  //     accountId: 2880,
  //     arsTaskstatus: 3,
  //     enddate: '2019-10-02T14:15:00.000+0000',
  //   },
  //   {
  //     requestAccessKey: 265,
  //     requestId: 'FFIDmanagerapproval.100133',
  //     requestType: 23,
  //     connectionType: 'AWS',
  //     startDate: '2018-12-01T13:37:00.000+0000',
  //     requestAccessStatus: 3,
  //     endpointName: 'i-0ede0da519c3d56cd',
  //     endPointDisplayname: 'i-0ede0da519c3d56cd',
  //     endpointCustomproperty9: '172.31.21.118',
  //     endpointCustomproperty10: 'us-east-1',
  //     endpointCustomproperty15: '52.71.231.2',
  //     endpointCustomproperty16: 'Linux',
  //     username: 'admin',
  //     accontName: 'ec2-user',
  //     accountId: 2880,
  //     arsTaskstatus: 3,
  //     enddate: '2018-12-01T13:45:00.000+0000',
  //   },
  // ];

  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return await response.json();
  } catch (error) {
    // error
  }
  return null;
}
function getGridCell(row, column) {
  const cellValue = get(row, column.viewKey);
  if (typeof cellValue !== 'object' && column.maxLength) {
    // if (cellValue) return cellValue.toString().substring(0, column.maxLength); //  to remove material tooltips. TODO

    if (column.defaultToolTip)
      return <EllipsedLabel label={cellValue} maxLength={column.maxLength} />;
    if (column.viewMore)
      return (
        <EllipsedViewMoreToolTip
          title={column.label}
          label={cellValue}
          maxLength={column.maxLength}
        />
      );

    return (
      <EllipsedLabelHtmlToolTip
        title={column.label}
        label={cellValue}
        maxLength={column.maxLength}
      />
    );
  }

  if (cellValue && typeof cellValue === 'object' && cellValue.errorText) {
    if (cellValue.value) {
      return (
        <div className="errorText">
          <Icon name="gridError" />
          {cellValue.value}
          <span className="errorPopup popupButtom">{cellValue.errorText}</span>
        </div>
      );
    }
    return (
      <div className="errorText">
        {' '}
        <Icon name="gridError" />
        {cellValue.errorText}
      </div>
    );
  }
  return cellValue;
}
function convertStatusUiToApi(uiMappedStatus) {
  if (Array.isArray(uiMappedStatus)) {
    const modifiedArray = [];
    uiMappedStatus.forEach(element => {
      const values = statusMappingToApi[element].split(':');
      values.forEach(element => {
        modifiedArray.push(element);
      });
    });
    return modifiedArray;
  }
  const values = statusMappingToApi[uiMappedStatus].split(':');
  if (values.length > 1) {
    const modifiedArray = [];
    values.forEach(element => {
      modifiedArray.push(element);
    });
    return modifiedArray;
  }
  return values[0];
}

function prepareApiFilterObject(filters, apiName, delimiter, statusMappingRequired) {
  const apiFiltersObj = {};
  Object.keys(filters).forEach(key => {
    if (Array.isArray(filters[key])) {
      if (filters[key].length) {
        let mappedApiArray = filters[key];
        if (statusMappingRequired && key === 'status') {
          mappedApiArray = convertStatusUiToApi(filters[key]);
        }

        apiFiltersObj[convertReduxToApi(apiName)[key]] = delimiter
          ? mappedApiArray.join(delimiter)
          : mappedApiArray.join();
      } else {
        apiFiltersObj[convertReduxToApi(apiName)[key]] = undefined;
      }
    }
    if (typeof filters[key] === 'string') {
      let mappedApiArray = filters[key];
      if (statusMappingRequired && key === 'status')
        mappedApiArray = convertStatusUiToApi(filters[key]);
      if (Array.isArray(mappedApiArray)) {
        apiFiltersObj[convertReduxToApi(apiName)[key]] = delimiter
          ? mappedApiArray.join(delimiter)
          : mappedApiArray.join();
      } else apiFiltersObj[convertReduxToApi(apiName)[key]] = mappedApiArray;
    }
  });
  return apiFiltersObj;
}

function convertToUtc(date) {
  return moment.utc(moment(date, 'YYYY-MM-DD HH:mm:ss')).format('YYYY-MM-DD HH:mm:ss');
}

function formatDateToStr(date, params) {
  let dateFormat = 'MMM DD, YYYY hh:mm A';
  if (params && typeof params === 'object') {
    if (params.format) dateFormat = params.format;
    else if (params.hideYear) dateFormat = 'MMM DD h:mm A';
    else if (params.hideTime) dateFormat = 'MMM DD YYYY';
  }
  return moment(date)
    .local()
    .format(dateFormat);
}

function formatDate(date){
  let finalDate = `${moment
    .utc(moment(date, 'YYYY-MM-DD HH:mm'))
    .format('ddd, DD MMM YYYY HH:mm:ss')} GMT`;
    return finalDate;
}

function convertLocalToGMT(localDateStr) {
  return `${moment(localDateStr || '')
    .utc()
    .format('ddd, DD MMM YYYY HH:mm:ss')} GMT`;
}
function areSameLocalDate(date, another) {
  return moment(date).format('L') === moment(another).format('L');
}
function cssSupported(prop, value) {
  const d = document.createElement('div');
  d.style[prop] = value;
  return d.style[prop] === value;
}

function hideBackgroundScroll(isScrollHidden) {
  const body = document.getElementsByClassName('body')[0];
  if (isScrollHidden) {
    body.classList.add('modal-open');
  } else {
    body.classList.remove('modal-open');
  }
}

function setFullScreenOpen(isFullScreen) {
  const body = document.getElementsByClassName('body')[0];
  if (isFullScreen) {
    body.classList.add('fullscreen-open');
  } else {
    body.classList.remove('fullscreen-open');
  }
}
function bookmarkableUrl(inputObj) {
  const url = Base64.encode(JSON.stringify(inputObj));
  return url;
}
const bookMarkIt = (history, bookMarkObj, appName, entitlement, findby, pamType) => {
  if (entitlement) {
    history.push(
      `?name=${appName}&pamType=${pamType ||
      ''}&entitlement=${entitlement}&filter=${bookmarkableUrl(bookMarkObj)}&findby=${findby}`
    );
  } else {
    history.push(`?name=${appName}&filter=${bookmarkableUrl(bookMarkObj)}&findby=${findby}`);
  }
};
function paramsFromBookMarkedUrl(e) {
  const decodedParams = Base64.decode(e);
  const obj = JSON.parse(decodedParams);
  return obj;
}
function getInitialFilterObj(searchQuery) {
  const filter = queryString.parse(searchQuery) ? queryString.parse(searchQuery).filter : null;
  let landingFilterObj = {
    appliedFilters: {},
    globalSearchText: '',
    reqBodyOptions: { max: 25, offset: 0 },
  };
  if (filter) {
    landingFilterObj = paramsFromBookMarkedUrl(queryString.parse(searchQuery).filter);
  }
  return landingFilterObj;
}
function isFiltersApplied(appliedFilters, globalSearchText) {
  return Object.keys(appliedFilters).length > 0 || globalSearchText.length > 0;
}
function instanceCheckoutStartDateTime() {
  const dateNow = moment();
  const fromTime =
    dateNow.get('minutes') >= 30
      ? { hours: dateNow.get('hours') + 1, minutes: 0, seconds: 0 }
      : { hours: dateNow.get('hours'), minutes: 30, seconds: 0 };
  const fromDate = dateNow.set({ hours: 0, minutes: 0, seconds: 0 });
  return { defaultFromTime: fromTime, defaultFromDate: fromDate };
}
function instanceCheckoutEndDateTime() {
  const dateEnd = moment().add(8, 'hours');
  const toTime =
    dateEnd.get('minutes') >= 30
      ? { hours: dateEnd.get('hours') + 1, minutes: 0, seconds: 0 }
      : { hours: dateEnd.get('hours'), minutes: 30, seconds: 0 };
  const toDate = dateEnd.set({ hours: 0, minutes: 0, seconds: 0 });
  return { defaultToTime: toTime, defaultToDate: toDate };
}
function getCommonPrivilegIds(instances) {
  let commonInstanceIds = instances[0].privilegedId;
  for (let i = 1; i < instances.length; i += 1) {
    commonInstanceIds = intersectionBy(commonInstanceIds, instances[i].privilegedId, 'name');
  }
  return commonInstanceIds;
}
function getUnionOfPrivilegeIds(instances) {
  const privilegeIdArrays = instances.map(instance => instance.privilegedId);
  const uniqPrivIds = uniqBy(flatten(privilegeIdArrays), 'name');
  return uniqPrivIds;
}

function preparePrivilegeIdDropDownOtions(privIdsArray, isLoggedInUserAlreadyPrivileged) {
  if (isLoggedInUserAlreadyPrivileged) {
    return privIdsArray.map(account => ({
      id: account.accountkey,
      label: account.name,
      accountKey: account.accountkey,
    }));
  }
  return [
    ...privIdsArray.map(account => ({
      id: account.accountkey,
      label: account.name,
      accountKey: account.accountkey,
    })),
    {
      accountKey: 'newPrivilege',
      id: cookies.get('user_name').split('@')[0],
      labelHtml: (
        <div>
          <span style={{ color: 'blue' }}>+ New Privilege ID </span>(
          <span>{cookies.get('user_name').split('@')[0]}</span>)
        </div>
      ),
      label: cookies.get('user_name').split('@')[0],
    },
  ];
}
function prepTimeObjFromTime(defaultValue) {
  // takes input of form {hours:20,minutes:25,seconds:0} and outputs format suitable for disoplay in ui component .1.e
  // output: {display:"8:25pm", value{hours:20,minutes:25, seconds:0}}
  const meridiem = defaultValue.hours >= 12 ? 'pm' : 'am';
  const hours = defaultValue.hours > 12 ? defaultValue.hours - 12 : defaultValue.hours;

  const timeValue = {
    display: `${hours}:${defaultValue.minutes === 0 ? '00' : defaultValue.minutes}${meridiem}`,
    value: defaultValue,
  };
  return timeValue;
}
function isFiltersAppliedForList(globalSearchText) {
  return globalSearchText.length > 0;
}

const CopyToClipBoard = eleId => {
  const copyText = document.getElementById(eleId);
  copyText.select();
  copyText.setSelectionRange(0, 99999);
  document.execCommand('copy');
};

const showLeftDurationForSession = endDate => {
  const expiration = endDate;
  const now = moment();
  const exp = moment(expiration);

  const days = exp.diff(now, 'days');
  const hours = exp.subtract(days, 'days').diff(now, 'hours');
  const minutes = exp.subtract(hours, 'hours').diff(now, 'minutes');
  const seconds = exp.subtract(minutes, 'minutes').diff(now, 'seconds');
  if (days === 1 && hours === 1) {
    return `${days} day, ${hours} hour`;
  }
  if (days === 1 && hours > 1) {
    return `${days} day, ${hours} hours`;
  }
  if (days > 1 && hours > 1) {
    return `${days} days, ${hours} hours`;
  }
  if (days > 1 && hours === 1) {
    return `${days} days, ${hours} hour`;
  }
  if (days > 1) {
    return `${days} days`;
  }
  if (days === 1) {
    return `${days} day`;
  }
  if (hours > 0 && minutes > 0) {
    return `${hours} ${hours > 1 ? 'hours' : 'hour'}, ${minutes} minutes`;
  }
  if (hours === 1) {
    return `${hours} hour`;
  }
  if (hours === 0 && days === 0 && minutes > 0) {
    return `${minutes} minutes`;
  }
  if (seconds > 0) {
    return `${seconds} seconds`;
  }
  return '';
};

const matchRouteToRegExArr = (route, regExArr, featureNameList, featureName) => {
  if (!featureName) {
    route = route.includes('?') ? route.split('?')[0] : route; // not dealing with url params at all
    route = route.includes('/ECMv6') ? route.replace('/ECMv6', '') : route;
    return regExArr.filter(regExStr => {
      regExStr = regExStr.includes('?') ? regExStr.split('?')[0] : regExStr; // not dealing with url params at all
      let returnValue = false;
      // double star
      if (regExStr.includes('**')) {
        regExStr = regExStr.replace('/**', '');
        returnValue = route.match(regExStr) !== null;
        return returnValue;
      }
      // single star
      if (regExStr.includes('*')) {
        const routePathParts = route.split('/');
        const regExStrPathParts = regExStr.split('/');
        // get all indexes of star and delete them, same with route, so you get identical strings to match
        const starIndexes = [];
        regExStrPathParts.forEach((part, i) => {
          if (part === '*') starIndexes.push(i);
        });
        starIndexes.forEach(indexOfStar => {
          delete routePathParts[indexOfStar];
          delete regExStrPathParts[indexOfStar];
        });
        const newRoute = routePathParts.join('/');
        const newRegexStr = regExStrPathParts.join('/');
        if (newRoute === newRegexStr) returnValue = true;
        return returnValue;
      }
      // exact match after trim
      if (route === regExStr) {
        returnValue = true;
        return returnValue;
      }
    });
  }
  return featureNameList.filter(val => {
    if (val.trim().toLowerCase() === featureName.trim().toLowerCase()) {
      return true;
    }
  });
};
const ls = new SecureLS({ encodingType: 'aes' });

const oneday = 1000 * 60 * 60 * 24; // should match milliseconds in a day
export const loadStateFromStorage = keyName => {
  try {
    const storedTimeStr = localStorage.getItem('storedTime');
    if (storedTimeStr) {
      const storedTime = Date.parse(storedTimeStr);
      if ((new Date() - storedTime) / oneday > 7) return undefined;
    }
    const serializedState = ls.get(keyName);

    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

export const saveStateToStorage = (keyName, state) => {
  try {
    // const serializedState = JSON.stringify(state.applicationRequest);
    ls.set(keyName, JSON.stringify(state));
    localStorage.setItem('storedTime', new Date());
  } catch (e) {
    // Ignore Error for now
  }
};
export const removeReduxState = keyName => {
  setTimeout(() => {
    localStorage.removeItem(keyName);
    localStorage.removeItem('storedTime');
  }, 2000);
};
// The following method is deprecated. Will be gone soon. Dont Use
const showComponentInPopupModel = (component, show) => {
  return ReactDOM.createPortal.bind(
    null,
    <>
      <div className="modal-backdrop fade show" />
      <div
        className="modal fade show"
        id="myModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModal"
        aria-modal="true"
        onClick={e => {
          show(false);
        }}
      >
        <div
          className="modal-dialog modal-lg"
          role="document"
          id="modalDialog"
          onClick={e => {
            e.stopPropagation();
          }}
        >
          <div className="modal-content">{component}</div>
        </div>
      </div>
    </>,
    document.getElementById('overlaypopup')
  );
};
function convertToGraphCoords(eventData) {
  let x = 0;
  const coordinates = [{ x: 0, y: 0 }];
  eventData.forEach(e => {
    console.log(`event${JSON.stringify(e.criticality)}`);
    switch (e.criticality) {
      case 'None':
        coordinates.push({ x, y: 0 });
        x += e.timespan / 1000;
        if (!Number.isNaN(x)) coordinates.push({ x, y: 0 });
        break;
      case 'Low':
        coordinates.push({ x, y: 1 });
        x += e.timespan / 1000;
        if (!Number.isNaN(x)) coordinates.push({ x, y: 1 });
        break;
      case 'Medium':
        coordinates.push({ x, y: 2 });
        x += e.timespan / 1000;
        if (!Number.isNaN(x)) coordinates.push({ x, y: 2 });
        break;

      case 'High':
        coordinates.push({ x, y: 3 });
        x += e.timespan / 1000;
        if (!Number.isNaN(x)) coordinates.push({ x, y: 3 });
        break;

      case 'Critical':
        coordinates.push({ x, y: 4 });
        x += e.timespan / 1000;
        if (!Number.isNaN(x)) coordinates.push({ x, y: 4 });
        break;

      default:
        coordinates.push({ x, y: 0 });
        x += e.timespan / 1000;
        if (!Number.isNaN(x)) coordinates.push({ x, y: 0 });
        break;
    }
  });
  return coordinates;
}
function ecmLogin() {
  cookies.set('MS_REDIRECT_URL', window.location.href, { path: '/' });
  cookies.set('MS_SUB_DOMAIN', window.location.hostname, { path: '/' });
  // cookies.set('MS_SUB_DOMAIN', 'ssm-eksdev.saviyntcloud.com', { path: '/' });
  window.location.href = '/ECM/logout/clearSession';
}
function pingECM() {
  // dont ping if its localhost
  if (window.location.hostname === 'localhost') return;
  fetch('/ECM/login/ping', {
    method: 'GET',
    headers: { 'X-NOLOADER': true },
  })
    .then(res => console.log(res.json()))
    .catch(error => {
      console.error(error);
    });
}

const validateTimeInput = timeStr => {
  const regex = /^\d{0,2}:\d{0,2}(a|p)?m$/;
  return regex.test(timeStr);
};

const validateRegex = (pattern, input, htmlCheckRequired) => {
  let isValid = true;
  let isHtml = false;
  if (pattern) {
    try {
      const reg = new RegExp(pattern);
      isValid = reg.test(input);
    } catch {
      // exception
    }
  }
  if (htmlCheckRequired) {
    isHtml = /<\/?[a-z][\s\S]*>/i.test(input);
  }

  return !isHtml && isValid;
};

const validateTime = timeStr => {
  const regex = /^[0-1]?\d:[0-5]?\d(a|p)m$/;
  if (regex.test(timeStr)) {
    const [hr, mi] = timeStr.split(':');
    if (parseInt(hr, 10) > 12) return false;
    return {
      hours: parseInt(hr, 10) + (mi.slice(-2) === 'pm') * 12,
      minutes: parseInt(mi.slice(0, -2), 10),
      seconds: 0,
    };
  }
  return false;
};

const formatFormFieldOptions = input => {
  const formatObject = s => ({ ID: s.trim() });
  if (typeof input === 'string') {
    const trimedStr = input.replace(/'/g, '"');
    return trimedStr.split(',').map(formatObject);
  }
  if (Array.isArray(input)) {
    return [...input.map(v => (typeof v === 'string' ? formatObject(v) : v))];
  }
  return [];
};

const JSONStringToObject = str => {
  try {
    const trimedStr = str.replace(/'/g, '"');
    return JSON.parse(trimedStr);
  } catch (e) {
    return {};
  }
};

const replaceLinksWithCookies = str => {
  try {
    const patt = /{((?:\\.|[^}])*)}/gim;
    let match = '';
    while ((match = patt.exec(str))) {
      str = str.replace(
        `{${str.substring(match.index + 1, patt.lastIndex - 1)}}`,
        cookies.get(str.substring(match.index + 1, patt.lastIndex - 1))
      );
    }
  } catch {
    // exception
  }
  return str;
};

const changePageTitle = label => {
  document.title = label;
  return true;
};

async function fetchUsers(params) {
  // Do ajax
  const { isUserGroup, endpointKey } = params;
  const userType = isUserGroup ? 'usergroups' : 'user';
  const SERVICE_ENDPOINT = checkEnvironment().IDWMS_API_URL;
  // const url = `${SERVICE_ENDPOINT}/users/resourceownerlist/${userType}?offset=0&max=100`;
  const url = `${SERVICE_ENDPOINT}/applicationobjects/${userType}/${endpointKey}`;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-NOLOADER': true,
    },
    body: JSON.stringify({}),
  });
  try {
    return await response.json();
  } catch (error) {
    // error
  }
  return null;
}

const fetchOptionsList = async (url, reqBody) => {
  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify(reqBody),
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${cookies.get('token_type')} ${cookies.get('access_token')}`,
    },
  });
  try {
    return await response.json();
    // return await response;
  } catch (error) {
    // error
  }
  return null;
};

function ecmLoginData() {
  // store.dispatch(userModulesAction());
  // store.dispatch(loginInfoAction())
  // store.dispatch({ type: 'loginSuccessAction', json });
}

const useIsMount = () => {
  const isMountRef = useRef(true);
  useEffect(() => {
    isMountRef.current = false;
  }, []);
  return isMountRef.current;
};
function isClickedOnFilterBox(e) {
  let parent = e.target.offsetParent;
  let isClickedInside = false;
  for (let i = 0; i < 5 && !isClickedInside; i++) {
    if (
      parent &&
      parent.classList &&
      parent.classList.value &&
      (parent.classList.value.includes('uniquieField') ||
        parent.classList.value.includes('columnFilterBox'))
    ) {
      isClickedInside = true;
    } else if (parent && parent.offsetParent) {
      parent = parent.offsetParent;
    }
  }
  return isClickedInside;
}
function isClickedOnAutocomplete(e) {
  try {
    return (
      e.path
        .map(t => t.className)
        .filter(c => c && typeof c === 'string' && c.indexOf('MuiAutocomplete') > -1).length > 0
    );
  } catch {
    // exception
  }
  return false;
}

const ConditionalWrapper = ({ condition, children, wrapper, defaultWrapper }) => {
  if (condition) return wrapper(children);
  if (defaultWrapper) return defaultWrapper(children);
  return children;
};

async function validateApplicationName(applicationName) {
  // Do ajax
  const SERVICE_ENDPOINT = checkEnvironment().IDWMS_API_URL;
  const url = `${SERVICE_ENDPOINT}/admin/applications/validateapplicationname/${applicationName}`;
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  });
  try {
    return await response.text();
    // return await response;
  } catch (error) {
    // error
  }
  return null;
}

const getDateAndTime = (fromDateHour, requestableTime, fromDateTime) => {
  const requiredDate =
    fromDateHour >= 23
      ? requestableTime >= '1800'
        ? moment(fromDateTime)
          .add(1, 'days')
          .set({ hour: 0, minute: 0, second: 0 })
          .add(requestableTime, 'seconds')
        : moment(fromDateTime).add(requestableTime, 'seconds')
      : moment(fromDateTime).add(requestableTime, 'seconds');
  return requiredDate;
};

export const loadAControlCenterStateFromStorage = keyName => {
  try {
    const storedTimeStr = localStorage.getItem('storedTime');
    if (storedTimeStr) {
      const storedTime = Date.parse(storedTimeStr);
      if ((new Date() - storedTime) / oneday > 7) return undefined;
    }
    const serializedState = localStorage.getItem('cc');

    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

export const loadApplicationRequestStateFromStorage = keyName => {
  try {
    const storedTimeStr = localStorage.getItem('storedTime');
    if (storedTimeStr) {
      const storedTime = Date.parse(storedTimeStr);
      if ((new Date() - storedTime) / oneday > 7) return undefined;
    }
    const serializedState = localStorage.getItem('ar');

    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};
export const convertDate = rawDate => {
  const DATE_FORMAT = 'MMM DD YYYY HH:mm (z)';
  return moment(new Date(rawDate))
    .utc()
    .format(DATE_FORMAT);
};
export {
  getDisplayName,
  propertyMappings,
  filterObj,
  convertApiToRedux,
  convertReduxToApi,
  prepareApiFilterObject,
  convertToUtc,
  convertLocalToGMT,
  areSameLocalDate,
  formatDateToStr,
  formatDate,
  getDistinctColumnValues,
  getGridCell,
  cssSupported,
  hideBackgroundScroll,
  setFullScreenOpen,
  bookmarkableUrl,
  paramsFromBookMarkedUrl,
  isFiltersApplied,
  isFiltersAppliedForList,
  bookMarkIt,
  getInitialFilterObj,
  instanceCheckoutStartDateTime,
  instanceCheckoutEndDateTime,
  prepTimeObjFromTime,
  getCommonPrivilegIds,
  getUnionOfPrivilegeIds,
  preparePrivilegeIdDropDownOtions,
  showLeftDurationForSession,
  CopyToClipBoard,
  showComponentInPopupModel,
  getSessionModalDetails,
  getAvailableSlots,
  approvalStatusMapping,
  statusMapping,
  convertToGraphCoords,
  matchRouteToRegExArr,
  supportedRequestTypes,
  approvalStatusPending,
  ecmLogin,
  pingECM,
  ecmLoginData,
  validateTime,
  validateTimeInput,
  formatFormFieldOptions,
  JSONStringToObject,
  validateRegex,
  replaceLinksWithCookies,
  changePageTitle,
  userAttributePrefix,
  userAttributePostfix,
  accountAttributePrefix,
  accountAttributePostfix,
  fetchUsers,
  testConnection,
  useIsMount,
  requestTypeValues,
  getStatusClass,
  isClickedOnFilterBox,
  isClickedOnAutocomplete,
  supportedRequestTypesForModifyRequests,
  ConditionalWrapper,
  getDateAndTime,
  validateApplicationName,
  isJson,
  fetchOptionsList,
  RequestTypeList,
  RequestStatusList,
};
