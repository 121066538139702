import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Dialog, Button, IconButton, TextField, Checkbox } from '@material-ui/core';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import CloseIcon from '@material-ui/icons/Close';
import MultiSelectWithSelectAll from './MultiSelectWithSelectAll';

const styles = theme => ({
  root: {
    margin: 0,
    padding: '26px 24px 10px',
    backgroundColor: '#f4f4f4',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  margin: {
    margin: theme.spacing(1),
  },
  paperWidthSm: {
    maxWidth: '36.25rem !important',
    minWidth: '36.25rem !important',
    minHeight: 500,
  },
});

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(0.5),
  },
  outlinedPrimary: {
    fontWeight: 500,
  },
  outlinedSecondary: {
    color: '#f43529',
    border: '1px solid #f43529',
    fontSize: 12,
    fontWeight: 500,
  },
  outlined: {
    padding: '7px 7px',
  },
  paperWidthSm: {
    maxWidth: '36.25rem !important',
    minWidth: '36.25rem !important',
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} timeout={1000} {...props} />;
});

const DialogContent = withStyles(() => ({
  root: {
    padding: '0 24px 24px 24px',
    backgroundColor: '#f4f4f4',
    borderTop: '1px solid #e8e8e8',
    borderBottom: '1px solid #e8e8e8',
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
    backgroundColor: '#f4f4f4',
  },
}))(MuiDialogActions);

const ExportColumnModal = ({ open, onClose, sodExportData, downLoadSODReport, intl }) => {
  const [completeData, setCompleteData] = useState({});
  const [selectedValues, setSelectedValues] = useState({});

  const DialogTitle = withStyles(styles)(props => {
    const { classes, onClose } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root}>
        <h6 className="discard-title">{intl.formatMessage({ id: 'SOD.Export.Title' })}</h6>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });

  const classes = useStyles();

  const rowMappingKey = {
    entitlementValuesProperties: {
      apiAttr: 'entAttrs',
      label: intl.formatMessage({ id: 'SODExport.Entitlement.label' }),
    },
    functionsProperties: {
      apiAttr: 'funcattrs',
      label: intl.formatMessage({ id: 'SODExport.Function.label' }),
    },
    mitigatingControlsProperties: {
      apiAttr: 'mcattrs',
      label: intl.formatMessage({ id: 'SODExport.MitigatingControls.label' }),
    },
    riskProperties: {
      apiAttr: 'riskAttrs',
      label: intl.formatMessage({ id: 'SODExport.Risk.label' }),
    },
    usersProperties: {
      apiAttr: 'userAttrs',
      label: intl.formatMessage({ id: 'SODExport.User.label' }),
    },
    genericAttrs: {
      apiAttr: 'genericAttrs',
      label: intl.formatMessage({ id: 'SODExport.Generic.label' }),
    },
  };

  useEffect(() => {
    if (sodExportData && Object.keys(sodExportData).length > 0) {
      let dat = {};
      let selVal = {};
      Object.keys(sodExportData).forEach(element => {
        // dat[element] = Object.values(sodExportData[element]);
        dat[element] = sodExportData[element].available;
      selVal[element] = sodExportData[element].selected;
    });
  setCompleteData(dat);
  setSelectedValues(selVal);
}
  }, [sodExportData]);

return (
  <Dialog
    open={open}
    onClose={onClose}
    TransitionComponent={Transition}
    disableBackdropClick
    classes={{ paperWidthSm: classes.paperWidthSm }}
  >
    <DialogTitle id="customized-dialog-title" onClose={onClose} />
    <DialogContent dividers>
      {Object.keys(completeData).map(item => {
        const label = rowMappingKey[item].label;
        const options = completeData[item].map(itm => {
          return { label: itm, value: itm };
        });
        const defaultValues = selectedValues[item].map(itm => {
          return { label: itm, value: itm };
        });
        return (
          <div className="export-content-layout">
            <div className="export-content-label">{label}</div>
            <MultiSelectWithSelectAll
              defaultValues={defaultValues}
              options={options}
              itemkey={item}
              setSelectedValues={setSelectedValues}
              selectedValues={selectedValues}
              selectAllLabel={intl.formatMessage({
                id: 'SODExport.SelectAll.label',
              })}
            />
          </div>
        );
      })}
    </DialogContent>
    <DialogActions>
      <Button
        variant="outlined"
        color="primary"
        className={clsx(classes.outlinedPrimary, classes.button, classes.outlined)}
        onClick={onClose}
      >
        {`${intl.formatMessage({
          id: 'SOD.Cancel.label',
        })}`}
      </Button>
      <Button
        variant="contained"
        color="primary"
        className={clsx(classes.outlinedPrimary, classes.button, classes.outlined)}
        onClick={() => {
          downLoadSODReport(selectedValues, rowMappingKey);
          onClose();
        }}
      >
        {`${intl.formatMessage({
          id: 'SOD.Export.label',
        })}`}
      </Button>
    </DialogActions>
  </Dialog>
);
};

ExportColumnModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  intl: PropTypes.shape({
    locale: PropTypes.string.isRequired,
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
};

export default injectIntl(ExportColumnModal);
