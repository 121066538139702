import React, { useEffect } from 'react';
import { injectIntl } from "react-intl";

const Control = props => {
    useEffect(() => {
    const scripts = [
        {
          src: '/ECMv6/api/rdpgateway/guacamole-common-js/all.js',
        },
        {
          src: '/ECMv6/api/rdpgateway/playback.js',
        },
        {
          src: '/ECMv6/api/sshgateway/saviyntconnect/emulator/hterm_all.js',
        },
        {
          src: '/ECMv6/api/sshgateway/saviyntconnect/emulator/socket.io/socket.io.js',
        },
        {
          src: '/ECMv6/api/sshgateway/saviyntconnect/emulator/live-v2.js',
        },
      ];
      scripts.forEach(data => {
        const script1 = document.createElement('script');
        script1.src = data.src;
        script1.async = true;
        document.body.appendChild(script1);
      });
    }, []);
    return(<></>);
}
export default injectIntl(Control);
