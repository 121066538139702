import React from 'react';
import get from 'lodash/get';
import { Icon, ToolTipModal } from 'ui-components/src';
import './SaviyntGrid.scss';

function getGridCell({row, column, tableId, dataIndex, columnIndex }) {
  let tabIndex = -1;
  if (column.isFixedColumn && (tableId === 'fixedTable' || tableId === 'fixedTable2') && columnIndex<4) tabIndex = dataIndex + 2;
  if (!column.isFixedColumn && tableId === 'movingTable') tabIndex = dataIndex + 2;

  const cellValue = get(row, column.viewKey);
  //if user gives html template function , example action buttons html
  if (column.htmlTemplate) {
    // return column.htmlTemplate(row, cellValue, tableId);
    return column.htmlTemplate({row, cellValue, tableId, tabIndex});
  }
  if (Array.isArray(cellValue) || typeof cellValue !== 'object') {
    // if(cellValue) return cellValue.substring(0,column.maxLength) //  to remove material tooltips. TODO
    if (column.popupTemplate && column.visualTemplate) {
      return (
        //tabindex on popup html should be -1 initially.. when the popup is visible, tabindex should be added.
        <ToolTipModal
          classes={column.popupClasses || ''}
          popup={column.popupTemplate({cellValue, row, tabIndex:-1})}
        >
          {setShow => {
            return column.visualTemplate({cellValue, setShow, row,tabIndex, tableId});
          }}
        </ToolTipModal>
      );
    }

    if (cellValue && typeof cellValue === 'string' && cellValue.length > column.maxLength)
      return (
        <ToolTipModal
          popup={
            <>
              <div class="pl-2 py-1 pr-1 tipText-title">{column.label}</div>
              <div class="pl-2 py-1 pr-1 tooltext">{cellValue}</div>
            </>
          }
          classes={column.popupClasses || 'absolute'}
          showOnHover
        >
          {() => <span>{cellValue.substring(0, column.maxLength) + '...'}</span>}
        </ToolTipModal>
      );
  }
  if (cellValue && typeof cellValue === 'object' && cellValue.errorText) {
    if (cellValue.value) {
      return (
        <div className="errorText">
          <Icon name="gridError" />
          {cellValue.value}
          <span className="errorPopup popupButtom">{cellValue.errorText}</span>
        </div>
      );
    }
    return (
      <div className="errorText">
        {' '}
        <Icon name="gridError" />
        {cellValue.errorText}
      </div>
    );
  }

  return cellValue;
}
function getViewMoreLayout(label,intl) {
  return {
    popupTemplate: ({cellValue}) => {
      let items = cellValue;
      if (!items) return;
      else if (typeof items === 'string') {
        items = items.split(',');
      }
      if (items.length > 1) {
        return (
          <div>
            <div className="pl-2 py-1 pr-1 tipText-title">{label}</div>
            <div className="tooltip-scroll pb-1 pt-1">
              {items.map(a => (
                <div className="pr-1 pl-2 mb-1">{a}</div>
              ))}
            </div>
          </div>
        );
      } else
        return (
          <>
            <div className="pl-2 py-1 pr-1 tipText-title">{label}</div>
            <div className="pl-2 py-1 pr-1">{cellValue}</div>
          </>
        );
    },
    visualTemplate: ({ cellValue, setShow, tableId, tabIndex }) => {
      let items = cellValue;
      if (!cellValue) return;

      else if (typeof items === 'string') {
        items = items.split(',');
      }
      if (items.length > 1) {
        return (
          <div >
            <span>{items[0]}</span>
            <span
            tabIndex={tableId === 'movingTable' ? tabIndex : 0}
              className="text-blue-700 cursor-pointer txt-width"
              onClick={e => {
                const popupPlacement = window.innerHeight - e.clientY > 150 ? "below" : "above";
                const top = popupPlacement === "above" ? e.clientY - 155 : e.clientY + 15;
                setShow(
                  {
                    visible: true,
                    position: {
                      top,
                      left: e.clientX - 20,
                    },
                    showOnHover: false,
                    anchorPosition: "popupPosition" + popupPlacement
                  }
                )
              }
              }
            >
              {' '}...{items.length - 1} {intl.formatMessage({ id: 'Grid.view.More' })}
            </span>
          </div>
        );
      } else {
        return (
          <div>
            <span>{items[0]}</span>
          </div>
        );
      }
    },
  };
}
export { getGridCell, getViewMoreLayout };
