/* eslint-disable new-cap */
import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { SaviyntGrid } from '../Grid/SaviyntGrid';
import moment from 'moment';
import InputAdornment from '@material-ui/core/InputAdornment';
import NotesIcon from '@material-ui/icons/Notes';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import styles from './MitigatingControlDialog.module.scss';
import { SaviyntRangePicker } from '../SaviyntRangePicker';
import {
  ToggleButtonComponent,
} from 'ui-components/src';

const cols = [
  {
    viewKey: 'mitigatingControl',
    key: 'mitigatingControl',
    label: 'Mitigating Control Name',
    maxLength: 35,
  },
  {
    viewKey: 'mitigatingControlDescription',
    key: 'mitigatingControlDescription',
    label: 'Description',
  },
  {
    viewKey: 'requestButton',
    key: 'requestButton',
    label: '',
  },
];

const style = theme => ({
  root: {
    margin: 0,
    padding: '8px 16px',
  },
  closeButton: {
    position: 'absolute',
    right: 13,
    padding: '5px',
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(style)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const MitigatingControlDialog = ({
  open,
  onClose,
  mitigationData,
  addMitigatingCtrl,
  risk,
  intl,
  ...props
}) => {
  const [data, setData] = useState([]);
  const [selectedDateRange, setSelectedDateRange] = useState({ fromDate: null, endDate: null });
  const [selectedMitiCtrl, setSelectedMitiCtrl] = useState(null);
  const fromDateTime = new moment();
  const toDateTime = new moment();
  const [gridLoading, setGridLoading] = useState(true);
  const [value, setToggleValue] = useState('All');
  const instanceFromDate = fromDateTime.clone().set({
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  const options = [
    {
      value: 'RECOMMENDED',
      label: intl.formatMessage({ id: 'ApplicationCart.Recommended' }),
    },
    { value: 'All', label: intl.formatMessage({ id: 'ApplicationCart.All' }) },
  ];


  const onToggleChange = value => {
    setToggleValue(value);
    setGridLoading(true);
    props.fetchAvailableMC(value === 'All' ? '0' : '1')
  };

  const addkeysToRow = row => {
    // if (selectedMitiCtrl && row.mitigationSelected) {
    //   setSelectedMitiCtrl(row.id);
    // }
    const requestButton = (
      <div className="more-options">
        <div className={styles.selectBtn}>
          <Button
            variant="outlined"
            color="primary"
            classes={{ outlined: styles.outlined }}
            onClick={() => setSelectedMitiCtrl(row)}
          >
            {(selectedMitiCtrl && selectedMitiCtrl.id === row.id) || row.mitigationSelected ? 'Selected' : 'Select'}
          </Button>
        </div>
      </div>
    );

    return { ...row, requestButton };
  };

  useEffect(() => {
    if (mitigationData) {
      setGridLoading(false);
      const dat = mitigationData.map(row => addkeysToRow(row));
      setData(dat);
    }
  }, [mitigationData]);

  useEffect(() => {
    setGridLoading(true);
  }, []);

  useEffect(() => {
    if (selectedMitiCtrl) {
      const dat = mitigationData.map(row => addkeysToRow(row));
      setData(dat);
    }
  }, [selectedMitiCtrl]);

  const instanceFromTime = {
    hours: fromDateTime.get('hours'),
    minutes: fromDateTime.get('minutes'),
    seconds: fromDateTime.get('seconds'),
  };
  const instanceToDate = toDateTime.clone().set({
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  const instanceToTime = {
    hours: toDateTime.get('hours'),
    minutes: toDateTime.get('minutes'),
    seconds: toDateTime.get('seconds'),
  };

  return (
    <>
      <Dialog
        classes={{ paperScrollPaper: styles.DialogFullHeight }}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={() => {
            onClose(false);
          }}
        >
          <div className={styles.dialogTitle}>
            <div> {intl.formatMessage({ id: 'ReqHist.SOD.Mitigation.MitigatingControl' })}</div>
            <div className={styles.dialogSubTitle1}>
              {' '}
              for Risk <span className={styles.dialogSubTitle2}>{risk.Risk}</span>
            </div>
          </div>
        </DialogTitle>
        <DialogContent dividers className={styles.contentMitigating}>
          <div className={styles.mitigatingDialog}>
            <h6 className={styles.mitigatingSelectHead}>
              {' '}
              {intl.formatMessage({ id: 'ReqHist.SOD.Mitigation.selectcontrol' })}
            </h6>
            <div className={`${styles.allbuttonLayout}`}>
              <ToggleButtonComponent
                options={options}
                defaultValue={value}
                onToggleChange={onToggleChange}
                colorOverride
              />
            </div>
            <div className={styles.listMitigating}>
              <SaviyntGrid
                columns={cols}
                data={data}
                setAppliedFilters={() => { }}
                appliedFilters={{}}
                reqBodyOptions={{}}
                setReqBodyOptions={() => { }}
                topOffset={-30}
                hideRearrangeColumn
                isLoading={gridLoading}
              />
            </div>
            <h6 className={styles.mitigatingHead}>
              {' '}
              {intl.formatMessage({ id: 'ReqHist.SOD.Mitigation.timeframe' })}
            </h6>
            <SaviyntRangePicker
              instanceFromDate={instanceFromDate}
              instanceToDate={instanceToDate}
              instanceFromTime={instanceFromTime}
              instanceToTime={instanceToTime}
              dateFormat="MMM DD, YYYY"
              disabledFromDateTime
              showErrorMessage={false}
              onChange={(range) => {
                setSelectedDateRange({
                  fromDate: range[0],
                  endDate: range[1],
                });
              }}
            />
          </div>
        </DialogContent>
        <DialogActions className={styles.mitigatingFooter}>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              onClose(false);
            }}
          >
            {intl.formatMessage({ id: 'ApplicationClose' })}
          </Button>
          <Button
            variant="outlined"
            color="primary"
            disabled={selectedMitiCtrl === null}
            onClick={() => {
              addMitigatingCtrl(selectedMitiCtrl, risk.exceptionKey, selectedDateRange, "Add", props.sodSingle);
              onClose(false);
            }}
          >
            {intl.formatMessage({ id: 'Submit' })}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

MitigatingControlDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  mitigationData: PropTypes.arrayOf(PropTypes.shape({ MitigatingControl: PropTypes.string }))
    .isRequired,
  onClose: PropTypes.func.isRequired,
  intl: PropTypes.arrayOf(
    PropTypes.shape({
      locale: PropTypes.string.isRequired,
      formatMessage: PropTypes.func.isRequired,
    })
  ).isRequired,
  addMitigatingCtrl: PropTypes.func.isRequired,
  risk: PropTypes.shape({
    Risk: PropTypes.string,
    selectedMitigationDetails: PropTypes.shape({ MitigatingControl: PropTypes.string })
  }).isRequired,
};

export default injectIntl(MitigatingControlDialog);

