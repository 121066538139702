import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import useOnClickOutside from 'use-onclickoutside';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import * as qs from 'query-string';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import NotesIcon from '@material-ui/icons/Notes';
import { withStyles } from '@material-ui/core/styles';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { CloseButton } from '../CustomButtons';
import { FullHeightDialog } from '../FullScreen';
import { Icon } from '../Icon';
import SlotComponent from './SlotComponent';
import { IDAssignment } from '../IDAssignment';
import './SAPReserveSlot.scss';
import 'react-dropdown/style.css';

const SAPReserveSlot = props => {
  const [businessJustification, setBusinessJustification] = useState(
    props.data.businessJustification
  );
  const [isIdRequestable, setIsIdRequestable] = useState(
    props.editMode
      ? props.data.isIdRequestable
      : props.data.endDate === null ||
          props.data.endDate === undefined ||
          props.data.endDate === '' ||
          moment().isAfter(moment(moment.utc(props.data.endDate)).local())
  );

  const wrapperRef = useRef(null);
  const fromDateTime = props.editMode
    ? moment(props.data.startTime, 'MMM DD, YYYY h:mm a')
    : moment();
  const toDateTime = props.editMode
    ? moment(props.data.endTime, 'MMM DD, YYYY h:mm a')
    : moment().add(1, 'days');
  const [selectedTime, setSelectedTime] = useState({ startTime: '', endTime: '', timeDiff: '' });
  const [resetFlag, setResetFlag] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const disableProgress = disabledState => {
    setDisabled(disabledState);
  };

  const instanceFromDate = fromDateTime.clone().set({
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  const instanceToDate = toDateTime.clone().set({
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  const [requestableDates, setRequestableDates] = useState({
    startDate: instanceFromDate,
    endDate: instanceToDate,
  });
  const parsed = qs.parse(props.location.search);

  useOnClickOutside(wrapperRef, () => {});

  useEffect(() => {
    if (props.editMode) {
      setIsIdRequestable(props.data.isIdRequestable);
    } else if (
      props.data.endDate === null ||
      props.data.endDate === undefined ||
      props.data.endDate === '' ||
      moment().isAfter(moment(moment.utc(props.data.endDate)).local())
    ) {
      setIsIdRequestable(true);
    }
  }, []);

  // useEffect(() => {
  //   debugger;
  //   console.log(selectedTime);
  // }, [selectedTime]);

  const MuiDialogTitle = withStyles({
    root: {
      margin: 0,
      padding: 0,
    },
  })(DialogTitle);
  const MuiDialogContent = withStyles({
    root: {
      margin: 0,
      padding: 0,
    },
  })(DialogContent);

  return (
    <div className="sap-reserve-slot-container">
      <FullHeightDialog open onClose={() => {}} aria-labelledby="customized-dialog-title">
        <MuiDialogTitle disableTypography>
          <div className="top-navigation d-flex justify-content-between align-items-center">
            <div className="go-back d-flex align-items-center" onClick={() => {}}>
              <Icon name="arrowLeftAlt" />
              <span>Back to ID list</span>
            </div>
            <CloseButton handleClose={props.setSessionDetModal} />
          </div>
          <div className="slot-header">
            <span>
              {props.intl.formatMessage({
                id: 'SlotReservationTitle',
              })}
            </span>
            <p className="priv-role-access-subtitle">
              Request for Emergency ID for{' '}
              <b>
                {parsed.name} - {parsed.entitlement} System
              </b>
            </p>
          </div>
        </MuiDialogTitle>
        <MuiDialogContent>
          <div
            className="timeframe"
            style={isIdRequestable ? { display: 'block' } : { display: 'block' }}
          >
            {/* <div className="timeframe-title">
                  {' '}
                  {props.intl.formatMessage({
                    id: 'TimeFrameIDAssignSelect',
                  })}
                </div>
                <div className="timeframe-subtitle">
                  {props.intl.formatMessage({
                    id: 'TimeFrameSubTitle',
                  })}
                </div> */}

            {/* <SaviyntRangePicker
                  instanceFromDate={instanceFromDate}
                  instanceToDate={instanceToDate}
                  instanceFromTime={instanceFromTime}
                  instanceToTime={instanceToTime}
                  dateFormat="MMM DD, YYYY"
                  onChange={range => {
                    setRequestableDates({ startDate: range[0], endDate: range[1] });
                    // setToDateTime(range[1]);
                  }}
                /> */}
            {isIdRequestable ? (
              <IDAssignment
                instance={props.data}
                setRequestableDates={setRequestableDates}
                from="slot"
                idAssignmentWidth="100%"
                disableProgress={disableProgress}
              />
            ) : (
              <>
                <div className="id-assignment-title">Your ID Assignment is valid with in</div>
                <div className="id-assignment-value">
                  {moment(props.data.startDate).format('MMM DD YYYY')} -{' '}
                  {moment(props.data.endDate).format('MMM DD YYYY')}
                </div>
              </>
            )}
          </div>
          <div
            className="sap-slot-title"
            style={!isIdRequestable ? { display: 'block' } : { display: 'none' }}
          >
            {' '}
            {props.intl.formatMessage({
              id: 'ReserveSlotMessage',
            })}
          </div>
          <div
            className="sap-slot-subtitle"
            style={!isIdRequestable ? { display: 'block' } : { display: 'none' }}
          >
            {props.intl.formatMessage({
              id: 'ReserveSLotSubTitle',
            })}
          </div>
          {!isIdRequestable ? (
            <>
              <SlotComponent
                {...props}
                isIdRequestable={isIdRequestable}
                setSelectedTime={setSelectedTime}
                requireDisabledSlots={!isIdRequestable}
                setResetFlag={setResetFlag}
                reset={resetFlag}
                selectedTime={selectedTime}
              />

              <TextField
                label={props.intl.formatMessage({
                  id: 'quickRequest.BusinessJustification',
                })}
                multiline
                // rows="2"
                // rowsMax="6"
                margin="none"
                variant="outlined"
                value={businessJustification}
                InputLabelProps={{
                  // style: {
                  //   fontSize: 20,
                  //   background: '#ffffff',
                  //   color: '#000000',
                  //   fontWeight: 500,
                  // },
                  shrink: true,
                }}
                InputProps={{
                  style: { height: 40, fontSize: 11 },
                  startAdornment: (
                    <InputAdornment position="start">
                      <NotesIcon />
                    </InputAdornment>
                  ),
                }}
                className="form-control sap-slot-textarea"
                onBlur={e => {
                  setBusinessJustification(e.target.value);
                }}
              />
            </>
          ) : null}
        </MuiDialogContent>

        <div className="slot-footer">
          <button
            disabled={disabled}
            // disabled={!isIdRequestable ? selectedTime.startTime === '' : false}
            // className={
            //   !isIdRequestable
            //     ? selectedTime.startTime !== ''
            //       ? 'btn btn-primary btn btn-ingrid'
            //       : 'btn btn-ingrid btn-not-selected'
            //     : 'btn btn-primary btn btn-ingrid'
            // }
            className="btn btn-primary btn btn-ingrid"
            type="submit"
            onClick={() => {
              props.onDone(
                {
                  businessJustification,
                  startTime: isIdRequestable
                    ? requestableDates.startDate
                    : `${selectedTime.startTime}`, // moment(props.data.startDate),
                  endTime: isIdRequestable ? requestableDates.endDate : `${selectedTime.endTime}`, // moment(props.data.endDate),
                  // startTime: `${selectedTime.startTime}`,
                  // endTime: `${selectedTime.endTime}`,
                  checkoutid: !isIdRequestable,
                },
                props.index
              );
              props.setSessionDetModal();
            }}
          >
            {props.intl.formatMessage({
              id: 'confirmSelection',
            })}
          </button>

          <button
            className="btn btn-ingrid btn-not-selected cancel-margin"
            type="button"
            onClick={() => props.setSessionDetModal()}
          >
            {props.intl.formatMessage({
              id: 'advancefilter.label.Cancel',
            })}
          </button>
        </div>
      </FullHeightDialog>
    </div>
  );
};
SAPReserveSlot.defaultProps = {
  detailData: [],
  data: {},
  index: 0,
  fromCreaterequest: false,
};
SAPReserveSlot.propTypes = {
  intl: PropTypes.arrayOf(
    PropTypes.shape({
      locale: PropTypes.string.isRequired,
      formatMessage: PropTypes.func.isRequired,
    })
  ).isRequired,
  setSessionDetModal: PropTypes.func.isRequired,
  onDone: PropTypes.func.isRequired,
  detailData: PropTypes.arrayOf(
    PropTypes.shape({
      startDate: PropTypes.string,
      enddate: PropTypes.string,
    })
  ),
  data: PropTypes.shape({
    timeFrame: PropTypes.string,
    startTime: PropTypes.string,
    endTime: PropTypes.string,
    businessJustification: PropTypes.string,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    isIdRequestable: PropTypes.bool,
  }),
  editMode: PropTypes.string.isRequired,
  index: PropTypes.number,
  location: PropTypes.shape({
    search: PropTypes.func.isRequired,
  }).isRequired,
  fromCreaterequest: PropTypes.bool,
};
export default injectIntl(withRouter(SAPReserveSlot));
