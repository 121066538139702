import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './Loading.scss';

const Loading = props => {
  if (props.isLoading) {
    return (
      <div className="loading-container">
        <div className="loading-bg" />
        <div id="preview-area">
          <div className="spinner">
            <div className="bounce1" />
            <div className="bounce2" />
            <div className="bounce3" />
          </div>
        </div>
      </div>
    );
  }

  return <div />;
};

const mapStateToProps = state => ({
  isLoading: state.loader.isLoading,
});

Loading.propTypes = {
  isLoading: PropTypes.bool.isRequired,
};

const ConnectedLoadingHandling = connect(mapStateToProps)(Loading);

export default ConnectedLoadingHandling;
