import { shape, string, func } from 'prop-types';

export default null;
export const ColumnType = shape({
  key: string.isRequired,
  viewKey: string,
  label: string,
  filter: shape({
    filterURL: string,
    type: string,
    filterFn: func,
  }),
});
export const DefaultColumnType = {
  key: '1',
};
