import React, { useEffect, useRef} from 'react';
// eslint-disable-next-line import/extensions
import { A } from '../../A';
// eslint-disable-next-line import/extensions
import { EllipsedLabel } from '../../EllipsedLabel';
import styles from '../GridBoot.module.scss';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { PropTypes } from 'prop-types';
import { filterObj } from 'ui-common/src/lib/utils';
import { ColumnType } from 'ui-common/src/lib/commonPropTypes';
import { injectIntl } from 'react-intl';
import './UniqueFieldFilter.scss';
import { IconButton } from '@material-ui/core';




const StringFilter = ({
  column,
  tableId,
  appliedFilters,
  setAppliedFilters,
  checkedFilters,
  setCheckedFilters,
  showColumnFilter,
  setShowColumnFilter,
  sortedColumn,
  setSortedColumn,
  filteredDistinctColumnValues,
  setFilteredDistinctColumnValues,
  distinctColumnValues,
  intl,
  requestColCacheEnabled,
}) => {
  // realKey functionality shud be added.
  const { key } = column;

  const filterRef = useRef();
  const hideFilterOnFocusOut = e => {
    setTimeout(() => {
      if (filterRef.current === e.target || filterRef.current.contains(document.activeElement)) {
        //still in filter popup.
      } else {
        setShowColumnFilter({ filterId: 'none' });
      }
    }, 100);
  };
  useEffect(()=>{
    const {x:filterLeft, width: filterWidth} = filterRef.current.getBoundingClientRect();
    const fixedTable2Start = document.querySelector('.fixedTable2').getBoundingClientRect().x;
    const tabindex1ElDimentions = document.querySelector('.divTable .divTableHead .tabbable[tabindex="1"]').getBoundingClientRect();
    const top = tabindex1ElDimentions.y+30;
    filterRef.current.style.top = top+'px';
    if(filterLeft+filterWidth>fixedTable2Start){
      filterRef.current.style.left = fixedTable2Start-(filterWidth) + 'px';
    }
  },[])

  return (
    <div
      ref={filterRef}
      tabIndex="1"
      className={`columnFilterBox ${key === showColumnFilter.filterId ? 'd-show' : 'd-none'} fixed`} 
      onBlur={hideFilterOnFocusOut}
    >
      <div className={column.sortDisabled ? "d-none" : "sorting-btn"}>
        <button
          type="submit"
          className={
            sortedColumn.columnName === key && sortedColumn.order === 'asc'
              ? 'sortingBtn active'
              : 'sortingBtn'
          }
          onClick={() =>
            setSortedColumn({
              columnName: column.key,
              order: 'asc',
            })
          }
          tabIndex="1"
        >
          {/* {intl.formatMessage({ id: 'DateFilter.A-Z' })} */}
          {column.sortButtonLatest ? intl.formatMessage({ id: 'Sorting.Oldest' }) : intl.formatMessage({ id: 'DateFilter.A-Z' })}


        </button>
        <button
        tabIndex="1"
          type="submit"
          className={
            sortedColumn.columnName === key && sortedColumn.order === 'desc'
              ? 'sortingBtn active'
              : 'sortingBtn'
          }
          onClick={() =>
            setSortedColumn({
              columnName: column.key,
              order: 'desc',
            })
          }
        >
          {/* {intl.formatMessage({ id: 'DateFilter.Z-A' })} */}
          {column.sortButtonLatest ? intl.formatMessage({ id: 'Sorting.Latest' }) : intl.formatMessage({ id: 'DateFilter.Z-A' })}


        </button>
      </div>
      <input
      tabIndex="1"
        type="text"
        className="form-control ml-2"
        placeholder={intl.formatMessage({ id: 'DateFilter.Filter' })}
        onChange={e => {
          // if (e.target.value.length > 1) {
          setFilteredDistinctColumnValues({
            ...filteredDistinctColumnValues,
            [key]: filterObj(distinctColumnValues[key], e.target.value),
          });
          // }
        }}
      />
      {showColumnFilter.isLoading && (<div className="d-flex justify-center"><img src="/ECMv6/assets/images/Double Ring-1s-80px.gif" /></div>)}
      { showColumnFilter.filterId !== 'none' && !showColumnFilter.isLoading && (<form action="">
        <ul className="col-filter-list">
          {filteredDistinctColumnValues[key] && // let distinctField = value.length > 0 ? value : "Empty";
            Object.keys(filteredDistinctColumnValues[key]).map(distinctField => (
              <li key={distinctField}>
                <FormControlLabel
                  control={
                    <IconButton>
                    <input
                    type="checkbox"
                    tabIndex="1"
                    className="stringCheckBox"
                      checked={
                        checkedFilters[column.key]
                          ? checkedFilters[column.key].includes(distinctField)
                          : false
                      }
                      onChange={e => {
                        if (e.target.checked) {
                          setCheckedFilters({
                            ...checkedFilters,
                            [key]: checkedFilters[key]
                              ? [...checkedFilters[key], distinctField]
                              : [distinctField],
                          });
                        } else {
                          setCheckedFilters({
                            ...checkedFilters,
                            [key]: checkedFilters[key]
                              ? checkedFilters[key].filter(filter => filter !== distinctField)
                              : [],
                          });
                        }
                      }}
                      color="primary"
                      onKeyPress={e => {
                        if (e.key === 'Enter') e.target.click();
                      }}
                      onFocusVisible={e => e.target.parentNode.style.outline = 'var(--primary-main) solid'}
                      onBlur={e => e.target.parentNode.style.outline = 'none'}
                      tabIndex="0"
                    />
                    </IconButton>
                  }
                  label={
                    distinctField.length > 0 ? (
                      <EllipsedLabel label={distinctField} maxLength={20} />
                    ) : (
                        '(No Value)'
                      )
                  }
                />

                {(!column.isCacheEnabled || !requestColCacheEnabled) && <span className=" pull-right count mt-2 pt-1">
                  {distinctColumnValues[column.key][distinctField]}
                </span>}
              </li>
            ))}
        </ul>
        <div className={`${"action "}${styles.actionmargin}`}>
          <A
          tabIndex="1"
            href="#"
            className="btn-reset"
            onClick={e => {
              e.preventDefault();
              setAppliedFilters({ ...checkedFilters, [column.key]: [] });
              setCheckedFilters({ ...checkedFilters, [column.key]: [] });
              if (column.key === sortedColumn.columnName) {
                setSortedColumn({ order: 'asc' });
              }
            }}
          >
            {intl.formatMessage({ id: 'DateFilter.Reset' })}
          </A>
          <button
            tabIndex="1"
            type="button"
            className={` ${styles.applymargin}`}
            onClick={e => {
              e.preventDefault();
              setAppliedFilters({ ...appliedFilters, ...checkedFilters });
              setShowColumnFilter({ filterId: 'none' });
            }}
          >
            {intl.formatMessage({ id: 'DateFilter.Apply' })}
          </button>
        </div>
      </form>
      )}
    </div>
  );
};

StringFilter.propTypes = {
  column: ColumnType.isRequired,
  showColumnFilter: PropTypes.func.isRequired,
  setShowColumnFilter: PropTypes.func.isRequired,
  sortedColumn: PropTypes.shape({
    columnName: PropTypes.string,
    order: PropTypes.string,
  }).isRequired,
  setSortedColumn: PropTypes.func.isRequired,
  distinctColumnValues: PropTypes.shape({
    key: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  checkedFilters: PropTypes.shape({
    key: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  setCheckedFilters: PropTypes.func.isRequired,
  appliedFilters: PropTypes.shape({
    key: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  setAppliedFilters: PropTypes.func.isRequired,
  filteredDistinctColumnValues: PropTypes.arrayOf.isRequired,
  setFilteredDistinctColumnValues: PropTypes.func.isRequired,
};
// eslint-disable-next-line import/prefer-default-export

const StringFilterIntl = injectIntl(StringFilter);

export { StringFilterIntl as StringFilter };
