import React,{useRef, useEffect} from 'react';
import useOnClickOutside from 'use-onclickoutside';
import {throttle} from 'lodash'
import styles from './ToolTipModal.module.scss';

const ToolTipModal = ({  popup, classes, showOnHover=false, children}) => {
    const modelRef = useRef(null);
    const contentRef = useRef(null);
    let showOnHoverEnabled = false;
    let isClicked = false;
    useOnClickOutside(modelRef,()=> setShow({visible:false}));

    const hideToolTip = throttle(() => {
            setShow({ visible: false })
       
    }, 2500,{leading:true});
    useEffect(() => {
                      // this will return html but we dont need it. Its needed to be called to check if showOnHoverEnabled is set.
                      children(setShow);
                    }, []);
    const setShow = ({ visible, position, showOnHover,anchorPosition }) => {
      const scrollBar  = document.querySelector('.noContentScroll');
      
        if (showOnHover) {
            showOnHoverEnabled = true;
            return;
        }        
        if (visible === true) {
            isClicked = true;
            modelRef.current.classList.remove('invisible');
            modelRef.current.classList.add('visible');
            modelRef.current.classList.add(anchorPosition);
            contentRef.current.classList.add('modalClicked');
            window.addEventListener('scroll', hideToolTip);
            scrollBar.addEventListener('scroll',hideToolTip);

        } else {
            scrollBar.removeEventListener('scroll',hideToolTip);
            window.removeEventListener('scroll', hideToolTip);
           if(modelRef.current.classList.contains('visible')){ 
             modelRef.current.classList.remove('visible');
            modelRef.current.classList.add('invisible');
            modelRef.current.classList.remove(anchorPosition);
            contentRef.current.classList.remove('modalClicked');
          }
        }
        if (position) {
            modelRef.current.style.top = position.top+'px';
            modelRef.current.style.left = position.left+'px';
        }
    }
    children(setShow); 
    return (
      <div className={showOnHover || showOnHoverEnabled ? styles.tooltip : ''}>
        <div ref={contentRef}>{children(setShow)}</div>
        <div
          ref={modelRef}
          className={`z-50 bg-white shadow-xl rounded-sm invisible fixed ${classes} ${styles.popupPositionbelow}   ${showOnHover || showOnHoverEnabled ? styles.popup : ''}`}
        >
          {popup}
        </div>
      </div>
    );
};

export default ToolTipModal;
