import { call, put } from 'redux-saga/effects';
import { Cookies } from 'react-cookie';
import { checkEnvironment } from 'ui-common/src/lib/utils';
import { refreshKPI } from './kpiForm-actions';
const cookies = new Cookies();

export function* updateKpiFormCode(action) {
  const IDW_ENDPOINT = checkEnvironment().IDWMS_API_URL;
  const url = `${IDW_ENDPOINT}/res/content`;
  const response = yield call(fetch, url, {
    method: 'POST',
    headers: {
      Authorization: `${cookies.get('token_type')} ${cookies.get('access_token')}`,
      'Content-Type': 'application/json',
      'X-NOLOADER': true,
    },
    body: JSON.stringify(action.payload),
  });
     if (response && response.status === 200 && action.refreshKPI) {
        yield put(refreshKPI());
      }
}
export function* updateKpiAttributes(action) {
  const IDW_ENDPOINT = checkEnvironment().IDWMS_API_URL;
  const url = `${IDW_ENDPOINT}/kpi/${action.payload.id}`;
  const response = yield call(fetch, url, {
    method: 'PUT',
    headers: {
      Authorization: `${cookies.get('token_type')} ${cookies.get('access_token')}`,
      'Content-Type': 'application/json',
      'X-NOLOADER': true,
    },
    body: JSON.stringify(action.payload),
  });
  if (response && response.status === 200) {
    yield put(refreshKPI());
  }
}

export function* newKpiAttributes(action) {
  const IDW_ENDPOINT = checkEnvironment().IDWMS_API_URL;
  const url = `${IDW_ENDPOINT}/kpi`;
  const response = yield call(fetch, url, {
    method: 'POST',
    headers: {
      Authorization: `${cookies.get('token_type')} ${cookies.get('access_token')}`,
      'Content-Type': 'application/json',
      'X-NOLOADER': true,
    },
    body: JSON.stringify(action.payload),
  });
  if (response && response.status === 200) {
    yield put(refreshKPI());
  }
}
